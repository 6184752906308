import { useContext, useEffect, useState } from 'react'
import useGraphqlRequest from '../../../../projects/SendRequestCalculess';
import { projectQuery } from '../../../../projects/graphql-queries/Projects.query';
import { UserContext } from '../../../../../../routing/IndexRouting';
import useApiRequest from '../../../../../hooks/useApiRequest';

const UseConfigureProjectsAndInterventionsHook = () => {
    const { userDetails } = useContext(UserContext);
    const [projects, setProjects] = useState(null);
    const [interventions, setInterventions] = useState(null)

    const [{ status, response }, makeRequest] = useApiRequest(`/interventionschemev2`, {
        verb: 'get',
    });
    if (status === null) makeRequest();


    useEffect(async () => {
        if (userDetails && userDetails._id) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            setProjects((await useGraphqlRequest(projectQuery(userDetails._id))).data?.getProjectsbyUserID?.otherProjects);
        }
    }, [userDetails]);


    useEffect(() => {
        if (response) setInterventions(response?.data?.data?.data)
    }, [response]);

    return { projects, interventions }
}

export default UseConfigureProjectsAndInterventionsHook