import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../../utils/Defaults';
import { usePrevious } from '../../../../../../hooks/useCategoryDetailsData';

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: '5%',
  },
  appliedfiltertitle: MarketplaceTypography.BodySamiBold,
  clearbuttonstyle: MarketplaceTypography.ButtonCaps,
  selectedfilterbutton: {
    color: MarketplaceColors.Secondary,
    border: `1px solid ${MarketplaceColors.Secondary}`,
    width: 'auto',
    height: 'auto',
    // maxHeight: '20px',
    borderRadius: '20px',
    margin: 2,
    padding: 0,
    paddingLeft: 3,
    paddingRight: 3,
  },
  cleariconstyle: {
    color: MarketplaceColors.TextColorPrimary,
    paddingLeft: '5px',
  },
  clearbuttonText: MarketplaceTypography.ChipCapsSmall,
}));

const SidebarAppliedFilters = (props) => {
  const classes = useStyles();
  const { search } = useLocation();
  let history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(search);
  const [source, setSource] = useState(query.get('source'));
  const [category, setCategory] = useState(query.get('category'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  const [page, setpage] = useState(query.get('page'));
  const [pagesize, setpagesize] = useState(query.get('pagesize'));
  const previousLocation = usePrevious(location);
  const { handleFilterData, appliedfilters, handleAppliedFilters, setFiltersData, setAppliedFilters } = useContext(ProductsPageContext);
  // const { category, supercategory, source, pagesize, page } = URLS;
  const mappingID = query.get('mappingID')

  useEffect(() => {
    if (previousLocation !== location) {
      const source = query.get('source');
      setSource(source);
      setCategory(query.get('category'));
      setSuperCategory(query.get('supercategory'));
      setpage(query.get('page'));
      setpagesize(query.get('pagesize'));
    }
  }, [previousLocation, location]);

  const handleClearAll = (source, category, supercategory) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFiltersData([]);
    setAppliedFilters([]);
    if (source === NavigatorSource.categories)
      history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${page}&pagesize=${pagesize}`);
    else if (source === NavigatorSource.supercategories)
      history.push(`/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${page}&pagesize=${pagesize}`);
    else if (source === NavigatorSource.ecolabels) history.push(`/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}`);
    else if (source === NavigatorSource.intervention) history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${page}&pagesize=${pagesize}&sort=default&mappingID=${mappingID}`);
  };

  const handleClearicon = (id, value, checked) => {
    handleAppliedFilters(id, value, checked);
    handleFilterData(id, value, checked);
    if (appliedfilters.length === 1) {
      if (source === NavigatorSource.categories)
        history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${page}&pagesize=${pagesize}`);
      else if (source === NavigatorSource.supercategories)
        history.push(`/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${page}&pagesize=${pagesize}`);
      else if (source === NavigatorSource.ecolabels) history.push(`/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}`);
    } else if (source === NavigatorSource.intervention) history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${page}&pagesize=${pagesize}&sort=default&mappingID=${mappingID}`);
  };

  const Filters = (props) => (
    <Button className={classes.selectedfilterbutton}>
      <Typography className={classes.clearbuttonText} style={{ color: MarketplaceColors.Breadcrumb }}>
        {props.value}
      </Typography>
      <ClearIcon className={classes.cleariconstyle} onClick={() => handleClearicon(props.id, props.value, false)} />
    </Button>
  );

  return (
    <Grid container xs={12} sm={12} lg={12} xl={12}>
      <Grid container xs={12} sm={12} lg={12} xl={12} className={classes.padding}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography className={classes.appliedfiltertitle}>Applied Filters</Typography>
        </Grid>
        {appliedfilters.length !== 0 && (
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography
              className={`${classes.padding} ${classes.clearbuttonstyle}`}
              style={{ color: MarketplaceColors.Secondary, cursor: 'pointer' }}
              onClick={() => handleClearAll(source, category, supercategory)}
            >
              CLEAR ALL
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container xs={12} sm={12} lg={12} xl={12} direction="row" className={classes.padding}>
        {appliedfilters.map((filters) => filters.checked && Filters(filters))}
      </Grid>
    </Grid>
  );
};

export default SidebarAppliedFilters;
