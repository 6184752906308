import React, { useEffect, useState } from 'react'
import { Grid, Typography, } from '@material-ui/core';
import { VariantFour, VariantFourDisabled, VariantOne, VariantSeven, dummyGoalsResponseForGoalsCreate } from './Goals';
import wasteIcon from '../../../assets/GOALS/goalcategoryimages/WasteGoalIcon.svg'
import PrimaryButton from '../../common/button/Button';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import waterIcon from '../../../assets/Fill 688.svg';
import powerIcon from '../../../assets/Fill 1452.svg';
import wasteicon from '../../../assets/interventions/Group 1365.svg';
import powerFilled from '../../../assets/GOALS/goalcategoryimages/energyFilledGoalIcon.svg';
import waterFilled from '../../../assets/GOALS/goalcategoryimages/waterFIlledGoalIcon.svg';
import wasteFilled from '../../../assets/GOALS/goalcategoryimages/wasteFilledGoalIcon.svg';
import materialNonFilled from '../../../assets/GOALS/goalcategoryimages/materialNonFilled.svg';
import useApiRequest from '../../hooks/useApiRequest';
import { useParams } from 'react-router';
import Header from '../../common/header/Header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const CreateGoals = (props) => {
    const history = useHistory()
    let { id } = useParams();
    const [goalsDataResponse, setGoalsDataResponse] = useState(null)

    const [{ status, response }, makeRequest] = useApiRequest(`/projectv2/${id}`, {
        verb: 'get',
    });
    if (status === null) makeRequest();
    useEffect(() => {
        if (response?.data) {
            setGoalsDataResponse(response?.data?.data?.data?.goals)
        } else {
            // console.log(dummyGoalsResponse?.data?.data, "vvvvvvvvv")
            setGoalsDataResponse(dummyGoalsResponseForGoalsCreate?.data?.data?.goals)

        }
    }, [response])

    // useEffect(() => {
    //     setGoalsDataResponse(dummyGoalsResponse?.data?.data?.goals)
    // }, [])

    console.log(goalsDataResponse, "goalsDataResponsegoalsDataResponse")

    return (
        <>
            <Header />
            <Grid style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: '8%' }} container justifyContent='center' alignItems='center' xs={12}>
                <Grid xs={12} style={{ paddingBottom: '3%', marginLeft: '-3%' }}>
                    <CommonHeadingWithIcon heading=" Almost There" variant='h3' />
                </Grid>
                <Grid item container justifyContent='center' alignItems='center' xs={12} style={{ paddingRight: '10%', }}>
                    <Typography style={{ textAlign: 'justify', lineHeight: 2 }}>
                        One last step! Research shows that setting goals and targets upfront improves adherence by 90%. Based on the details of the project you shared, we have identified Net Zero goals and applicable targets. Review and customize your target selection if you would like.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h5'>
                        <b>Goals</b>
                    </Typography>
                </Grid>
                {/* DONE */}
                <Grid item xs={12} container justifyContent='flex-start' alignItems='flex-start'>
                    <Grid container xs={3}>
                        <VariantOne border='2px solid #F28D14' background='#FFF4D5' icon={powerFilled} title={goalsDataResponse?.categories?.energy?.goal?.name} />
                    </Grid>
                    <Grid container xs={3}>
                        <VariantOne border='2px solid #3BC7EE' background='#C9F5FF' icon={waterFilled} title={goalsDataResponse?.categories?.water?.goal?.name} />
                    </Grid>
                    <Grid container xs={3}>
                        <VariantOne border='2px solid #BC8A5F' background='#EEE1D7' icon={wasteFilled} title={goalsDataResponse?.categories?.waste?.goal?.name} />
                    </Grid>
                    <Grid container xs={3}>
                        <VariantOne border='2px solid #916AAC' background='#EDD5FA' icon={wasteIcon} title={goalsDataResponse?.categories?.embodiedCarbon?.goal?.name} />
                    </Grid>
                </Grid>
                <Grid xs={12} style={{ marginTop: '3%' }}>
                    <Typography variant='h5'>
                        <b>Achievable Targets</b>
                    </Typography>
                </Grid>
                <Grid item container justifyContent='center' alignItems='center' xs={12} style={{ marginBottom: '3%', paddingRight: '10%' }}>
                    <Typography style={{ textAlign: 'justify', lineHeight: 2 }} variant='h6'>
                        Here are targets categorized to align with your Net Zero goals. You can toggle each target based on preference and project needs. Turning off a target disables associated interventions unless shared by other active targets. Targets are classified into Tiers I to IV, indicating impact levels on Net Zero goals up to 25%, 50%, 75%, and 100% respectively.
                    </Typography>
                </Grid>
                <Grid item container justifyContent='flex-start' alignItems='flex-start' xs={12} style={{ marginBottom: '5%' }}>
                    <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                        <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                            <Grid xs={3} container alignItems='center' justifyContent='center' ><img src={powerIcon} alt='waste icon' /></Grid>
                            <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Energy Targets</Typography></Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            {goalsDataResponse?.categories?.energy?.targets?.map((target) => {
                                if (target?.achievability === 'No') {
                                    return (
                                        <VariantSeven border='2px solid #CCCCCC' title={target?.name} />
                                    )
                                } else if (target?.achievability === 'Yes' && target?.state === false) {
                                    return (
                                        <VariantFourDisabled title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} />
                                    )
                                }
                                else return (
                                    <VariantFour border='2px solid #F6AA4F' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#FFCE93' barText={target?.progress > 100 ? 100 : target?.progress} disableBartext={true} />
                                )

                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                        <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                            <Grid xs={3} container alignItems='center' justifyContent='center'><img src={waterIcon} alt='waste icon' /></Grid>
                            <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Water Targets</Typography></Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            {goalsDataResponse?.categories?.water?.targets?.map((target) => {
                                if (target?.state === true) {
                                    return (
                                        <VariantFour border='2px solid #3BC7EE' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#74DEFB' barText={target?.progress > 100 ? 100 : target?.progress} disableBartext={true} />
                                    )
                                } else return (
                                    <VariantFourDisabled title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} />
                                )

                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                        <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                            <Grid xs={3} container alignItems='center' justifyContent='center'><img src={wasteicon} alt='waste icon' /></Grid>
                            <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Waste Targets</Typography></Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            {/* for waste currently its dummy value */}
                            {dummyGoalsResponseForGoalsCreate?.data?.data?.goals?.categories?.waste?.targets?.map((target) => {
                                if (target?.state === true) {
                                    return (
                                        <VariantFour border='2px solid #BC8A5F' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#F0D4BF' barText={target?.progress > 100 ? 100 : target?.progress} disableBartext={true} />
                                    )
                                } else return (
                                    <VariantFourDisabled title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            })}
                            {/* // {goalsDataResponse?.categories?.waste?.targets?.map((target) => {
                            //     return (
                            //         <VariantFour border='2px solid #BC8A5F' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#F0D4BF' barText={target?.progress > 100 ? 100 : target?.progress} />
                            //     )
                            // })} */}
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                        <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                            <Grid xs={3} container alignItems='center' justifyContent='center'><img src={materialNonFilled} alt='waste icon' style={{ height: 30 }} /></Grid>
                            <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Material Targets</Typography></Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            {goalsDataResponse?.categories?.embodiedCarbon?.targets?.map((target) => {
                                if (target?.state === true) {
                                    return (
                                        <VariantFour border='2px solid #916AAC' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#EDCDFF' barText={target?.progress > 100 ? 100 : target?.progress} disableBartext={true} />
                                    )
                                } else return (
                                    <VariantFourDisabled title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" style={{ marginBottom: '5%' }}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <PrimaryButton children="Save and Exit" style={{ width: '150px', borderRadius: '40px', height: '30px' }} onClick={() => history.push(`/projects/${id}/insights`)} />
                    </Grid>
                </Grid>
            </Grid >
        </>


    )
}

export default CreateGoals