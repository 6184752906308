import React from 'react';
import { Grid, Typography, withWidth } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MarketplaceColors } from '../../config';
import AddBoxIcon from '@material-ui/icons/AddBox';

const HeadAndBackIcon = ({ handleClick, arrowColor, iconHeight, iconWidth, headingStyle, variant, heading, bold, showAddToIntervention, handleShowModalForAddToIntervention }) => {
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={0} style={{ paddingTop: '0.6%' }}>
        <ArrowBackIosIcon
          onClick={handleClick}
          style={{
            color: arrowColor ? arrowColor : '#66E3BE',
            cursor: 'pointer',
            width: iconWidth ? iconWidth : '35.41px',
            height: iconHeight ? iconHeight : '35px',
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <Typography style={headingStyle} variant={variant}>
          {!bold ? heading : <b>{heading}</b>}
        </Typography>
      </Grid>
      {showAddToIntervention && <Grid item xs={3} container justifyContent='center' alignItems='center'>
        <Grid item xs={2} container justifyContent='flex-end' alignItems='center'><AddBoxIcon style={{ color: MarketplaceColors.Tertiary, fontSize: "30px" }} /></Grid>
        <Grid item xs={10} container justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' style={{ cursor: 'pointer', color: MarketplaceColors.Tertiary, textTransform: 'uppercase' }} onClick={handleShowModalForAddToIntervention}><b>add to intervention</b></Typography>
        </Grid>
      </Grid>}
    </Grid>
  );
};

export default withWidth()(HeadAndBackIcon);
