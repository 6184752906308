import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import PlumbingDetailsComponent from '../plumbing/PlumbingDetailsComponent';
import { SomethingChanged } from '../EditProject';

const EditProjectsPlumbingPIP = (props) => {
    let history = useHistory();
    let { id } = useParams();
    let { type, next, name } = props;
    const [myArray, setMyArray] = useState([]);

    // others
    const [data, setData] = useState('');
    const [projectPIP, setProjectPIP] = useState([]);
    const [builtup, setBuiltuparea] = useState('');
    const [dwelling, setDwelling] = useState('');
    const [mannuals, setmannuals] = useState('');
    const [customs, setcustoms] = useState('');
    const [projectData, setProjectData] = useState('')

    const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
    const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
        verb: 'get',
    });
    const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
        verb: 'get',
    });
    if (status === null && type && type !== 'file') makeRequest();
    if (mo?.status === null && type && type !== 'file') doRequest();

    useEffect(() => {
        if (mo?.response) {
            setProjectData(mo?.response?.data?.data?.data)
        }
    }, [mo?.response]);
    //useEffects
    useEffect(() => {
        if (projectPIP.length !== 0) {
            let arr2 = [];
            projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
            if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
        }
    }, [projectPIP]);

    useEffect(() => {
        if (projectPIP.length !== 0 && myArray.length !== 0) {
            myArray.forEach((ele) => {
                let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
                if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
            });
        }
    }, [projectPIP, myArray]);

    useEffect(() => {
        if (response) {
            const unsortedData = response && response.data.data.data;
            const sorted = unsortedData.sort((a, b) => compare(a, b));
            setData(sorted);
        }
    }, [response]);

    useEffect(() => {
        if (id) {
            axios
                .get(`/ProjectPIPMapping/get?project=${id}`)
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        setProjectPIP(response.data.data.data);
                    }
                })
                .catch((err) => console.log(err, 'error'));
        }
    }, [id]);



    useEffect(() => {
        if (data) {
            const mannuals = ['sink', 'shower', 'bathroom', 'health', 'fullwc', 'halfwc', 'showerhead.unitprice', 'healthfaucet.unitprice', 'kitchensinkfaucet.unitprice', 'bathroomfaucet.unitprice', 'fullwc.unitprice', 'halfwc.unitprice']
            const custom = ['custom.percapita.perday.waterconsumption']
            const mannualData = data?.filter((d) => d?.category === 'fixture' && mannuals.includes(d.name))
            const customData = data?.filter((d) => d?.category === 'fixture' && custom.includes(d.name))
            setmannuals(mannualData);
            setcustoms(customData)
        }
    }, [data]);


    useEffect(() => {
        if (projectPIP) {
            const builtupArea = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'builtup');
            const studio = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'studio');
            const single = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'single');
            const double = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'double');
            const three = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'three');
            const four = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'four');
            const StudioUnits = studio && studio.value ? parseInt(studio.value) : 0;
            const OneBHKUnits = single && single.value ? parseInt(single.value) : 0;
            const TwoBHKUnits = double && double.value ? parseInt(double.value) : 0;
            const ThreeBHKUnits = three && three.value ? parseInt(three.value) : 0;
            const FourBHKUnits = four && four.value ? parseInt(four.value) : 0;
            // find the value as sqm or sqft from pip mapping
            // const projectUnit = unit && unit.value ? unit.value : 'Sqm';
            setBuiltuparea(builtupArea && builtupArea.value);
            setDwelling(StudioUnits + OneBHKUnits + TwoBHKUnits + ThreeBHKUnits + FourBHKUnits);
            // set project unit if found from pips
            // setUnit(projectUnit && projectUnit);
        }
    }, [projectPIP]);

    const compare = (a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        else return 0;
    };
    const handleNext = (next) => {
        if (next && next === 'goals') history.push(`/projects/${id}/goals`);
        else {
            if (somethingChanged) {
                props.toogleShowModal(true);
                props.SETNEXT(next);
            } else {
                if (next && next === 'insights') history.push(`/projects/${id}/insights`);
                if (next && next === 'projects') history.push(`/projects`);
                else history.push(`/projects/${id}/edit?type=${next}`);
            }
        }
    };

    useEffect(() => {
        if (props.saveAfterSomethingChanged === true && next) {
            props.toogleShowModal(false);
            handlSetSomethingChanged(false);
            props.setFalseAfterSomethingChanged();
            props.SETNEXT('');
            handleSave(next && next);
        }
    }, [props.saveAfterSomethingChanged, next]);

    const handleGoBack = (type) => {
        if (type === 'basic') history.push(`/projects/${id}/insights`);
        else history.push(`/projects/${id}/edit?type=basic`);
    };
    const handleSave = (next) => {
        // sends data
        let body2 = {
            projectId: id,
            values: myArray && myArray.length !== 0 && myArray,
        };
        axios.put(`/ProjectPIPMapping`, body2).then((response) => {
            props.toogleShowModal(false);
            handlSetSomethingChanged(false);
            props.setFalseAfterSomethingChanged();
            props.SETNEXT('');
            swal({ icon: 'success', title: 'Data Saved' });
            if (next && next === 'insights') history.push(`/projects/${id}/insights`);
            if (next && next === 'projects') history.push(`/projects`);
            else history.push(`/projects/${id}/edit?type=${next}`);
        });

    };

    // creates myArray for sending all values together
    const appendChanged = (id, value) => {
        const body = {
            PIPScheme: id,
            value: value,
        };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.PIPScheme === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else {
            setMyArray((oldArray) => [...oldArray, body]);
        }
    };

    return (
        <>
            <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
                <HeadAndBackIcon
                    heading={`${name && name} ${projectData?.name ? projectData.name : ''}`}
                    handleClick={() => handleGoBack(type && type)}
                    visitGoals={false}
                    headingVariant="h5"
                    headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
                    showArrow={false}
                />
            </Grid>

            {/* for fixture new component*/}
            {type && type === 'fixture' && (
                <PlumbingDetailsComponent
                    myArray={myArray && myArray}
                    fixturesData={data && data}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(id, value) => appendChanged(id, value)}
                    type={type && type}
                    mannuals={mannuals && mannuals}
                    builtup={builtup && builtup}
                    dwelling={dwelling && dwelling}
                    customs={customs && customs}
                />
            )}

            <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
                <Grid item xs={2}>
                    <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
                </Grid>
            </Grid>
        </>
    );
};
export default EditProjectsPlumbingPIP;
