import React, { useEffect, useState } from 'react';
import marketplacelandingimage from '../../../../../assets/Marketplace/marketplacelandingimage.svg';// exceeds 500
// import carousalimage1 from '../../../../../assets/carousal/carousalimage1.svg';
import carousalimage3 from '../../../../../assets/carousal/carousalimage3.svg';
import carousalimage4 from '../../../../../assets/carousal/carousalimage4.svg';
import block from '../../../../../assets/Marketplace/block.svg';// exceeds 500
import useScreenType from '../../../../hooks/checkScreenType';
import notAvailable from '../../../../../assets/Marketplace/Image not available 1.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Skeleton } from '@material-ui/lab';
function Carousal({ next, prev, updateCurrentSlide, currentSlide, renderArrowNext, renderArrowPrev, productPage, imageUrl, handleClick, zoomed, interventionpage, staticassets }, props) {
  const { isMobile } = useScreenType();
  const imageStyle = isMobile ? { width: '50%' } : { width: '100%' };
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isImageAccessible, setIsImageAccessible] = useState(undefined);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  useEffect(() => {
    if (marketplacelandingimage) {
      const image = new Image();
      image.src = marketplacelandingimage;
      image.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [marketplacelandingimage]);
  useEffect(() => {
    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        setIsImageAccessible(true);
        setImageLoaded(true);
        setImageHeight(image.height);
        setImageWidth(image.width);
      };
      image.onerror = () => {
        setIsImageAccessible(false);
        setImageLoaded(true);
      };
    }
  }, [imageUrl]);

  const getImageWidthDynamic = (height) => {
    return Math.ceil(height * (imageWidth / imageHeight));
  };
  const getImageHeight = () => {
    return imageWidth > imageHeight ? (window.innerWidth >= 1540 ? 300 : 290) : (window.innerWidth >= 1540 ? 590 : 450);
  };
  return (
    <>
      {productPage && !interventionpage ? (
        <Carousel
          infiniteLoop={true}
          useKeyboardArrows={true}
          interval={3000}
          stopOnHover={true}
          autoPlay
          showArrows={true}
          showIndicators={true}
          showStatus={false}
          showThumbs={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}
          {...props}
        >
          {imageLoaded && isImageAccessible != undefined && (
            <>
              {zoomed ? (
                <div style={{ borderTop: `${imageWidth > imageHeight ? '180px solid white' : '30px solid white'}`, borderBottom: `${imageWidth > imageHeight ? '180px solid white' : '30px solid white'}` }}>
                  <img
                    style={{
                      height: `${getImageHeight()}px`,
                      width: `${getImageWidthDynamic(getImageHeight())}px`,
                      backgroundColor: 'white',
                    }}
                    src={isImageAccessible ? imageUrl : notAvailable}
                    alt="..."
                  />
                </div>
              ) : (
                <div style={{ width: '310px', margin: 'auto' }}>
                  <div onClick={isImageAccessible ? handleClick : () => { }} style={{ cursor: isImageAccessible ? 'pointer' : '' }}>
                    <img
                      style={{
                        maxWidth: '308px',
                        maxHeight: '290px',
                        width: `${getImageWidthDynamic(290)}px`,
                        height: '290px',
                      }}
                      src={isImageAccessible ? imageUrl : notAvailable}
                      alt="..."
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </Carousel>
      ) : interventionpage ? <Carousel
        infiniteLoop={true}
        useKeyboardArrows={true}
        interval={3000}
        stopOnHover={true}
        autoPlay
        showArrows={true}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        {...props}
      >
        {staticassets.map((asset) => {
          return (
            <div
              key={asset.id}
              style={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              {imageLoaded ? (
                <img
                  src={asset?.URL}
                  alt="..."
                  style={{
                    maxWidth: "100%",
                    maxHeight: "80%",
                  }}
                />
              ) : (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  style={{ width: "100%" }}
                  height={isMobile ? 150 : 400}
                />
              )}
            </div>
          );
        })}

      </Carousel> : (
        <Carousel
          infiniteLoop={true}
          useKeyboardArrows={true}
          interval={3000}
          stopOnHover={true}
          autoPlay
          showArrows={true}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          // renderArrowNext={renderArrowNext}
          // renderArrowPrev={renderArrowPrev}
          {...props}
        >
          <div>
            {imageLoaded ? (
              <img src={productPage ? block : marketplacelandingimage} alt="..." style={imageStyle} />
            ) : (
              <Skeleton variant="rect" animation="wave" style={{ width: '100%' }} height={isMobile ? 150 : 600} />
            )}
          </div>
          {/* <div>
            <img src={productPage ? block : carousalimage3} alt="..." style={imageStyle} />
          </div> */}
          {/* <div>
            <img src={productPage ? block : carousalimage4} alt="..." style={imageStyle} />
          </div> */}
        </Carousel>
      )}
    </>
  );
}

export default Carousal;
