import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import rsToCr from '../../../../../utils/rsToCr';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

const useStyles = makeStyles((theme) => ({
  smallpaper1: {
    width: '80%',
    padding: theme.spacing(0, 0, 0, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    borderRadius: '10px',
    // background: 'grey',
  },
  smallpaper2: {
    width: '100%',
    borderRadius: '10px',
    padding: theme.spacing(0, 0, 0, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    // background: 'grey',
  },
  textColor: {
    color: 'rgba(255, 255, 255, 0.82)',
  },
  content: {
    marginTop: '28%',
  },
}));

const TooltipData = [
  { category: 'Energy', data: 'A home is assumed to consume 1,080 kWh of electricity annually (or 90 kWh per month) ' },
  { category: 'Water', data: 'Capacity of 1 Water Tanker is taken as 6 kL' },
  { category: 'Waste', data: 'Capacity of 1 Waste Truck is taken as 10 tonnes or 10,000 Kg' },
  { category: 'Materials', data: null },
];

function TableOverview({ resource, financeSavings, resourceSavings, categoryname, carbonsaving }, props) {
  const classes = useStyles();
  // const name = categoryname;
  let capex = financeSavings && financeSavings.capex && financeSavings.capex;
  let opex = financeSavings && financeSavings.opexSavings && financeSavings.opexSavings;
  let capexTotal = financeSavings && financeSavings.totalCapex && financeSavings.totalCapex;
  let category = resourceSavings && resourceSavings.category;
  let carbon = resourceSavings && resourceSavings.carbon;
  let capexUnit = capex && capex.unit;
  let opexUnit = opex && opex.unit;
  let categoryUnit = category && category.unit;
  let carbonUnit = carbon && carbon.unit;
  let capexValue = capex && capexUnit && rsToCr(capexUnit, capex.value);
  let opexValue = opex && opexUnit && rsToCr(opexUnit, opex.value);
  let categoryValue = category && numberWithCommas(category.value);
  let carbonValue = carbon && carbon.value;
  let capexTotalValue = capexTotal && rsToCr('Rs', capexTotal.value);

  return (
    <>
      <Grid container xs={6} align="left" direction="column">
        <Typography variant="h5">Financial Impact (₹ cr)</Typography>
        <Paper className={classes.smallpaper1}>
          <Grid container alignItems="center" item xs={12} direction="row">
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Capex Increase
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {capexValue ? (
                <Typography variant="h5">
                  <b>{capexValue && capexValue}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Total Capex
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {capexValue ? (
                <Typography variant="h5">
                  <b>{capexTotalValue && capexTotalValue}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
            {/* <Grid item xs={1}></Grid> */}
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Annual Savings
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {opexValue ? (
                <Typography variant="h5">
                  <b>{opexValue && opexValue}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container xs={6} align="left" direction="column">
        <Typography variant="h5">Resource Impact</Typography>

        <Paper className={classes.smallpaper2}>
          <Grid container alignItems="left" item xs={12} direction="row">
            <Grid container xs={4} direction="column" style={{ paddingTop: '2%' }}>
              <Typography variant="h6" color="textSecondary">
                {resource === 'Waste' ? resource + ` Avoided` : resource + ` Savings`}
              </Typography>
              <Grid xs style={{ marginTop: '-4%' }}></Grid>
              {categoryUnit ? (
                <Typography variant="h5">
                  <b>{categoryValue && categoryValue} </b>
                  {categoryUnit && categoryUnit}
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
            {resource !== 'Materials' && <Grid container xs={4} direction="column" style={{ borderRight: '1px solid #CCCCCC', paddingTop: '2%' }}>
              <Typography variant="h6" color="textSecondary">
                Carbon Savings
              </Typography>
              <Grid xs style={{ marginTop: '-4%' }}></Grid>
              {categoryUnit && carbonValue >= 0 ? (
                <Typography variant="h5">
                  <b>{numberWithCommas(carbonValue)} </b>
                  {carbonUnit && carbonUnit}
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>}
            <Grid container item xs={4} alignItems="center" justifyContent="center" style={{ paddingLeft: '2%' }}>
              {resourceSavings ? (
                <>
                  <Typography variant="h6" color="textSecondary">
                    {`${numberWithCommas(resourceSavings.categoryConverted.value)} ${resourceSavings.categoryConverted.unit}`}
                    <CustomizedTooltips
                      marginTop="3%"
                      icon="info"
                      placement="left"
                      title={
                        TooltipData && TooltipData.find((d) => d.category === resource) && TooltipData.find((d) => d.category === resource).data !== null ? (
                          <Typography variant="h6" style={{ lineHeight: '20px' }}>
                            {TooltipData.find((d) => d.category === resource).data}
                          </Typography>
                        ) : (
                          <Typography variant="h6">A single car on average emits 4.6 tons of CO2e annually</Typography>
                        )
                      }
                    />
                  </Typography>
                </>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default TableOverview;
