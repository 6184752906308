import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core';
import { VariantCommon } from '../../../goals/Goals';


const CategorywiseInsights = ({ title, subheading, background, barBackground, barprogress, barprogressText, capexIncrease }) => {
    return (
        <Grid container xs={12} justifyContent='flex-start' style={{ paddingTop: '5%' }}>
            <Grid container xs={7} justifyContent='flex-start' style={{ paddingRight: '5%' }}>
                <Grid container justifyContent='flex-start'>
                    <Typography variant='subtitle2' style={{ fontSize: '15px' }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid container justifyContent='flex-start' style={{ paddingTop: '8%' }}>
                    <Grid xs={10}>
                        <VariantCommon variant="determinate" progressValue={barprogress > 100 ? 100 : barprogress} style={{ flexGrow: '1', }} background={background} barBackground={barBackground} />
                    </Grid>
                    <Grid xs={2} style={{ paddingLeft: '2%' }}>
                        <Typography style={{ fontSize: '13px' }}>{barprogressText}</Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container xs={5} justifyContent='flex-start' style={{ paddingTop: '2%' }}>
                <Grid container justifyContent='flex-start' style={{ paddingLeft: '20%' }}>
                    <Typography variant='h6' color='textSecondary'>
                        {subheading}
                    </Typography>
                </Grid>
                <Grid container justifyContent='flex-start' style={{ paddingTop: '8%', paddingLeft: '20%' }}>
                    <Typography style={{ fontSize: '13px' }}>
                        {capexIncrease}
                    </Typography>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default CategorywiseInsights