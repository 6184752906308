import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import IGBCCard from './IGBCCard';
import IGBCComingSoon from '../../../../../assets/insights/carousal/IGBC - Coming soon (1).svg';// exceeds 500

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const IGBCModal = (props) => {
  let purpose = props.purpose;

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      outline: 'none',
      backgroundColor: `${purpose === 'IGBC' ? '#ffffff' : 'transparent'}`,
      borderRadius: '15px',
      padding: theme.spacing(2, 4, 3),
      width: '950px',
      outline: 'none',
      maxHeight: 600,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    modalhead: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '25px',
      textAlign: 'left',
      letterSpacing: '0.714286px',
      color: '#000000',
    },

    linkfont: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'flex-end',
      color: '#00C58A',
      fontWeight: 850,
    },
    close: {
      marginLeft: '95%',
    },
    text3: {
      color: '#3BC7EE',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.714286px',
      textAlign: 'center',
      paddingTop: '10px',
    },
    text8: {
      color: '#00C58A',
      lineHeight: '120.5%',
      letterSpacing: '0.5px',
      textAlign: 'center',
      textDecorationLine: 'underline',
      paddingTop: '10px',
    },
  }));
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const igbc = props.igbc;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <CloseIcon className={classes.close} onClick={handleClose} />
      <Typography className={classes.modalhead}>IGBC Certified Projects within 5 kms</Typography>

      {igbc &&
        igbc.value.map((data) => {
          return (
            <Grid container direction="column" item xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
              <IGBCCard igbc={data} />
            </Grid>
          );
        })}
      <Link to="#" exact={true} style={{ color: 'rgba(0, 219, 153, 0.82)' }}>
        <Typography className={classes.linkfont} position="flexend">
          {/* Call to action button? */}
        </Typography>
      </Link>
    </div>
  );

  const body2 = (
    <div style={modalStyle} className={classes.paper} onClick={handleClose}>
      {/* <CloseIcon className={classes.close} onClick={handleClose} /> */}
      <img src={IGBCComingSoon} alt="..." height="500" width="850" />
    </div>
  );
  return (
    <>
      {purpose === 'IGBC' ? (
        <Typography className={classes.text3} style={{ cursor: 'pointer' }} onClick={handleOpen}>
          View more details
        </Typography>
      ) : (
        <Typography variant="h6" className={classes.text8} style={{ cursor: 'pointer' }} onClick={handleOpen}>
          Explore IGBC certification for your project
        </Typography>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ backgroundColor: 'transparent' }}
      >
        {purpose === 'IGBC' ? body1 : body2}
      </Modal>
    </>
  );
};
export default IGBCModal;
