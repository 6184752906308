import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useApiRequest from "../../../../hooks/useApiRequest";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function OrganisationSelect({ selectedOrganisationID, projectOrganisation }) {
    const classes = useStyles();
    const [allOrganisations, setAllOrganisations] = React.useState("");
    const [state, setState] = React.useState("");

    const [re, makeRequest] = useApiRequest(`/organisationsschemev2`, {
        verb: 'get',
    });


    useEffect(() => {
        if (re.status === null) makeRequest();
    }, [re]);

    useEffect(() => {
        if (re.response) setAllOrganisations(re?.response?.data?.data?.data);
    }, [re.response]);

    useEffect(() => {
        if (allOrganisations && state) {
            const selectedOrganisation = allOrganisations?.find((org) => org?.name === state)
            selectedOrganisationID(selectedOrganisation?._id)
        }
    }, [allOrganisations, state]);

    useEffect(() => {
        if (allOrganisations && projectOrganisation) {
            const selectedOrganisation = allOrganisations && allOrganisations.find((org) => org?._id === projectOrganisation)
            setState(selectedOrganisation?.name)
        }
    }, [allOrganisations, projectOrganisation]);

    const handleChange = (event) => setState(event.target.value);
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                    Organisation
                </InputLabel>
                <Select
                    native
                    value={state}
                    onChange={handleChange}
                    label="Organisation"
                >
                    <>
                        <option aria-label="None" value="" />
                        {allOrganisations && allOrganisations.map(({ name }) => {
                            return <option value={name}>{name}</option>
                        })}
                    </>

                </Select>
            </FormControl>
        </div>
    );
}
