import { NavigatorSource } from '../utils/Defaults';
import {
  buildCustomProductsQuery,
  buildCustomSupercategoryQuery,
  buildEcolabelQuery,
  buildSearchQuery,
  buildgetProductsQuery
} from '../views/certification-and-super-categories/utils/QueryParser';

export const categoryDetailsQuery = (
  source,
  category,
  subcategory,
  superCategory,
  certifications,
  brands,
  page,
  pagesize,
  filters,
  sort = 'default',
  searchquery,
  identifiers,
  productstatus
) => {
  if (source === NavigatorSource.categories) {
    return categorySourceFilterQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.supercategories) {
    return superCategoriesSourceFilterQuery(category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.ecolabels) {
    return ecolabelsFilterQuery(brands, page, pagesize, filters, sort);
  } else if (source === NavigatorSource.search) {
    console.log('state?.response, state?.response');
    return searchFilterQuery(brands, page, pagesize, filters, sort, searchquery);
  }
  else if (source === NavigatorSource.intervention) {
    console.log('state?.response, state?.response');
    return interventionSourceFilterQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus);
  }
};

export const categorySourceFilterQuery = (category, subcategory, brands, certifications, page, pagesize, filters, sort) => `query Q {
  categories(filters:{name:{ in:["${category}"] }}){
    data {
     id 
     attributes {
       name 
       type 
       subcategories { 
        data { 
          id 
          attributes 
          { name 
          }
        }} 
       filters { 
        data { 
         id 
         attributes { 
          name 
          options { 
            id 
            option 
          }
        }}}  
       brands { 
        data { 
          id 
          attributes {
             name 
            }
          }} 
        }}
 }
 customProducts(${buildCustomProductsQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort)}) {
  totalproducts
  products{
    id
    name
    shortDescription
    image
    brand{
      name
    }
    category{
      id
      name
    }
    subcategory{
      name
    }
    ecolabels
  }
  }   
}`;

export const superCategoriesSourceFilterQuery = (category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort) => `query Q {
  customSuperCategories(
    ${buildCustomSupercategoryQuery(category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort)}      
  ) {
    totalproducts
    id
    Name
    products{
      id
      name
      shortDescription
      image
      brand{
        name
      }
      category{
        id
        name
      }
      subcategory{
        name
      }
      ecolabels
    }
  }
  categories(filters: { super_categories: { Name: { in: "${superCategory}" } } }) {
      data {
        id
        attributes {
          name
          type
          brands {
            data {
              attributes {
                name
              }
            }
          }
          filters {
            data {
              attributes {
                name
                options {
                  option
                }
              }
            }
          }
          subcategories {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
}  
`;

export const ecolabelsFilterQuery = (brands, page, pagesize, filters, sort) => `query Q {
  ecoLabels {
    data {
      id
      attributes {
        name
      }
    }
  }
  brands(pagination:{page:1, pageSize:200}) {
    data {
      id
      attributes {
        name
      }
    }
  }
  customProducts(${buildEcolabelQuery(brands, page, pagesize, filters, sort)}) {
    totalproducts
    products {
      id
      name
      shortDescription
      image
      brand {
        name
      }
      category {
        id
        name
      }
      subcategory {
        name
      }
      ecolabels
    }
  }
}
`;
export const searchFilterQuery = (brands, page, pagesize, filters, sort, searchquery) => `query Q {
  ecoLabels {
    data {
      id
      attributes {
        name
      }
    }
  }
  brands(pagination:{page:1, pageSize:200}) {
    data {
      id
      attributes {
        name
      }
    }
  }
  searchProducts(${buildSearchQuery(brands, page, pagesize, filters, sort, searchquery)}) {
    totalproducts
    products {
      id
      name
      shortDescription
      image
      brand {
        name
      }
      category {
        id
        name
      }
      subcategory {
        id
        name
      }
      ecolabels
    }
  }
}
`;

export const interventionSourceFilterQuery = (category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus) => `query Q {
  categories(filters:{name:{ in:["${category}"] }}){
    data {
     id 
     attributes {
       name 
       type 
       subcategories { 
        data { 
          id 
          attributes 
          { name 
          }
        }} 
       filters { 
        data { 
         id 
         attributes { 
          name 
          options { 
            id 
            option 
          }
        }}}  
       brands { 
        data { 
          id 
          attributes {
             name 
            }
          }} 
        }}
 }
 getProducts(${buildgetProductsQuery(category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus)}){
  totalproducts
  products {
      id
      name
      shortDescription
      image
      brand {
        id
        name
      }
      category{
        id
        name
      }
      subcategory{
        id
        name
      }
      ecolabels
      qualityAssuranceReliability
      environmentalImpactEcosystemHealth
      environmentalImpactHumanHealth
      environmentalImpactCircularity
      sdgs
      misc
      resourceSavings
      environmentalImpactClimateHealth
      productCode
      warrantyAfterSales
      references
      defaultProductTag
      sdplusInterventions
      dimension
      price
      properties
      identifier
      filters
    }
}
}`;
// place this condition in categoryDetailsQuery in use of certifications
// else if (source === NavigatorSource.certifications && certifications !== null) {
//   return certificationsSourceFilterQuery(certifications, page, pagesize);
// } else if (source === NavigatorSource.certifications && certifications === null) {
//   return allcertificationsSourceFilterQuery(page, pagesize);
// }
// export const certificationsSourceFilterQuery = (certification, page, pagesize) => `query Q {
//   products( pagination:{ page:${page}, pageSize:${pagesize} }, filters: { certifications: {name: { in:["${certification}"] }} }) {
//     meta{
//       pagination{total}
//     }
//     data {
//       id
//       attributes{
//         name
//         image
//         brand{
//           data{
//             attributes{
//               name
//             }
//           }
//         }
//         shortDescription
//         certifications{
//           data{
//             attributes{
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// }`;

// export const allcertificationsSourceFilterQuery = (page, pagesize) => `query Q {
//   products(pagination:{ page:${page}, pageSize:${pagesize} }) {
//     meta{
//       pagination{total}
//     }
//     data {
//       id
//       attributes{
//         name
//         image
//         brand{
//           data{
//             attributes{
//               name
//             }
//           }
//         }
//         shortDescription
//         certifications{
//           data{
//             attributes{
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// }`;
