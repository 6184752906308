import React, { useEffect, useState } from 'react';
import { Container, Tabs, Tab } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// import Energy from './tabview/Energy';
import Water from './tabview/Water';
import Waste from './tabview/Waste';
import Materials from './tabview/Materials';
import CategorywiseInsights from './tabview/CategorywiseInsights';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import useApiRequest from '../../../hooks/useApiRequest';
import { dummyGoalsResponseForGoalsInsights } from '../../goals/Goals';
import numberWithCommas from '../../../../utils/numberToComma';

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: '0.6px solid #CCCCCC',
  },
  indicator: {
    backgroundColor: '#F28D14',
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
    minWidth: '70px',
    labelIcon: {
      color: '#9E9E9E',
      '&:focus': {
        fontStyle: 'normal',
        fontWeight: '900',
      },
    },
    image: {
      height: '500px',
      width: '500px',
    },
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    position: 'sticky',

    top: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#555',
  },
  link: {
    marginRight: theme.spacing(2),
    color: '#555',
    fontSize: 16,
  },
}));

const Rightbar = ({ goals, project, newintervention }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [name, setName] = React.useState(0);

  const handleChange = (event, label) => setValue(label);
  const handleLabel = (label) => setName(name < 3 ? name + 1 : 0);

  let { id } = useParams();
  const [goalsDataResponse, setGoalsDataResponse] = useState(goals && goals)
  // console.log(goals?.categories?.energy?.goal?.progress, "goals response")

  return (
    <>
      <Container className={classes.container} data-tour="goaltracking">
        <StyledTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <StyledTab label="Energy" onClick={handleLabel} />
          <StyledTab label="Water" onClick={handleLabel} />
          <StyledTab label="Waste" onClick={handleLabel} />
          <StyledTab
            // disabled
            label="Materials"
            onClick={handleLabel}
          />
        </StyledTabs>
        {value === 0 && (
          <>
            {/* <CategorywiseInsights
              title={goalsDataResponse?.categories?.energy?.goal?.name}
              subheading='CAPEX Increase'
              background='#E5E5E5'
              barBackground='#F28D14'
              barprogress={goalsDataResponse?.categories?.energy?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.energy?.goal?.progress}
              barprogressText={`${goalsDataResponse?.categories?.energy?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.energy?.goal?.progress}%`}
              capexIncrease={goalsDataResponse?.categories?.energy?.targets?.reduce((acc, obj) => acc + obj?.additionalCostPerUnitArea, 0).toFixed(2)}
            /> */}
            <CategorywiseInsights
              title={goals?.categories?.energy?.goal?.name}
              subheading='CAPEX Increase (cr)'
              background='#E5E5E5'
              barBackground='#F28D14'
              barprogress={goals?.categories?.energy?.goal?.progress > 100 ? 100 : goals?.categories?.energy?.goal?.progress}
              barprogressText={`${goals?.categories?.energy?.goal?.progress > 100 ? 100 : goals?.categories?.energy?.goal?.progress}%`}
              capexIncrease={1.72}
            />
            {/* <CategorywiseInsights
              title='Targets Achieved'
              subheading='Energy Savings'
              background='#E5E5E5'
              barBackground='#FFCE93'
              barprogress={(goalsDataResponse?.categories?.energy?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goalsDataResponse?.categories?.energy?.targets?.length) * 100}
              barprogressText={`${goalsDataResponse?.categories?.energy?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goalsDataResponse?.categories?.energy?.targets?.length}`}
              capexIncrease={`${isNaN(numberWithCommas(goalsDataResponse?.categories?.energy?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))) ? 0 : numberWithCommas(goalsDataResponse?.categories?.energy?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0).toFixed(2))} KWh`}
            /> */}
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Energy Savings'
              background='#E5E5E5'
              barBackground='#FFCE93'
              barprogress={100}
              barprogressText={`1/1`}
              capexIncrease={`5,24,426 KWh`}
            />
          </>

        )}
        {value === 1 && (
          <>
            {/* <CategorywiseInsights
              title={goalsDataResponse?.categories?.water?.goal?.name}
              subheading='CAPEX Increase'
              background='#E5E5E5'
              barBackground='#3BC7EE'
              barprogress={goalsDataResponse?.categories?.water?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.water?.goal?.progress}
              barprogressText={`${goalsDataResponse?.categories?.water?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.water?.goal?.progress}%`}
              capexIncrease={goalsDataResponse?.categories?.water?.targets?.reduce((acc, obj) => acc + obj?.additionalCostPerUnitArea, 0).toFixed(2)}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Water Savings'
              background='#E5E5E5'
              barBackground='#74DEFB'
              barprogress={(goalsDataResponse?.categories?.water?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goalsDataResponse?.categories?.water?.targets?.length) * 100}
              barprogressText={`${goalsDataResponse?.categories?.water?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goalsDataResponse?.categories?.water?.targets?.length}`}
              capexIncrease={`${isNaN(numberWithCommas(goalsDataResponse?.categories?.water?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))) ? 0 : numberWithCommas(goalsDataResponse?.categories?.water?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))} KL`}
            /> */}
            <CategorywiseInsights
              title={goals?.categories?.water?.goal?.name}
              subheading='CAPEX Increase (cr)'
              background='#E5E5E5'
              barBackground='#3BC7EE'
              barprogress={67}
              barprogressText={`67%`}
              capexIncrease={1.24}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Water Savings'
              background='#E5E5E5'
              barBackground='#74DEFB'
              barprogress={(goals?.categories?.water?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goals?.categories?.water?.targets?.length) * 100}
              barprogressText={`${goals?.categories?.water?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goals?.categories?.water?.targets?.length}`}
              capexIncrease={`63,283 KL`}
            />
          </>

        )}
        {value === 2 && (
          <>
            {/* <CategorywiseInsights
              title={goalsDataResponse?.categories?.waste?.goal?.name}
              subheading='CAPEX Increase'
              background='#E5E5E5'
              barBackground='#BC8A5F'
              barprogress={goalsDataResponse?.categories?.waste?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.waste?.goal?.progress}
              barprogressText={`${goalsDataResponse?.categories?.waste?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.waste?.goal?.progress}%`}
              capexIncrease={goalsDataResponse?.categories?.waste?.targets?.reduce((acc, obj) => acc + obj?.additionalCostPerUnitArea, 0).toFixed(2)}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Waste Savings'
              background='#E5E5E5'
              barBackground='#F0D4BF'
              barprogress={(goalsDataResponse?.categories?.waste?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goalsDataResponse?.categories?.waste?.targets?.length) * 100}
              barprogressText={`${goalsDataResponse?.categories?.waste?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goalsDataResponse?.categories?.waste?.targets?.length}`}
              capexIncrease={`${isNaN(numberWithCommas(goalsDataResponse?.categories?.waste?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))) ? 0 : numberWithCommas(goalsDataResponse?.categories?.waste?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))} Kg`}
            /> */}
            <CategorywiseInsights
              title={goals?.categories?.waste?.goal?.name}
              subheading='CAPEX Increase (cr)'
              background='#E5E5E5'
              barBackground='#BC8A5F'
              barprogress={goals?.categories?.waste?.goal?.progress > 100 ? 100 : goals?.categories?.waste?.goal?.progress}
              barprogressText={`${goals?.categories?.waste?.goal?.progress > 100 ? 100 : goals?.categories?.waste?.goal?.progress}%`}
              capexIncrease={-0.01}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Waste Savings'
              background='#E5E5E5'
              barBackground='#F0D4BF'
              barprogress={0}
              barprogressText={`0/1`}
              capexIncrease={`1,77,992 Kg`}
            />
          </>

        )}
        {value === 3 && (
          <>
            {/* <CategorywiseInsights
              title={goalsDataResponse?.categories?.embodiedCarbon?.goal?.name?.length > 21 ? goalsDataResponse?.categories?.embodiedCarbon?.goal?.name?.slice(0, 21 - 3) + '...' : goalsDataResponse?.categories?.embodiedCarbon?.goal?.name}
              subheading='CAPEX Increase'
              background='#E5E5E5'
              barBackground='#916AAC'
              barprogress={goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress}
              barprogressText={`${goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress}%`}
              capexIncrease={goalsDataResponse?.categories?.embodiedCarbon?.targets?.reduce((acc, obj) => acc + obj?.additionalCostPerUnitArea, 0).toFixed(2)}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Carbon Savings'
              background='#E5E5E5'
              barBackground='#EDCDFF'
              barprogress={(goalsDataResponse?.categories?.embodiedCarbon?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goalsDataResponse?.categories?.embodiedCarbon?.targets?.length) * 100}
              barprogressText={`${goalsDataResponse?.categories?.embodiedCarbon?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goalsDataResponse?.categories?.embodiedCarbon?.targets?.length}`}
              capexIncrease={`${isNaN(numberWithCommas(goalsDataResponse?.embodiedCarbon?.energy?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))) ? 0 : numberWithCommas(goalsDataResponse?.embodiedCarbon?.energy?.targets?.reduce((acc, obj) => acc + obj?.maxRenewableEnergySavings, 0))} Tonnes`}
            /> */}
            <CategorywiseInsights
              title={goals?.categories?.embodiedCarbon?.goal?.name?.length > 21 ? goals?.categories?.embodiedCarbon?.goal?.name?.slice(0, 21 - 3) + '...' : goals?.categories?.embodiedCarbon?.goal?.name}
              subheading='CAPEX Increase (cr)'
              background='#E5E5E5'
              barBackground='#916AAC'
              barprogress={goals?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goals?.categories?.embodiedCarbon?.goal?.progress}
              barprogressText={`${goals?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goals?.categories?.embodiedCarbon?.goal?.progress}%`}
              capexIncrease={0.95}
            />
            <CategorywiseInsights
              title='Targets Achieved'
              subheading='Material Savings'
              background='#E5E5E5'
              barBackground='#EDCDFF'
              barprogress={(goals?.categories?.embodiedCarbon?.targets?.filter((d) => d?.achievability === 'Yes')?.length / goals?.categories?.embodiedCarbon?.targets?.length) * 100}
              barprogressText={`${goals?.categories?.embodiedCarbon?.targets?.filter((d) => d?.achievability === 'Yes')?.length}/${goals?.categories?.embodiedCarbon?.targets?.length}`}
              capexIncrease={`22,089 tCO2e`}
            />
          </>

        )}
      </Container>
    </>
  );
};

export default Rightbar;
