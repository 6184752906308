import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import ProductCard from '../../../marketplace/common/ProductCard';
import useInterventionDetails from '../../../marketplace/hooks/useInterventionDetailsData';
import DummyAddProductCard from '../../../marketplace/common/DummyAddProductCard';
import axios from 'axios';
import { useParams } from 'react-router';
import { HWMVilla, HWmApartment, HWmClubHouse, getSubcategory, reorderProducts } from './CustomFilterForKongInterv';

const useStyles = makeStyles((theme) => ({
  vendorGrid: {
    marginTop: '3%',
  },
  textwithrefresh: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshicon: {
    color: 'rgba(38, 224, 163, 1)',
    cursor: 'pointer',
  },
  defaultText: {
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
  },
}));

const widthstyle = {
  width: '220px',
};

const mapping = [
  { intervention: 'Solar PV', category: 'Hot Water Management', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Solar Water Heater', category: 'Hot Water Management', productIdentifier: 'NA', defaultTag: 'NA' },
  {
    intervention: 'Hot Water Management Villa',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmVilla.productIdentifier',
    defaultTag: 'hwmVilla.defaultProductIdentifier',
  },
  {
    intervention: 'Hot Water Management Apartment',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmApartment.productidentifier',
    defaultTag: 'hwmApartment.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Penthouse',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmPentHouse.productidentifier',
    defaultTag: 'hwmPentHouse.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Clubhouse',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmClubHouse.productidentifier',
    defaultTag: 'hwmClubHouse.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Swimming Pool',
    category: 'Hot Water Management',
    subcategory: 'Heat Pumps',
    productIdentifier: 'hwmSwimmingPool.productIdentifier',
    defaultTag: 'hwmSwimmingPool.defaultProductIdentifier',
  },
  { intervention: 'Efficient Fixtures', category: 'Plumbing Fixtures', productIdentifier: 'NA' },

  {
    intervention: 'Efficient Fixtures - Health Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Health Faucet',
    productIdentifier: 'efhealthfaucet.productidentifier',
    defaultTag: 'efhealthfaucet.defaultproductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Faucets',
    productIdentifier: 'effaucet.productidentifier',
    defaultTag: 'effaucet.defaultproductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Kitchen Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Kitchen Sink',
    productIdentifier: 'efkitchenfaucet.productidentifier',
    defaultTag: 'efkitchenfaucet.defaultproductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Showers',
    category: 'Plumbing Fixtures',
    subcategory: 'Shower',
    productIdentifier: 'efshower.productidentifier',
    defaultTag: 'efshower.defaultProductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Dual Flush',
    category: 'Plumbing Fixtures',
    subcategory: 'Flushing',
    productIdentifier: 'efdualflush.productidentifier',
    defaultTag: 'efdualflush.defaultproductidentifier',
  },

  { intervention: 'Concrete', category: 'Cement', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Utility & Balcony', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Bedroom', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Living & Dining', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Bathroom', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Kitchen', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Structural Steel', category: 'Steel', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Exterior Paints', category: 'Paints', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Interior Paints', category: 'Paints', productIdentifier: 'NA', defaultTag: 'NA' },
  {
    intervention: 'Treated Water Reuse',
    category: 'Waste Water Treatment',
    subcategory: 'Tertiary Treatment',
    productIdentifier: 'treatedwaterreuse.productidentifier',
    defaultTag: 'treatedwaterreuse.defaultProductidentifier',
  },
  {
    intervention: 'Natural STP',
    category: 'Waste Water Treatment',
    subcategory: 'Natural STP',
    productIdentifier: 'naturalstp.productidentifier',
    defaultTag: 'naturalstp.defaultProductidentifier',
  },
  {
    intervention: 'Smart Water Meters',
    category: 'Water Management',
    subcategory: 'Smart Water Meter',
    productIdentifier: 'swm.productidentifier',
    defaultTag: 'swm.defaultProductidentifier',
  },
];

export const MarketPlaceLink = ({
  interventionname,
  setProductidentifier,
  activeSandbox,
  dirtyValue,
  projectid,
  handleRefreshdetails,
  handleBackdropOpen,
  interventiondetails,
}) => {
  const classes = useStyles();
  const [shortlistedProducts, setShortlistedProducts] = useState([]);
  const [defaultProduct, setDefaultProduct] = useState('');
  const [fianlIdentifiers, setFinalIdentifiers] = useState([]);
  const { productDetailsData, fetchData } = useInterventionDetails(fianlIdentifiers.join());

  const [selectedProduct, setSelectedProduct] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [dataReload, setDataReload] = useState(false);
  let { id, iid } = useParams();
  console.log(productDetailsData && productDetailsData, 'bbbbbbbbbbbbbbbbbbb');
  useEffect(() => {
    let products = reorderProducts(productDetailsData && productDetailsData?.products, defaultProduct && defaultProduct); //productDetailsData && [...productDetailsData?.products].reverse();
    if (interventionname === 'Hot Water Management Apartment') {
      HWmApartment(interventiondetails && interventiondetails, products && products, setFilteredProducts, interventionname && interventionname);
    }
    if (interventionname === 'Hot Water Management Clubhouse') {
      HWmClubHouse(interventiondetails && interventiondetails, products && products, setFilteredProducts, interventionname && interventionname);
    }
    if (interventionname === 'Hot Water Management Penthouse') {
      HWmClubHouse(interventiondetails && interventiondetails, products && products, setFilteredProducts, interventionname && interventionname);
    } else {
      setFilteredProducts(products && products);
    }
  }, [productDetailsData, interventiondetails, interventionname, defaultProduct, shortlistedProducts]);

  // console.log(filteredProducts, 'new data')

  const getInterventionMappingData = () => {
    axios
      .get(`/ProjectInterventionMapping/${iid}`)
      .then((response) => {
        setShortlistedProducts(response?.data?.data?.data[0]?.shortlistedProducts);
        // console.log(response?.data?.data?.data[0]?.shortlistedProducts, 'dddddddddddddd 0');
      })
      .catch((err) => {
        console.log(err);
      });
    const tag = mapping.find((ele) => ele.intervention === interventionname)?.productIdentifier;
    if (tag !== 'NA') {
      const metaobj = interventiondetails && interventiondetails?.quantities?.details.find((ele) => ele.id === tag);
      console.log(metaobj, 'ffffffffffffffffffffffffff');
      setSelectedProduct(metaobj?.value);
    }
  };

  useEffect(() => {
    const defaulttag = mapping.find((ele) => ele.intervention === interventionname)?.defaultTag;
    let a = interventiondetails && interventiondetails?.quantities?.details.find((ele) => ele.id === defaulttag);
    console.log(defaulttag, a, interventiondetails, 'ffffffffffffffffffffffffff');
    {
      a &&
        axios
          .get(`/ProjectInterventionMapping/${iid}`)
          .then((response) => {
            setShortlistedProducts(response?.data?.data?.data[0]?.shortlistedProducts);
            // console.log(response?.data?.data?.data[0]?.shortlistedProducts, 'dddddddddddddd 0')
            let b = response?.data?.data?.data[0]?.shortlistedProducts;
            let finalIdentifiers = [...[a && a?.value], ...b];
            let uniqueArray = [...new Set(finalIdentifiers)];
            console.log(uniqueArray, a?.value, 'dddddddddddddd 1');
            setDefaultProduct(a?.value);
            setFinalIdentifiers(uniqueArray);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    const tag = mapping.find((ele) => ele.intervention === interventionname)?.productIdentifier;
    if (tag !== 'NA') {
      const metaobj = interventiondetails && interventiondetails?.quantities?.details.find((ele) => ele.id === tag);
      setSelectedProduct(metaobj?.value);
    }
  }, [iid, interventiondetails]);

  useEffect(() => {
    axios
      .get(`/ProjectInterventionMapping/${iid}`)
      .then((response) => {
        setShortlistedProducts(response?.data?.data?.data[0]?.shortlistedProducts);
        // console.log(response?.data?.data?.data[0]?.shortlistedProducts, 'dddddddddddddd 0')
        const defaulttag = mapping.find((ele) => ele.intervention === interventionname)?.defaultTag;
        let a = interventiondetails && interventiondetails?.quantities?.details.find((ele) => ele.id === defaulttag);
        let b = response?.data?.data?.data[0]?.shortlistedProducts;
        let finalIdentifiers = [...[a?.value], ...b];
        let uniqueArray = [...new Set(finalIdentifiers)];
        // console.log(uniqueArray, a?.value, 'dddddddddddddd 1')
        setDefaultProduct(a?.value);
        setFinalIdentifiers(uniqueArray);
      })
      .catch((err) => {
        console.log(err);
      });
    const tag = mapping.find((ele) => ele.intervention === interventionname)?.productIdentifier;
    if (tag !== 'NA') {
      const metaobj = interventiondetails && interventiondetails?.quantities?.details.find((ele) => ele.id === tag);
      setSelectedProduct(metaobj?.value);
    }
  }, [dataReload]);

  useEffect(() => {
    if (fianlIdentifiers.length !== 0) {
      console.log(fianlIdentifiers, 'bbbbbbbbbbbbbbbbbbb');
      fetchData();
    } else {
      // If shortlistedProducts is empty, still call fetchData to update product data
      fetchData();
    }
  }, [fianlIdentifiers]);

  const handleRefresh = () => {
    // getInterventionMappingData()
    setDataReload(!dataReload);
  };

  const getCategoryname = () => {
    const category = mapping.find((element) => element.intervention === interventionname);
    return category.category;
  };

  const getSubcategoryName = () => {
    const item = mapping.find((element) => element.intervention === interventionname);
    return item.subcategory;
  };
  const getProductIdeantifier = () => {
    const item = mapping.find((element) => element.intervention === interventionname);
    return item.productIdentifier;
  };

  useEffect(() => {
    const ideantifier = productDetailsData && productDetailsData?.products.find((ele) => ele.id === selectedProduct)?.identifier;
    // setProductidentifier(ideantifier)
    // console.log(ideantifier, 'ideantifierideantifier')
  }, [productDetailsData]);

  console.log(selectedProduct, shortlistedProducts, 'dddddddddddddd');
  return (
    <Grid container direction="column" item xs={12} className={classes.vendorGrid}>
      {
        <Grid container direction="column" item xs={3}>
          <Typography variant="h5">Selected Product</Typography>
        </Grid>
      }
      {productDetailsData?.products &&
        selectedProduct &&
        productDetailsData?.products?.map((element) => {
          // console.log(selectedProduct, element, Number(selectedProduct), 'number')
          if (element.identifier === selectedProduct) {
            return (
              <ProductCard
                widthstyle={widthstyle}
                productImage={element?.image}
                productName={element?.name}
                productBrandName={element?.brand?.name}
                productShortDescription={element?.shortDescription}
                productCertifications={element?.certifications}
                ecolabels={element?.ecolabels}
                productCategoryID={element?.category?.id}
                productID={element?.id}
                identifier={element?.identifier}
                dirtyValue={dirtyValue}
                activeSandbox={activeSandbox}
                source="intervention"
                section="selected"
                mappingid={iid}
                getInterventionMappingData={getInterventionMappingData}
                projectid={projectid}
                handleRefreshdetails={handleRefreshdetails}
                handleBackdropOpen={handleBackdropOpen}
                productIdeantifier={getProductIdeantifier}
                interventionname={interventionname}
                interventiondetails={interventiondetails}
                product={element}
                defaultProduct={defaultProduct}
                selectedProduct={selectedProduct}
                dataReload={dataReload}
                setDataReload={setDataReload}
              />
            );
          }
        })}
      {filteredProducts && filteredProducts.length !== 0 ? (
        <>
          {' '}
          <Grid container direction="column" item xs={3}>
            <Typography variant="h5" className={classes.textwithrefresh}>
              Shortlisted Products
              <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
            </Typography>
          </Grid>
          <Grid container direction="row" item xs={12}>
            <DummyAddProductCard
              widthstyle={widthstyle}
              mappingid={iid}
              categoryName={getCategoryname}
              subcategoryName={getSubcategory(interventiondetails && interventiondetails, interventionname && interventionname)}
            />
            {filteredProducts &&
              filteredProducts.map((ele) => {
                return (
                  <ProductCard
                    widthstyle={widthstyle}
                    productImage={ele?.image}
                    productName={ele?.name}
                    productBrandName={ele?.brand?.name}
                    productShortDescription={ele?.shortDescription}
                    productCertifications={ele?.certifications}
                    ecolabels={ele?.ecolabels}
                    productCategoryID={ele?.category?.id}
                    productID={ele?.id}
                    identifier={ele?.identifier}
                    dirtyValue={dirtyValue}
                    activeSandbox={activeSandbox}
                    source="intervention"
                    section="shortlisted"
                    mappingid={iid}
                    getInterventionMappingData={getInterventionMappingData}
                    projectid={projectid}
                    handleRefreshdetails={handleRefreshdetails}
                    handleBackdropOpen={handleBackdropOpen}
                    productIdeantifier={getProductIdeantifier}
                    interventionname={interventionname}
                    interventiondetails={interventiondetails}
                    product={ele}
                    defaultProduct={defaultProduct}
                    selectedProduct={selectedProduct}
                    dataReload={dataReload}
                    setDataReload={setDataReload}
                  />
                );
              })}
          </Grid>
        </>
      ) : (
        <>
          <Grid container direction="column" item xs={3}>
            <Typography variant="h5" className={classes.textwithrefresh}>
              Shortlisted Products
              <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
            </Typography>
          </Grid>
          <Grid container direction="row" item xs={12}>
            <DummyAddProductCard
              widthstyle={widthstyle}
              mappingid={iid}
              categoryName={getCategoryname}
              subcategoryName={getSubcategory(interventiondetails && interventiondetails, interventionname && interventionname)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
