import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { FilterSections, FilterSubSections } from '../../sidebar/FilterSidebar';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
  texttitle: MarketplaceTypography.BodySamiBold,
  arrowstyle: {
    color: MarketplaceColors.Secondary,
    fontSize: '180%',
  },
  padding: {
    paddingTop: '5%',
  },
  paddingforleft: {
    paddingLeft: '7px',
  },
}));

const FilterSidebarCategories = (props) => {
  const classes = useStyles();

  const { URLS, appliedfilters } = useContext(ProductsPageContext);

  const { source } = URLS;

  const {
    categories,
    section,
    setSection,
    subsection,
    setSubSection,
    CheckboxComponent,
    uniqueSubcategories,
    uniqueBrands,
    uniqueEcolabels,
    userSelectedFirstSection,
  } = props;

  const handleSection = (section, categoryName) => (section === categoryName ? setSection(FilterSections.NoSection) : setSection(categoryName));
  const handleSubSection = (subsection, subCategoryName) =>
    subsection === subCategoryName ? setSubSection(FilterSubSections.NoSection) : setSubSection(subCategoryName);
  const getIsCategorythere = (categoryname) => appliedfilters.find((ele) => ele.value === categoryname);

  const filtersData = (filter, handleSubSection, subsection, CheckboxComponent) =>
    filter?.attributes?.name !== 'Sub Category' && (
      <Grid container>
        <Typography
          className={`${classes.texttitle} ${classes.padding} ${classes.paddingforleft}`}
          onClick={() => handleSubSection(subsection, filter?.attributes?.name)}
          style={{ display: 'flex', alignItems: 'center', color: MarketplaceColors.Breadcrumb, cursor: 'pointer' }}
        >
          {filter?.attributes?.name}
          {subsection === filter?.attributes?.name && <ArrowDropUpIcon className={classes.arrowstyle} />}
          {subsection !== filter?.attributes?.name && <ArrowDropDownIcon className={classes.arrowstyle} />}
        </Typography>

        {filter?.attributes?.name !== 'Eco Labels'
          ? subsection === filter?.attributes?.name && filter?.attributes?.options.map((option) => CheckboxComponent(FilterSections.Filters, option?.option))
          : userSelectedFirstSection != 'Filters'
            ? uniqueEcolabels && uniqueEcolabels.map((option) => CheckboxComponent(FilterSections.Filters, option))
            : subsection === filter?.attributes?.name && filter?.attributes?.options.map((option) => CheckboxComponent(FilterSections.Filters, option?.option))}
      </Grid>
    );
  const CategoriesAndSubcategories = (category) => (
    <Grid container xs={12}>
      <Typography
        className={`${classes.texttitle} ${classes.padding}`}
        onClick={() => handleSection(section, category?.attributes?.name)}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        {category?.attributes?.name}
        {section === category?.attributes?.name && <ArrowDropUpIcon className={classes.arrowstyle} />}
        {section !== category?.attributes?.name && <ArrowDropDownIcon className={classes.arrowstyle} />}
      </Typography>
      {section === category?.attributes?.name && (
        <Grid container>
          <Typography
            className={`${classes.texttitle} ${classes.padding} ${classes.paddingforleft}`}
            onClick={() => handleSubSection(subsection, FilterSubSections.SubCategory)}
            style={{ display: 'flex', alignItems: 'center', color: MarketplaceColors.Breadcrumb, cursor: 'pointer' }}
          >
            Subcategories
            {subsection === FilterSubSections.SubCategory && <ArrowDropUpIcon className={classes.arrowstyle} />}
            {subsection !== FilterSubSections.SubCategory && <ArrowDropDownIcon className={classes.arrowstyle} />}
          </Typography>
          {/* subcategories based upon users first selection*/}
          {subsection === FilterSubSections.SubCategory && userSelectedFirstSection != 'Brands'
            ? category?.attributes?.subcategories?.data.map((subcategory) => CheckboxComponent(subsection, subcategory?.attributes?.name))
            : subsection === FilterSubSections.SubCategory && userSelectedFirstSection == 'Brands'
              ? Object.entries(uniqueSubcategories)
                .filter(([categoryId, { categoryName }]) => categoryName === category?.attributes?.name)
                .map(([categoryId, { subcategories }]) => ({ categoryId, subcategories: [...subcategories] }))[0]
                ?.subcategories.map((subcategory) => CheckboxComponent(subsection, subcategory))
              : subsection === FilterSubSections.SubCategory &&
              category?.attributes?.subcategories?.data.map((subcategory) => CheckboxComponent(subsection, subcategory?.attributes?.name))}

          {/* filters */}
          {category?.attributes?.filters?.data.map((filter) => filtersData(filter, handleSubSection, subsection, CheckboxComponent))}
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {categories &&
        categories.map((category) => {
          if (source === NavigatorSource.supercategories && getIsCategorythere(category?.attributes?.name) !== undefined)
            return CategoriesAndSubcategories(category);
          else if (source === NavigatorSource.categories || source === NavigatorSource.intervention) return CategoriesAndSubcategories(category);
        })}
    </>
  );
};

export default FilterSidebarCategories;
