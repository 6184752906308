// Build query filter for custom products
export const buildCustomProductsQuery = (category, subcategory, brands, certifications, page, pagesize, filters, sort) => {
  let query = `page:${page}, pagesize:${pagesize}`;
  let categoryquery = `categoryQuery:"${category}"`;
  let subcategoryquery = `subCategoryQuery:"${subcategory}"`;
  let brandqueryquery = `brandQuery:"${brands}"`;
  let certificationsquery = `certificationQuery:"${certifications}"`;
  let filtersquery = `filtersQuery:"${filters}"`;
  let sortquery = `sortQuery:"${sort}"`;
  if (category) query += `,${categoryquery}`;
  if (subcategory) query += `,${subcategoryquery}`;
  if (brands) query += `,${brandqueryquery}`;
  if (certifications) query += `,${certificationsquery}`;
  if (filters) query += `,${filtersquery}`;
  if (sort) query += `,${sortquery}`;
  return query;
};

//build getProducts query for source intervention
export const buildgetProductsQuery = (category, subcategory, brands, certifications, page, pagesize, filters, sort, identifiers, productstatus) => {
  // console.log(identifiers, 'identifiers')
  let query = `page:${page}, pagesize:${pagesize}`;
  let categoryquery = `categoryQuery:"${category}"`;
  let subcategoryquery = `subCategoryQuery:"${subcategory}"`;
  let brandqueryquery = `brandQuery:"${brands}"`;
  let certificationsquery = `certificationQuery:"${certifications}"`;
  let filtersquery = `filtersQuery:"${filters}"`;
  let sortquery = `sortQuery:"${sort}"`;
  let productquery = `identifiers:"${identifiers}"`;
  let productqueryfornoproduct = `identifiers:${identifiers}`;
  let productstatusquery = `productstatus:"${productstatus}"`
  if (category) query += `,${categoryquery}`;
  if (subcategory) query += `,${subcategoryquery}`;
  if (brands) query += `,${brandqueryquery}`;
  if (certifications) query += `,${certificationsquery}`;
  if (filters) query += `,${filtersquery}`;
  if (sort) query += `,${sortquery}`;
  if (identifiers === '"noproducts"') {
    console.log('inside 2', 'identifiers')
    query += `,${productqueryfornoproduct}`
  } else if (identifiers !== '"noproducts"') {
    console.log('inside 1', 'identifiers')
    query += `,${productquery}`
  } else {
    query += `identifiers:""`
  }
  if (productstatus) query += `,${productstatusquery}`
  return query;
};

//build query filter for custom super category
export const buildCustomSupercategoryQuery = (category, subcategory, superCategory, certifications, brands, page, pagesize, filters, sort) => {
  let query = `page:${page}, pagesize:${pagesize}, supercategoryQuery:"${superCategory}"`;
  let categoryquery = `categoryQuery:"${category}"`;
  let subcategoryquery = `subCategoryQuery:"${subcategory}"`;
  let brandqueryquery = `brandQuery:"${brands}"`;
  let sortquery = `sortQuery:"${sort}"`;
  // let certificationsquery = `certificationQuery:"${certifications}"`;
  let filtersquery = `filtersQuery:"${filters}"`;
  if (category) query += `,${categoryquery}`;
  if (subcategory) query += `,${subcategoryquery}`;
  if (brands) query += `,${brandqueryquery}`;
  // if (certifications) query += `,${certificationsquery}`;
  if (filters) query += `,${filtersquery}`;
  if (sort) query += `,${sortquery}`;
  return query;
};

//build query filter for eco labels
export const buildEcolabelQuery = (brands, page, pagesize, filters, sort) => {
  let query = `page:${page}, pagesize:${pagesize}`;
  let brandqueryquery = `brandQuery:"${brands}"`;
  let filtersquery = `filtersQuery:"${filters}"`;
  let sortquery = `sortQuery:"${sort}"`;
  if (brands) query += `,${brandqueryquery}`;
  if (filters) query += `,${filtersquery}`;
  if (sort) query += `,${sortquery}`;
  return query;
};

//build query filter for search
export const buildSearchQuery = (brands, page, pagesize, filters, sort, searchquery) => {
  let query = `page:${page}, pagesize:${pagesize}`;
  // let brandqueryquery = `brandQuery:"${brands}"`;
  // let filtersquery = `filtersQuery:"${filters}"`;
  let sortquery = `sortQuery:"${sort}"`;
  let searchParam = `searchParam:"${searchquery}"`;

  // if (brands) query += `,${brandqueryquery}`;
  // if (filters) query += `,${filtersquery}`;
  if (sort) query += `,${sortquery}`;
  if (searchquery) query += `,${searchParam}`;
  return query;
};
