import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import axios from '../../../../../config/Axios';
import PrimaryButton from '../../../../common/button/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '../../../../../assets/interventions/Group 73.svg';
import S3 from 'react-aws-s3';
import { NotetakingContext } from '../../../../../projectRouting/Index';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999999,
    },
    paper: {
        position: 'absolute',
        width: '600px',
        outline: 'none',
        height: 400,
        maxHeight: 600,
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        padding: theme.spacing(2, 2, 2, 3),
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    linePadding: {
        marginTop: '2%'
    },
    gridstyle: {
        marginTop: '2%',
        display: 'flex',
        alignItems: 'center'
    },
    inputfieldstyle: {
        display: 'none'
    },
    labelstyle: {
        color: 'rgb(102, 227, 190)',
        cursor: 'pointer',
        marginTop: '1%',
        fontSize: '16px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    addboxiconstyle: {
        color: 'rgb(102, 227, 190)',
        width: '35px',
        height: '70%',
        cursor: "pointer"
    },
    errorcolor: {
        color: "rgba(237, 94, 104, 1)",
        paddingRight: "10px"
    },
    trybuttonstyle: {
        color: '#00C58A',
        textDecoration: 'underline',
        cursor: 'pointer',
        paddingLeft: "10px"
    },
    uploadingTextStyle: {
        fontSize: '16px',
        fontWeight: 800,
        lineHeight: '25px',
        letterSpacing: '0.7142857313156128px',
        textAlign: 'left',
        color: 'rgba(0, 197, 138, 1)',
        display: 'flex',
        alignItems: 'center',
    },
    circularProgressStyle: {
        marginRight: "10px",
        color: 'rgba(0, 197, 138, 1)',
    },
    padding: {
        paddingTop: "5%"
    },
    imagedivstyle: {
        position: 'relative',
        display: 'inline-block',
        marginRight: '10px'
    },
    imagepreviewstyle: {
        width: "80px",
        height: "70px",
        transition: 'opacity 0.3s ease-in-out',
        cursor: "pointer",
        border: '1px solid rgba(204, 204, 204, 1)',
        borderRadius: "8px",
        '&:hover': {
            border: '1px solid rgba(73, 221, 177, 1)'
        }
    },
    deleteiconstyle: {
        position: 'absolute',
        top: '23px',
        right: '30px',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
        cursor: "pointer"
    },
    bottomdivstyle: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        padding: '20px'
    },
    cancelbuttonstyle: {
        color: '#00C58A',
        cursor: 'pointer'
    }
}));

const UploadImage = ({
    imageModal,
    setImageModal,
    statcAssetids,
    setStaticAssetIds,
    Uploadedimagesurls,
    setUploadedimageURLS
}) => {
    const classes = useStyles();
    const [uploadError, setUploadError] = useState(false);
    const handleOpen = () => setImageModal(true)
    const { notetakingContext } = useContext(NotetakingContext)

    const [selectedFile, setSelectedFile] = useState([]);
    const [sizeError, setSizeError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [startUpload, setStartUpload] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [fileURLS, setFileURLS] = useState([])
    const [limit, setLimit] = useState(false)
    const [currentfile, setCurrentFile] = useState('')
    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        // console.log(selectedFile.length)
        // console.log(file)
        let size = (file.size / 1024 / 1024).toFixed(2);
        let filearr = file.name.split('.');
        let type = filearr[filearr.length - 1];
        if (size > 5) {
            setSizeError(true);
        } else if (
            type !== 'png' &&
            type !== 'jpg' &&
            type !== 'jpeg'
        ) {
            setFileError(true);
        } else if (sizeError === false && fileError === false) {
            selectedFile.push(file);
            setCurrentFile(file)
        }
    };

    useEffect(() => {
        if (currentfile) {
            if (selectedFile.length >= 5) { setLimit(true) }
            const fileURL = URL.createObjectURL(currentfile);
            setFileURLS(prevFileURLs => [...prevFileURLs, fileURL]);
        }
    }, [currentfile]);


    const handleClose = () => {
        setImageModal(false);
        setSizeError(false);
        setFileError(false);
        setIsSuccess(false);
        setSelectedFile([]);
        setStartUpload(false);
        setLimit(false);
        setFileURLS([])
    };

    const handleSave = () => {
        console.log(selectedFile, isSuccess, 'isSuccess')
        if (selectedFile.length !== 0 && isSuccess === false) {
            setStartUpload(true);
            selectedFile.map((file) => {
                const config = {
                    bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
                    region: 'us-east-2',
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                    dirName: 'public/notes',
                    ContentType: file.type,
                    ACL: 'public-read',
                }
                const ReactS3Client = new S3(config);
                ReactS3Client.uploadFile(file, file.name)
                    .then((response) => {
                        if (response && response.status === 204) {
                            // Uploadedimagesurls.push(response.location)
                            const body = {
                                URL: response.location,
                            };
                            axios
                                .post('/sign-s3', body)
                                .then((res) => {
                                    if (res && res.data.data) {
                                        setStaticAssetIds(prevAssetids => [...prevAssetids, res.data.data.data._id]);
                                        setUploadedimageURLS(prevAssetURLs => [...prevAssetURLs, res.data.data.data]);
                                        setStartUpload(false);
                                        handleClose()
                                    }
                                })
                                .catch((err) => {
                                    setUploadError(true)
                                    console.log(err, 'err');
                                });
                        } else {
                            setUploadError(true)
                            // console.log('fail');
                        }
                    });
            })
        } else {
            handleClose()
        }
    };

    const handleDeleteClick = (index) => {
        if (Uploadedimagesurls.length !== 0) {
            const newAssetIds = [...statcAssetids];
            const newUploadImageUrls = [...Uploadedimagesurls];
            newAssetIds.splice(index, 1);
            newUploadImageUrls.splice(index, 1);
            setStaticAssetIds(newAssetIds);
            setUploadedimageURLS(newUploadImageUrls);
            setLimit(false)
        } else {
            const newFiles = [...selectedFile];
            const newURLS = [...fileURLS]
            newFiles.splice(index, 1);
            newURLS.splice(index, 1)
            setFileURLS(newURLS)
            setSelectedFile(newFiles);
            setLimit(false)
        }
    }
    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                style={{
                    zIndex: notetakingContext ? 999999 : 1000
                }}
                open={imageModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={imageModal}>
                    <div className={classes.paper}>
                        <Grid container justify="left" direction="row" item xs={12}>
                            <Grid xs={12} item>
                                <Typography variant="h5">
                                    <b>Upload Images </b>{' '}
                                </Typography>
                            </Grid>
                            {/* ADD FILE */}
                            <Grid xs={12} item direction="column" className={classes.linePadding}>
                                <Typography variant="h6" color="textSecondary">
                                    Supported formats -<b>.JPG, .PNG, .jpeg</b>
                                </Typography>
                                <Typography variant="h6" className={classes.linePadding} color="textSecondary">
                                    Max file size 5 MB. Max 5 attachments per note.
                                </Typography>
                                <Grid item container xs={12} className={classes.gridstyle}>
                                    {!uploadError && !limit && !sizeError && !fileError && !startUpload && (
                                        <div className={classes.gridstyle}>
                                            <input className={classes.inputfieldstyle} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} />
                                            <label htmlFor="upload-photo" className={classes.labelstyle}> <AddBoxIcon className={classes.addboxiconstyle} />Browse</label>
                                        </div>
                                    )}
                                    {!uploadError && limit && !sizeError && !fileError && (
                                        <div className={classes.gridstyle}>
                                            <input className={classes.inputfieldstyle} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} disabled />
                                            <label htmlFor="upload-photo" style={{ color: "rgba(158, 158, 158, 1)" }} className={classes.labelstyle}><AddBoxIcon className={classes.addboxiconstyle} style={{ color: "rgba(158, 158, 158, 1)" }} />Browse</label>
                                        </div>
                                    )}
                                    {sizeError && (
                                        <>
                                            <Typography variant="h6" className={classes.errorcolor}>
                                                <b>File size exceeds limit<span className={classes.trybuttonstyle} onClick={() => setSizeError(false)}>Try Again</span>
                                                </b>
                                            </Typography>
                                        </>
                                    )}
                                    {fileError && (
                                        <>
                                            <Typography variant="h6" className={classes.errorcolor}>
                                                <b>
                                                    Incompatible file type
                                                    <span className={classes.trybuttonstyle} onClick={() => setFileError(false)}>Try Again</span>
                                                </b>
                                            </Typography>
                                        </>
                                    )}
                                    {startUpload ? (
                                        <>
                                            <Typography className={classes.uploadingTextStyle}>
                                                <CircularProgress className={classes.circularProgressStyle} size={20} /> Uploading
                                            </Typography>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {isSuccess && (
                                        <div className={classes.gridstyle}>
                                            <input className={classes.inputfieldstyle} id="upload-photo" name="upload-photo" type="file" onChange={handleUploadClick} />
                                            <label htmlFor="upload-photo" className={classes.labelstyle}><AddBoxIcon className={classes.addboxiconstyle} />Browse</label>
                                        </div>
                                    )}
                                    {uploadError && (
                                        <>
                                            <Typography variant="h6" className={classes.errorcolor}>
                                                <b>
                                                    Error Uploading File.
                                                    <span className={classes.trybuttonstyle} onClick={() => setUploadError(false)}> Try Again </span>
                                                </b>
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                                <Grid container item xs={12} direction="row" className={classes.padding}>
                                    {fileURLS && fileURLS.map((file, index) => (
                                        <div key={index} className={classes.imagedivstyle}>
                                            <img
                                                src={file}
                                                alt={`Uploaded file ${index}`}
                                                className={classes.imagepreviewstyle}
                                                onMouseEnter={(e) => {
                                                    e.target.style.opacity = 0.3;
                                                    e.target.nextSibling.style.opacity = 1;
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.opacity = 1;
                                                    e.target.nextSibling.style.opacity = 0;
                                                }}
                                            />
                                            <img
                                                className={classes.deleteiconstyle}
                                                onClick={() => handleDeleteClick(index)}
                                                onMouseEnter={(e) => {
                                                    e.target.previousSibling.style.opacity = 0.3;
                                                    e.target.style.opacity = 1
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.previousSibling.style.opacity = 1;
                                                    e.target.style.opacity = 0
                                                }}
                                                src={DeleteIcon}
                                                alt="Delete"
                                                height="18px"
                                                width="18px"
                                            />
                                        </div>
                                    ))}
                                    {Uploadedimagesurls && Uploadedimagesurls.map((asset, index) => (
                                        <div key={index} className={classes.imagedivstyle}>
                                            <img
                                                src={asset?.URL}
                                                alt={`Uploaded file ${index}`}
                                                className={classes.imagepreviewstyle}
                                                onMouseEnter={(e) => {
                                                    e.target.style.opacity = 0.3;
                                                    e.target.nextSibling.style.opacity = 1;
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.opacity = 1;
                                                    e.target.nextSibling.style.opacity = 0;
                                                }}
                                            />
                                            <img
                                                className={classes.deleteiconstyle}
                                                onClick={() => handleDeleteClick(index)}
                                                onMouseEnter={(e) => {
                                                    e.target.previousSibling.style.opacity = 0.3;
                                                    e.target.style.opacity = 1
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.previousSibling.style.opacity = 1;
                                                    e.target.style.opacity = 0
                                                }}
                                                src={DeleteIcon}
                                                alt="Delete"
                                                height="18px"
                                                width="18px"
                                            />
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>

                            {/* BUTTON GROUP */}
                            <div className={classes.bottomdivstyle}>
                                <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                                    <Grid item xs={2}>
                                        <Typography variant="h6" className={classes.cancelbuttonstyle} onClick={handleClose}>Cancel</Typography>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={3}>
                                        <PrimaryButton children="Add" onClick={handleSave} />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default UploadImage;
