import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Backdrop, CircularProgress, makeStyles, Grid } from '@material-ui/core';
import Header from '../../common/header/Header';
import solarPV from '../../../assets/solarPV.svg';
import HotWaterManagement from '../../../assets/HotWaterManagement.svg';
import solarData from '../../../assets/faq-data/SolarPV';
import HotWaterManagementData from '../../../assets/faq-data/HotWaterManagement';
import Sidebar from '../../common/sidebar/Sidebar';
const FaqUsers = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: '10%',
        },
        heading: {
            marginTop: '3%',
            width: '100%',
        },
        link: {
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 850,
            textDecorationLine: 'underline',
            color: '#00C58A',
            paddingTop: '2%',
        },
        subheading: {
            fontWeight: 850,
            color: '#00C58A',
            cursor: 'pointer',
        },
        question: {
            lineHeight: '22px',
            fontWeight: 900,
            letterSpacing: '0.714286px',
            color: '#000000',
            paddingTop: '2%',
        },
        answer: {
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 500,
            letterSpacing: '0.714286px',
            color: '#000000',
            paddingLeft: '12px',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        expand_icon: {
            color: "#26E0A3",
            height: "32px",
            width: "32px",
            borderRadius: "50%",
            backgroundColor: "#F0FBF2",
            marginRight: '8px',
        },
        expand_icon_top: {
            color: "#26E0A3",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            backgroundColor: "#F0FBF2",
        },
        imageText: {
            textAlign: 'left',
            display: 'inline-block',
            '& div': {
                textAlign: 'center'
            }
        },
        fontTextBold: {
            fontFamily: "Lato",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.7142857313156128px"
        },
        fontSelected: {
            textDecoration: 'none !important',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.714286px',
            color: 'rgba(0, 0, 0, 0.82)',
            fontFamily: "Lato",
            fontWeight: "0px"
        },
        fontText: {
            textDecoration: 'none !important',
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.714286px',
            color: 'rgba(0, 0, 0, 0.6)',
            '&:focus, &:active, &:hover': {
                textDecoration: 'none',
                color: 'rgba(0, 0, 0, 0.82)',
            },
            fontFamily: "Lato, sans-serif",
        },
        fontTextTwo: {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "17px",
            letterSpacing: "0.7142857313156128px",
            padding: "0px 14px 14px 0px",
        },
        headingText: {
            fontFamily: "Lato",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "36px",
        },
        questionText: {
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "16px",
            letterSpacing: "0.7142857313156128px",
            padding: "10px 10px 10px 18px"
        },
        mainHeading: {
            fontFamily: "Lato",
            fontSize: "40px",
            fontWeight: 700,
            lineHeight: "48px",
            letterSpacing: "1.4285714626312256px"
        },
        MuiAccordionroot: {

            "&.MuiAccordion-root:before": {
                backgroundColor: "#fafafa"
            },
            '&.MuiAccordionSummary-root': {
                border: '1px solid #E5E5E5',
                borderRadius: '8px'
            },
            '&.Mui-expanded': {
                border: '1px solid #C6F2CD',
                borerRadius: '8px'
            },

        },
        accordion: {
            border: '1px solid #E5E5E5',
            marginBottom: '8px',
            borderRadius: '8px', // Default border for all accordions
            '&.Mui-expanded': {
                border: '1px solid #00C58A', // Border for the expanded accordion
                borderRadius: '8px',
            },
        },
        MuiAccordionrootstyle: {
            '&.MuiAccordionDetails-root': {
                padding: '0px'
            }
        }

    }));
    const classes = useStyles();
    const [loader, setLoader] = useState(false);
    let currentprojects = null;
    const [selectedTab, setSelectedTab] = useState('Solar PV');
    const [openAccordionSolarPV, setOpenAccordionSolarPV] = useState(true);
    const [openAccordionHotWaterManagement, setOpenAccordionHotWaterManagement] = useState(false);
    const toggleAcordion = () => {
    }

    return (
        <div style={{ display: 'flex' }}>
            <div >
                <Backdrop className={classes.backdrop} open={loader}>
                    <CircularProgress style={{ color: '#28DEAA' }} />
                </Backdrop>
                <Header currentprojects={currentprojects && currentprojects} />
            </div>
            <div style={{ marginTop: '4%', display: 'flex', flexDirection: 'row', }}>
                <div style={{ width: '14vw' }}>
                    <Sidebar fromFaq={true}>
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10%' }}>
                                <h4 className={classes.fontTextBold}>Categories</h4>
                            </div>
                            <div onClick={() => { setOpenAccordionHotWaterManagement(false); setOpenAccordionSolarPV(true); }} style={{ display: 'flex', alignItems: 'center', paddingLeft: '10%', cursor: 'pointer', height: '35px', background: openAccordionSolarPV === true ? `linear-gradient(to right, rgba(64, 225, 156, 0.1) 0%, rgba(64, 225, 156, 0.1) 100%)` : '', border: 'none' }} >
                                <Typography className={openAccordionSolarPV ? classes.fontSelected : classes.fontText}>Solar PV</Typography>
                            </div>
                            <div onClick={() => { setOpenAccordionSolarPV(false); setOpenAccordionHotWaterManagement(true); }} style={{ cursor: 'pointer', height: '35px', display: 'flex', alignItems: 'center', paddingLeft: '10%', background: openAccordionHotWaterManagement === true ? `linear-gradient(to right, rgba(64, 225, 156, 0.1) 0%, rgba(64, 225, 156, 0.1) 100%)` : '', border: 'none' }}>
                                <Typography className={openAccordionHotWaterManagement ? classes.fontSelected : classes.fontText}>Hot Water Management</Typography>
                            </div>
                        </>
                    </Sidebar >
                </div>
                <div style={{ width: '80vw', marginLeft: '2%', marginTop: '1%' }}>
                    <Typography variant="h1" classes={classes.mainHeading}>FAQ</Typography>
                    <Typography className={classes.fontText}>This section addresses common questions you may have about interventions, mandates or calculations to help you get the most out of SD+. If you don't find what you're looking for here, feel free to contact us at <span style={{ color: "#26E0A3" }}>connect@sdplus.io</span></Typography>
                    {
                        <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', backgroundColor: '#fafafa', padding: '0px' }} expanded={openAccordionSolarPV}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                IconButtonProps={{
                                    onClick: () => {
                                        setOpenAccordionSolarPV(value => !value);
                                        setOpenAccordionHotWaterManagement(false);
                                        setSelectedTab('Solar PV');
                                    }
                                }}
                                style={{ padding: "0px" }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={solarPV} />
                                    <Typography className={classes.headingText} variant="h5" style={{ marginLeft: '2px' }}> Solar PV</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>

                                {solarData.data.map(d => {
                                    return (
                                        <Accordion className={classes.accordion} style={{ boxShadow: 'none', backgroundColor: '#fafafa' }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                style={{ padding: "0px" }}
                                            >
                                                <Typography className={classes.questionText}>{d.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.fontTextTwo}><span className={classes.imageText} dangerouslySetInnerHTML={{ __html: d.response }} /></Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>

                    }
                    {
                        <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', backgroundColor: '#fafafa', padding: '0px' }} expanded={openAccordionHotWaterManagement}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                IconButtonProps={{
                                    onClick: () => {
                                        setOpenAccordionHotWaterManagement(value => !value);
                                        setOpenAccordionSolarPV(false);
                                        setSelectedTab('Hot Water Management');
                                    }
                                }}
                                style={{ padding: "0px" }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={HotWaterManagement} />
                                    <Typography className={classes.headingText} variant="h5" style={{ marginLeft: '2px' }}>Hot Water Management</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>

                                {HotWaterManagementData.data.map(d => {
                                    return (
                                        <Accordion className={classes.accordion} style={{ boxShadow: 'none', backgroundColor: '#fafafa' }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                style={{ padding: "0px" }}

                                            >
                                                <Typography className={classes.questionText}>{d.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.fontTextTwo}><span className={classes.imageText} dangerouslySetInnerHTML={{ __html: d.response }} /></Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>

                    }
                </div>
            </div>
        </div >
    )
}

export default FaqUsers;