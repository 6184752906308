export const interventionDetailsQuery = (identifiers) =>
  `{
  getProducts(identifiers: "${identifiers}") {
    totalproducts
    products {
      id
      name
      shortDescription
      image
      brand {
        id
        name
      }
      category{
        id
        name
      }
      subcategory{
        id
        name
      }
      ecolabels
      qualityAssuranceReliability
      environmentalImpactEcosystemHealth
      environmentalImpactHumanHealth
      environmentalImpactCircularity
      sdgs
      misc
      resourceSavings
      environmentalImpactClimateHealth
      productCode
      warrantyAfterSales
      references
      defaultProductTag
      sdplusInterventions
      dimension
      price
      properties
      identifier
      filters
    }
  }
}
`;
