import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, LinearProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

function ContributionToGoalsCard(props) {
  const useStyles = makeStyles((theme) => ({
    paper4: {
      outline: 'none',
      height: props.height,
      width: props.width,
      padding: theme.spacing(1, 2, 2, 0),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    progress: {
      flexGrow: 1,
      marginTop: '8px',
    },
    grid: {
      marginTop: '7%',
    },
  }));
  const classes = useStyles();
  const { inputs } = props;
  const target = inputs ? inputs.filter((item) => item && item.tags[0] === 'goal-target') : '';
  const contribution = inputs ? inputs.filter((item) => item && item.tags[0] === 'goal-contribution') : '';
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#F28D14',
    },
  }))(LinearProgress);
  const BorderLinearProgress2 = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: props.goalcontributioncolor,
    },
  }))(LinearProgress);
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <>
      <Card className={classes.paper4}>
        <Grid container alignItems="center" direction="row" xs={12}>
          <Grid xs={12} container alignItems="center" direction="column">
            <Typography color="textSecondary">{target && target[0].label}</Typography>
            <LightTooltip title={`${target && target[0].value}%`} placement="top-start">
              <div className={classes.progress}>
                <BorderLinearProgress variant="determinate" value={target && target[0].value} style={{ width: 180 }} />
              </div>
            </LightTooltip>
          </Grid>
          <Grid xs={12} container alignItems="center" direction="column" className={classes.grid}>
            <Typography color="textSecondary">{contribution && contribution[0].label}</Typography>
            <LightTooltip title={`${contribution && contribution[0].value}%`} placement="bottom-start">
              <div className={classes.progress}>
                <BorderLinearProgress2 variant="determinate" value={contribution && contribution[0].value} style={{ width: 180 }} />
              </div>
            </LightTooltip>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default ContributionToGoalsCard;
