import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const CommonHeadingWithIcon = (props) => {
  const { variant } = props
  return (
    <>
      <Grid container alignItems="center" item xs={12}>
        <Grid item xs={0} align="right">
          <ArrowBackIosIcon onClick={props.handleClick} style={{ color: '#66E3BE', cursor: 'pointer' }} />
        </Grid>
        <Grid item xs={10} align="left">
          <Typography variant={variant ? variant : "h4"}>{props.heading}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonHeadingWithIcon;
