import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

export const HWMVillaCustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    const userswhcapcity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhCapacity')
    const optionforswhVilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.options')
    const swhTypeVilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhType')
    const hwmVillaswhVariant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhVariant')
    const hwmhpTypeVilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.HpType')
    const hwmhpheatingVilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.heatingSpeed')
    const individualhpproductcapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.hpindividualProductCapacity')
    const individualswhproductcapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhindividualProductCapacity')
    const hpnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhnumberOfProducts')
    const swhnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.hpnumberOfProducts')
    const swharearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.swhareaRequired')
    const hparearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.hpareaRequired')
    const hwmVillauserHpCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.userHpCapacity')

    // const [swhVillaOption, setSwhVillaOption] = useState('')
    const [heatPump, setHeatpump] = useState(false)
    const [solarWaterHeater, setSolarWaterHeater] = useState(false)
    // const [swhCapacity, setSwhCapacity] = useState(0)
    const [swhType, setSwhType] = useState('')
    const [swhTechnology, setSwhTechnology] = useState('')
    const [hpCapacity, setHpCapacity] = useState(0)
    const [hpType, setHpType] = useState('')
    const [hpTemp, setHpTemp] = useState('')

    useEffect(() => {
        if (optionforswhVilla.value === "solar water heater") {
            setSolarWaterHeater(true)
        } else if (optionforswhVilla.value === 'heat pump') {
            setHeatpump(true)
        }
        if (swhTypeVilla.value === 'individual') {
            setSwhType('individual')
        }
        if (hwmVillaswhVariant.value === 'etc') {
            setSwhTechnology('etc')
        } else if (hwmVillaswhVariant.value === "fpc") {
            setSwhTechnology('fpc')
        }
        if (hwmhpTypeVilla.value === "domestic") {
            setHpType('domestic')
        }
        if (hwmhpheatingVilla.value === 'slow') {
            setHpTemp('slow')
        } else if (hwmhpheatingVilla.value === 'fast') {
            setHpTemp('fast')
        }
    }, [])
    const handleHwmVillaOption = (option) => {
        if (option === 'solar water heater' && !solarWaterHeater) {
            setSolarWaterHeater(true);
            setHeatpump(false);
            appendChanged(optionforswhVilla.id, 'solar water heater')
        } else if (option === 'heat pump' && !heatPump) {
            setSolarWaterHeater(false);
            setHeatpump(true);
            appendChanged(optionforswhVilla.id, 'heat pump')
        }
    };
    const handleSWhType = (type) => {
        if (type === 'individual') {
            if (swhType === type) {
                setSwhType('individual')
                appendChanged(swhTypeVilla.id, 'individual');
            } else {
                setSwhType(type)
                appendChanged(swhTypeVilla.id, type);
            }
        }
    }
    const handleSWhTechnology = (technology) => {
        if (technology === 'etc') {
            if (swhTechnology === technology) {
                setSwhTechnology('etc')
                appendChanged(hwmVillaswhVariant.id, 'etc');
            } else {
                setSwhTechnology(technology)
                appendChanged(hwmVillaswhVariant.id, technology);
            }
        } else if (technology === 'fpc') {
            if (swhTechnology === technology) {
                setSwhTechnology('fpc')
                appendChanged(hwmVillaswhVariant.id, 'fpc');
            } else {
                setSwhTechnology(technology)
                appendChanged(hwmVillaswhVariant.id, technology);
            }
        }
    }
    const handleHpType = (type) => {
        if (type === 'domestic') {
            if (hpType === type) {
                setHpType('domestic')
                appendChanged(hwmhpTypeVilla.id, 'domestic');
            } else {
                setHpType(type)
                appendChanged(hwmhpTypeVilla.id, type);
            }
        }
    }
    const handleHpTemp = (temp) => {
        if (temp === 'slow') {
            if (hpTemp === temp) {
                setHpTemp('slow')
                appendChanged(hwmhpheatingVilla.id, 'slow');
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheatingVilla.id, temp);
            }
        } else if (temp === 'fast') {
            if (hpTemp === temp) {
                setHpTemp('fast')
                appendChanged(hwmhpheatingVilla.id, 'fast');
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheatingVilla.id, temp);
            }
        }
    }
    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Grid container direction="column" alignItems="left" item xs={12}>
                <Grid container item xs={12}>
                    <Grid item xs={4}>
                        <Typography className={classes.textHeader1}>
                            {solarWaterHeater ? (
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmVillaOption('solar water heater')} />
                            ) : (
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmVillaOption('solar water heater')} />
                            )}
                            Solar Water Heater
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.textHeader1}>
                            {heatPump ? (
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmVillaOption('heat pump')} />
                            ) : (
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmVillaOption('heat pump')} />
                            )}
                            Heat Pump
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {solarWaterHeater && <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                    <Grid xs>
                        <Typography className={classes.textheader2}>
                            Solar Water Heater Capacity
                        </Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d.id === userswhcapcity.id)
                                    ? userswhcapcity.value
                                    : myArray.find((d) => d.id === userswhcapcity.id) && myArray.find((d) => d.id === userswhcapcity.id).value
                                }
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(userswhcapcity.id, Number(e.target.value));
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(userswhcapcity.id, Number(userswhcapcity?.defaultValue))}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">LPD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} container alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Type</Typography>
                        <Typography className={classes.textHeader5}>
                            {<RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
                            Central</Typography>
                        <Typography className={classes.textHeader3}>
                            {swhType === 'individual' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhType('individual')} />}
                            {swhType !== 'individual' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhType('individual')} />}
                            Individual</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Technology</Typography>
                        <Typography className={classes.textHeader3}>
                            {swhTechnology === 'etc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                            {swhTechnology !== 'etc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                            ETC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                                placement="right"
                            />
                        </Typography>
                        <Typography className={classes.textHeader3}>
                            {swhTechnology === 'fpc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                            {swhTechnology !== 'fpc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                            FPC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                                placement="right"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </>}
            {heatPump && <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                    <Grid xs>
                        <Typography className={classes.textheader2}>
                            Heat Pump Capacity
                        </Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d.id === hwmVillauserHpCapacity.id)
                                    ? hwmVillauserHpCapacity.value
                                    : myArray.find((d) => d.id === hwmVillauserHpCapacity.id) && myArray.find((d) => d.id === hwmVillauserHpCapacity.id).value
                                }
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    // setHpCapacity(e.target.value);
                                    appendChanged(hwmVillauserHpCapacity.id, Number(e.target.value))
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(hwmVillauserHpCapacity.id, Number(hwmVillauserHpCapacity?.defaultValue))} disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">LPD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} container alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Type</Typography>
                        <Typography className={classes.textHeader5}>
                            {<RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}Commercial</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpType === 'domestic' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('domestic')} />}
                            {hpType !== 'domestic' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />}
                            Domestic</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Heating</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpTemp === 'slow' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                            {hpTemp !== 'slow' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                            Slow Heating</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpTemp === 'fast' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                            {hpTemp !== 'fast' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                            Fast Heating</Typography>
                    </Grid>
                </Grid>
            </>}
            {solarWaterHeater && <Grid container item xs={12} direction="row">
                <Grid item xs={6}>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{individualswhproductcapacity?.value} LPD</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3} >No. of Products</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{swhnumberofproducts?.value} nos.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Area Required</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmVillaareaRequired?.value))} ${hwmVillaareaRequired?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{swharearequired?.value} sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            {heatPump && <Grid container item xs={12} direction="row">
                <Grid item xs={6}>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{individualhpproductcapacity?.value} LPH</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3} >No. of Products</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{hpnumberofproducts?.value} nos.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Area Required</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmVillaareaRequired?.value))} ${hwmVillaareaRequired?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{hparearequired?.value} sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid>
    );
};

export const HWMVillaCustomAdvancesParameters = (props) => {
    const classes = useStyles();
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const demandbybathvilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.demandByBath')
    const demandassumptiontypevilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.demandAssumptionType')
    const hwmVillademandAssumptionvilla = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.lpdPerVilla')
    const hwmVillelecostperkwh = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.ElectricityCostperkWh')
    const rooftopareaperswhlpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.rooftopAreaPerSWHLPD')
    const rooftopareaperhplpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.rooftopAreaPerHPLPD')
    const hotwaterusageshours = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.hotwaterusuageHours')
    const numberofplumbingshafts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.numberofPlumbingShafts')
    const co2emissionfactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.co2emmissionfactor')
    const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.multipliersource')
    const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.customCapexMultiplier')
    const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.vendorCapexMultiplier')
    const lpcdPerOccupant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmVilla.lpcdPerOccupant')

    const [demandBybath, setDemandByBath] = useState('')
    const [demandAssumption, setDemandAssumption] = useState('')
    const [assumptionperoccupant, setAssumptionperoccupant] = useState(0)
    const [assumptionPervilla, setAssumptionPervilla] = useState(0)
    const [vendorTextField, selectVendorTextField] = useState(false)
    const [customTextField, setCustomTextField] = useState(false)
    const [vendorCapex, setVendorCapex] = useState(0)
    const [customCapex, setCustomcapex] = useState(0)
    const [electricityCost, setElectricityCost] = useState(0)
    const [rftopAreaperSWhLPD, setRftopAreaperSWhLPD] = useState(0)
    const [rftopAreaperHPLPD, setRftopAreaperHPLPD] = useState(0)
    const [hwUsageHours, setHwUsageHours] = useState(0)
    const [numberofPlumbingShafts, setNumberofPlumbingShafts] = useState(0)
    const [disableDemandByBath, setDisableDemandByBath] = useState(false)

    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };

    useEffect(() => {

        if (demandassumptiontypevilla && demandassumptiontypevilla.value) {
            setDemandAssumption(demandassumptiontypevilla.value)
            setAssumptionPervilla(hwmVillademandAssumptionvilla.value)
            setAssumptionperoccupant(lpcdPerOccupant.value)
            setDemandByBath(demandbybathvilla.value)
        }
        if (customcapexmultiplier && customcapexmultiplier.value) {
            setCustomcapex(customcapexmultiplier.value)
        }

        if (hwmVillelecostperkwh && hwmVillelecostperkwh.value) {
            setElectricityCost(hwmVillelecostperkwh.value)
        }
        //
        if (rooftopareaperswhlpd && rooftopareaperswhlpd.value) {
            setRftopAreaperSWhLPD(rooftopareaperswhlpd.value)
        }
        if (rooftopareaperhplpd && rooftopareaperhplpd.value) {
            setRftopAreaperHPLPD(rooftopareaperhplpd.value)
        }
        if (hotwaterusageshours && hotwaterusageshours.value) {
            setHwUsageHours(hotwaterusageshours.value)
        }
        if (numberofplumbingshafts && numberofplumbingshafts.value) {
            setNumberofPlumbingShafts(numberofplumbingshafts.value)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'custom') {
            setCustomTextField(true)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'vendor') {
            selectVendorTextField(true)
        }
    }, [])

    const handleDemandByBath = (demandbath) => {
        if (demandbath === 'all baths') {
            if (demandBybath === demandbath) {
                setDemandByBath('all baths')
                appendChanged(demandbybathvilla.id, 'all baths');
            } else {
                setDemandByBath(demandbath)
                appendChanged(demandbybathvilla.id, demandbath);
            }
        } else if (demandbath === 'single bath') {
            if (demandBybath === demandbath) {
                setDemandByBath('single bath')
                appendChanged(demandbybathvilla.id, 'single bath');
            } else {
                setDemandByBath(demandbath)
                appendChanged(demandbybathvilla.id, demandbath);
            }
        } else if (demandbath === 'remaining baths') {
            if (demandBybath === demandbath) {
                setDemandByBath('remaining baths')
                appendChanged(demandbybathvilla.id, 'remaining baths');
            } else {
                setDemandByBath(demandbath)
                appendChanged(demandbybathvilla.id, demandbath);
            }
        } else if (demandbath === '' && demandAssumption !== 'lpcd per occupant') {
            setDemandByBath('')
            appendChanged(demandbybathvilla.id, '');
        }
    }
    const handleDemandByAssumption = (assumption) => {

        if (assumption === 'lpcd per occupant') {
            if (demandAssumption === assumption) {
                setDemandAssumption('lpcd per occupant')
                appendChanged(demandassumptiontypevilla.id, 'lpcd per occupant');
            } else {
                setDemandByBath('')
                appendChanged(demandbybathvilla.id, '')
                setDisableDemandByBath(false)
                setDemandAssumption(assumption)
                appendChanged(demandassumptiontypevilla.id, assumption);
            }
        } else if (assumption === 'lpd per villa') {
            if (demandAssumption === assumption) {
                setDemandAssumption('lpd per villa')
                appendChanged(demandassumptiontypevilla.id, 'lpd per villa');
                setDemandByBath('')
                appendChanged(demandbybathvilla.id, '')
                setDisableDemandByBath(true)
            } else {
                setDemandAssumption(assumption)
                appendChanged(demandassumptiontypevilla.id, assumption);
                setDisableDemandByBath(true)
            }
        } else if (assumption === 'link to installed efficient fixtures') {
            if (demandAssumption === assumption) {
                setDemandAssumption('link to installed efficient fixtures')
                appendChanged(demandassumptiontypevilla.id, 'link to installed efficient fixtures');

            } else {
                appendChanged(demandassumptiontypevilla.id, assumption);
                setDemandAssumption(assumption)
            }
        }
    }

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Typography className={classes.textHeader1}>Demand by Bath</Typography>
            <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
                {demandBybath === 'all baths' && !disableDemandByBath && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('all baths')} />}
                {demandBybath !== 'all baths' && !disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('all baths')} />}
                {demandBybath !== 'all baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
                All baths</Typography>
            <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
                {demandBybath === 'single bath' && !disableDemandByBath && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('single bath')} />}
                {demandBybath !== 'single bath' && !disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('single bath')} />}
                {demandBybath !== 'single bath' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
                Master bathroom</Typography>
            <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
                {demandBybath === 'remaining baths' && !disableDemandByBath && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />}
                {demandBybath !== 'remaining baths' && !disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />}
                {demandBybath !== 'remaining baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
                All baths except master bathroom</Typography>
            <Grid container item xs={12} direction="column" style={{ paddingTop: "3%" }}>
                <Typography className={classes.textHeader1}>Demand Assumption</Typography>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
                    <Grid xs>
                        <Typography className={classes.textHeader3}>
                            {demandAssumption === 'lpcd per occupant' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => {
                                handleDemandByAssumption('lpcd per occupant')
                                if (demandBybath === '') {
                                    setDemandByBath('all baths')
                                    appendChanged(demandbybathvilla.id, 'all baths')
                                }
                            }} />}
                            {demandAssumption !== 'lpcd per occupant' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => {
                                handleDemandByAssumption('lpcd per occupant')
                                setDemandByBath('all baths')
                                appendChanged(demandbybathvilla.id, 'all baths')
                            }} />}
                            Per Occupant</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                className={demandAssumption !== 'lpcd per occupant' ? classes.textDisable : undefined}
                                id="outlined-basic"
                                variant="outlined"
                                value={assumptionperoccupant}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setAssumptionperoccupant(e.target.value);
                                    appendChanged(lpcdPerOccupant.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAssumptionperoccupant(lpcdPerOccupant?.defaultValue)
                                    appendChanged(lpcdPerOccupant.id, lpcdPerOccupant?.defaultValue)
                                }
                                }
                                disabled={demandAssumption !== 'lpcd per occupant' ? true : false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6" className={demandAssumption !== 'lpcd per occupant' ? classes.textDisable : undefined}>LPCD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
                    <Grid xs>
                        <Typography className={classes.textHeader3}>
                            {demandAssumption === 'lpd per villa' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => {
                                handleDemandByAssumption('lpd per villa')
                                setDemandByBath('')
                                appendChanged(demandbybathvilla.id, '')
                            }
                            } />}
                            {demandAssumption !== 'lpd per villa' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => {
                                handleDemandByAssumption('lpd per villa')
                                setDemandByBath('')
                                appendChanged(demandbybathvilla.id, '')
                                disableDemandByBath(true)
                            }} />}
                            Per Villa</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={assumptionPervilla}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setAssumptionPervilla(e.target.value);
                                    appendChanged(hwmVillademandAssumptionvilla.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAssumptionPervilla(hwmVillademandAssumptionvilla?.defaultValue);
                                    appendChanged(hwmVillademandAssumptionvilla.id, hwmVillademandAssumptionvilla?.defaultValue);
                                }}
                                disabled={demandAssumption !== 'lpd per villa' ? true : false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6" className={demandAssumption !== 'lpd per villa' ? classes.textDisable : undefined}>LPCD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography className={classes.textHeader3}>
                    {demandAssumption === 'link to installed efficient fixtures' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => {
                        handleDemandByAssumption('link to installed efficient fixtures')
                        setDemandByBath('all baths')
                        appendChanged(demandbybathvilla.id, 'all baths')
                        setDisableDemandByBath(false)
                    }} />}
                    {demandAssumption !== 'link to installed efficient fixtures' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => {
                        handleDemandByAssumption('link to installed efficient fixtures')
                        setDemandByBath('all baths')
                        appendChanged(demandbybathvilla.id, 'all baths')
                        setDisableDemandByBath(false)
                    }} />}
                    As per selected Efficient Fixtures</Typography>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Intervention Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes will only affect this intervention </Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {vendorTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'vendor')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'vendor')
                                }} />}
                            Selected Vendor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <Typography className={classes.textHeader3}>{vendorcapexmultiplier.value} RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {customTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'custom')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'custom')
                                }} />}
                            Custom</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customCapex}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCustomcapex(e.target.value);
                                    appendChanged(customcapexmultiplier.id, e.target.value)
                                }}
                                fullWidth
                                required
                                disabled={!customTextField ? true : false}
                                removeResetIcon={true}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6" className={!customTextField ? classes.textDisable : undefined}>RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Project Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes may affect other project or intervention values</Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>Electricity Cost per kWh</Typography>
            <Grid item xs={4} direction="column" style={{ paddingTop: "10px" }}>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={electricityCost}
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                setElectricityCost(e.target.value);
                                appendChanged(hwmVillelecostperkwh.id, e.target.value);
                            }}
                            fullWidth
                            required
                            onClick={(e) => {
                                e.preventDefault();
                                setElectricityCost(hwmVillelecostperkwh?.defaultValue)
                                appendChanged(hwmVillelecostperkwh && hwmVillelecostperkwh.id, hwmVillelecostperkwh?.defaultValue);
                            }}
                            disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">RS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Constants</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Rooftop Area per SWH LPD</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={rftopAreaperSWhLPD}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRftopAreaperSWhLPD(e.target.value);
                                    appendChanged(rooftopareaperswhlpd.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRftopAreaperSWhLPD(rooftopareaperswhlpd?.defaultValue)
                                    appendChanged(rooftopareaperswhlpd && rooftopareaperswhlpd.id, rooftopareaperswhlpd?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Rooftop Area per HP LPD</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={rftopAreaperHPLPD}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRftopAreaperHPLPD(e.target.value);
                                    appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRftopAreaperHPLPD(rooftopareaperhplpd?.defaultValue)
                                    appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, rooftopareaperhplpd?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Hot Water Usage Hours</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={hwUsageHours}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setHwUsageHours(e.target.value);
                                    appendChanged(hotwaterusageshours && hotwaterusageshours.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setHwUsageHours(hotwaterusageshours?.defaultValue)
                                    appendChanged(hotwaterusageshours && hotwaterusageshours.id, hotwaterusageshours?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">hrs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "10px" }}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Number of Plumbing Shafts</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Used for calculating heat loss from standby water</Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={numberofPlumbingShafts}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setNumberofPlumbingShafts(e.target.value);
                                    appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setNumberofPlumbingShafts(numberofplumbingshafts?.defaultValue)
                                    appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, numberofplumbingshafts?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">nos</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="column">

                        <Typography className={classes.textHeader3}>{co2emissionfactor?.value}  kg CO2e/kWh</Typography>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};

export const HWMVillaProjectInputs = (props) => {
    const classes = useStyles()
    const { selectedInterventionDetails, onClose } = props
    const villa1bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.onebhk')
    const villa2bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.twobhk')
    const villa3bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.threebhk')
    const villa4bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.fourbhk')
    const rooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.rooftop')
    const unavailablerooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.nonavailable')
    const floorheight = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.floor2floorheight')

    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Villa 1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa1bhk?.value} nos</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Villa 2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa2bhk?.value} nos</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Villa 3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa3bhk?.value} nos</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Villa 4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa4bhk?.value} nos</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Rooftop Area</Typography>
                    <Typography className={classes.textHeader3}>{numberWithCommas(Number(rooftoparea?.value))} sqm</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Unavailable Rooftop Area </Typography>
                    <Typography className={classes.textHeader3}>{unavailablerooftoparea?.value} sqm</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Floor Height</Typography>
                    <Typography className={classes.textHeader3}>{floorheight?.value} m</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                // handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        // color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
    textDisable: {
        color: '#9E9E9E'
    }
}));
