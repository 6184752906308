import userRoles from '../config/roles';
import NotFound from '../components/common/notfound/Index';
import Index from '../projectRouting/Index';
import AdminRoutes from '../adminrouting/Index';
import MyProjects from '../components/views/projects/myprojects/MyProjects';
import CreateProjectV2 from '../components/views/createproject/CreateProjects';
import EditProject from '../components/views/editproject/EditProject';
import Error from '../components/common/notfound/Error';
// import ProjectReportGeneration from '../components/views/interventions/intervention/projectReport/ProjectReportGeneration';
import RedirectComponent from './Redirect';
import FAQ from '../components/views/faq/FAQ';
import EditCertification from '../components/views/certification/edit/editCertification';
import PDFFile from '../components/views/interventions/intervention/newprojectreport/Test';
import Marketplace from '../components/views/marketplace/views/landing/Marketplace';
import WallBlocks from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/WallBlocks';
import Cement from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Cement';
import Paint from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Paints';
import Glazing from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Glazing';
import InteriorFlooring from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/InteriorFlooring';
import ResourcesKnowledgeHub from '../components/views/marketplace/views/resources-and-knowledgehub/ResourcesKnowledgeHub';
import Faq from '../components/views/marketplace/views/faq/Faq';
import PrivacyPolicy from '../components/views/marketplace/views/privacy-policy/PrivacyPolicy';
import ProductDetails from '../components/views/marketplace/views/productDetails/ProductDetails';
import Categories from '../components/views/marketplace/views/categories/Categories';
import LegalTerms from '../components/views/marketplace/views/terms-and-conditions/Terms-and-conditions';
import CertificationAndSuperCategories from '../components/views/marketplace/views/certification-and-super-categories/CertificationAndSuperCategories';
import Steel from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Steel';
import FaqUsers from '../components/views/faqusers/FaqUsers';
import CreateGoals from '../components/views/goals/CreateGoals';

const privateRouting = [
  {
    path: '/',
    component: RedirectComponent,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/get-help',
    component: FAQ,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/projects',
    component: MyProjects,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace',
    component: Marketplace,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/steel',
    component: Steel,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/wallblocks',
    component: WallBlocks,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/cement',
    component: Cement,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/paints',
    component: Paint,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/glazing',
    component: Glazing,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources/interiorflooring',
    component: InteriorFlooring,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/resources',
    component: ResourcesKnowledgeHub,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/faq',
    component: Faq,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/categories/:id/products/:iid',
    component: ProductDetails,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/categories',
    component: Categories,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/terms-and-conditions',
    component: LegalTerms,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/marketplace/categorydetails',
    component: CertificationAndSuperCategories,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/admin',
    component: AdminRoutes,
    // exact: true,
    requiredRoles: userRoles.admins,
  },
  {
    path: '/projects/create',
    component: CreateProjectV2,
    exact: true,
    requiredRoles: userRoles.version2,
  },
  {
    path: '/projects/:id/certification/edit',
    component: EditCertification,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/projects/:id/edit',
    component: EditProject,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/projects/:id/goals',
    component: CreateGoals,
    exact: true,
    requiredRoles: userRoles.all,
  },

  {
    path: '/projects/:id',
    component: Index,
    // exat: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/project/:id/pdf',
    component: PDFFile,
    exat: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/error',
    component: Error,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '/faq',
    component: FaqUsers,
    exact: true,
    requiredRoles: userRoles.all,
  },
  {
    path: '*/',
    component: NotFound,
    requiredRoles: userRoles.usersandadmins,
  },
];

export default privateRouting;
