import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SelectedInterv from '../child-components/SelectedIntervention';
import InterventionsComponent from '../child-components/interventionsComponent';

const InterventionsAndSelectedInterventions = (props) => {
  // console.log(props.newintervention, 'props.newintervention')
  // let energyCategory = props.newintervention && props.newintervention?.categorized.find((category) => category.name === 'Energy');
  // console.log(energyCategory, 'props.newintervention')
  return (
    <>
      <Grid container xs={12}>
        <Grid container item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
          <Typography variant="h5" style={{ letterSpacing: '1px' }}>
            Interventions
          </Typography>
          <InterventionsComponent newintervention={props.newintervention} />
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: 10 }}>
          <Typography variant="h5" style={{ letterSpacing: '1px' }}>
            Resource Savings
          </Typography>
          <SelectedInterv newintervention={props.newintervention && props.newintervention} goals={props.goals && props.goals} />
        </Grid>
      </Grid>
    </>
  );
};

export default InterventionsAndSelectedInterventions;
