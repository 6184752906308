import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, } from '@material-ui/core';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

const getValueFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.value;
};

const getDefaultValueFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.defaultValue;
};

const getIdFromLabel = (interventionData, label) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i.label === label
    })[0].id;

};
const getBooleanFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i.id === id
    })[0]?.value || '';
};
export const EfficientFixturesShowers_CustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    const [clubhouse, setClubhouse] = useState(getBooleanFromId(selectedInterventionDetails, "swm.clubhouseUtilitySensorEnabled") === 'YES' ? true : false);
    const [supplySideTanker, setSupplySideTanker] = useState(getBooleanFromId(selectedInterventionDetails, "swm.supplySideTankerUtilitySensorEnabled") === 'YES' ? true : false);
    const [supplySideBorewell, setSupplySideBorewell] = useState(getBooleanFromId(selectedInterventionDetails, "swm.supplySideBorewellUtilitySensorEnabled") === 'YES' ? true : false);
    const [other, setOther] = useState(getBooleanFromId(selectedInterventionDetails, "swm.otherSensorEnabled") === 'YES' ? true : false);
    const [otherSensorNumber, setOtherSensorNumber] = useState(getValueFromId(selectedInterventionDetails, "swm.others.actual"))
    const appendChanged = (id, value) => {
        console.log('appendChanged', id, value);
        console.log(id, value);
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    return (
        <Grid container direction="column" alignItems="left" item xs={12}>

            <Grid item xs={8} container alignItems='flex-start' >
                <Grid item xs={6}>
                    <Typography className={classes.textHeader3}>Brand</Typography>
                    <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{getValueFromId(selectedInterventionDetails, 'efshower.efshowerBrand')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textHeader3} >Product Name</Typography>
                    <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{getValueFromId(selectedInterventionDetails, 'efshower.productName')}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="left" item xs={12} style={{ marginTop: '3%' }}>
                <Grid item xs={8} container alignItems='flex-start' >
                    <Grid item xs={6}>
                        <Typography className={classes.textHeader3}>Fixture Flow Rate</Typography>
                        <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{getValueFromId(selectedInterventionDetails, 'efshower.fixtureFlowRate')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textHeader3} >Per Unit Capex</Typography>
                        <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{getValueFromId(selectedInterventionDetails, 'efshower.perUnitCapex')}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid>);
};

export const EfficientFixturesShowers_CustomAdvancesParameters = (props) => {
    const classes = useStyles();
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const WaterTankerPriceperKL = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efshower.watertankerPerPricePerKL')
    const HousekeepingReferenceOccupancy = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efshower.HousekeepingReferenceOccupancy')
    const ClubHouseReferenceOccupancy = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efshower.ClubHouseReferenceOccupancy')
    const co2EmmitionFactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efshower.co2EmmitionFactor')
    const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efshower.MultiplierSource')
    const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'efshower.CapexMultiplier')
    const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'efshower.VendorCapexMultiplier')

    const [customPrice, setCustomPrice] = useState(getValueFromId(selectedInterventionDetails, 'efshower.CapexMultiplier'));
    const [vendorCapex, setVendorPrice] = useState(getValueFromId(selectedInterventionDetails, 'efshower.VendorCapexMultiplier'));
    const [capexMultiplierSource, setCapexMultiplierSource] = useState(getValueFromId(selectedInterventionDetails, 'efshower.MultiplierSource'));
    const [tankerPrice, setTankerPrice] = useState(getValueFromId(selectedInterventionDetails, 'efshower.watertankerPerPricePerKL'));
    const [houseKeepingRefOccupancy, setHouseKeepingRefOccupancy] = useState(getValueFromId(selectedInterventionDetails, 'efshower.HousekeepingReferenceOccupancy'));
    const [clubhouseRefOccupancy, setClubhouseRefOccupancy] = useState(getValueFromId(selectedInterventionDetails, 'efshower.ClubHouseReferenceOccupancy'));
    console.log(HousekeepingReferenceOccupancy, "customcapexmultiplier")
    const [vendorTextField, selectVendorTextField] = useState(capexmultipliersource?.value === 'VENDOR' ? true : false)
    const [customTextField, setCustomTextField] = useState(capexmultipliersource?.value === 'CUSTOM' ? true : false)

    console.log(myArray, "customcapexmultiplier")
    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };


    // useEffect(() => {
    //     if (capexmultipliersource?.value === 'VENDOR') {
    //         selectVendorTextField(true)
    //         setCustomTextField(false)
    //     }
    //     if (capexmultipliersource?.value === 'CUSTOM') {
    //         setCustomTextField(true)
    //         selectVendorTextField(false)
    //     }
    // }, [capexmultipliersource])

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>

            <Typography className={classes.textHeader1}>Intervention Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes will only affect this intervention </Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        {(capexMultiplierSource === 'CUSTOM' || capexMultiplierSource === '') && <RadioButtonUncheckedIcon className={classes.selectedCheckbox} onClick={() => {
                            appendChanged('efshower.MultiplierSource', 'VENDOR');
                            setCapexMultiplierSource('VENDOR');
                        }} />}
                        {(capexMultiplierSource === 'VENDOR') && <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                            setCapexMultiplierSource('');
                        }} />}
                        <Typography>Selected Vendor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" justifyContent='flex-start' direction="row" style={{ display: "flex", alignItems: "flex-start", paddingLeft: '3%' }}>
                        <Typography className={classes.textHeader5} ><b>{vendorCapex} Rs</b></Typography>                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        {(capexMultiplierSource === 'VENDOR' || capexMultiplierSource === '') && < RadioButtonUncheckedIcon className={classes.selectedCheckbox}
                            onClick={() => {
                                appendChanged('efshower.MultiplierSource', 'CUSTOM');
                                setCapexMultiplierSource('CUSTOM');
                            }} />}
                        {(capexMultiplierSource === 'CUSTOM') && <CheckCircleIcon className={classes.selectedCheckbox}
                            onClick={() => {
                                setCapexMultiplierSource('');
                            }} />}
                        <Typography>Custom</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>

                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customPrice}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged('efshower.CapexMultiplier', +e.target.value);
                                    setCustomPrice(+e.target.value);
                                }}
                                fullWidth
                                required
                                disabled={true}
                                disableTextField={capexMultiplierSource === 'VENDOR' ? true : false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Project Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes may affect other project or intervention values</Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>Water Tanker Price per KL</Typography>
            <Grid item xs={4} direction="column" style={{ paddingTop: "10px" }}>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={tankerPrice}
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                appendChanged('efshower.watertankerPerPricePerKL', Number(e.target.value));
                                setTankerPrice(+(e.target.value));
                            }}
                            fullWidth
                            required
                            onClick={() => {
                                appendChanged('efshower.watertankerPerPricePerKL', undefined);
                                setTankerPrice(getDefaultValueFromId(selectedInterventionDetails, 'efshower.watertankerPerPricePerKL'));
                            }}
                            disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">RS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Constants</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Housekeeping Reference Occupancy</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={houseKeepingRefOccupancy}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged('efshower.HousekeepingReferenceOccupancy', +e.target.value);
                                    setHouseKeepingRefOccupancy(+e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={() => {
                                    appendChanged('efshower.HousekeepingReferenceOccupancy', undefined)
                                    setHouseKeepingRefOccupancy(getDefaultValueFromId(selectedInterventionDetails, 'efshower.HousekeepingReferenceOccupancy'))
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>ClubHouse Reference Occupancy</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={clubhouseRefOccupancy}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged('efshower.ClubHouseReferenceOccupancy', Number(e.target.value));
                                    setClubhouseRefOccupancy(+e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={() => {
                                    appendChanged('efshower.ClubHouseReferenceOccupancy', undefined);
                                    setClubhouseRefOccupancy(getDefaultValueFromId(selectedInterventionDetails, 'efshower.ClubHouseReferenceOccupancy'));
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5} ><b>{co2EmmitionFactor && co2EmmitionFactor?.value} {co2EmmitionFactor && co2EmmitionFactor?.unit}</b></Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};

export const EfficientFixturesShowers_ProjectInputs = (props) => {
    const classes = useStyles()
    const { selectedInterventionDetails, onClose } = props
    const villa1bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.onebhk')
    const villa2bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.twobhk')
    const villa3bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.threebhk')
    const villa4bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.fourbhk')
    const rooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.rooftoparea')
    const unavailablerooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.nonavailable')
    const floorheight = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.floor2floorheight')

    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Studio</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.studio') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.single') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.double') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.three') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.four') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.onebhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.twobhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.threebhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.fourbhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={onClose}
                />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        // paddingTop: '10%',
    },
    nonselectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
}));
