import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PrimaryButton = ({ onClick, style, children, className, type, backgroundColor, disabled, textcolor }, props) => {
  const StyledButton = withStyles({
    root: {
      color: textcolor ? textcolor : '#ffffff',
      background: backgroundColor ? backgroundColor : 'linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)',
      borderRadius: '40px',
      '&:focus': {
        outline: 'none',
      },
      padding: 8
    },
  })(Button);

  return (
    <Fragment>
      <StyledButton type={type} fullWidth variant="contained" style={style} className={className} disabled={disabled} onClick={onClick} {...props}>
        {children}
      </StyledButton>
    </Fragment>
  );
};
export default PrimaryButton;
