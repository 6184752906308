import React, { useContext, useEffect, useState } from 'react';
import Sidebar from './sidebar/FilterSidebar';
import { makeStyles, withWidth } from '@material-ui/core';
import MarketplaceNavbar from '../../../../navbar/MarketplaceNavbar';
import { ProductsPageContext } from '../../../../../../../routing/IndexRouting';
import Main from '../Main';
import axios from 'axios';
import { NavigatorSource } from '../../../../utils/Defaults';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
}));

function CategoryDetailsDesktop(props) {
  const classes = useStyles();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { products, categories, totalproducts, ecoLabels, allbrands, uniqueBrands, uniqueSubcategories, userSelectedFirstSection, uniqueEcolabels, shortlistedProducts, mappingid, setShortlistedProducts } = props;
  const { URLS } = useContext(ProductsPageContext);
  const { source } = URLS;

  return (
    <>
      <div className={classes.root}>
        <MarketplaceNavbar />
        <Sidebar
          categories={categories}
          source={source}
          products={products}
          ecoLabels={ecoLabels}
          allbrands={allbrands}
          // for filters
          uniqueBrands={uniqueBrands}
          uniqueSubcategories={uniqueSubcategories}
          userSelectedFirstSection={userSelectedFirstSection}
          uniqueEcolabels={uniqueEcolabels}
        />
        <Main products={products} categories={categories} totalproducts={totalproducts} ecoLabels={ecoLabels} shortlistedProducts={shortlistedProducts} mappingid={mappingid} setShortlistedProducts={setShortlistedProducts} />
      </div>
    </>
  );
}

export default withWidth()(CategoryDetailsDesktop);
