import React, { useState, useEffect, useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Grid, Popover } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from 'react-router-dom';
import rsToCr from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';

import rooftopRunoff from '../../../../../assets/rooftopRunoff.svg';
import surfaceRunoff from '../../../../../assets/surfaceRunoff.svg';
import solarwaterheater from '../../../../../assets/solarwaterheater.svg';
import solarPV from '../../../../../assets/solarPV.svg';
import smartWaterMeter from '../../../../../assets/smartwatermeter.svg';
import medicalWaste from '../../../../../assets/medical.png';
import eWastehandling from '../../../../../assets/eWastehandling.svg';
import EfficientFixtures from '../../../../../assets/efficientFixtures.svg';
import dualflush from '../../../../../assets/dualflush.svg';
import CentralisedRO from '../../../../../assets/CentralisedRO.svg';
import blackwaterReuse from '../../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../../assets/greywater.svg';
import incinaretor from '../../../../../assets/icons/waste/snitary.svg';
import lightManagementSystem from '../../../../../assets/lightManagementSystem.svg';
import composter from '../../../../../assets/composter.svg';
import toxicNew from '../../../../../assets/icons/waste/segregation.svg';
import EnergyCustom from '../../../../../assets/icons/energy/custom-icon.png';
import WaterCustom from '../../../../../assets/icons/water/custom-icon.png';
import WasteCustom from '../../../../../assets/icons/waste/custom-icon.png';
import MaterialsCustom from '../../../../../assets/interventions/materials/custom.svg';
import ExteriorPaints from '../../../../../assets/interventions/materials/exterior-paints.svg';
import concrete from '../../../../../assets/interventions/materials/concrete.svg';
import structure from '../../../../../assets/interventions/materials/structure.svg';
import balconyandutility from '../../../../../assets/interventions/materials/balconyandutility.svg';
import livinganddining from '../../../../../assets/interventions/materials/livinganddining.svg';
import bedroom from '../../../../../assets/interventions/materials/bedroom.svg';
import bathroom from '../../../../../assets/interventions/materials/bathroom.svg';
import kitchen from '../../../../../assets/interventions/materials/kitchen.svg';
import hwmvillicon from '../../../../../assets/interventions/HWM Villa Icon.svg'
import nstpIcon from '../../../../../assets/interventions/NSTPicon.svg'

import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import axios from '../../../../../config/Axios';
import numberWithCommas from '../../../../../utils/numberToComma';
// import { useParams } from 'react-router';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TooltipData, ParentPage, ChildPage } from '../../../../../staticData/TooltipStaticData';
import CustomTooltipTitle from '../../../../common/tooltip/customTooltipTitle';

const icons = [
  { intervention: 'Biogas To Electricity Generation', icon: lightManagementSystem },
  { intervention: 'Solar Water Heater', icon: solarwaterheater },
  { intervention: 'Solar PV', icon: solarPV },
  { intervention: 'Centralized RO', icon: CentralisedRO },
  { intervention: 'Efficient Fixtures', icon: EfficientFixtures },
  { intervention: 'Smart Water Meters', icon: smartWaterMeter },
  { intervention: 'Dual Flush', icon: dualflush },
  { intervention: 'Rooftop RWH', icon: rooftopRunoff },
  { intervention: 'Surface RWH', icon: surfaceRunoff },
  { intervention: 'Black Water Re-use', icon: blackwaterReuse },
  { intervention: 'Wet Waste Management', icon: biogasPlant },
  { intervention: 'E-Waste Handling', icon: eWastehandling },
  { intervention: 'Medical Waste Handling', icon: medicalWaste }, ////
  { intervention: 'Grey Water Re-use', icon: greywaterReuse },
  { intervention: 'Sanitary Napkin Incinerator', icon: incinaretor },
  { intervention: 'Collection & Segregation Point', icon: toxicNew }, //////
  { intervention: 'Segregation Point', icon: composter },
  { intervention: 'Exterior Paints', icon: ExteriorPaints },
  { intervention: 'Interior Paints', icon: ExteriorPaints },
  { intervention: 'Structural Steel', icon: structure },
  { intervention: 'Concrete', icon: concrete },
  { intervention: 'Living & Dining', icon: livinganddining },
  { intervention: 'Bedroom', icon: bedroom },
  { intervention: 'Kitchen', icon: kitchen },
  { intervention: 'Bathroom', icon: bathroom },
  { intervention: 'Utility & Balcony', icon: balconyandutility },

  { intervention: 'Hot Water Management Villa', icon: hwmvillicon },
  { intervention: 'Hot Water Management Apartment', icon: hwmvillicon },
  { intervention: 'Hot Water Management Clubhouse', icon: hwmvillicon },
  { intervention: 'Hot Water Management Swimming Pool', icon: hwmvillicon },
  { intervention: 'Hot Water Management Penthouse', icon: hwmvillicon },

  { intervention: 'SPV for Apartment', icon: solarPV },
  { intervention: 'SPV for Clubhouse', icon: solarPV },
  { intervention: 'SPV for Villa', icon: solarPV },
  { intervention: 'SPV for Penthouse', icon: solarPV },

  { intervention: 'Efficient Fixtures - Health Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Kitchen Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Showers', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Dual Flush', icon: dualflush },
  { intervention: 'Treated Water Reuse', icon: greywaterReuse },
  { intervention: 'Natural STP', icon: nstpIcon },


  { intervention: 'Energy', icon: EnergyCustom },
  { intervention: 'Water', icon: WaterCustom },
  { intervention: 'Waste', icon: WasteCustom },
  { intervention: 'Materials', icon: MaterialsCustom },
];

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledRowCell = withStyles((theme) => ({
  body: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  linkfont: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#00C58A',
  },
  decoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  decoration2: {
    cursor: 'pointer',
  },
  grid: {
    marginTop: '3%',
  },
  typography: {
    padding: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

let sortedData;
export default function CategoryRow(props) {
  const { row, StyledTableCell } = { ...props };
  const category = props.category;
  const state = props.state;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [custominterventions, setCustomintervention] = useState([])
  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  let history = useHistory();
  let iid = props.projectID;

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [toggle] = useState(true);
  // const handleState = () => setToggle(!toggle);
  const stopLoader = props.stopLoder;
  const iconData = row.interventions.map((row, index) => {
    let icon;
    for (let i = 0; i < icons.length; i++) {
      if (icons[i].intervention === row.name && toggle) {
        icon = icons[i].icon;
      }
    }
    return icon;
  });
  const handleRefresh = (id, state) => {
    setSpinner(true);
    const body = {
      enabled: state,
    };
    axios
      .put(`/ProjectInterventionMapping/${id}`, body)
      .then((res) => {
        if (res) {
          props.handleRefresh();
        }
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axios.get(`/custom-intervention?project=${iid}`)
      .then((respo) => {
        console.log(respo, 'rowrow2')
        setCustomintervention(respo.data?.data?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const findTooltipdataforcustomIntervention = (name) => {
    console.log(name, 'rowrow')
    const gettooltipdata = custominterventions && custominterventions.find((ele) => ele.name === name)
    const tooltipdata = gettooltipdata?.description === '' ? 'NA' : gettooltipdata?.description
    return tooltipdata
  }

  useEffect(() => {
    if (stopLoader === true) setSpinner(false);
  }, [stopLoader]);

  if (props.data === 'Energy') sortedData = row?.interventions?.length !== 0 && row.interventions.sort((a, b) => (a.name === 'Solar PV' ? -1 : 0));
  console.log(sortedData, row, 'sorteddata');
  if (props.data === 'Water') {
    sortedData =
      row?.interventions?.length !== 0 &&
      row.interventions
        .sort((a, b) =>
          a.name === 'Centralized RO' ||
            (a.name === 'Grey Water Re-use' && b.name === 'Black Water Re-use') ||
            (a.name === 'Rooftop RWH' && b.name === 'Surface RWH')
            ? -1
            : b.name === 'Dual Flush'
              ? -1
              : 0
        )
        .sort((a, b) => (b.name === 'Smart Water Meters' ? -1 : 0));
  }
  if (props.data === 'Waste')
    sortedData = row?.interventions?.length !== 0 && row.interventions.sort((a, b) => (a.name === 'Collection & Segregation Point' ? -1 : 0));

  if (props.data === 'Materials') sortedData = row?.interventions?.length !== 0 && row.interventions;

  const energyCustomIcon = icons && icons.find((a) => a.intervention === 'Energy');
  const waterCustomIcon = icons && icons.find((a) => a.intervention === 'Water');
  const wasteCustomIcon = icons && icons.find((a) => a.intervention === 'Waste');
  const materialCustomIcon = icons && icons.find((a) => a.intervention === 'Materials');

  const findInterventionFromStaticData = (data, interventionName) => {
    let FinalResult;
    let result =
      data &&
      data.find((d) => d.parentPage === ParentPage.INTERVENTIONS && d.childPage === ChildPage.INTERVENTIONS_SUMMARY && d.componentName === interventionName);
    if (result && result) {
      FinalResult = result.content;
    } else {
      FinalResult = null;
    }
    return FinalResult;
  };

  const stateBasedValueTableCell = (state, value1, value2, locked, value2Unit, changeColor) => {
    if (!value1 || value1 === 0) return <StyledTableCell style={locked ? { color: 'grey' } : {}}>{state ? `0 Rs/Sqft` : `0.00 cr`}</StyledTableCell>
    else if (!value2 || value2 === 0) return <StyledTableCell style={locked ? { color: 'grey' } : {}}>{state ? `0 Rs/Sqft` : `0.00 cr`}</StyledTableCell>
    else if (state) return <StyledTableCell style={locked ? { color: 'grey' } : value2 < 0 && changeColor ? { color: '#26E0A3' } : {}}> {value2 + ' ' + value2Unit}</StyledTableCell>
    else return <StyledTableCell style={locked ? { color: 'grey' } : value1 < 0 && changeColor ? { color: '#26E0A3' } : {}}> {rsToCr('Rs', value1) + ' ' + unit('cr')}</StyledTableCell>
  }

  const stateBasedValueRowCell = (state, value1, value2, locked, value2Unit, changeColor) => {
    if (!value1 || value1 === 0) return <StyledRowCell style={locked ? { color: 'grey' } : {}}>{state ? `0 Rs/Sqft` : `0.00 cr`}</StyledRowCell>
    else if (!value2 || value2 === 0) return <StyledRowCell style={locked ? { color: 'grey' } : {}}>{state ? `0 Rs/Sqft` : `0.00 cr`}</StyledRowCell>
    if (state) return <StyledRowCell style={locked ? { color: 'grey' } : value2 < 0 && changeColor ? { color: '#26E0A3' } : {}}> {value2 + ' ' + value2Unit}</StyledRowCell>
    else return <StyledRowCell style={locked ? { color: 'grey' } : value1 < 0 && changeColor ? { color: '#26E0A3' } : {}}> {rsToCr('Rs', value1) + ' ' + unit('cr')}</StyledRowCell>
  }

  console.log(row && row, "rowrowrowrowrowrow")

  return (
    <>
      <Backdrop className={classes.backdrop} open={spinner}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      {row?.interventions?.length !== 0 ? (
        <StyledTableRow
          // onClick={handleState}
          style={{ height: '8vh' }}
        >
          <StyledTableCell component="th" scope="row">
            {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
          </StyledTableCell>

          {/* Capex Increase */}
          {stateBasedValueTableCell(state, row?.quantities?.finance?.capex?.value, numberWithCommas(row?.quantities?.finance?.capexConverted?.value), false, row?.quantities?.finance?.capexConverted?.unit, true)}

          {/* total capex  */}
          {stateBasedValueTableCell(state, row?.quantities?.finance?.totalCapex?.value, numberWithCommas(row?.quantities?.finance?.totalCapexConverted?.value), false, row?.quantities?.finance?.totalCapexConverted?.unit, false)}

          {/* Opex Savings */}
          <StyledTableCell>
            {rsToCr(row.quantities.finance.opexSavings.unit, row && row.quantities.finance.opexSavings.value) + ' ' + unit('cr')}
          </StyledTableCell>
          <StyledTableCell>
            {row && numberWithCommas(row.quantities.resource.category.value) + ' ' + unit(row.quantities.resource.category.unit)}
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        ''
      )}

      {toggle &&
        sortedData &&
        sortedData.map((row, index) => {
          // console.log(row, "ddddddddddddddddddddddddddddddddddddddd")
          return (
            <StyledTableSubRow key={index}>
              <StyledRowCell component="th" scope="row">
                <>
                  <Grid container xs>
                    <Grid xs={1} style={{ paddingTop: 5 }}>
                      {(category === 'Energy' || category === 'Waste' || category === 'Water' || category === 'Materials') && (
                        <CustomCheckBox
                          color="rgba(0, 0, 0, 0.38)"
                          checkState={row.enabled}
                          uncheckicon={<RadioButtonUncheckedIcon style={{ fontSize: 20 }} />}
                          checkicon={<CheckCircleIcon style={{ fontSize: 20 }} />}
                          handleChange={
                            row.enabled === true
                              ? () => handleRefresh(row.interventionProjectMappingId, false)
                              : () => handleRefresh(row.interventionProjectMappingId, true)
                          }
                          disabled={row.locked === true ? true : false}
                        />
                      )}
                    </Grid>
                    <Grid xs={2}>
                      {props.name !== 'Custom Interventions' ? (
                        <img style={{ borderRadius: '10px' }} src={iconData[index]} alt="..." />
                      ) : category === 'Energy' ? (
                        <img style={{ borderRadius: '10px' }} src={energyCustomIcon && energyCustomIcon.icon} alt="..." />
                      ) : category === 'Water' ? (
                        <img style={{ borderRadius: '10px' }} src={waterCustomIcon && waterCustomIcon.icon} alt="..." />
                      ) : category === 'Waste' ? (
                        category === 'Waste' && <img style={{ borderRadius: '10px' }} src={wasteCustomIcon && wasteCustomIcon.icon} alt="..." />
                      ) : (
                        category === 'Materials' && <img style={{ borderRadius: '10px' }} src={materialCustomIcon && materialCustomIcon.icon} alt="..." />
                      )}
                    </Grid>
                    <Grid
                      xs={9}
                      className={classes.grid}
                      onClick={() => {
                        if (row.locked !== true) {
                          if (props.name !== 'Custom Interventions' && row.name !== 'Centralized RO') {
                            // setInterventions(row);
                            history.push(`/projects/${iid}/interventions/${row.interventionProjectMappingId}`);
                          } else if (row.name !== 'Centralized RO') {
                            // handle custom intervention model
                            props.handleOpenBackdrop(true);
                            props.handleEditCustomIntervention(true);
                            props.handleEditCustominterventionID(row.interventionProjectMappingId);
                          } else if (row.name === 'Centralized RO' && props.disabled === false) {
                            history.push(`/projects/${iid}/interventions/${row.interventionProjectMappingId}`);
                          }
                        } else {
                          console.log('intervention is disabled: show a alert');
                        }
                      }}
                      style={{ paddingTop: '3px', paddingLeft: '2%', cursor: 'pointer' }}
                      item
                      container
                    >
                      {row.name !== 'Centralized RO' && (
                        <span
                          style={row.locked !== false ? { color: 'grey', textDecoration: 'underline' } : { textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                        </span>
                      )}

                      {row.name === 'Centralized RO' && (
                        <span
                          style={props.disabled !== false ? { color: 'grey', textDecoration: 'underline' } : { textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                        </span>
                      )}

                      {props.name !== 'Custom Interventions' ? <CustomizedTooltips
                        marginTop="1%"
                        icon="info"
                        placement="right"
                        title={
                          findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1)) !== null ? (
                            <CustomTooltipTitle data={findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1))} />
                          ) : (
                            <Typography variant="h6">NA</Typography>
                          )
                        }
                      /> : <CustomizedTooltips
                        marginTop="1%"
                        icon="info"
                        placement="right"
                        title={
                          findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1)) !== null ? (
                            <CustomTooltipTitle data={findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1))} />
                          ) : (
                            <Typography variant="h6">{findTooltipdataforcustomIntervention(row.name)}</Typography>
                          )
                        }
                      />}
                    </Grid>
                  </Grid>
                </>
              </StyledRowCell>

              {/* Capex row wise */}
              {stateBasedValueRowCell(state, row?.quantities?.finance?.capex?.value, numberWithCommas(row?.quantities?.finance?.capexConverted?.value), row?.locked, row?.quantities?.finance?.capexConverted?.unit, true)}
              {/* Total Capex row wise */}
              {/* {stateBasedValueRowCell(state, row?.quantities?.finance?.totalCapex?.value, numberWithCommas(row?.quantities?.finance?.totalCapexConverted?.value), row?.locked, row?.quantities?.finance?.totalCapexConverted?.unit, true)} */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {!state ? `${rsToCr('Rs', row?.quantities?.finance?.totalCapex?.value)} cr` : `${row?.quantities?.finance?.totalCapexConverted?.value} ${row?.quantities?.finance?.totalCapexConverted?.unit}`}
              </StyledRowCell>
              {/* ewwwewewew */}

              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {`${row.quantities && row.quantities.finance && row.quantities.finance.opexSavings && row.quantities.finance.opexSavings.value
                  ? rsToCr('Rs', row.quantities.finance.opexSavings.value)
                  : rsToCr('Rs', 0.00)
                  } 
              ${unit('cr')}`}
              </StyledRowCell>
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {`${row.quantities && row.quantities.resource && row.quantities.resource.category && row.quantities.resource.category.value
                  ? numberWithCommas(row.quantities.resource.category.value)
                  : numberWithCommas(0.00)
                  } 
                ${row.quantities && row.quantities.resource && row.quantities.resource.category && row.quantities.resource.category.unit
                    ? unit(row.quantities.resource.category.unit)
                    : ' '
                  }`}
              </StyledRowCell>
            </StyledTableSubRow>
          );
        })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{row.name}</Typography>
      </Popover>
    </>
  );
}

{
  /* <StyledTableCell align="right">
          {!toggle && <ExpandMoreIcon onClick={handleState} />}
          {toggle && <ExpandLessIcon onClick={handleState} />}
        </StyledTableCell> */
}
// style={Math.sign(row && row?.quantities?.finance?.totalCapex?.value) === -1 ? { color: '#26E0A3' } : { color: 'black' }}
