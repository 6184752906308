import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import axios from '../../../../../config/Axios';
import { InterventionDetailsContext } from '../interventionDetails';
import EditInterventionDetails from './editInterventionDetails';
import ShowConstants from './showConstants';
import ShowProjectAndConstAssumptionData from './showProjectAndCostAssumptionData';
import ShowCapexAndDetails from './showCapexAndDetails';
import ShowInterventionCostAssumptionsParameters from './showInterventionCostAssumptionParameters';
import ShowSaveAndCancel from './showSaveAndCancel';
import { NotetakingContext } from '../../../../../projectRouting/Index';
import listicon from '../../../../../assets/interventions/listicon.svg';
import { useParams } from 'react-router';
import {
  KongInterventionsComponents,
  solarWaterHeaterMapping,
  HWM_OPTIONS_MAP,
  HWM_SWH_TYPE_MAP,
  ID_MAP_HWM,
  INTERVENTIONS_MAP,
  handleSolarWaterHeaterApt,
  handleApartmentHeatPump,
  HWM_APT_IDENTIFIER_TAG,
  HWM_CLB_IDENTIFIER_TAG,
  handleApartmentCapacity,
  compareArrays,
  heatPumpMappingClubhouse,
  handleSolarWaterHeaterClb,
  handleSolarWaterHeaterPen,
} from './KongInterventions';

export const AllProjectContext = React.createContext();

export const KongInterventions = [
  'Hot Water Management Apartment',
  'Hot Water Management Clubhouse',
  'Hot Water Management Penthouse',
  'Hot Water Management Villa',
  'Efficient Fixtures - Health Faucet',
  'Efficient Fixtures - Faucet',
  'Efficient Fixtures - Kitchen Faucet',
  'Efficient Fixtures - Showers',
  'Treated Water Reuse',
  'Natural STP',
  'Smart Water Meters',
  'Efficient Fixtures - Dual Flush',
];

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DataAndCostAssumptions = (props) => {
  const classes = useStyles();
  const { notetakingContext, handleNotetaking } = useContext(NotetakingContext);
  let { id, iid } = useParams();

  const { interventionProjectMappingId, projectId, inputs, activeSandboxId, selectedIntervention, selectedInterventionDetails } = props;
  const { myArrayContext, handleMyArray } = useContext(InterventionDetailsContext);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState('');
  const [myArray, setMyArray] = useState([]);
  const [parameter, setParameter] = useState('basic');

  const constant = props.inputs && props.inputs ? props.inputs.filter((item) => item && item.tags.includes('constant')) : '';
  const projectCustomizable = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('project-customizable')) : '';
  const interventionCustomizable = inputs && inputs ? inputs.filter((item) => item && item.tags.includes('intervention-customizable')) : '';
  const multiplierSource = inputs && inputs ? inputs.find((item) => item && item.tags.includes('multiplier-source')) : '';
  const capexMultiplier = inputs && inputs ? inputs.find((item) => item && item.tags.includes('capex-multiplier')) : '';
  const VendorcapexMultiplier = inputs && inputs ? inputs.find((item) => item && item.tags.includes('vendor-capex-multiplier')) : '';

  const basichandleClick = () => setParameter('basic');
  const advancehandleClick = () => setParameter('advance');
  const projecthandleClick = () => setParameter('project');
  const handleOpen = () => {
    // handleNotetaking()
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // handleNotetaking()
    props.handleCancel();
  };

  const appendChanged = (id, value) => {
    const body = { id: id, value: !value ? 0 : value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  //for new interventions - hwm
  const getDataValue = (id) => {
    const data = myArray?.find((ele) => ele.id === id);
    return data ? data.value : null;
  };
  const getDataValueFromIntDetails = (id) => {
    const data = selectedInterventionDetails && selectedInterventionDetails?.find((ele) => ele.id === id);
    return data ? data.value : null;
  };
  const getData = (key) => getDataValue(key);
  const appendChangedValue = (tag, key) => appendChanged(tag, getDataValue(key));
  console.log(selectedInterventionDetails && selectedInterventionDetails, 'selectedInterventionDetails');
  const handleSave = () => {
    const handleHotWaterManagementApartment = () => {
      const hwmOption = getData(ID_MAP_HWM[0].hwmOption);
      const swhType = getData(ID_MAP_HWM[0].swhType);
      const swhVariant = getData(ID_MAP_HWM[0].swhVariant);
      const hpType = getData(ID_MAP_HWM[0].hpType);
      const hpHeatingSpeed = getData(ID_MAP_HWM[0].heatingSpeed);
      const hpCapacity = getData(ID_MAP_HWM[0].hpCapacity);
      const swhCapacity = getData(ID_MAP_HWM[0].swhCapacity);
      const hybridHeatingSpeed = getData(ID_MAP_HWM[0].hybridHeatingSpeed);
      const hybridSwhVariant = getData(ID_MAP_HWM[0].hybridSwhVariant);

      const optionFromIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].hwmOption);
      const swhTypeIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].swhType);
      const swhVariantIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].swhVariant);
      const hpTypeIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].hpType);
      const hpHeatingSpeedIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].heatingSpeed);
      const hybridHeatingSpeedIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].hybridHeatingSpeed);
      const hybridSwhVariantIntDetails = getDataValueFromIntDetails(ID_MAP_HWM[0].hybridSwhVariant);
      handleApartmentCapacity(
        hwmOption,
        optionFromIntDetails,
        swhType,
        swhTypeIntDetails,
        swhVariant,
        swhVariantIntDetails,
        hpType,
        hpTypeIntDetails,
        hpHeatingSpeed,
        hpHeatingSpeedIntDetails,
        hpCapacity,
        swhCapacity,
        ID_MAP_HWM,
        HWM_OPTIONS_MAP,
        hybridHeatingSpeed,
        hybridSwhVariant,
        hybridHeatingSpeedIntDetails,
        hybridSwhVariantIntDetails,
        appendChangedValue
      );

      if (hwmOption === HWM_OPTIONS_MAP.solarWaterHeater) handleSolarWaterHeaterApt(swhType, swhVariant, HWM_APT_IDENTIFIER_TAG, appendChangedValue);
      else if (hwmOption === HWM_OPTIONS_MAP.heatpump) handleApartmentHeatPump(hpType, HWM_APT_IDENTIFIER_TAG, appendChangedValue);

      if (hwmOption === HWM_OPTIONS_MAP.solarWaterHeater) handleSolarWaterHeaterApt(swhType, swhVariant, HWM_APT_IDENTIFIER_TAG, appendChangedValue);
      else if (hwmOption === HWM_OPTIONS_MAP.heatpump) handleApartmentHeatPump(hpType, HWM_APT_IDENTIFIER_TAG, appendChangedValue);
      else if (hwmOption === HWM_OPTIONS_MAP.hybrid) appendChangedValue(HWM_APT_IDENTIFIER_TAG, ID_MAP_HWM[0].hybrid);
    };
    const handleHotWaterManagementClubHouse = () => {
      const hwmOption = getData(ID_MAP_HWM[1].hwmOption);
      const swhType = getData(ID_MAP_HWM[1].swhType);
      const swhVariant = getData(ID_MAP_HWM[1].swhVariant);
      const hpType = getData(ID_MAP_HWM[1].hpType);

      if (hwmOption === HWM_OPTIONS_MAP.solarWaterHeater) handleSolarWaterHeaterClb(swhType, swhVariant, HWM_CLB_IDENTIFIER_TAG, appendChangedValue);
      else if (hwmOption === HWM_OPTIONS_MAP.heatpump) heatPumpMappingClubhouse(hpType, HWM_CLB_IDENTIFIER_TAG, appendChangedValue);
    };

    const handleHotWaterManagementpenthouse = () => {
      const hwmOption = getData(ID_MAP_HWM[2].hwmOption);
      const swhType = getData(ID_MAP_HWM[2].swhType);
      const swhVariant = getData(ID_MAP_HWM[2].swhVariant);
      const hpType = getData(ID_MAP_HWM[2].hpType);

      if (hwmOption === HWM_OPTIONS_MAP.solarWaterHeater) handleSolarWaterHeaterPen(swhType, swhVariant, HWM_CLB_IDENTIFIER_TAG, appendChangedValue);
      else if (hwmOption === HWM_OPTIONS_MAP.heatpump) heatPumpMappingClubhouse(hpType, HWM_CLB_IDENTIFIER_TAG, appendChangedValue);
    };

    if (selectedIntervention === INTERVENTIONS_MAP.HWM_APARTMENT) handleHotWaterManagementApartment();
    if (selectedIntervention === INTERVENTIONS_MAP.HWM_ClubHouse) handleHotWaterManagementClubHouse();
    if (selectedIntervention === INTERVENTIONS_MAP.HWM_Penthouse) handleHotWaterManagementpenthouse();

    if (myArray && myArray.length > 0) {
      const values = myArray.map((v) => ({
        id: v.id,
        value: v.value === '' ? v.value : !isNaN(v.value) ? (Number.isInteger(v.value) ? parseInt(v.value) : parseFloat(v.value)) : v.value,
      }));
      const body = { type: 'dirty', values: values };
      // handleApartmentCapacity(changes, INTERVENTIONS_MAP, selectedIntervention, ID_MAP_HWM, hwmOption, HWM_OPTIONS_MAP, appendChangedValue);
      axios
        .put(`/projectv2/${props.projectId}/interventions/${props.interventionProjectMappingId}/sandboxes/${props.activeSandboxId}`, body)
        .then((res) => {
          if (res && res.data.data) {
            handleClose();
            props.handleRefresh();
            props.handleBackdropOpen();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (myArray.length > 0) handleMyArray(myArray);
  }, [myArray, handleMyArray]);

  useEffect(() => {
    if (multiplierSource) setSource(multiplierSource.value);
  }, [multiplierSource]);

  useEffect(() => {
    if (myArrayContext && myArrayContext.length > 0) setMyArray(myArrayContext);
  }, [myArrayContext]);
  // console.log(selectedIntervention, 'selectedIntervention 2')
  const advanceParameter = (
    <Grid container alignItems="flex-start" xs={12} className={classes.grid}>
      <ShowInterventionCostAssumptionsParameters
        interventionCustomizable={interventionCustomizable}
        myArray={myArray}
        appendChanged={(a, b) => appendChanged(a, b)}
        onClick={props.onClick}
      />
      {multiplierSource && (
        <ShowCapexAndDetails
          source={source}
          setSource={(a) => setSource(a)}
          appendChanged={(a, b) => appendChanged(a, b)}
          capexMultiplier={capexMultiplier}
          VendorcapexMultiplier={VendorcapexMultiplier}
          myArray={myArray}
          multiplierSource={multiplierSource}
        />
      )}
      <ShowProjectAndConstAssumptionData
        projectCustomizable={projectCustomizable}
        myArray={myArray}
        appendChanged={(a, b) => appendChanged(a, b)}
        onClick={props.onClick}
      />
      <ShowConstants constant={constant && constant} myArray={myArray} appendChanged={(a, b) => appendChanged(a, b)} />
      <ShowSaveAndCancel handleClose={handleClose} handleSave={handleSave} />
    </Grid>
  );

  const basicParameters = (
    <EditInterventionDetails
      interventionProjectMappingId={interventionProjectMappingId}
      projectId={projectId}
      inputs={inputs}
      activeSandboxId={activeSandboxId && activeSandboxId}
      handleRefresh={() => props.handleRefresh()}
      handleBackdropOpen={() => props.handleBackdropOpen()}
      bordercolor="2px solid rgba(73, 221, 177, 0.5)"
      background="rgba(198, 242, 205, 0.25)"
      customizable={props.customizable}
      refresh={props.refresh}
      boundaryMin={props.boundaryMin}
      boundaryMax={props.boundaryMax}
      mandates={props.mandates}
      mandateGenerals={props.mandateGenerals && props.mandateGenerals}
      mandateicon={props.mandateicon}
      projectinputs={props.projectinputs}
      onClose={handleClose}
      mandateGeneralsIcon={props.mandateGeneralsIcon}
    />
  );

  const BasicComponent = KongInterventionsComponents[selectedIntervention]?.basic;
  const AdvancedComponent = KongInterventionsComponents[selectedIntervention]?.advanced;
  const ProjectComponent = KongInterventionsComponents[selectedIntervention]?.project;

  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'basic' ? classes.button1 : classes.button2} onClick={basichandleClick}>
              <Typography variant="h6" className={parameter === 'basic' ? classes.textinside1 : classes.textinside2} style={{}}>
                Basic Parameters
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'advance' ? classes.button1 : classes.button2} onClick={advancehandleClick}>
              <Typography variant="h6" className={parameter === 'advance' ? classes.textinside1 : classes.textinside2}>
                Advanced Parameters
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'project' ? classes.button1 : classes.button2} onClick={projecthandleClick}>
              <Typography variant="h6" className={parameter === 'project' ? classes.textinside1 : classes.textinside2}>
                Project Inputs
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: '3%' }} />

        {parameter === 'basic' && !KongInterventions.includes(selectedIntervention) && basicParameters}
        {parameter === 'advance' && !KongInterventions.includes(selectedIntervention) && advanceParameter}
        {parameter === 'basic' && (
          <BasicComponent
            selectedInterventionDetails={selectedInterventionDetails}
            myArray={myArray}
            setMyArray={setMyArray}
            onClose={handleClose}
            handleSave={handleSave}
          />
        )}
        {parameter === 'advance' && (
          <AdvancedComponent
            selectedInterventionDetails={selectedInterventionDetails}
            myArray={myArray}
            setMyArray={setMyArray}
            onClose={handleClose}
            handleSave={handleSave}
          />
        )}
        {parameter === 'project' && (
          <ProjectComponent
            selectedInterventionDetails={selectedInterventionDetails}
            myArray={myArray}
            setMyArray={setMyArray}
            onClose={handleClose}
            handleSave={handleSave}
          />
        )}
      </>
    </div>
  );
  return (
    <div>
      {/* <Link
        className="button"
        style={{
          background: 'transparent',
          color: '#00C58A',
          cursor: 'pointer',
          width: '30%',
          fontWeight: 900,
        }}
        to="#"
        onClick={handleOpen}
      > */}
      <Grid container item xs={12}>
        <Typography
          variant="h6"
          style={{
            background: 'transparent',
            color: 'rgba(0, 197, 138, 1)',
            cursor: 'pointer',
            fontWeight: 800,
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleOpen}
        >
          <img src={listicon} alt="..." style={{ paddingRight: '10px' }} /> INTERVENTION PARAMETERS
        </Typography>
      </Grid>
      {/* </Link> */}
      {/* overflow: 'visible' */}
      <Modal
        disableEnforceFocus
        style={{ position: 'absolute', zIndex: !notetakingContext ? 1000 : 0 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body1}
      </Modal>
    </div>
  );
};
export default DataAndCostAssumptions;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '950px',
    outline: 'none',
    maxHeight: 500,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(5, 5, 2, 5),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  edit: {
    color: '#00C58A',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  grid: {
    marginTop: '2%',
  },
  textinside1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '10%',
  },
  textinside2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
    paddingTop: '10%',
  },
  button1: {
    filter: 'drop-shadow(0px 4px 20px rgba(0, 197, 138, 0.4))',
    width: '223px',
    height: '65px',
    background: '#66E3BE',
    borderRadius: '10px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  button2: {
    width: '223px',
    height: '65px',
    background: '#FFFFFF',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
}));

// old handle save
// const handleSave = () => {
//   if (selectedIntervention === "Hot Water Management Apartment") {
//     console.log(myArray, 'my array')
//     const hwmOption = myArray?.find((ele) => ele.id === "hwmApartment.options");
//     const swhType = myArray?.find((ele) => ele.id === "hwmApartment.swhType");
//     const swhVariant = myArray?.find((ele) => ele.id === "hwmApartment.swhVariant");
//     const hpType = myArray?.find((ele) => ele.id === "hwmApartment.HpType");
//     let hwmidentifiertag = 'hwmApartment.productidentifier'
//     if (hwmOption?.value === "solar water heater") {
//       if (swhType?.value === "central") {
//         let data = myArray?.find((ele) => ele.id === "hwmApartment.swhcentral")
//         if (data === undefined || data === null) {
//           appendChanged(hwmidentifiertag, null)
//         } else {
//           appendChanged(hwmidentifiertag, data?.value)
//         }
//       }
//       if (swhType?.value === "individual") {
//         if (swhVariant?.value === "etc") {
//           let data = myArray?.find((ele) => ele.id === "hwmApartment.swhIndividualEtc")
//           if (data === undefined || data === null) {
//             appendChanged(hwmidentifiertag, null)
//           } else {
//             appendChanged(hwmidentifiertag, data?.value)
//           }
//         }
//         if (swhVariant?.value === 'fpc') {
//           let data = myArray?.find((ele) => ele.id === "hwmApartment.swhindividualFpc")
//           if (data === undefined || data === null) {
//             appendChanged(hwmidentifiertag, null)
//           } else {
//             appendChanged(hwmidentifiertag, data?.value)
//           }
//         }
//       }
//     } else if (hwmOption?.value === "heat pump") {
//       if (hpType?.value === "commercial") {
//         let data = myArray?.find((ele) => ele.id === "hwmApartment.hpcommercial")
//         if (data === undefined || data === null) {
//           appendChanged(hwmidentifiertag, null)
//         } else {
//           appendChanged(hwmidentifiertag, data?.value)
//         }
//       }
//       if (hpType?.value === "domestic") {
//         let data = myArray?.find((ele) => ele.id === "hwmApartment.hpDomestic")
//         if (data === undefined || data === null) {
//           appendChanged(hwmidentifiertag, null)
//         } else {
//           appendChanged(hwmidentifiertag, data?.value)
//         }
//       }
//     } else if (hwmOption?.value === "hybrid") {
//       let data = myArray?.find((ele) => ele.id === "hwmApartment.hybrid")
//       if (data === undefined || data === null) {
//         appendChanged(hwmidentifiertag, null)
//       } else {
//         appendChanged(hwmidentifiertag, data?.value)
//       }
//     }
//   }
//   if (myArray && myArray.length > 0) {
//     const values = myArray.map((v) => ({
//       id: v.id,
//       value: v.value === '' ? v.value : !isNaN(v.value) ? (Number.isInteger(v.value) ? parseInt(v.value) : parseFloat(v.value)) : v.value,
//     }));
//     const body = { type: 'dirty', values: values };
//     console.log(body, 'value to be sent')
//     axios
//       .put(`/projectv2/${props.projectId}/interventions/${props.interventionProjectMappingId}/sandboxes/${props.activeSandboxId}`, body)
//       .then((res) => {
//         if (res && res.data.data) {
//           handleClose();
//           props.handleRefresh();
//           props.handleBackdropOpen();
//         }
//       })
//       .catch((err) => console.log(err));
//   }
// };
