import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, } from '@material-ui/core';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';


export const EfficientFixturesDualFlush_CustomBasicParameters = (props) => {
    const { selectedInterventionDetails, onClose, handleSave } = props
    const classes = useStyles();
    const brand = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.efdualflushBrand')
    const productName = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.productName')

    const fixtureFlowRateFull = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.fixtureFlowRateFull')
    const fixtureFlowRateHalf = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.fixtureFlowRateHalf')

    const perUnitCapex = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.perUnitCapex')
    return (
        <Grid container direction="column" alignItems="left" item xs={12}>

            <Grid item xs={8} container alignItems='flex-start' >
                <Grid item xs={6}>
                    <Typography className={classes.textHeader3}>Brand</Typography>
                    <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{brand?.value}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textHeader3} >Product Name</Typography>
                    <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{productName?.value}</Typography>
                </Grid>
            </Grid>

            <Grid container direction="row" alignItems="left" item xs={12} style={{ marginTop: '3%' }}>
                <Grid item xs={12} container alignItems='flex-start' >

                    <Grid item xs={4} container>
                        <Grid xs={12} item>
                            <Typography className={classes.textHeader3}>Flush Rate - Full</Typography>
                        </Grid>
                        <Grid xs={12} item container>
                            <Grid>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{fixtureFlowRateFull?.value}</Typography>
                            </Grid>
                            <Grid style={{ paddingLeft: '2%' }}>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{fixtureFlowRateFull?.unit}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4} container>
                        <Grid xs={12} item>
                            <Typography className={classes.textHeader3} >Flush Rate - Half</Typography>
                        </Grid>
                        <Grid xs={12} item container>
                            <Grid>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{fixtureFlowRateHalf?.value}</Typography>
                            </Grid>
                            <Grid style={{ paddingLeft: '2%' }}>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>   {fixtureFlowRateHalf?.unit}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container>
                        <Grid xs={12} item>
                            <Typography className={classes.textHeader3} >Per Unit Capex</Typography>
                        </Grid>
                        <Grid xs={12} item container>
                            <Grid >
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{perUnitCapex?.value}</Typography>
                            </Grid>
                            <Grid style={{ paddingLeft: '2%' }}>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{perUnitCapex?.unit}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid>
    );
};


export const EfficientFixturesDualFlush_CustomAdvancesParameters = (props) => {
    const classes = useStyles();
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const WaterTankerPriceperKL = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.watertankerPerPricePerKL')
    const HousekeepingReferenceOccupancy = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.HousekeepingReferenceOccupancy')
    const ClubHouseReferenceOccupancy = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.ClubHouseReferenceOccupancy')
    const co2EmmitionFactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.co2EmmitionFactor')
    const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele?.id === 'efdualflush.MultiplierSource')
    const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'efdualflush.CapexMultiplier')
    const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'efdualflush.VendorCapexMultiplier')

    const [vendorTextField, selectVendorTextField] = useState(capexmultipliersource?.value === 'VENDOR' ? true : false)
    const [customTextField, setCustomTextField] = useState(capexmultipliersource?.value === 'CUSTOM' ? true : false)

    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };


    useEffect(() => {
        if (capexmultipliersource?.value === 'VENDOR') {
            selectVendorTextField(true)
            setCustomTextField(false)
        }
        if (capexmultipliersource?.value === 'CUSTOM') {
            setCustomTextField(true)
            selectVendorTextField(false)
        }
    }, [capexmultipliersource])

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>

            <Typography className={classes.textHeader1}>Intervention Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes will only affect this intervention </Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {vendorTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    appendChanged(capexmultipliersource.id, '')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'VENDOR')
                                }} />}
                            Selected Vendor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" justifyContent='flex-start' direction="row" style={{ display: "flex", alignItems: "flex-start", paddingLeft: '3%' }}>
                        <Typography className={classes.textHeader5} ><b>{vendorcapexmultiplier?.value} {vendorcapexmultiplier?.unit}</b></Typography>                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {customTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    appendChanged(capexmultipliersource.id, '')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'CUSTOM')
                                }} />}
                            Custom</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>

                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d?.id === customcapexmultiplier?.id)
                                    ? customcapexmultiplier?.value
                                    : myArray.find((d) => d.id === customcapexmultiplier?.id) && myArray.find((d) => d?.id === customcapexmultiplier?.id)?.value}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(customcapexmultiplier.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(customcapexmultiplier?.id, customcapexmultiplier?.defaultValue)}
                                disabled={true}
                                disableTextField={customTextField ? false : true}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Project Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes may affect other project or intervention values</Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>Water Tanker Price per KL</Typography>
            <Grid item xs={4} direction="column" style={{ paddingTop: "10px" }}>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={myArray && !myArray.find((d) => d?.id === WaterTankerPriceperKL?.id)
                                ? WaterTankerPriceperKL?.value
                                : myArray.find((d) => d.id === WaterTankerPriceperKL?.id) && myArray.find((d) => d?.id === WaterTankerPriceperKL?.id)?.value}
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                appendChanged(WaterTankerPriceperKL.id, Number(e.target.value));
                            }}
                            fullWidth
                            required
                            onClick={() => appendChanged(WaterTankerPriceperKL?.id, Number(WaterTankerPriceperKL?.defaultValue))}
                            disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">RS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Constants</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Housekeeping Reference Occupancy</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d?.id === HousekeepingReferenceOccupancy?.id)
                                    ? HousekeepingReferenceOccupancy?.value
                                    : myArray.find((d) => d.id === HousekeepingReferenceOccupancy?.id) && myArray.find((d) => d?.id === HousekeepingReferenceOccupancy?.id)?.value}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(HousekeepingReferenceOccupancy?.id, Number(e.target.value));
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(HousekeepingReferenceOccupancy?.id, Number(HousekeepingReferenceOccupancy?.defaultValue))}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">{HousekeepingReferenceOccupancy?.unit}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>ClubHouse Reference Occupancy</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d?.id === ClubHouseReferenceOccupancy?.id)
                                    ? ClubHouseReferenceOccupancy?.value
                                    : myArray.find((d) => d.id === ClubHouseReferenceOccupancy?.id) && myArray.find((d) => d?.id === ClubHouseReferenceOccupancy?.id)?.value}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(ClubHouseReferenceOccupancy?.id, Number(e.target.value));
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(ClubHouseReferenceOccupancy?.id, Number(ClubHouseReferenceOccupancy?.defaultValue))}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">{ClubHouseReferenceOccupancy?.unit}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5} ><b>{co2EmmitionFactor && co2EmmitionFactor?.value} {co2EmmitionFactor && co2EmmitionFactor?.unit}</b></Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};


export const EfficientFixturesDualFlush_ProjectInputs = (props) => {
    const classes = useStyles()
    const { selectedInterventionDetails, onClose } = props
    const villa1bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.onebhk')
    const villa2bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.twobhk')
    const villa3bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.threebhk')
    const villa4bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.villa.fourbhk')
    const pentHouse1bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.penthouse.onebhk')
    const pentHouse2bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.penthouse.twobhk')
    const pentHouse3bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.penthouse.threebhk')
    const pentHouse4bhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.penthouse.fourbhk')

    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Studio</Typography>
                    <Typography className={classes.textHeader3}>{villa1bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa2bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa3bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{villa4bhk?.value} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{pentHouse1bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{pentHouse2bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{pentHouse3bhk?.value} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{pentHouse4bhk?.value} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                // handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
}));
