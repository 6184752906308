import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import MarketplaceButton from '../../../common/Button'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { MarketplaceColors } from '../../../config';

const ProductDetailsAddToInterventionFailure = ({ style, className, intervention, onClose }) => {
    return (
        <div style={style} className={className}>
            <Grid container>
                <Grid xs={12} container alignItems='center' justifyContent='center' >
                    <Typography variant='h5'><b>Add Product</b></Typography>
                </Grid>
                <Grid xs={12} container alignItems='center' justifyContent='center' style={{ marginTop: '4%' }}>
                    <ErrorOutlineIcon style={{ fontSize: '80px', color: MarketplaceColors.Secondary }} />
                </Grid>
                <Grid xs={12} container alignItems='center' justifyContent='center' style={{ marginTop: '8%' }}>
                    This product has been added to "{intervention}"" already
                </Grid>
                <Grid xs={12} container alignItems='flex-end' justifyContent='flex-end' style={{ marginTop: '8%' }}>
                    <Grid xs={5}>
                        <MarketplaceButton
                            style={{ fontSize: '10px' }}
                            children="ok"
                            onClick={onClose}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default ProductDetailsAddToInterventionFailure