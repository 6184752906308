import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useScreenType from '../../../hooks/checkScreenType';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import _ from 'lodash';
import AddIconLarge from '../../../../assets/interventions/AddIconLarge.svg'

const useStyles = makeStyles((theme) => ({
    cardDesktop: {
        width: '220px',
        height: '340px',
        // margin: '10px',
        marginRight: '2%',
        marginBottom: '2%',
        boxShadow: 'none',
        border: '1px solid #00000033',
        borderRadius: '10px',
    },
    cardMobile: {
        width: '183px',
        margin: '10px',
        boxShadow: 'none',
        border: '1px solid #00000033',
        borderRadius: '10px',
    },
    media: {
        marginTop: 80,
        height: 80,
        backgroundSize: 'contain',
    },
    subname: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '19.2px',
        letterSpacing: '0em',
        display: "flex",
        justifyContent: 'center',
        color: "rgba(38, 224, 163, 1)"
    },
    description: {
        fontFamily: 'Lato',
        fontSize: '11px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.71px',
        textAlign: 'center',
        color: "rgba(102, 102, 102, 1)",
    },
}));

function DummyAddProductCard({ width, widthstyle, categoryName, subcategoryName, mappingid }) {
    const classes = useStyles();
    const { isMobile } = useScreenType(width)
    const handleClick = () => {
        const path = subcategoryName === undefined ? `/marketplace/categoryDetails?source=Intervention&category=${categoryName()}&page=1&pagesize=20&mappingID=${mappingid}` : `/marketplace/categoryDetails?source=Intervention&category=${categoryName()}&page=1&pagesize=20&mappingID=${mappingid}&subcategory=${subcategoryName}`
        window.open(path, "_blank")
    }
    return (
        <Card onClick={handleClick} fullwidth className={isMobile ? classes.cardMobile : classes.cardDesktop} style={widthstyle}>
            <a
                target="_blank"
                onclick={"return false;"}
                // href={`/marketplace/categoryDetails?source=Intervention&category=Cement&page=1&pagesize=20`}
                style={{ textDecoration: 'none', color: 'black' }}
            >
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={AddIconLarge}
                        title="Add Product"
                    />
                    <CardContent style={{ paddingLeft: '15px' }}>
                        <Typography gutterBottom className={classes.subname}>
                            ADD PRODUCTS
                        </Typography>
                        <Grid container item xs={12} style={{ padding: 30 }}>
                            <Typography className={classes.description}>
                                Shortlist and compare products for your intervention
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </a>
        </Card>
    );
}

export default withWidth()(DummyAddProductCard);
