import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import Fields from '../../../../common/textfield/Fields';
import ResetFields from '../common/textFiledwithReset';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
function ShowCustomizableParameters({
  customizable,
  myArray,
  setMaxError,
  setMinError,
  appendChanged,
  boundaryMin,
  boundaryMax,
  source,
  setSource,
  minError,
  maxError,
}) {
  const classes = useStyles();
  return (
    customizable &&
    customizable.map((data, index) => {
      if (!data.tags.includes('tank-size-customization') && !data.tags.includes('tank-size-options')) {
        return (
          <>
            <Grid item xs={6} direction="column" style={{ marginBottom: '12px' }}>
              <Grid item xs={12}>
                <Typography variant="h6">{data.label}</Typography>
              </Grid>
              <Grid item xs={12} container direction="row" alignItems="center" alignContent="center" style={{ paddingTop: '3%', paddingBottom: '2%' }}>
                <Grid item xs={5}>
                  {!data.hasOwnProperty('valueOptions') ? (
                    data.editable === true ? (
                      <>
                        <ResetFields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            myArray && !myArray.find((d) => d.id === data.id)
                              ? data.value
                              : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                          }
                          fullWidth
                          required
                          error={minError || maxError}
                          onChange={(e) => {
                            e.preventDefault();
                            if (boundaryMax && e.target.value > boundaryMax.value) {
                              setMaxError(true);
                              setMinError(false);
                            } else if (boundaryMin && e.target.value < boundaryMin.value) {
                              setMaxError(false);
                              setMinError(true);
                            } else {
                              setMaxError(false);
                              setMinError(false);
                            }
                            appendChanged(data.id, e.target.value);
                          }}
                          onClick={() => appendChanged(data.id, data.defaultValue)}
                        />
                      </>
                    ) : (
                      <>
                        <Fields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            myArray && !myArray.find((d) => d.id === data.id)
                              ? data.value
                              : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                          }
                          fullWidth
                          required
                          disabled
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged(data.id, e.target.value);
                          }}
                        />
                      </>
                    )
                  ) : (
                    <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                          className={classes.select}
                          fullWidth
                          // style={{ maxWidth: '70%' }}
                          value={
                            myArray && !myArray.find((d) => d.id === data.id)
                              ? data.value
                              : myArray.find((d) => d.id === data.id) && myArray.find((d) => d.id === data.id).value
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged(data.id, e.target.value);
                          }}
                        >
                          {data.id === 'rooftoprwh.rrwhdailydemand' && data.valueOptions
                            ? data.valueOptions.map((value, i) => {
                              return (
                                <MenuItem value={value} name={value} key={i}>
                                  {value}
                                </MenuItem>
                              );
                            })
                            : null}
                          {data.id !== 'rooftoprwh.rrwhdailydemand' && data.valueOptions
                            ? data.valueOptions.map((value, i) => {
                              if (!value.hasOwnProperty('enabled')) {
                                return (
                                  <MenuItem value={value} name={value} key={i}>
                                    {value}
                                  </MenuItem>
                                );
                              }
                              if (value.hasOwnProperty('enabled') === true) {
                                return (
                                  <MenuItem value={value.option} name={value.option} key={i} disabled={value.enabled ? false : true}>
                                    {value.option}
                                  </MenuItem>
                                );
                              }
                            })
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {data.valueOptions ? (
                    <ReplaySharpIcon
                      style={{ fontSize: '20px', color: '#666666', cursor: 'pointer', marginLeft: '5px' }}
                      onClick={() => appendChanged(data.id, data.defaultValue)}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">{data.unit ? data.unit : ''}</Typography>
                </Grid>
                {boundaryMin && boundaryMax && data && data.id === (boundaryMin.relatedTo || boundaryMax.relatedTo) ? (
                  <>
                    <Grid item xs={12} direction="row" container style={{ paddingTop: '2%' }} alignContent="center">
                      <Grid xs={2} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', textAlign: 'center' }}>
                        <Typography style={{ fontSize: '11px' }} color="textSecondary">{`${boundaryMin && boundaryMin.label}- ${boundaryMin && boundaryMin.value
                          }`}</Typography>
                      </Grid>
                      <Grid xs={2} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: '11px' }} color="textSecondary">{`${boundaryMax && boundaryMax.label}- ${boundaryMax && boundaryMax.value
                          }`}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} direction="row" container style={{ paddingTop: '2%' }} alignContent="flex-start">
                      {minError && minError && (
                        <Typography style={{ fontSize: '11px', color: 'red' }}>{`Please enter a value above ${boundaryMin && boundaryMin.value}`}</Typography>
                      )}
                      {maxError && maxError && (
                        <Typography style={{ fontSize: '11px', color: 'red' }}>{`Please enter a value below ${boundaryMax && boundaryMax.value}`}</Typography>
                      )}
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </>
        );
      } else if (!data.tags.includes('tank-size-customization')) {
        return (
          <>
            <Grid container item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6">Tank Size</Typography>
              <Grid item container alignItems="flex-start" direction="row" xs={12} style={{ marginTop: '1%' }}>
                <Grid item xs={4} container alignItems="flex-start" style={{ marginTop: '1%' }}>
                  <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
                    {source === 'Default' ? (
                      <CheckCircleRoundedIcon style={{ color: '#49DDB1' }} />
                    ) : (
                      <RadioButtonUncheckedOutlinedIcon
                        style={{ color: '#49DDB1', cursor: 'pointer' }}
                        onClick={() => {
                          setSource('Default');
                          appendChanged(data.id, 'Default');
                          appendChanged(
                            'rooftoprwh.rrwhtanksize',
                            customizable.find((d) => d.tags.includes('tank-size-customization')) &&
                            customizable.find((d) => d.tags.includes('tank-size-customization')).defaultValue
                          );
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} container direction="column" style={{ paddingLeft: '1%' }}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary" variant="h6">
                        Default
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                      <Grid xs={9} item>
                        <ResetFields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            customizable.find((d) => d.tags.includes('tank-size-customization')) &&
                            customizable.find((d) => d.tags.includes('tank-size-customization')).defaultValue
                          }
                          placeholder=""
                          fullWidth
                          required
                          disabled={true}
                        />
                      </Grid>
                      <Grid xs={2} container justifyContent="center" alignItems="center">
                        <Typography variant="h6">{data && data.unit}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} container alignItems="flex-start" style={{ marginTop: '1%' }}>
                  <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
                    {source === 'Custom' ? (
                      <CheckCircleRoundedIcon style={{ color: '#49DDB1' }} />
                    ) : (
                      <RadioButtonUncheckedOutlinedIcon
                        style={{ color: '#49DDB1', cursor: 'pointer' }}
                        onClick={() => {
                          setSource('Custom');
                          appendChanged(data.id, 'Custom');
                        }}
                      />
                    )}
                  </Grid>
                  <Grid xs={10} container direction="column" style={{ paddingLeft: '1%' }}>
                    <Grid xs={12} item>
                      <Typography color="textSecondary" variant="h6">
                        Custom
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container direction="row" style={{ marginTop: '5%' }} justifyContent="flex-start">
                      <Grid xs={9} item>
                        <Fields
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            myArray && !myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksize')
                              ? customizable.find((d) => d.tags.includes('tank-size-customization')) &&
                              customizable.find((d) => d.tags.includes('tank-size-customization')).value
                              : myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksize') && myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksize').value
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged('rooftoprwh.rrwhtanksize', e.target.value);
                          }}
                          fullWidth
                          required
                          disabled={source && source === 'Custom' ? false : true}
                        />
                      </Grid>
                      <Grid xs={2} container justifyContent="center" alignItems="center">
                        <Typography variant="h6">{data.unit}</Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12} container direction="row" style={{ marginTop: '3%' }}>
                      {/* <Grid item xs={1}></Grid>
                      <Grid item xs={9}>
                        <Typography variant="h6">Min - 1,000</Typography>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      }
    })
  );
}

export default ShowCustomizableParameters;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));
