import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import MediaCard from '../../../../common/ProductCard';
import productimage2 from '../../../../../../../assets/Marketplace/product/productcardimage2.svg';
import leftarrow from '../../../../../../../assets/carousal/leftarrow.svg';
import rightarrow from '../../../../../../../assets/carousal/rightarrow.svg';
import LeftArrow from '../../../../common/LeftArrow';
import RightArrow from '../../../../common/RightArrow';
import Skeleton from '@material-ui/lab/Skeleton';

const DesktopProductList = (props) => {
  const { width, supercategoryProductList, selected } = props;
  console.log(supercategoryProductList, 'supercategoryProductList');
  const leftarrowStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '320px',
    paddingLeft: '30px',
    cursor: 'pointer',
  };
  const rightarrowStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '320px',
    paddingRight: '30px',
    cursor: 'pointer',
  };
  const imageStyles = {
    margin: 'auto',
    display: 'block',
  };

  let number1 = width === 'md' ? 3 : 4;
  let number2 = width === 'md' ? 2 : 4;
  let number3 = width === 'md' ? supercategoryProductList?.length + 1 : supercategoryProductList?.length;
  const [finalproducts, setFinalProducts] = useState(supercategoryProductList && supercategoryProductList.slice(0, number1));
  const [initialCount, setInitailCount] = useState(0);
  const [finalCount, setFinalCount] = useState(number1);

  const prev = () => {
    if (initialCount - number1 >= 0) {
      setFinalProducts(supercategoryProductList.slice(initialCount - number1, finalCount - number1));
      setInitailCount(initialCount - number1);
      setFinalCount(finalCount - number1);
    } else {
      setFinalProducts(supercategoryProductList.slice(supercategoryProductList.length - number2, number3));
      setInitailCount(supercategoryProductList.length - number2);
      setFinalCount(number3);
    }
  };
  const next = () => {
    if (finalCount >= supercategoryProductList.length) {
      setFinalCount(number1);
      setInitailCount(0);
      setFinalProducts(supercategoryProductList.slice(0, number1));
    } else {
      setFinalProducts(supercategoryProductList.slice(finalCount, finalCount + number1));
      setInitailCount(finalCount);
      setFinalCount(finalCount + number1);
    }
  };

  useEffect(() => {
    setInitailCount(0);
    setFinalCount(number1);
    setFinalProducts(supercategoryProductList.slice(0, number1));
  }, [number1, supercategoryProductList, width]);

  return (
    <Grid container direction="row" item xs={12} style={{ paddingBottom: '2%', paddingTop: '2%' }}>
      <LeftArrow leftarrow={leftarrow} prev={prev} arrowStyles={leftarrowStyles} imageStyles={imageStyles} />
      <Grid item xs={10}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item alignItems="center" justifyContent="center">
          {supercategoryProductList && finalproducts ? (
            finalproducts &&
            finalproducts.map((ele) => {
              return (
                <MediaCard
                  ele={ele}
                  productimage={productimage2}
                  width={width}
                  productImage={ele?.image}
                  identifier={ele?.identifier}
                  productName={ele?.name}
                  productBrandName={ele?.brand?.name}
                  productShortDescription={ele?.shortDescription}
                  ecolabels={ele?.ecolabels}
                  productCertifications=""
                  productCategoryID={ele?.category?.id}
                  productID={ele?.id}
                />
              );
            })
          ) : (
            <>
              <Skeleton variant="rect" height="55vh" width="15vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="55vh" width="15vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="55vh" width="15vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
              <Skeleton variant="rect" height="55vh" width="15vw" animation="wave" style={{ marginRight: '22px', borderRadius: '5px' }} />
            </>
          )}
        </Grid>
      </Grid>

      {<RightArrow rightarrow={rightarrow} next={next} arrowStyles={rightarrowStyles} imageStyles={imageStyles} />}
    </Grid>
  );
};

export default DesktopProductList;
