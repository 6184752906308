import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useApiRequest from '../../../../../hooks/useApiRequest';
import { UserContext } from '../../../../../../routing/IndexRouting';
import cookie from 'react-cookies';
import axios from '../../../../../../config/Axios';

const UseInterventionMappingsDataInProductDetails = () => {
    const { userDetails } = useContext(UserContext);
    const token = cookie.load('SDPLUS_TOKEN');
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const projectInterventionMapping = query.get('mappingID');
    const [{ status, response }, makeRequest] = useApiRequest(`ProjectInterventionMapping/${projectInterventionMapping}`, {
        verb: 'get',
    });
    if (status === null && projectInterventionMapping) makeRequest();

    const [interventionMappingData, setInterventionMappingData] = useState(null);
    const [ProjectName, setProjectName] = useState(null);
    const [InterventionName, setInterventionName] = useState(null);
    const [InterventionID, setInterventionID] = useState(null)

    useEffect(() => {
        if (response) {
            let data = response?.data?.data?.data[0]
            let { interventionScheme } = data
            setInterventionID(interventionScheme)
            setInterventionMappingData(response?.data?.data?.data[0])
        }
    }, [response]);

    useEffect(() => {
        if (userDetails && userDetails._id && token && InterventionID) {
            axios
                .post(
                    '/graphql',
                    {
                        query: `query ExampleQuery {
                    getProjectsbyUserID(
                      id: "${userDetails._id}"
                      filter: {
                        goalFilter: { category: [] }
                        interventionFilter: { category: [] }
                        pipNames: { pips: ["rooftop", "builtup"] }
                      }
                      pagination: { skip: 0, limit: 100 }
                    ) {
                      currentProject {
                        location {
                          name
                          type
                          coordinates
                        }
                        category
                        pips {
                          pipScheme {
                            name
                            unit
                          }
                          value
                        }
                        _id
                        name
                      }
                      otherProjects {
                        name
                        location {
                          name
                          type
                          coordinates
                        }
                        category
                        pips {
                          pipScheme {
                            name
                            unit
                          }
                          value
                        }
                        _id
                      }
                    }
                  }`,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res) {
                        const projectName = res?.data?.data?.getProjectsbyUserID?.currentProject?.name
                        setProjectName(projectName)
                        axios.get(`/interventionschemev2/${InterventionID}`).then((res) => setInterventionName(res?.data?.data?.data?.name)
                        );
                    }
                })
                .catch((err) => {
                    console.log(err, 'error');
                });
        }
    }, [token, userDetails, InterventionID]);

    return { interventionMappingData, ProjectName, InterventionName }
};

export default UseInterventionMappingsDataInProductDetails;