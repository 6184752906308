import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from '../../../../common/button/Button';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'center',
        paddingTop: "20px"
    },
    subtext: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '0.71px',
        textAlign: 'center',
        paddingTop: "20px"
    },
    dialogstyle: {
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.07)',
        borderRadius: "10px",
        width: '70%',
    },
    errorIconStyle: {
        fontSize: "100px", textAlign: 'center', marginLeft: "40%"
    },
    color: {
        color: 'rgba(51, 81, 142, 1)'
    }
}));

export default function CustomAlert({ handleRemove, open, setOpen, disabled, backgroundColor }) {
    // const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container item xs={12} style={{
            display: "flex",
            justifyContent: "center",

        }}>
            <PrimaryButton
                onClick={handleClickOpen}
                children="remove"
                style={!disabled ? {
                    background: '#FFFFFF',
                    border: '1px solid rgba(237, 94, 104, 1)',
                    color: 'rgba(237, 94, 104, 1)',
                    width: '90%',
                    boxShadow: "none",
                } : {
                    background: '#FFFFFF',
                    border: '1px solid rgba(158, 158, 158, 1)',
                    color: 'rgba(158, 158, 158, 1)',
                    width: '90%',
                    boxShadow: "none",
                }}
                disabled={disabled}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogstyle }}
            >
                <Typography className={classes.heading}>Remove Product</Typography>
                <DialogContent>
                    <ErrorOutlineIcon className={classes.errorIconStyle} />
                    <Typography className={classes.subtext}>Remove this product from shortlist</Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} className={classes.color}>
                        Cancel
                    </Button>
                    <PrimaryButton
                        onClick={handleRemove}
                        children="remove"
                        style={{
                            background: 'rgba(51, 81, 142, 1)',
                            color: '#FFFFFF',
                            boxShadow: "none",
                            width: "30%"
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
