export const reorderProducts = (products, selectedProduct) => {
    if (!products || !selectedProduct) return products;
    // const isObjectArray = typeof products[0] === 'object' && products[0] !== null;
    const selected =
        products.find(product => product.identifier === selectedProduct);

    const rest = products.filter(product => product.identifier !== selectedProduct);
    // console.log(products, selectedProduct, selected, rest, 'abcdef')

    return selected ? [selected, ...rest] : products;
};

const getValueFromIntDetails = (interventiondetails, id) => {
    return interventiondetails?.quantities?.details.find((ele) => ele.id === id);
}

const filterProducts = (products, filterConditions) => {
    return products && products?.filter((product) => {
        const systemTypes = product?.filters?.['System types'] || [];
        return filterConditions.every(condition => condition(product, systemTypes));
    });
};

const getSolarWaterHeatersFilter = (swhType, swhVariant) => (product, systemTypes) => {
    return product?.subcategory?.name === 'Solar Water Heaters' &&
        systemTypes.includes(swhType === "central" ? 'Centralized Solar Heater' : 'Individual Solar Heater') &&
        product?.filters?.Variant[0] === swhVariant?.value.toUpperCase();
};

const getHeatPumpsFilter = (hpType) => (product, systemTypes) => {
    if (hpType === "commercial") {
        return product?.subcategory?.name === 'Heat Pumps' &&
            (systemTypes.includes('Swimming Pool Heat Pumps') || systemTypes.includes('Commercial Heat pumps'));
    }
    if (hpType === "domestic") {
        return product?.subcategory?.name === 'Heat Pumps' && systemTypes.includes('Domestic Heat Pumps');
    }
    return false;
};

const getHybridSystemFilter = () => (product) => {
    return product && product?.subcategory?.name === 'Hybrid system';
};

export const HWmApartment = (interventiondetails, products, setFilteredProducts, interventionname, selectedProduct) => {
    if (interventionname === "Hot Water Management Apartment") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmApartment.options");
        const swhType = getValueFromIntDetails(interventiondetails, "hwmApartment.swhType");
        const swhVariant = getValueFromIntDetails(interventiondetails, "hwmApartment.swhVariant");
        const hpType = getValueFromIntDetails(interventiondetails, "hwmApartment.HpType");

        let filters = [];

        if (hwmOption?.value === "solar water heater" && (swhType?.value === "central" || swhType?.value === "individual")) {
            filters.push(getSolarWaterHeatersFilter(swhType.value, swhVariant));
        } else if (hwmOption?.value === "heat pump" && (hpType?.value === "commercial" || hpType?.value === "domestic")) {
            filters.push(getHeatPumpsFilter(hpType.value));
        } else if (hwmOption?.value === "hybrid") {
            filters.push(getHybridSystemFilter());
        }

        const filteredProducts = filterProducts(products && products, filters);
        setFilteredProducts(filteredProducts);
    }
}

export const HWmPenthouse = (interventiondetails, products, setFilteredProducts, interventionname, selectedProduct) => {
    if (interventionname === "Hot Water Management Penthouse") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmPentHouse.options");
        const swhType = getValueFromIntDetails(interventiondetails, "hwmPentHouse.swhType");
        const swhVariant = getValueFromIntDetails(interventiondetails, "hwmPentHouse.swhVariant");
        const hpType = getValueFromIntDetails(interventiondetails, "hwmPentHouse.HpType");

        let filters = [];

        if (hwmOption?.value === "solar water heater" && (swhType?.value === "central" || swhType?.value === "individual")) {
            filters.push(getSolarWaterHeatersFilter(swhType.value, swhVariant));
        } else if (hwmOption?.value === "heat pump" && (hpType?.value === "commercial" || hpType?.value === "domestic")) {
            filters.push(getHeatPumpsFilter(hpType.value));
        }
        const filteredProducts = filterProducts(products && products, filters);
        setFilteredProducts(filteredProducts);
    }
}

export const HWmClubHouse = (interventiondetails, products, setFilteredProducts, interventionname, selectedProduct) => {
    if (interventionname === "Hot Water Management Clubhouse") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmClubHouse.options");
        const swhType = getValueFromIntDetails(interventiondetails, "hwmClubHouse.swhType");
        const swhVariant = getValueFromIntDetails(interventiondetails, "hwmClubHouse.swhVariant");
        const hpType = getValueFromIntDetails(interventiondetails, "hwmClubHouse.HpType");

        let filters = [];

        if (hwmOption?.value === "solar water heater" && (swhType?.value === "central" || swhType?.value === "individual")) {
            filters.push(getSolarWaterHeatersFilter(swhType.value, swhVariant));
        } else if (hwmOption?.value === "heat pump" && (hpType?.value === "commercial" || hpType?.value === "domestic")) {
            filters.push(getHeatPumpsFilter(hpType.value));
        }

        const filteredProducts = filterProducts(products && products, filters);
        setFilteredProducts(filteredProducts);
    }
}
export const HWMVilla = (interventiondetails, products, setFilteredProducts, interventionname, selectedProduct) => {
    if (interventionname === "Hot Water Management Villa") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmVilla.options");
        const swhType = getValueFromIntDetails(interventiondetails, "hwmVilla.swhType");
        const swhVariant = getValueFromIntDetails(interventiondetails, "hwmVilla.swhVariant");
        const hpType = getValueFromIntDetails(interventiondetails, "hwmVilla.HpType");

        let filters = [];

        if (hwmOption?.value === "solar water heater" && (swhType?.value === "central" || swhType?.value === "individual")) {
            filters.push(getSolarWaterHeatersFilter(swhType.value, swhVariant));
        } else if (hwmOption?.value === "heat pump" && (hpType?.value === "commercial" || hpType?.value === "domestic")) {
            filters.push(getHeatPumpsFilter(hpType.value));
        }

        const filteredProducts = filterProducts(products && products, filters);
        setFilteredProducts(filteredProducts);
    }
}

export const getSubcategory = (interventiondetails, interventionname) => {
    if (interventionname === "Hot Water Management Apartment") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmApartment.options");
        if (hwmOption?.value === 'solar water heater') {
            return 'Solar Water Heaters'
        } else if (hwmOption?.value === 'heat pump') {
            return 'Heat Pumps'
        } else if (hwmOption?.value === 'hybrid') {
            return 'Hybrid Systms'
        }
    }
    if (interventionname === "Hot Water Management Clubhouse") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmClubHouse.options");
        if (hwmOption?.value === 'solar water heater') {
            return 'Solar Water Heaters'
        } else if (hwmOption?.value === 'heat pump') {
            return 'Heat Pumps'
        }
    } else if (interventionname === "Hot Water Management Villa") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmVilla.options");
        if (hwmOption?.value === 'solar water heater') {
            return 'Solar Water Heaters'
        } else if (hwmOption?.value === 'heat pump') {
            return 'Heat Pumps'
        }
    }
    if (interventionname === "Hot Water Management Penthouse") {
        const hwmOption = getValueFromIntDetails(interventiondetails, "hwmPentHouse.options");
        if (hwmOption?.value === 'solar water heater') {
            return 'Solar Water Heaters'
        } else if (hwmOption?.value === 'heat pump') {
            return 'Heat Pumps'
        }
    }
}
