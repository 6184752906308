import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

export const HWMSwimmingPoolCustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    const hwmhpType = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.HpType')
    const hwmhpheating = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.heatingSpeed')
    const individualproductcapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.hpindividualProductCapacity')
    const numberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.hpnumberOfProducts')
    const hwmareaRequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.hpAreaRequired')
    const hwmuserHpCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.userHpCapacity')

    const [hpType, setHpType] = useState('')
    const [hpTemp, setHpTemp] = useState('')

    useEffect(() => {
        if (hwmhpType.value === "Domestic") {
            setHpType('Domestic')
        } else if (hwmhpType.value === "swimming pool") {
            setHpType('swimming pool')
        }
        if (hwmhpheating.value === 'slow') {
            setHpTemp('slow')
        } else if (hwmhpheating.value === 'fast') {
            setHpTemp('fast')
        }
    }, [])

    const handleHpType = (type) => {
        if (type === 'swimming pool') {
            if (hpType === type) {
                setHpType('')
                appendChanged(hwmhpType.id, null);
            } else {
                setHpType(type)
                appendChanged(hwmhpType.id, type);
            }
        }
    }
    const handleHpTemp = (temp) => {
        if (temp === 'slow') {
            if (hpTemp === temp) {
                setHpTemp('')
                appendChanged(hwmhpheating.id, null);
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheating.id, temp);
            }
        } else if (temp === 'fast') {
            if (hpTemp === temp) {
                setHpTemp('')
                appendChanged(hwmhpheating.id, null);
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheating.id, temp);
            }
        }
    }
    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Grid item xs={4} container alignItems="flex-start" direction="column">
                <Grid xs>
                    <Typography className={classes.textheader2}>
                        Heat Pump Capacity
                    </Typography>
                </Grid>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={myArray && !myArray.find((d) => d.id === hwmuserHpCapacity.id)
                                ? hwmuserHpCapacity.value
                                : myArray.find((d) => d.id === hwmuserHpCapacity.id) && myArray.find((d) => d.id === hwmuserHpCapacity.id).value
                            }
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                appendChanged(hwmuserHpCapacity.id, Number(e.target.value))
                            }}
                            fullWidth
                            required
                            onClick={() => appendChanged(hwmuserHpCapacity.id, Number(hwmuserHpCapacity?.defaultValue))} disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">LPD</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} container alignItems='flex-start' style={{ maxWidth: '58%' }}>
                <Grid item xs={6}>
                    <Typography className={classes.textheader2}>Type</Typography>
                    <Typography className={classes.textHeader3}>
                        {hpType === 'swimming pool' ? (
                            <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                        ) : (
                            <RadioButtonUncheckedIcon
                                className={classes.nonselectedRadiobox}
                                onClick={() => handleHpType('swimming pool')}
                            />
                        )}
                        Swimming Pool</Typography>

                </Grid>
                <Grid item xs={6} >
                    <Typography className={classes.textheader2} style={{ paddingRight: '50px' }}>Heating</Typography>
                    <Typography className={classes.textHeader3}>
                        {hpTemp === 'slow' ? (
                            <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                        ) : (
                            <RadioButtonUncheckedIcon
                                className={classes.nonselectedRadiobox}
                                onClick={() => handleHpTemp('slow')}
                            />
                        )}
                        Slow Heating</Typography>
                    <Typography className={classes.textHeader3}>
                        {hpTemp === 'fast' ? (
                            <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                        ) : (
                            <RadioButtonUncheckedIcon
                                className={classes.nonselectedRadiobox}
                                onClick={() => handleHpTemp('fast')}
                            />
                        )}
                        Fast Heating</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} direction="row">
                <Grid item xs={6}>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%", justifyContent: 'center' }}>
                        <Grid item xs={7}>
                            <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography>
                        </Grid>
                        <Grid item xs={5} >
                            <Typography className={classes.textHeader3} >No. of Products</Typography>
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Area Required</Typography>
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmareaRequired?.value))} ${hwmareaRequired?.unit}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};

export const HWMSwimmingPoolCustomAdvancesParameters = (props) => {
    const classes = useStyles();
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const hwmelecostperkwh = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.ElectricityCostperkWh')
    const rooftopareaperhplpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.rooftopAreaPerHPLPD')
    const hotwaterusageshours = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.hotwaterusuageHours')
    const numberofplumbingshafts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.numberofPlumbingShafts')
    const co2emissionfactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.co2emmissionfactor')
    const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.multipliersource')
    const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.customCapexMultiplier')
    const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmSwimmingPool.vendorCapexMultiplier')

    const [vendorTextField, selectVendorTextField] = useState(false)
    const [customTextField, setCustomTextField] = useState(false)
    const [vendorCapex, setVendorCapex] = useState(0)
    const [customCapex, setCustomcapex] = useState(0)
    const [electricityCost, setElectricityCost] = useState(0)
    const [rftopAreaperHPLPD, setRftopAreaperHPLPD] = useState(0)
    const [hwUsageHours, setHwUsageHours] = useState(0)
    const [numberofPlumbingShafts, setNumberofPlumbingShafts] = useState(0)

    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };

    useEffect(() => {
        if (hwmelecostperkwh && hwmelecostperkwh.value) {
            setElectricityCost(hwmelecostperkwh.value)
        }
        if (rooftopareaperhplpd && rooftopareaperhplpd.value) {
            setRftopAreaperHPLPD(rooftopareaperhplpd.value)
        }
        if (hotwaterusageshours && hotwaterusageshours.value) {
            setHwUsageHours(hotwaterusageshours.value)
        }
        if (numberofplumbingshafts && numberofplumbingshafts.value) {
            setNumberofPlumbingShafts(numberofplumbingshafts.value)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'custom') {
            setCustomTextField(true)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'vendor') {
            selectVendorTextField(true)
        }
        if (customcapexmultiplier && customcapexmultiplier.value) {
            setCustomcapex(customcapexmultiplier.value)
        }
    }, [])

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Intervention Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes will only affect this intervention </Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>CAPEX(per kWp installed)</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {vendorTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'vendor')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'vendor')
                                }} />}
                            Selected Vendor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={vendorcapexmultiplier?.value}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setVendorCapex(e.target.value);
                                }}
                                fullWidth
                                required
                                // onClick={() => setVendorCapex(0)}
                                disabled={true}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {customTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'custom')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'custom')
                                }} />}
                            Custom</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customCapex}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCustomcapex(e.target.value);
                                    appendChanged(customcapexmultiplier.id, e.target.value)
                                }}
                                fullWidth
                                required
                                onClick={() => setCustomcapex(customcapexmultiplier?.value)}
                                disabled={!customTextField ? true : false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Project Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes may affect other project or intervention values</Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>Electricity Cost per kWh</Typography>
            <Grid item xs={4} direction="column" style={{ paddingTop: "10px" }}>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={electricityCost}
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                setElectricityCost(e.target.value);
                                appendChanged(hwmelecostperkwh && hwmelecostperkwh.id, e.target.value);
                            }}
                            fullWidth
                            required
                            onClick={(e) => {
                                e.preventDefault();
                                setElectricityCost(hwmelecostperkwh?.defaultValue)
                                appendChanged(hwmelecostperkwh && hwmelecostperkwh.id, hwmelecostperkwh?.defaultValue);
                            }}
                            disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">RS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Constants</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Rooftop Area per HP LPD</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={rftopAreaperHPLPD}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRftopAreaperHPLPD(e.target.value);
                                    appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRftopAreaperHPLPD(rooftopareaperhplpd?.defaultValue);
                                    appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, rooftopareaperhplpd?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Number of Plumbing Shafts</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Used for calculating heat loss from standby water</Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={numberofPlumbingShafts}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setNumberofPlumbingShafts(e.target.value);
                                    appendChanged(numberofplumbingshafts.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setNumberofPlumbingShafts(numberofplumbingshafts?.defaultValue);
                                    appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, numberofplumbingshafts?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">nos</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Hot Water Usage Hours</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={hwUsageHours}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setHwUsageHours(e.target.value);
                                    appendChanged(hotwaterusageshours.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setHwUsageHours(hotwaterusageshours?.defaultValue);
                                    appendChanged(hotwaterusageshours && hotwaterusageshours.id, hotwaterusageshours?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">hrs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "10px" }}>

                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <Typography className={classes.textconstants} >{numberWithCommas(co2emissionfactor?.value)} kg CO2e/kWh</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};

export const HWMSwimmingPoolProjectInputs = (props) => {
    const classes = useStyles()
    const { selectedInterventionDetails, onClose } = props
    const swimmingPoolsurfaceArea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.pool')
    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Swimming Pool Surface Area</Typography>
                    <Typography className={classes.textHeader3}>{swimmingPoolsurfaceArea?.value} Sqm</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                // handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>

        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        // color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
}));
