import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from './components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import ComplexPIPS from './components/ComplexPIPS';

export const CAPEXPIPsForWWB = {
    OrganicComposter: 'wwb.userbasecase.organicwastecomposter.capex',
    Composting: 'wwb.userbasecase.composting.capex',
};

export const renewableenergy = {
    spv: 'basecasespvcapacity',
    swh: 'basecasesolarwaterheatercapacity'
};

export const Dwelling = {
    perimeter: 'perimeter',
    wwr: 'wwr',
    floors: 'floors',
    floorheight: 'floor2floorheight',
};

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

const EditProjectsCommonPIP = (props) => {
    let history = useHistory();
    let { id } = useParams();
    let skeleton = [1, 2, 3, 4, 5];
    let { type, next, name } = props;
    const [myArray, setMyArray] = useState([]);
    const [simplePIPs, setSimplePIPs] = useState('');
    const [villarefatedPIP, setVillaRelatedPIP] = useState('')
    const [renewableEnergyPIP, setRenewableEnergyPIP] = useState('')
    const [organicWasteManagementPIP, setOrganicWasteManagementPIP] = useState('')

    // others
    const [data, setData] = useState('');
    const [projectPIP, setProjectPIP] = useState([]);
    const [projectData, setProjectData] = useState('')

    // unit states
    const [unit, setUnit] = useState('');
    const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
    const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
        verb: 'get',
    });
    const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
        verb: 'get',
    });
    if (status === null && type && type !== 'file') makeRequest();
    if (mo?.status === null && type && type !== 'file') doRequest();

    useEffect(() => {
        if (mo?.response) {
            setProjectData(mo?.response?.data?.data?.data)
        }
    }, [mo?.response]);

    useEffect(() => {
        const basicPIPs = ['nonavailable', 'lobbies', 'parking', 'pool']
        const commonAreaDetailsPIPIds = ['clubhouse.rooftop.area']
        const renewableEnergyPIPs = ['basecasesolarwaterheatercapacity', "basecasespvcapacity",]
        const organicWasteManagementPIPs = ['wwb.userbasecase', 'wwb.userbasecase.organicwastecomposter.capex', 'wwb.userbasecase.composting.capex']
        if (data) {
            console.log(data, "dataaaaaaaaa")
            const SimplePIPs = data.filter(field => basicPIPs.includes(field.name))
            const villaPIP = data.filter(field => commonAreaDetailsPIPIds.includes(field.name))
            const renewableEnergy = data.filter(field => renewableEnergyPIPs.includes(field.name))?.sort((a, b) => {
                if (a?.name.includes('basecasesolarwaterheatercapacity')) return -1;
                if (b?.name.includes('basecasespvcapacity')) return 1;
                return 0;
            })
            const OrganicWasteManagementPIPs = data.filter(field => organicWasteManagementPIPs.includes(field.name))
            setSimplePIPs(SimplePIPs);
            setVillaRelatedPIP(villaPIP);
            setRenewableEnergyPIP(renewableEnergy)
            setOrganicWasteManagementPIP(OrganicWasteManagementPIPs)
        }
    }, [data]);


    //useEffects
    useEffect(() => {
        if (projectPIP.length !== 0) {
            let arr2 = [];
            projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
            if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
        }
    }, [projectPIP]);

    useEffect(() => {
        if (projectPIP.length !== 0 && myArray.length !== 0) {
            myArray.forEach((ele) => {
                let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
                if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
            });
        }
    }, [projectPIP, myArray]);

    useEffect(() => {
        if (response) {
            const unsortedData = response && response.data.data.data;
            const sorted = unsortedData.sort((a, b) => compare(a, b));
            setData(sorted);
        }
    }, [response]);

    useEffect(() => {
        if (id) {
            axios
                .get(`/ProjectPIPMapping/get?project=${id}`)
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        setProjectPIP(response.data.data.data);
                    }
                })
                .catch((err) => console.log(err, 'error'));
        }
    }, [id]);

    useEffect(() => {
        if (projectPIP) {
            // check for pips for existing unit for the project
            // find the value as sqm or sqft from pip mapping
            const projectUnit = unit && unit?.value ? unit.value : 'Sqm';
            // set project unit if found from pips
            setUnit(projectUnit && projectUnit);
        }
    }, [projectPIP]);

    const compare = (a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        else return 0;
    };

    const handleNext = (next) => {
        if (next && next === 'goals') history.push(`/projects/${id}/goals`);
        else {
            if (somethingChanged) {
                props.toogleShowModal(true);
                props.SETNEXT(next);
            } else {
                if (next && next === 'insights') history.push(`/projects/${id}/insights`);
                if (next && next === 'projects') history.push(`/projects`);
                else history.push(`/projects/${id}/edit?type=${next}`);
            }
        }
    };

    useEffect(() => {
        if (props.saveAfterSomethingChanged === true && next) {
            props.toogleShowModal(false);
            handlSetSomethingChanged(false);
            props.setFalseAfterSomethingChanged();
            props.SETNEXT('');
            handleSave(next && next);
        }
    }, [props.saveAfterSomethingChanged, next]);

    const handleGoBack = (type) => {
        if (type === 'basic') history.push(`/projects/${id}/insights`);
        else history.push(`/projects/${id}/edit?type=basic`);
    };


    const handleSave = (next) => {
        // sends data
        let body2 = {
            projectId: id,
            values: myArray && myArray.length !== 0 && myArray,
        };
        axios.put(`/ProjectPIPMapping`, body2).then((response) => {
            props.toogleShowModal(false);
            handlSetSomethingChanged(false);
            props.setFalseAfterSomethingChanged();
            props.SETNEXT('');
            swal({ icon: 'success', title: 'Data Saved' });
            if (next && next === 'insights') history.push(`/projects/${id}/insights`);
            if (next && next === 'projects') history.push(`/projects`);
            else history.push(`/projects/${id}/edit?type=${next}`);
        });

    };

    // creates myArray for sending all values together
    const appendChanged = (id, value) => {
        const body = {
            PIPScheme: id,
            value: value,
        };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.PIPScheme === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else {
            setMyArray((oldArray) => [...oldArray, body]);
        }
    };



    return (
        <>
            <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%', marginBottom: '3%' }}>
                <HeadAndBackIcon
                    heading={`${name && name} ${projectData?.name ? projectData.name : ''}`}
                    handleClick={() => handleGoBack(type && type)}
                    visitGoals={false}
                    headingVariant="h5"
                    headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
                    showArrow={false}
                />
            </Grid>

            {/*will be cleaned up later*/}
            {type && type === 'common' && simplePIPs && projectPIP?.length !== 0
                ? simplePIPs.map((field, index) => {
                    return (
                        <SimplePIPS
                            index={index}
                            unit={unit && unit}
                            field={field && field}
                            myArray={myArray && myArray}
                            projectPIP={projectPIP && projectPIP}
                            appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                            type={type}
                        />
                    )
                })
                : type &&
                type === 'common' &&
                skeleton &&
                skeleton.map((d, index) => {
                    return <Skeletons index={index} />;
                })}

            {/* villa related pip */}
            {type && type === 'common' && villarefatedPIP && projectPIP?.length !== 0 && projectData?.subcategory !== 'villa'
                ? villarefatedPIP.map((field, index) => {
                    return (
                        <SimplePIPS
                            index={index}
                            unit={unit && unit}
                            field={field && field}
                            myArray={myArray && myArray}
                            projectPIP={projectPIP && projectPIP}
                            appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                            type={type}
                        />
                    )
                })
                : type &&
                type === 'common' &&
                skeleton &&
                skeleton.map((d, index) => {
                    return <Skeletons index={index} />;
                })}

            {type && type === 'common' && organicWasteManagementPIP && projectPIP?.length !== 0
                ? organicWasteManagementPIP.map((field, index) => {
                    if (field?.options?.length !== 0) {
                        return (
                            <ComplexPIPS
                                index={index}
                                unit={unit && unit}
                                field={field && field}
                                myArray={myArray && myArray}
                                projectPIP={projectPIP && projectPIP}
                                appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                type={type}
                                PIPs={organicWasteManagementPIP && organicWasteManagementPIP}
                                subcategory={projectData?.subcategory && projectData?.subcategory}
                            />
                        );
                    }

                })
                : type &&
                type === 'common' &&
                skeleton &&
                skeleton.map((d, index) => {
                    return <Skeletons index={index} />;
                })}

            {/* renewabale */}
            <Grid item container justifyContent="left" direction="column" xs={12} style={{ paddingLeft: '2%', }}>
                <Typography variant='h5' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Renewable Energy
                </Typography>
            </Grid >

            {type && type === 'common' && renewableEnergyPIP && projectPIP?.length !== 0
                ? renewableEnergyPIP.map((field, index) => {
                    return (
                        <SimplePIPS
                            index={index}
                            unit={unit && unit}
                            field={field && field}
                            myArray={myArray && myArray}
                            projectPIP={projectPIP && projectPIP}
                            appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                            type={type}
                        />
                    )
                })
                : type &&
                type === 'common' &&
                skeleton &&
                skeleton.map((d, index) => {
                    return <Skeletons index={index} />;
                })
            }




            <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
                <Grid item xs={2}>
                    <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
                </Grid>
            </Grid>
        </>
    );
};
export default EditProjectsCommonPIP;
