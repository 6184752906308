import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { useLocation, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Default, NavigatorSource } from '../../../../../../utils/Defaults';
import { useEffect } from 'react';
import { usePrevious } from '../../../../../../hooks/useCategoryDetailsData';

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: '5%',
  },
  bottomstyle: MarketplaceTypography.BodySamiBold,
  buttonstyle: {
    color: MarketplaceColors.Secondary,
    border: `1px solid ${MarketplaceColors.Secondary}`,
    width: '120px',
    height: '30px',
    borderRadius: '20px',
    marginLeft: '5px',
  },
  buttontext: MarketplaceTypography.ButtonSmall,
}));

const FilterSidebarCustomButtons = ({ }) => {
  const { search } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(search);
  const [source, setSource] = useState(query.get('source'));
  const [category, setCategory] = useState(query.get('category'));
  const [supercategory, setSuperCategory] = useState(query.get('supercategory'));
  const [page, setpage] = useState(query.get('page'));
  const [pagesize, setpagesize] = useState(query.get('pagesize'));
  const previousLocation = usePrevious(location);
  const mappingID = query.get('mappingID')

  const { filtersData, setIsDefault, setFiltersData, setAppliedFilters } = useContext(ProductsPageContext);

  useEffect(() => {
    if (previousLocation !== location) {
      const source = query.get('source');
      setSource(source);
      setCategory(query.get('category'));
      setSuperCategory(query.get('supercategory'));
      setpage(query.get('page'));
      setpagesize(query.get('pagesize'));
    }
  }, [previousLocation, location]);

  const classes = useStyles();
  const handleCancel = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFiltersData([]);
    setAppliedFilters([]);
    if (source === NavigatorSource.categories)
      history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}`);
    if (source === NavigatorSource.supercategories)
      history.push(`/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${Default.page}&pagesize=${Default.pagesize}`);
    if (source === NavigatorSource.ecolabels) history.push(`/marketplace/categoryDetails?source=${source}&page=${Default.page}&pagesize=${Default.pagesize}`);
    if (source === NavigatorSource.intervention) history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}&sort=default&mappingID=${mappingID}`);
  };
  const handleAppliedfilters = () => {
    // console.log(filtersData, 'appliedfilters');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setAppliedFilters(filtersData);
    setIsDefault(true);
    if (filtersData.length === 0) {
      if (source === NavigatorSource.categories)
        history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}`);
      if (source === NavigatorSource.supercategories)
        history.push(`/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${Default.page}&pagesize=${Default.pagesize}`);
      if (source === NavigatorSource.ecolabels) history.push(`/marketplace/categoryDetails?source=${source}&page=${Default.page}&pagesize=${Default.pagesize}`);
      if (source === NavigatorSource.intervention) history.push(`/marketplace/categoryDetails?source=${source}&category=${category}&page=${Default.page}&pagesize=${Default.pagesize}&sort=default&mappingID=${mappingID}`);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-start" direction="row" item xs={12} className={classes.padding}>
      <Grid container alignItems="center" justifyContent="flex-start" item xs={4}>
        <Typography className={classes.bottomstyle} style={{ color: MarketplaceColors.Secondary, cursor: 'pointer' }} onClick={() => handleCancel()}>
          Cancel
        </Typography>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-start" item xs={8}>
        <Button className={classes.buttonstyle} onClick={handleAppliedfilters}>
          <Typography className={classes.buttontext} style={{ color: MarketplaceColors.Secondary }}>
            Apply Filters
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterSidebarCustomButtons;
