import React, { useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import marketingsvg1 from '../../../assets/bigSVGs/marketingsvg1.svg';
import marketingsvg2 from '../../../assets/bigSVGs/marketingsvg2.svg';
import marketingsvg3 from '../../../assets/bigSVGs/marketingsvg3.svg';
import marketingsvg4 from '../../../assets/bigSVGs/marketingsvg4.svg';
import marketingsvg5 from '../../../assets/bigSVGs/marketingsvg5.svg';// exceeds 500
import { PreviousPageContext } from '../../../routing/IndexRouting';

function MarketingDummy(props) {
  const { setPreviousPage } = useContext(PreviousPageContext);

  useEffect(() => {
    setPreviousPage('marketing');
  }, []);

  return (
    <Grid container xs={12} alignItems="center" justifyContent="center" style={{ paddingTop: '9%', paddingBottom: '5%' }}>
      <img src={marketingsvg1} alt="marketing" style={{ height: '80%', width: '94%' }} />
      <img src={marketingsvg2} alt="marketing" style={{ height: '80%', width: '95%', marginTop: '4%' }} />
      <img src={marketingsvg3} alt="marketing" style={{ height: '80%', width: '95%', marginTop: '4%' }} />
      <img src={marketingsvg4} alt="marketing" style={{ height: '80%', width: '95%', marginTop: '4%' }} />
      <img src={marketingsvg5} alt="marketing" style={{ height: '80%', width: '95%', marginTop: '4%' }} />
    </Grid>
  );
}

export default MarketingDummy;
