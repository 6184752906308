import { HWMVillaCustomAdvancesParameters, HWMVillaCustomBasicParameters, HWMVillaProjectInputs } from './hwmVilla';
import {
  EfficientFixturesHealthFaucet_CustomAdvancesParameters,
  EfficientFixturesHealthFaucet_CustomBasicParameters,
  EfficientFixturesHealthFaucet_ProjectInputs,
} from './efficientFixturesHealthFaucet';
import {
  EfficientFixturesFaucet_CustomAdvancesParameters,
  EfficientFixturesFaucet_CustomBasicParameters,
  EfficientFixturesFaucet_ProjectInputs,
} from './efficientFixturesFaucet';
import { HWMApartmentCustomAdvancesParameters, HWMApartmentCustomBasicParameters, HWMAprtmentProjectInputs } from './hwmApartment';
import {
  EfficientFixturesKitchenFaucet_CustomAdvancesParameters,
  EfficientFixturesKitchenFaucet_CustomBasicParameters,
  EfficientFixturesKitchenFaucet_ProjectInputs,
} from './efficientFixturesKitchenFaucet';
import {
  EfficientFixturesShowers_CustomAdvancesParameters,
  EfficientFixturesShowers_CustomBasicParameters,
  EfficientFixturesShowers_ProjectInputs,
} from './efficientFixturesShower';
import { TwrCustomAdvanceParameters, TwrCustomBasicParameters, TwrProjectInputParameters } from './TreatedWaterReuse';
import { NaturalSTPCustomAdvanceParameters, NaturalSTPCustomBasicParameters, NstpProjectInputParameters } from './NaturalSTP';
import {
  EfficientFixturesDualFlush_CustomAdvancesParameters,
  EfficientFixturesDualFlush_CustomBasicParameters,
  EfficientFixturesDualFlush_ProjectInputs,
} from './efficientFixturesDualFlush';
import { SwmCustomAdvanceParameters, SwmCustomBasicParameters, SwmProjectInputParameters } from './swm';

import { HWMPentHouseCustomAdvancesParameters, HWMPentHouseCustomBasicParameters, HWMPentHouseProjectInputs } from './hwmPentHouse';
import { HWMClubHouseCustomAdvancesParameters, HWMClubHouseCustomBasicParameters, HWMClubHouseProjectInputs } from './hwmClubHouse';
import { HWMSwimmingPoolCustomAdvancesParameters, HWMSwimmingPoolCustomBasicParameters, HWMSwimmingPoolProjectInputs } from './hwmSwimmingPool';

export const KongInterventionsComponents = {
  'Hot Water Management Apartment': {
    basic: HWMApartmentCustomBasicParameters,
    advanced: HWMApartmentCustomAdvancesParameters,
    project: HWMAprtmentProjectInputs,
    productIdentifier: 'hwmApartment.productidentifier',
  },
  'Hot Water Management Villa': {
    basic: HWMVillaCustomBasicParameters,
    advanced: HWMVillaCustomAdvancesParameters,
    project: HWMVillaProjectInputs,
    productIdentifier: '',
  },
  'Hot Water Management Clubhouse': {
    basic: HWMClubHouseCustomBasicParameters,
    advanced: HWMClubHouseCustomAdvancesParameters,
    project: HWMClubHouseProjectInputs,
    productIdentifier: 'hwmClubHouse.productidentifier',
  },
  'Hot Water Management Penthouse': {
    basic: HWMPentHouseCustomBasicParameters,
    advanced: HWMPentHouseCustomAdvancesParameters,
    project: HWMPentHouseProjectInputs,
    productIdentifier: 'hwmPentHouse.productidentifier',
  },

  'Efficient Fixtures - Health Faucet': {
    basic: EfficientFixturesHealthFaucet_CustomBasicParameters,
    advanced: EfficientFixturesHealthFaucet_CustomAdvancesParameters,
    project: EfficientFixturesHealthFaucet_ProjectInputs,
    productIdentifier: 'efhealthfaucet.productidentifier',
  },
  'Efficient Fixtures - Faucet': {
    basic: EfficientFixturesFaucet_CustomBasicParameters,
    advanced: EfficientFixturesFaucet_CustomAdvancesParameters,
    project: EfficientFixturesFaucet_ProjectInputs,
    productIdentifier: 'efhealthfaucet.productidentifier',
  },
  'Efficient Fixtures - Kitchen Faucet': {
    basic: EfficientFixturesKitchenFaucet_CustomBasicParameters,
    advanced: EfficientFixturesKitchenFaucet_CustomAdvancesParameters,
    project: EfficientFixturesKitchenFaucet_ProjectInputs,
    productIdentifier: 'efhealthfaucet.productidentifier',
  },
  'Efficient Fixtures - Showers': {
    basic: EfficientFixturesShowers_CustomBasicParameters,
    advanced: EfficientFixturesShowers_CustomAdvancesParameters,
    project: EfficientFixturesShowers_ProjectInputs,
    productIdentifier: 'efhealthfaucet.productidentifier',
  },
  'Efficient Fixtures - Dual Flush': {
    basic: EfficientFixturesDualFlush_CustomBasicParameters,
    advanced: EfficientFixturesDualFlush_CustomAdvancesParameters,
    project: EfficientFixturesDualFlush_ProjectInputs,
    productIdentifier: 'efhealthfaucet.productidentifier',
  },
  'Hot Water Management Swimming Pool': {
    basic: HWMSwimmingPoolCustomBasicParameters,
    advanced: HWMSwimmingPoolCustomAdvancesParameters,
    project: HWMSwimmingPoolProjectInputs,
    productIdentifier: 'hwmSwimmingPool.productIdentifier',
  },
  'Treated Water Reuse': {
    basic: TwrCustomBasicParameters,
    advanced: TwrCustomAdvanceParameters,
    project: TwrProjectInputParameters,
    productIdentifier: 'treatedwaterreuse.productidentifier',
  },
  'Natural STP': {
    basic: NaturalSTPCustomBasicParameters,
    advanced: NaturalSTPCustomAdvanceParameters,
    project: NstpProjectInputParameters,
    productIdentifier: 'naturalstp.productidentifier',
  },
  'Smart Water Meters': {
    basic: SwmCustomBasicParameters,
    advanced: SwmCustomAdvanceParameters,
    project: SwmProjectInputParameters,
    productIdentifier: 'swm.productidentifier',
  },
};

//for capacity
export const handleApartmentCapacity = (
  hwmOption,
  optionFromIntDetails,
  swhType,
  swhTypeIntDetails,
  swhVariant,
  swhVariantIntDetails,
  hpType,
  hpTypeIntDetails,
  hpHeatingSpeed,
  hpHeatingSpeedIntDetails,
  hpCapacity,
  swhCapacity,
  ID_MAP_HWM,
  HWM_OPTIONS_MAP,
  hybridHeatingSpeed,
  hybridSwhVariant,
  hybridHeatingSpeedIntDetails,
  hybridSwhVariantIntDetails,
  appendChangedValue
) => {
  if (
    hwmOption !== optionFromIntDetails ||
    swhType !== swhTypeIntDetails ||
    swhVariant !== swhVariantIntDetails ||
    hpType !== hpTypeIntDetails ||
    hpHeatingSpeed !== hpHeatingSpeedIntDetails
  ) {
    if (hwmOption === HWM_OPTIONS_MAP.solarWaterHeater) {
      appendChangedValue(ID_MAP_HWM[0].swhCapacity, null);
    } else if (hwmOption === HWM_OPTIONS_MAP.heatpump) {
      appendChangedValue(ID_MAP_HWM[0].hpCapacity, null);
    } else if (hwmOption === HWM_OPTIONS_MAP.hybrid) {
      appendChangedValue(ID_MAP_HWM[0].hybridSwhCapacity, null);
      appendChangedValue(ID_MAP_HWM[0].hybridHPCapacity, null);
    }
  }
};

// solar water heater Apartment
export const handleSolarWaterHeaterApt = (swhType, swhVariant, tag, appendChangedValue) => {
  if (swhType === HWM_SWH_TYPE_MAP.central) appendChangedValue(tag, solarWaterHeaterMappingApt.central);
  else if (swhType === HWM_SWH_TYPE_MAP.individual && solarWaterHeaterMappingApt.individual[swhVariant])
    appendChangedValue(tag, solarWaterHeaterMappingApt.individual[swhVariant]);
};

// solar water heater clubhouse
export const handleSolarWaterHeaterClb = (swhType, swhVariant, tag, appendChangedValue) => {
  if (swhType === HWM_SWH_TYPE_MAP.central) appendChangedValue(tag, solarWaterHeaterMappingClb.central);
  else if (swhType === HWM_SWH_TYPE_MAP.individual && solarWaterHeaterMappingClb.individual[swhVariant])
    appendChangedValue(tag, solarWaterHeaterMappingClb.individual[swhVariant]);
};

// solar water heater penthouse
export const handleSolarWaterHeaterPen = (swhType, swhVariant, tag, appendChangedValue) => {
  if (swhType === HWM_SWH_TYPE_MAP.central) appendChangedValue(tag, solarWaterHeaterMappingPen.central);
  else if (swhType === HWM_SWH_TYPE_MAP.individual && solarWaterHeaterMappingPen.individual[swhVariant])
    appendChangedValue(tag, solarWaterHeaterMappingPen.individual[swhVariant]);
};

// Heat pump Apartment
export const handleApartmentHeatPump = (hpType, tag, appendChangedValue) => {
  if (heatPumpMappingApartment[hpType]) appendChangedValue(tag, heatPumpMappingApartment[hpType]);
};

// Heat pump clubHouse
export const handleClubhouseHeatPump = (hpType, tag, appendChangedValue) => {
  if (heatPumpMappingClubhouse[hpType]) appendChangedValue(tag, heatPumpMappingClubhouse[hpType]);
};

// Heat pump pentHouse
export const handlePenthouseHeatPump = (hpType, tag, appendChangedValue) => {
  if (heatPumpMappingPenthouse[hpType]) appendChangedValue(tag, heatPumpMappingPenthouse[hpType]);
};

export const INTERVENTIONS_MAP = {
  HWM_APARTMENT: 'Hot Water Management Apartment',
  HWM_ClubHouse: 'Hot Water Management Clubhouse',
  HWM_Penthouse: 'Hot Water Management Penthouse',
};

export const HWM_APT_IDENTIFIER_TAG = 'hwmApartment.productidentifier';
export const HWM_CLB_IDENTIFIER_TAG = 'hwmClubHouse.productidentifier';
export const HWM_PEN_IDENTIFIER_TAG = 'hwmPentHouse.productidentifier';

export const heatPumpMappingApartment = {
  commercial: 'hwmApartment.hpcommercial',
  domestic: 'hwmApartment.hpDomestic',
};

export const heatPumpMappingClubhouse = {
  commercial: 'hwmClubHouse.hpcommercial',
  domestic: 'hwmClubHouse.hpDomestic',
};

export const heatPumpMappingPenthouse = {
  commercial: 'hwmPentHouse.hpcommercial',
  domestic: 'hwmPentHouse.hpDomestic',
};

export const solarWaterHeaterMappingApt = {
  central: 'hwmApartment.swhcentral',
  individual: {
    etc: 'hwmApartment.swhIndividualEtc',
    fpc: 'hwmApartment.swhindividualFpc',
  },
};

export const solarWaterHeaterMappingClb = {
  central: 'hwmApartment.swhcentral',
  individual: {
    etc: 'hwmApartment.swhIndividualEtc',
    fpc: 'hwmApartment.swhindividualFpc',
  },
};

export const solarWaterHeaterMappingPen = {
  central: 'hwmApartment.swhcentral',
  individual: {
    etc: 'hwmApartment.swhIndividualEtc',
    fpc: 'hwmApartment.swhindividualFpc',
  },
};

export const ID_MAP_HWM = [
  {
    hwmOption: 'hwmApartment.options',
    swhType: 'hwmApartment.swhType',
    swhVariant: 'hwmApartment.swhVariant',
    hpType: 'hwmApartment.HpType',
    swhCentral: 'hwmApartment.swhcentral',
    swhIndividualEtc: 'hwmApartment.swhIndividualEtc',
    swhIndividualFpc: 'hwmApartment.swhindividualFpc',
    hpCommercial: 'hwmApartment.hpcommercial',
    hpDomestic: 'hwmApartment.hpDomestic',
    hybrid: 'hwmApartment.hybrid',
    swhCapacity: 'hwmApartment.swhCapacity',
    hpCapacity: 'hwmApartment.userHpCapacity',
    heatingSpeed: 'hwmApartment.heatingSpeed',
    hybridHeatingSpeed: 'hwmApartment.hybridheatingSpeed',
    hybridSwhVariant: 'hwmApartment.hybridswhVariant',
    hybridSwhCapacity: 'hwmApartment.hybridswhCapacity',
    hybridHPCapacity: 'hwmApartment.hybridhpCapacity',
  },
  {
    hwmOption: 'hwmClubHouse.options',
    swhType: 'hwmClubHouse.swhType',
    swhVariant: 'hwmClubHouse.swhVariant',
    hpType: 'hwmClubHouse.HpType',
    swhCentral: 'hwmClubHouse.swhcentral',
    swhIndividualEtc: 'hwmClubHouse.swhIndividualEtc',
    swhIndividualFpc: 'hwmClubHouse.swhindividualFpc',
    hpCommercial: 'hwmClubHouse.hpcommercial',
    hpDomestic: 'hwmClubHouse.hpDomestic',
  },
  {
    hwmOption: 'hwmClubHouse.options',
    swhType: 'hwmClubHouse.swhType',
    swhVariant: 'hwmClubHouse.swhVariant',
    hpType: 'hwmClubHouse.HpType',
    swhCentral: 'hwmClubHouse.swhcentral',
    swhIndividualEtc: 'hwmClubHouse.swhIndividualEtc',
    swhIndividualFpc: 'hwmClubHouse.swhindividualFpc',
    hpCommercial: 'hwmClubHouse.hpcommercial',
    hpDomestic: 'hwmClubHouse.hpDomestic',
    swhCapacity: 'hwmClubHouse.swhCapacity',
    hpCapacity: 'hwmClubHouse.userHpCapacity',
    heatingSpeed: 'hwmClubHouse.heatingSpeed',
  },
];

export const HWM_OPTIONS_MAP = {
  solarWaterHeater: 'solar water heater',
  heatpump: 'heat pump',
  hybrid: 'hybrid',
};

export const HWM_SWH_TYPE_MAP = {
  central: 'central',
  individual: 'individual',
};
