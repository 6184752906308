import React, { useEffect, useState } from 'react'
import { Grid, Typography, LinearProgress, withStyles, makeStyles, Paper } from '@material-ui/core';
import { VariantCommon, VariantFour, VariantFourDisabled, VariantOne, VariantSeven, VariantSix, dummyGoalsResponseForGoalsInsights } from './Goals';
import wasteIcon from '../../../assets/GOALS/goalcategoryimages/WasteGoalIcon.svg'
import PrimaryButton from '../../common/button/Button';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import waterIcon from '../../../assets/Fill 688.svg';
import powerIcon from '../../../assets/Fill 1452.svg';
import wasteicon from '../../../assets/interventions/Group 1365.svg';

import powerFilled from '../../../assets/GOALS/goalcategoryimages/energyFilledGoalIcon.svg';
import waterFilled from '../../../assets/GOALS/goalcategoryimages/waterFIlledGoalIcon.svg';
import wasteFilled from '../../../assets/GOALS/goalcategoryimages/wasteFilledGoalIcon.svg';
import materialNonFilled from '../../../assets/GOALS/goalcategoryimages/materialNonFilled.svg';
import { useParams } from 'react-router';
import useApiRequest from '../../hooks/useApiRequest';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';




const GoalsInsights = () => {
    const history = useHistory()
    let { id } = useParams();
    const [goalsDataResponse, setGoalsDataResponse] = useState('')

    const [{ status, response }, makeRequest] = useApiRequest(`/projectv2/${id}`, {
        verb: 'get',
    });
    if (status === null) makeRequest();

    useEffect(() => {
        if (response?.data) {
            setGoalsDataResponse(response?.data?.data?.data?.goals)
        } else {
            setGoalsDataResponse(dummyGoalsResponseForGoalsInsights?.data?.data?.goals)
        }

    }, [response])




    // console.log(goalsDataResponse, "goalsResponse")
    const costPerSqftEnergy = goalsDataResponse?.categories?.energy?.goal?.costPerSqFt !== null ? goalsDataResponse?.categories?.energy?.goal?.costPerSqFt?.toFixed(2) : 0
    const costPerSqftWater = goalsDataResponse?.categories?.water?.goal?.costPerSqFt !== null ? goalsDataResponse?.categories?.water?.goal?.costPerSqFt.toFixed(2) : 0
    const costPerSqftWaste = goalsDataResponse?.categories?.waste?.goal?.costPerSqFt !== null ? goalsDataResponse?.categories?.waste?.goal?.costPerSqFt?.toFixed(2) : 0
    const costPerSqftMaterials = goalsDataResponse?.categories?.embodiedCarbon?.goal?.costPerSqFt !== null ? goalsDataResponse?.categories?.embodiedCarbon?.goal?.costPerSqFt?.toFixed(2) : 0


    return (

        <Grid style={{ paddingLeft: '4%', paddingRight: '4%', paddingTop: '9%' }} container justifyContent='center' alignItems='center' xs={12}>
            <Grid xs={12} style={{ paddingBottom: '3%', marginLeft: '-3%' }}>
                <CommonHeadingWithIcon heading="Goals" variant='h3' handleClick={() => history.goback()} />
            </Grid>
            {/* DONE */}
            <Grid item xs={12} container justifyContent='flex-start' alignItems='flex-start'>
                <Grid container xs={3}>
                    {/* <VariantOne border='2px solid #F28D14' background='#FFF4D5' icon={powerFilled} title={goalsDataResponse?.categories?.energy?.goal?.name} text={`+${costPerSqftEnergy} Rs/Sqft`} progressValue={goalsDataResponse?.categories?.energy?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.energy?.goal?.progress} barBackground='#F28D14' barText={goalsDataResponse?.categories?.energy?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.energy?.goal?.progress} /> */}
                    <VariantOne border='2px solid #F28D14' background='#FFF4D5' icon={powerFilled} title={goalsDataResponse?.categories?.energy?.goal?.name} text={`+29 Rs/Sqft`} progressValue={14} barBackground='#F28D14' barText={goalsDataResponse?.categories?.energy?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.energy?.goal?.progress} />
                </Grid>
                <Grid container xs={3}>
                    {/* <VariantOne
                        border='2px solid #3BC7EE'
                        background='#C9F5FF'
                        icon={waterFilled}
                        title={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.name}
                        text={costPerSqftWater ? `+${costPerSqftWater} Rs/Sqft` : `+${dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.costPerSqFt} Rs/Sqft`}
                        progressValue={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress > 100 ? 100 : dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress}
                        barBackground='#3BC7EE'
                        barText={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress > 100 ? 100 : dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress} /> */}
                    <VariantOne
                        border='2px solid #3BC7EE'
                        background='#C9F5FF'
                        icon={waterFilled}
                        title={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.name}
                        text={`+21 Rs/Sqft`}
                        progressValue={67}
                        barBackground='#3BC7EE'
                        barText={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress > 100 ? 100 : dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.water?.goal?.progress} />
                </Grid>
                <Grid container xs={3}>
                    {/* <VariantOne border='2px solid #BC8A5F'
                        background='#EEE1D7'
                        icon={wasteFilled}
                        title={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.name} text={`+${costPerSqftWaste} Rs/Sqft`}
                        progressValue={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.progress > 100 ? 100 : dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.progress} barBackground='#BC8A5F'
                        barText={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.progress > 100 ? 100 : dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.progress} /> */}
                    <VariantOne border='2px solid #BC8A5F'
                        background='#EEE1D7'
                        icon={wasteFilled}
                        title={dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.goal?.name} text={`+0 Rs/Sqft`}
                        progressValue={87} barBackground='#BC8A5F'
                        barText={87} />

                </Grid>
                <Grid container xs={3}>
                    {/* <VariantOne border='2px solid #916AAC' background='#EDD5FA' icon={wasteIcon} title={goalsDataResponse?.categories?.embodiedCarbon?.goal?.name} text={`+${costPerSqftMaterials} Rs/Sqft`} progressValue={goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress} barBackground='#916AAC' barText={goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress > 100 ? 100 : goalsDataResponse?.categories?.embodiedCarbon?.goal?.progress} /> */}
                    <VariantOne border='2px solid #916AAC' background='#EDD5FA' icon={wasteIcon} title={goalsDataResponse?.categories?.embodiedCarbon?.goal?.name} text={`+16 Rs/Sqft`} progressValue={100} barBackground='#916AAC' barText={100} />
                </Grid>
            </Grid>
            <Grid xs={12} style={{ marginTop: '3%' }}>
                <Typography variant='h5'>
                    <b>Achievable Targets</b>
                </Typography>
            </Grid>
            <Grid item container justifyContent='center' alignItems='center' xs={12} style={{ marginBottom: '3%', paddingRight: '10%' }}>
                <Typography style={{ textAlign: 'justify', lineHeight: 2 }} variant='h6'>
                    Here are targets categorized to align with your Net Zero goals. You can toggle each target based on preference and project needs. Turning off a target disables associated interventions unless shared by other active targets. Targets are classified into Tiers I to IV, indicating impact levels on Net Zero goals up to 25%, 50%, 75%, and 100% respectively.
                </Typography>
            </Grid>
            <Grid container style={{ paddingRight: '3%' }}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} style={{ marginBottom: '3%', border: '2px solid #C6F2CD', paddingLeft: '2%', paddingRight: '2%', paddingBottom: '2%', borderRadius: '16px', }}>
                    <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h5'><b>Scoreboard</b></Typography></Grid>
                    <Grid item container justifyContent='flex-start' alignItems='center' xs={3} direction='column' style={{ marginRight: '4%' }}>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h6' color='textSecondary' style={{ marginBottom: '3%' }}>Goals Achieved</Typography></Grid>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '3%' }}><Typography variant='h6'><b>{goalsDataResponse?.categories?.scorecard?.goals?.achieved}/{goalsDataResponse?.categories?.scorecard?.goals?.targeted} Goals</b></Typography></Grid>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '5%' }}>
                            <VariantCommon variant="determinate" progressValue={(goalsDataResponse?.categories?.scorecard?.goals?.achieved / goalsDataResponse?.categories?.scorecard?.goals?.targeted) * 100} style={{ flexGrow: '1', }} background='#E5E5E5' barBackground='linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)' />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='flex-start' alignItems='center' xs={3} direction='column' style={{ marginRight: '4%' }}>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h6' color='textSecondary' style={{ marginBottom: '3%' }}>Targets Achieved</Typography></Grid>
                        {/* <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '3%' }}><Typography variant='h6'><b>{goalsDataResponse?.categories?.scorecard?.targets?.achieved}/{goalsDataResponse?.categories?.scorecard?.targets?.targeted} Targets</b></Typography></Grid> */}
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '3%' }}><Typography variant='h6'><b>{3}/{4} Targets</b></Typography></Grid>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '5%' }}>
                            {/* <VariantCommon variant="determinate" progressValue={(goalsDataResponse?.categories?.scorecard?.targets?.achieved / goalsDataResponse?.categories?.scorecard?.targets?.targeted) * 100} style={{ flexGrow: '1', }} background='#E5E5E5' barBackground='linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)' /> */}
                            <VariantCommon variant="determinate" progressValue={75} style={{ flexGrow: '1', }} background='#E5E5E5' barBackground='linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)' />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='flex-start' alignItems='center' xs={3} direction='column'>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h6' color='textSecondary' style={{ height: 29 }}>Capex Increase</Typography></Grid>
                        {/* <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h6' style={{ marginTop: '1%', height: 32, }}><b>{goalsDataResponse?.categories?.scorecard?.capexIncrease?.toFixed(2)} Rs/Sqft</b></Typography></Grid> */}
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center'><Typography variant='h6' style={{ marginTop: '1%', height: 32, }}><b>{66} Rs/Sqft</b></Typography></Grid>
                        <Grid item xs={12} container justifyContent='flex-start' alignItems='center' style={{ marginTop: '5%' }}></Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container justifyContent='flex-start' alignItems='flex-start' xs={12} style={{ marginBottom: '5%' }}>
                <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                        <Grid xs={3} container alignItems='center' justifyContent='center' ><img src={powerIcon} alt='waste icon' /></Grid>
                        <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Energy Targets</Typography></Grid>
                    </Grid>
                    <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                        {goalsDataResponse?.categories?.energy?.targets?.map((target) => {
                            if (target?.achievability === 'No') {
                                return (
                                    <VariantSeven border='2px solid #CCCCCC' title={target?.name} />
                                )
                            } else if (target?.achievability === 'Yes' && target?.state === false) {
                                return (
                                    <VariantFourDisabled border='2px solid #F6AA4F' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#FFCE93' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            } else {
                                return (
                                    <VariantFour border='2px solid #F6AA4F' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#FFCE93' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            }

                        })}
                    </Grid>
                </Grid>
                <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                        <Grid xs={3} container alignItems='center' justifyContent='center'><img src={waterIcon} alt='waste icon' /></Grid>
                        <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Water Targets</Typography></Grid>
                    </Grid>
                    <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                        {goalsDataResponse?.categories?.water?.targets?.map((target) => {
                            if (target?.achievability === 'No') {
                                return (
                                    <VariantSeven border='2px solid #CCCCCC' title={target?.name} />
                                )
                            } else if (target?.achievability === 'Yes' && target?.state === false) {
                                return (
                                    <VariantFourDisabled border='2px solid #3BC7EE' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#74DEFB' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            } else {
                                return (
                                    // <VariantFour border='2px solid #3BC7EE' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#74DEFB' barText={target?.progress > 100 ? 100 : target?.progress} />
                                    <VariantFour border='2px solid #3BC7EE' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#74DEFB' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            }
                        })}
                    </Grid>


                </Grid>
                <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                        <Grid xs={3} container alignItems='center' justifyContent='center'><img src={wasteicon} alt='waste icon' /></Grid>
                        <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Waste Targets</Typography></Grid>
                    </Grid>
                    {/* for waste its dummy value right now */}
                    <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                        {dummyGoalsResponseForGoalsInsights?.data?.data?.goals?.categories?.waste?.targets?.map((target) => {
                            if (target?.achievability === 'No') {
                                return (
                                    <VariantSeven border='2px solid #CCCCCC' title={target?.name} />
                                )
                            } else if (target?.achievability === 'Yes' && target?.state === false) {
                                return (
                                    <VariantFourDisabled border='2px solid #BC8A5F' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#F0D4BF' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            } else {
                                return (
                                    <VariantFour border='2px solid #BC8A5F' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#F0D4BF' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            }
                        })}
                        {/* {dummyGoalsResponseForGoalsCreate?.data?.data?.goals?.categories?.waste?.targets?.map((target) => {
                                (
                                    <VariantFour border='2px solid #BC8A5F' title={target?.name} tier={target?.tier} text='' progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#F0D4BF' barText={target?.progress > 100 ? 100 : target?.progress} />
                                )
                            })} */}
                    </Grid>
                </Grid>
                <Grid item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item container alignItems='center' justifyContent='center' style={{ marginBottom: '3%' }}>
                        <Grid xs={3} container alignItems='center' justifyContent='center'><img src={materialNonFilled} alt='waste icon' style={{ height: 30 }} /></Grid>
                        <Grid xs={9} container alignItems='center' justifyContent='flex-start' ><Typography variant='h5'>Material Targets</Typography></Grid>
                    </Grid>
                    <Grid xs={12} container justifyContent='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                        {goalsDataResponse?.categories?.embodiedCarbon?.targets?.map((target) => {
                            return (
                                // <VariantFour border='2px solid #916AAC' title={target?.name} tier={target?.tier} text={`+${target?.additionalCostPerUnitArea} Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#EDCDFF' barText={target?.progress > 100 ? 100 : target?.progress} />
                                <VariantFour border='2px solid #916AAC' title={target?.name} tier={target?.tier} text={`+0 Rs/Sqft`} progressValue={target?.progress > 100 ? 100 : target?.progress} barBackground='#EDCDFF' barText={target?.progress > 100 ? 100 : target?.progress} />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" style={{ marginBottom: '5%' }}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                    <PrimaryButton children="Save and Exit" style={{ width: '150px', borderRadius: '40px', height: '30px' }} />
                </Grid>
            </Grid>
        </Grid >
    )
}

export default GoalsInsights