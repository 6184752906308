import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBase } from '@material-ui/core';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';

export default function ResetFields({ onClick, value, onChange, disabled, disableTextField, removeResetIcon }, props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 20,
      border: 'rgba(0, 0, 0, 0.38)',
      color: 'black',
      height: 43,
      padding: '13px 10px',
      '&.Mui-focused': {
        boxShadow: `${alpha('rgba(0, 0, 0, 0.38', 0.25)} 0 0 0 0.1rem`,
        borderColor: 'rgba(0, 0, 0, 0.38)',
      },
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.38);',
      },
    },
  }));
  const classes = useStyles();
  return (
    <InputBase
      className={classes.root}
      id="outlined-adornment-weight"
      endAdornment={
        !removeResetIcon && (
          <InputAdornment position="end">
            <ReplaySharpIcon style={{ fontSize: '20px', color: disabled ? '#9E9E9E' : '#2E2E2E', cursor: 'pointer' }} onClick={onClick} />
          </InputAdornment>
        )}
      value={value}
      onChange={onChange}
      aria-describedby="outlined-weight-helper-text"
      labelWidth={0}
      style={{
        border: disabled ? '1px solid #9E9E9E' : '1px solid #2E2E2E',

      }} inputProps={{
        style: { color: disabled ? '#9E9E9E' : '#2E2E2E' },
      }}
      {...props}
      disabled={disableTextField && disableTextField}
    />
  );
}
