import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button, TextField, Checkbox } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import axios from '../../../../../config/Axios';
import { InterventionDetailsContext } from '../interventionDetails';
import EditInterventionDetails from './editInterventionDetails';
import ShowConstants from './showConstants';
import ShowProjectAndConstAssumptionData from './showProjectAndCostAssumptionData';
import ShowCapexAndDetails from './showCapexAndDetails';
import ShowInterventionCostAssumptionsParameters from './showInterventionCostAssumptionParameters';
import ShowSaveAndCancel from './showSaveAndCancel';
import { NotetakingContext } from '../../../../../projectRouting/Index';
import listicon from '../../../../../assets/interventions/listicon.svg'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { CheckBox, CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
        fontSize: "25px"
    },
    nonselectedTextDisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
        fontSize: "25px"
    },
}));
const getValueFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.defaultValue;
};

const getValue = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.value;
};
const getIdFromLabel = (interventionData, label) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i.label === label
    })[0].id;

};
const getBooleanFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i.id === id
    })[0]?.value || '';
};
export const TwrCustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    const [stpCapacity, setStpCapacity] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.stpCapacity.actual'));
    const [roCapacity, setRoCapacity] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.roCapacity.actual'));
    const [waterDischargeToSewageLines, setWaterDischargeToSewageLines] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.dischargePercentage'));
    const [flushing, setFlushing] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.flushingEnabled') === 'YES' ? true : false);
    const [landscape, setLandscape] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.landscapingEnabled') === 'YES' ? true : false);
    const [carWash, setCarWash] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.carWashEnabled') === 'YES' ? true : false);
    const [commonAreaCleaning, setCommonAreaCleaning] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.commonAreaCleaningEnabled') === 'YES' ? true : false);
    const [pool, setPool] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.poolEnabled') === 'YES' ? true : false);
    const [domestic, setDomestic] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.domesticEnabled') === 'YES' ? true : false);
    const [offsiteReuse, setOffsiteReuse] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.offsiteReuseEnabled') === 'YES' ? true : false)
    const [reuseTreatedWater, setReuseTreatedWater] = useState(getBooleanFromId(selectedInterventionDetails, 'treatedwaterreuse.reuseTreatedWater'));
    const appendChanged = (id, value) => {
        console.log('appendChanged', id, value);
        console.log(id, value);
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    return (
        <Grid item xs={12} container alignItems="flex-start" direction="row">
            <div style={{ display: 'flex' }}>
                <>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textheader2}>
                            STP Capacity
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={stpCapacity}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setStpCapacity(e.target.value);
                                    appendChanged('treatedwaterreuse.stpCapacity.user', Number(e.target.value));

                                }}
                                onClick={(e) => {
                                    setStpCapacity(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.stpCapacity.default'))
                                    appendChanged('treatedwaterreuse.stpCapacity.user', undefined);

                                }}
                                disabled={false}
                            />
                            <Typography style={{ marginLeft: '5%' }}>KLD</Typography>
                        </div>
                    </Grid>

                </>
                <>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textheader2}>
                            RO Capacity
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={roCapacity}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRoCapacity(e.target.value);
                                    appendChanged('treatedwaterreuse.roCapacity.user', Number(e.target.value));
                                }}
                                onClick={(e) => {
                                    setRoCapacity(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.roCapacity.default'))
                                    appendChanged('treatedwaterreuse.roCapacity.user', undefined);
                                }}
                                disabled={false}
                            />
                            <Typography style={{ marginLeft: '5%' }}>KLD</Typography>
                        </div>
                    </Grid>
                </>
                <>
                    <Grid xs={12} container alignItems="flex-start" direction="column" >
                        <Typography className={classes.textheader2}>
                            Water Discharge to Sewage Lines
                        </Typography>
                        <Typography style={{ marginTop: '4%', marginLeft: '35%' }}>{waterDischargeToSewageLines}%</Typography>
                    </Grid>
                </>
            </div>
            <div style={{ display: 'flex' }}>
                <Grid xs={10}>
                    <Typography className={classes.textheader2}>
                        Non Potable use
                    </Typography>
                    <div style={{ display: 'flex', marginTop: '2%' }}>
                        {!flushing && <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.flushingEnabled', flushing === true ? 'NO' : 'YES'); setFlushing(!flushing) }} />}
                        {flushing && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.flushingEnabled', flushing === true ? 'NO' : 'YES'); setFlushing(!flushing) }} />}
                        <Typography>Flushing</Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {!landscape && <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.landscapingEnabled', landscape === true ? 'NO' : 'YES'); setLandscape(!landscape) }} />}
                        {landscape && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.landscapingEnabled', landscape === true ? 'NO' : 'YES'); setLandscape(!landscape); }} />}
                        <Typography>Landscape</Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {!carWash && <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.carWashEnabled', carWash === true ? 'NO' : 'YES'); setCarWash(!carWash) }} />}
                        {carWash && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.carWashEnabled', carWash === true ? 'NO' : 'YES'); setCarWash(!carWash) }} />}
                        <Typography>Car Wash</Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {!commonAreaCleaning && <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.commonAreaCleaningEnabled', commonAreaCleaning === true ? 'NO' : 'YES'); setCommonAreaCleaning(!commonAreaCleaning); }} />}
                        {commonAreaCleaning && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.commonAreaCleaningEnabled', commonAreaCleaning === true ? 'NO' : 'YES'); setCommonAreaCleaning(!commonAreaCleaning) }} />}
                        <Typography>Common Area Cleaning</Typography>
                    </div>
                </Grid>
                <Grid xs={12} xm={12} xl={12} style={{ marginLeft: '15%' }}>
                    <Typography className={classes.textheader2}>
                        Reuse Treated Water
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                            {(reuseTreatedWater === '' || reuseTreatedWater === 'offsite-reuse') && <RadioButtonUncheckedIcon className={classes.selectedCheckbox} onClick={() => {
                                appendChanged('treatedwaterreuse.reuseTreatedWater', 'potable-use');
                                setReuseTreatedWater('potable-use');
                                appendChanged('treatedwaterreuse.offsiteReuseEnabled', 'NO')
                                setOffsiteReuse(false);
                            }} />}
                            {reuseTreatedWater === 'potable-use' && <CheckCircleIcon className={classes.selectedCheckbox}
                                onClick={() => {
                                    appendChanged('treatedwaterreuse.reuseTreatedWater', '');
                                    setReuseTreatedWater('');
                                    setPool(false);
                                    setDomestic(false);
                                    appendChanged('treatedwaterreuse.domesticEnabled', 'NO');
                                    appendChanged('treatedwaterreuse.poolEnabled', 'NO');
                                }} />}
                            <Typography>Potable Use (requires RO filteration)</Typography>
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <div style={{ display: 'flex' }}>
                                {!pool && <CheckBoxOutlineBlankOutlined className={reuseTreatedWater === 'potable-use' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled} onClick={() => {
                                    if (reuseTreatedWater === 'potable-use' && reuseTreatedWater !== '') {
                                        appendChanged('treatedwaterreuse.poolEnabled', pool === true ? 'NO' : 'YES');
                                        setPool(!pool);
                                    }
                                }} />}
                                {pool && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.poolEnabled', pool === true ? 'NO' : 'YES'); setPool(!pool) }} />}
                                <Typography style={{ color: reuseTreatedWater === 'potable-use' ? '' : '#9E9E9E' }}>Pool</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                {!domestic && <CheckBoxOutlineBlankOutlined className={reuseTreatedWater === 'potable-use' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled} onClick={() => {
                                    if (reuseTreatedWater === 'potable-use' && reuseTreatedWater !== '') {
                                        appendChanged('treatedwaterreuse.domesticEnabled', domestic === true ? 'NO' : 'YES');
                                        setDomestic(!domestic);
                                    }
                                }} />}
                                {domestic && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.domesticEnabled', domestic === true ? 'NO' : 'YES'); setDomestic(!domestic) }} />}
                                <Typography style={{ color: reuseTreatedWater === 'potable-use' ? '' : '#9E9E9E' }}>Domestic Consumption</Typography>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                            {(reuseTreatedWater === '' || reuseTreatedWater === 'potable-use') && <RadioButtonUncheckedIcon className={classes.selectedCheckbox} onClick={() => {
                                setPool(false);
                                setDomestic(false);
                                appendChanged('treatedwaterreuse.domesticEnabled', 'NO');
                                appendChanged('treatedwaterreuse.poolEnabled', 'NO');
                                appendChanged('treatedwaterreuse.reuseTreatedWater', 'offsite-reuse');
                                setReuseTreatedWater('offsite-reuse');
                            }} />}
                            {reuseTreatedWater === 'offsite-reuse' && <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {

                                appendChanged('treatedwaterreuse.reuseTreatedWater', '');
                                setReuseTreatedWater('');
                                setOffsiteReuse(false);
                                appendChanged('treatedwaterreuse.offsiteReuseEnabled', 'NO')
                            }} />}
                            <Typography>Offsite Reuse</Typography>
                        </div>
                        <div style={{ marginLeft: '10%' }}>
                            <div style={{ display: 'flex' }}>
                                {!offsiteReuse && <CheckBoxOutlineBlankOutlined className={reuseTreatedWater === 'offsite-reuse' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled} onClick={() => {
                                    if (reuseTreatedWater === 'offsite-reuse' && reuseTreatedWater !== '') {
                                        appendChanged('treatedwaterreuse.offsiteReuseEnabled', offsiteReuse === true ? 'NO' : 'YES');
                                        setOffsiteReuse(!offsiteReuse);
                                    }
                                }} />}
                                {offsiteReuse && <CheckBox className={classes.nonselectedCheckbox} onClick={() => { appendChanged('treatedwaterreuse.offsiteReuseEnabled', offsiteReuse === true ? 'NO' : 'YES'); setOffsiteReuse(!offsiteReuse) }} />}
                                <Typography style={{ color: reuseTreatedWater === 'offsite-reuse' ? '' : '#9E9E9E' }}>Construction / Commercial / Industrial</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </div>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                />
            </Grid>
        </Grid>
    );

}
export const TwrCustomAdvanceParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const appendChanged = (id, value) => {
        console.log(id, value);
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };

    const [customPrice, setCustomPrice] = useState(getValue(selectedInterventionDetails, 'treatedwaterreuse.customCapex'));
    const [capexMultiplierSource, setCapexMultiplierSource] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.capexMultiplierSource'))
    const [userTankerPrice, setUserTankerPrice] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.waterTankerPrice.actual'))
    const [stpEfficiency, setStpEfficiency] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.stpEfficiency.actual'))
    const [treatedWaterPrice, setTreatedWaterPrice] = useState(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.offsiteReusePrice.actual'))
    const classes = useStyles();
    return (
        <>
            <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid xs={4}>
                    <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Intervention Cost Assumptions</Typography>
                        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
                    </Grid>
                    <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                        {'Tertiary Treatment Capex (per KL)'}
                    </Typography>
                </Grid>
                <Grid xs={8} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                            {(capexMultiplierSource === 'CUSTOM' || capexMultiplierSource === '') && <RadioButtonUncheckedIcon className={classes.selectedCheckbox} onClick={() => {
                                appendChanged('treatedwaterreuse.capexMultiplierSource', 'VENDOR');
                                setCapexMultiplierSource('VENDOR');
                            }} />}
                            {(capexMultiplierSource === 'VENDOR') && <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                setCapexMultiplierSource('');
                            }} />}
                            <Typography>Selected Vendor</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
                            <Typography>{getValue(selectedInterventionDetails, 'treatedwaterreuse.vendorCapex')}</Typography>
                            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                            {(capexMultiplierSource === 'VENDOR' || capexMultiplierSource === '') && < RadioButtonUncheckedIcon className={classes.selectedCheckbox}
                                onClick={() => {
                                    appendChanged('treatedwaterreuse.capexMultiplierSource', 'CUSTOM');
                                    setCapexMultiplierSource('CUSTOM');
                                }} />}
                            {(capexMultiplierSource === 'CUSTOM') && <CheckCircleIcon className={classes.selectedCheckbox}
                                onClick={() => {
                                    setCapexMultiplierSource('');
                                }} />}
                            <Typography>Custom</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customPrice}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCustomPrice(e.target.value);
                                    appendChanged('treatedwaterreuse.customCapex', Number(e.target.value));
                                }}
                                disabled={true}
                                disableTextField={capexMultiplierSource !== 'CUSTOM' ? true : false}
                            />
                            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={4}>
                    <Grid xs={12} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Project Cost Assumptions</Typography>
                        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
                    </Grid>
                    <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                        {'Water Tanker Price per KL'}
                    </Typography>
                    <Grid xs={8} style={{ display: 'flex' }}>
                        <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={userTankerPrice}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setUserTankerPrice(e.target.value);
                                    appendChanged('treatedwaterreuse.waterTankerPrice.user', Number(e.target.value));

                                }}
                                onClick={(e) => {
                                    setUserTankerPrice(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.waterTankerPrice.default'))
                                    appendChanged('treatedwaterreuse.waterTankerPrice.user', undefined);

                                }}
                            />
                            <Typography style={{ marginLeft: '20%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid xs={4} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Constants</Typography>
                        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
                    </Grid>
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid xs={8} >
                            <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                                {'STP Efficiency'}
                            </Typography>
                            <Grid xs={12} style={{ display: 'flex', width: '150px' }}>
                                <Grid style={{ marginTop: '2%' }} >
                                    <ResetFields
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={stpEfficiency}
                                        placeholder=""
                                        fullWidth
                                        required
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setStpEfficiency(e.target.value);
                                            appendChanged('treatedwaterreuse.stpEfficiency.user', Number(e.target.value));

                                        }}
                                        onClick={(e) => {
                                            setStpEfficiency(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.stpEfficiency.default'))
                                            appendChanged('treatedwaterreuse.stpEfficiency.user', undefined);

                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={8} style={{ marginLeft: '5%' }}>
                            <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                                {'Treated Water Price'}
                            </Typography>
                            <Grid xs={12} style={{ display: 'flex', width: '200px' }}>
                                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                                    <ResetFields
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={treatedWaterPrice}
                                        placeholder=""
                                        fullWidth
                                        required
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setTreatedWaterPrice(e.target.value);
                                            appendChanged('treatedwaterreuse.offsiteReusePrice.user', Number(e.target.value));

                                        }}
                                        onClick={(e) => {
                                            setTreatedWaterPrice(getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.offsiteReusePrice.default'))
                                            appendChanged('treatedwaterreuse.offsiteReusePrice.user', undefined);
                                        }}
                                    />
                                    <Typography style={{ marginLeft: '5%' }}>Rs/KL</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={8} style={{ marginLeft: '5%' }}>
                            <Typography color="textSecondary" variant="h6" style={{ marginTop: '7%' }}>
                                {'CO2e Emission Factor'}
                            </Typography>
                            <Grid xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '3%' }}>
                                <Typography>{getValueFromId(selectedInterventionDetails, 'treatedwaterreuse.coTwoEmissionFactor') + '          ' + `kg CO2e/kWh`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <ShowSaveAndCancel
                        handleClose={onClose}
                        handleSave={handleSave}
                    />
                </Grid>
            </Grid >
        </>
    );

};
export const TwrProjectInputParameters = (props) => {
    const { selectedInterventionDetails, onClose, } = props;
    const classes = useStyles();
    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Studio</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.studio') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.single') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.double') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.three') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.four') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.penthouse.onebhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.penthouse.twobhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.penthouse.threebhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValue(selectedInterventionDetails, 'pip.penthouse.fourbhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={onClose}
                />
            </Grid>
        </Grid>
    )

};
