import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { FilterSections } from '../FilterSidebar';
import { MarketplaceTypography, MarketplaceColors } from '../../../../../../config/index';
import { ProductsPageContext } from '../../../../../../../../../routing/IndexRouting';
import { NavigatorSource } from '../../../../../../utils/Defaults';

const useStyles = makeStyles((theme) => ({
    texttitle: MarketplaceTypography.BodySamiBold,
    arrowstyle: {
        color: MarketplaceColors.Secondary,
        fontSize: '180%',
    },
    padding: {
        paddingTop: '5%',
    },
}));

const FilterSidebarContextual = (props) => {
    const classes = useStyles();
    const { URLS } = useContext(ProductsPageContext);
    const { source } = URLS;
    const { section, setSection, categories, CheckboxComponent, allbrands, uniqueBrands, userSelectedFirstSection } = props;
    const brandsArr = [];
    categories && categories.map((category) => category?.attributes?.brands?.data.map((brand) => brandsArr.push(brand?.attributes?.name)));

    const handleSection = (section) => (section === FilterSections.Contextual ? setSection(FilterSections.NoSection) : setSection(FilterSections.Contextual));
    return (
        <Grid container>
            <Typography
                className={`${classes.texttitle} ${classes.padding}`}
                onClick={() => handleSection(section)}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
                Contextual Filters
                {section === FilterSections.Contextual && <ArrowDropUpIcon className={classes.arrowstyle} />}
                {section != FilterSections.Contextual && <ArrowDropDownIcon className={classes.arrowstyle} />}
            </Typography>

            {/* brands */}
            {section === FilterSections.Contextual && source === NavigatorSource.intervention && userSelectedFirstSection != section
                ? CheckboxComponent(section, 'Hide Shortlisted Products')
                : ''}
            {section === FilterSections.Contextual && source === NavigatorSource.intervention && userSelectedFirstSection != section
                ? CheckboxComponent(section, 'Only Show Shortlisted Products')
                : ''}
        </Grid>
    );
};

export default FilterSidebarContextual;
