import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

export const HWMApartmentCustomBasicParameters = (props) => {
  const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props;
  const classes = useStyles();
  const userswhcapcity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhCapacity');
  const optionforswhApartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.options');
  const swhTypeApartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhType');
  const hwmApartmentswhVariant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhVariant');
  const hwmhpTypeApartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.HpType');
  const hwmhpheatingApartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.heatingSpeed');

  const individualswhproductcapacity =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhindividualProductCapacity');
  const individualhpproductcapacity =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hpindividualProductCapacity');
  const individualhybridswhproductcapacity =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridswhindividualProductCapacity');
  const individualhybridhpproductcapacity =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridhpindividualProductCapacity');

  const swhnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhnumberOfProducts');
  const hpnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hpnumberOfProducts');
  const hybridswhnumberofproducts =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridswhnumberOfProducts');
  const hybridhpnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridhpnumberOfProducts');

  const swharearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.swhareaRequired');
  const hparearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hpareaRequired');
  const hybridswharearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridswhareaRequired');

  const hwmApartmentuserHpCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.userHpCapacity');
  const hwmApartmentHybridSWHCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridswhCapacity');
  const hwmApartmentHybridHpCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridhpCapacity');
  const hwmApartmentHybridHpHeatingSpeed =
    selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridheatingSpeed');
  const hwmApartmentHybridSWHVariant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hybridswhVariant');

  const [hwmApartmentOption, setHwmApartmentOption] = useState('');
  const [swhType, setSwhType] = useState('');
  const [swhTechnology, setSwhTechnology] = useState('');
  const [hpType, setHpType] = useState('');
  const [hpTemp, setHpTemp] = useState('');
  const [hybridHeatingSpeed, setHybridHeatingSpeed] = useState('');
  const [hybridSwhVariant, setHybridSwhVariant] = useState('');

  useEffect(() => {
    if (optionforswhApartment.value === 'solar water heater') {
      setHwmApartmentOption('solar water heater');
    } else if (optionforswhApartment.value === 'heat pump') {
      setHwmApartmentOption('heat pump');
    } else if (optionforswhApartment.value === 'hybrid') {
      setHwmApartmentOption('hybrid');
    }
    if (swhTypeApartment.value === 'individual') {
      setSwhType('individual');
    } else if (swhTypeApartment.value === 'central') {
      setSwhType('central');
    }
    if (hwmApartmentHybridHpHeatingSpeed && hwmApartmentHybridHpHeatingSpeed) {
      setHybridHeatingSpeed(hwmApartmentHybridHpHeatingSpeed?.value);
    }
    if (hwmApartmentHybridSWHVariant && hwmApartmentHybridSWHVariant) {
      setHybridSwhVariant(hwmApartmentHybridSWHVariant?.value);
    }
    if (hwmApartmentswhVariant.value === 'etc') {
      setSwhTechnology('etc');
    } else if (hwmApartmentswhVariant.value === 'fpc') {
      setSwhTechnology('fpc');
    }
    if (hwmhpTypeApartment.value === 'domestic') {
      setHpType('domestic');
    } else if (hwmhpTypeApartment.value === 'commercial') {
      setHpType('commercial');
    }
    if (hwmhpheatingApartment.value === 'slow') {
      setHpTemp('slow');
    } else if (hwmhpheatingApartment.value === 'fast') {
      setHpTemp('fast');
    }
  }, []);

  const handleHwmApartmentOption = (option) => {
    if (option === 'solar water heater') {
      if (hwmApartmentOption !== 'solar water heater') {
        setHwmApartmentOption('solar water heater');
        appendChanged(optionforswhApartment.id, 'solar water heater');
        appendChanged(hwmApartmentswhVariant.id, null);
      } else {
        setHwmApartmentOption('');
        appendChanged(optionforswhApartment.id, null);
      }
    } else if (option === 'heat pump') {
      if (hwmApartmentOption !== 'heat pump') {
        setHwmApartmentOption('heat pump');
        appendChanged(optionforswhApartment.id, 'heat pump');
      } else {
        setHwmApartmentOption(null);
        appendChanged(optionforswhApartment.id, null);
      }
    } else if (option === 'hybrid') {
      if (hwmApartmentOption !== 'hybrid') {
        setHwmApartmentOption('hybrid');
        // setSwhType('')
        // appendChanged(swhTypeApartment.id, null);
        appendChanged(optionforswhApartment.id, 'hybrid');
      } else {
        setHwmApartmentOption('');
        appendChanged(optionforswhApartment.id, null);
      }
    }
  };
  const handleSWhTypeSWH = (type) => {
    if (type === 'central') {
      if (swhType === type) {
        setSwhType('');
        appendChanged(swhTypeApartment.id, null);
      } else {
        setSwhTechnology(null);
        appendChanged(hwmApartmentswhVariant.id, null);
        setSwhType(type);
        setHpType(null);
        appendChanged(hwmhpTypeApartment.id, null);
        appendChanged(swhTypeApartment.id, type);
      }
    } else if (type === 'individual') {
      if (swhType === type) {
        setSwhType('');
        appendChanged(swhTypeApartment.id, null);
      } else {
        setSwhType(type);
        appendChanged(swhTypeApartment.id, type);
      }
    }
  };
  const handleSWhTechnology = (technology) => {
    if (technology === 'etc') {
      if (swhTechnology === technology) {
        setSwhTechnology(null);
        appendChanged(hwmApartmentswhVariant.id, null);
      } else {
        setSwhTechnology(technology);
        appendChanged(hwmApartmentswhVariant.id, technology);
      }
    } else if (technology === 'fpc') {
      if (swhTechnology === technology) {
        setSwhTechnology(null);
        appendChanged(hwmApartmentswhVariant.id, null);
      } else {
        setSwhTechnology(technology);
        appendChanged(hwmApartmentswhVariant.id, technology);
      }
    }
  };
  const handleHybridSWhTechnology = (technology) => {
    if (technology === 'etc') {
      if (hybridSwhVariant === technology) {
        setHybridSwhVariant(null);
        appendChanged(hwmApartmentHybridSWHVariant.id, null);
      } else {
        setHybridSwhVariant(technology);
        appendChanged(hwmApartmentHybridSWHVariant.id, technology);
      }
    } else if (technology === 'fpc') {
      if (hybridSwhVariant === technology) {
        setHybridSwhVariant(null);
        appendChanged(hwmApartmentHybridSWHVariant.id, null);
      } else {
        setHybridSwhVariant(technology);
        appendChanged(hwmApartmentHybridSWHVariant.id, technology);
      }
    }
  };
  const handleHpType = (type) => {
    if (type === 'commercial') {
      if (hpType === type) {
        setHpType('');
        appendChanged(hwmhpTypeApartment.id, null);
      } else {
        setHpType(type);
        appendChanged(hwmhpTypeApartment.id, type);
      }
    } else if (type === 'domestic') {
      if (hpType === type) {
        setHpType('');
        appendChanged(hwmhpTypeApartment.id, null);
      } else {
        setHpType(type);
        appendChanged(hwmhpTypeApartment.id, type);
      }
    }
  };
  const handleHpTemp = (temp) => {
    if (temp === 'slow') {
      if (hpTemp === temp) {
        setHpTemp('');
        appendChanged(hwmhpheatingApartment.id, null);
      } else {
        setHpTemp(temp);
        appendChanged(hwmhpheatingApartment.id, temp);
      }
    } else if (temp === 'fast') {
      if (hpTemp === temp) {
        setHpTemp('');
        appendChanged(hwmhpheatingApartment.id, null);
      } else {
        setHpTemp(temp);
        appendChanged(hwmhpheatingApartment.id, temp);
      }
    }
  };
  const handleHybridHpTemp = (temp) => {
    if (temp === 'slow') {
      if (hybridHeatingSpeed === temp) {
        setHybridHeatingSpeed('');
        appendChanged(hwmApartmentHybridHpHeatingSpeed.id, null);
      } else {
        setHybridHeatingSpeed(temp);
        appendChanged(hwmApartmentHybridHpHeatingSpeed.id, temp);
      }
    } else if (temp === 'fast') {
      if (hybridHeatingSpeed === temp) {
        setHybridHeatingSpeed('');
        appendChanged(hwmApartmentHybridHpHeatingSpeed.id, null);
      } else {
        setHybridHeatingSpeed(temp);
        appendChanged(hwmApartmentHybridHpHeatingSpeed.id, temp);
      }
    }
  };
  const appendChanged = (id, value) => {
    const body = { id: id, value: !value ? 0 : value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };
  console.log(myArray, 'myArray');
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'solar water heater' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmApartmentOption('solar water heater')} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('solar water heater')} />
            )}
            Solar Water Heater
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'heat pump' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmApartmentOption('heat pump')} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('heat pump')} />
            )}
            Heat Pump
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'hybrid' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmApartmentOption('hybrid')} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('hybrid')} />
            )}
            Hybrid
          </Typography>
        </Grid>
      </Grid>
      {hwmApartmentOption === 'solar water heater' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>Solar Water Heater Capacity</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    myArray && !myArray.find((d) => d.id === userswhcapcity.id)
                      ? userswhcapcity.value
                      : myArray.find((d) => d.id === userswhcapcity.id) && myArray.find((d) => d.id === userswhcapcity.id).value
                  }
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    appendChanged(userswhcapcity.id, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => appendChanged(userswhcapcity.id, Number(userswhcapcity?.defaultValue))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">LPD</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {swhType === 'central' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />}
                {swhType !== 'central' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />}
                Central
              </Typography>
              <Typography className={classes.textHeader3}>
                {swhType === 'individual' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />}
                {swhType !== 'individual' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />
                )}
                Individual
              </Typography>
            </Grid>
            {swhType === 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
            {swhType !== 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3}>
                  {swhTechnology === 'etc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                  {swhTechnology !== 'etc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3}>
                  {swhTechnology === 'fpc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                  {swhTechnology !== 'fpc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {hwmApartmentOption === 'heat pump' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>Heat Pump Capacity</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    myArray && !myArray.find((d) => d.id === hwmApartmentuserHpCapacity.id)
                      ? hwmApartmentuserHpCapacity.value
                      : myArray.find((d) => d.id === hwmApartmentuserHpCapacity.id) && myArray.find((d) => d.id === hwmApartmentuserHpCapacity.id).value
                  }
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    appendChanged(hwmApartmentuserHpCapacity.id, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => appendChanged(hwmApartmentuserHpCapacity.id, Number(hwmApartmentuserHpCapacity?.defaultValue))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">LPH</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {hpType === 'commercial' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('commercial')} />}
                {hpType !== 'commercial' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('commercial')} />}
                Commercial
              </Typography>
              <Typography className={classes.textHeader3}>
                {hpType === 'domestic' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('domestic')} />}
                {hpType !== 'domestic' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />}
                Domestic
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Heating</Typography>
              <Typography className={classes.textHeader3}>
                {hpTemp === 'slow' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                {hpTemp !== 'slow' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                Slow Heating
              </Typography>
              <Typography className={classes.textHeader3}>
                {hpTemp === 'fast' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                {hpTemp !== 'fast' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                Fast Heating
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {hwmApartmentOption === 'hybrid' && (
        <>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                <Grid xs>
                  <Typography className={classes.textheader2}>Solar Water Heater Capacity</Typography>
                </Grid>
                <Grid xs={10} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
                  <Grid xs={5}>
                    <ResetFields
                      id="outlined-basic"
                      variant="outlined"
                      value={
                        myArray && !myArray.find((d) => d.id === hwmApartmentHybridSWHCapacity.id)
                          ? hwmApartmentHybridSWHCapacity.value
                          : myArray.find((d) => d.id === hwmApartmentHybridSWHCapacity.id) &&
                            myArray.find((d) => d.id === hwmApartmentHybridSWHCapacity.id).value
                      }
                      placeholder=""
                      onChange={(e) => {
                        e.preventDefault();
                        appendChanged(hwmApartmentHybridSWHCapacity.id, Number(e.target.value));
                      }}
                      fullWidth
                      required
                      onClick={() => appendChanged(hwmApartmentHybridSWHCapacity.id, Number(hwmApartmentHybridSWHCapacity?.defaultValue))}
                      disabled={false}
                    />
                  </Grid>
                  <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                    <Typography variant="h6">LPD</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} container alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Type</Typography>
                  <Typography className={classes.textHeader3}>
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    Central
                  </Typography>
                  <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                    <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                    Individual
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Technology</Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridSwhVariant === 'etc' && (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHybridSWhTechnology('etc')} />
                    )}
                    {hybridSwhVariant !== 'etc' && (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridSWhTechnology('etc')} />
                    )}
                    ETC
                    <CustomizedTooltips
                      icon="info"
                      title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                      placement="right"
                    />
                  </Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridSwhVariant === 'fpc' && (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHybridSWhTechnology('fpc')} />
                    )}
                    {hybridSwhVariant !== 'fpc' && (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridSWhTechnology('fpc')} />
                    )}
                    FPC
                    <CustomizedTooltips
                      icon="info"
                      title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                      placement="right"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                <Grid xs>
                  <Typography className={classes.textheader2}>Heat Pump Capacity</Typography>
                </Grid>
                <Grid xs={10} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
                  <Grid xs={5}>
                    <ResetFields
                      id="outlined-basic"
                      variant="outlined"
                      value={
                        myArray && !myArray.find((d) => d.id === hwmApartmentHybridHpCapacity.id)
                          ? hwmApartmentHybridHpCapacity.value
                          : myArray.find((d) => d.id === hwmApartmentHybridHpCapacity.id) && myArray.find((d) => d.id === hwmApartmentHybridHpCapacity.id).value
                      }
                      placeholder=""
                      onChange={(e) => {
                        e.preventDefault();
                        appendChanged(hwmApartmentHybridHpCapacity.id, Number(e.target.value));
                      }}
                      fullWidth
                      required
                      onClick={() => appendChanged(hwmApartmentHybridHpCapacity.id, Number(hwmApartmentHybridHpCapacity?.defaultValue))}
                      disabled={false}
                    />
                  </Grid>
                  <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                    <Typography variant="h6">LPH</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} container alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Type</Typography>
                  <Typography className={classes.textHeader3}>
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    Commercial
                  </Typography>
                  <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                    <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                    Domestic
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Heating</Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridHeatingSpeed === 'slow' && (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHybridHpTemp('slow')} />
                    )}
                    {hybridHeatingSpeed !== 'slow' && (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridHpTemp('slow')} />
                    )}
                    Slow Heating
                  </Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridHeatingSpeed === 'fast' && (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHybridHpTemp('fast')} />
                    )}
                    {hybridHeatingSpeed !== 'fast' && (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridHpTemp('fast')} />
                    )}
                    Fast Heating
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {hwmApartmentOption === 'solar water heater' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {individualswhproductcapacity?.value} LPD
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>No. of Products</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {swhnumberofproducts?.value} nos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Area Required</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmApartmentareaRequired?.value))} ${hwmApartmentareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {swharearequired?.value} sqm
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {hwmApartmentOption === 'heat pump' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {individualhpproductcapacity?.value} LPH
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>No. of Products</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {hpnumberofproducts?.value} nos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Area Required</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmApartmentareaRequired?.value))} ${hwmApartmentareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {hparearequired?.value} sqm
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {hwmApartmentOption === 'hybrid' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {individualhybridswhproductcapacity?.value} LPD
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>No. of Products</Typography>
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {hybridswhnumberofproducts?.value} nos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Area Required</Typography>
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {hybridswharearequired?.value} sqm
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {individualhybridhpproductcapacity?.value} LPH
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>No. of Products</Typography>
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {hybridhpnumberofproducts?.value} nos.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              {/* <Grid item xs={6}>
                                <Typography className={classes.textHeader3}>Area Required</Typography>
                                <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>2 sqm</Typography>
                            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}>
        <ShowSaveAndCancel
          handleClose={onClose}
          handleSave={handleSave}
          // minError={minError && minError}
          // maxError={maxError && maxError}
        />
      </Grid>
    </Grid>
  );
};

export const HWMApartmentCustomAdvancesParameters = (props) => {
  const classes = useStyles();
  const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props;
  const optionforswhApartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.options');
  const demandbybath = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.demandByBath');
  const demandassumptiontype = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.demandAssumptionType');
  const hwmdemandAssumption = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.demandAssumption');
  const hwmelecostperkwh = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.ElectricityCostperkWh');
  const rooftopareaperswhlpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.rooftopAreaPerSWHLPD');
  const rooftopareaperhplpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.rooftopAreaPerHPLPD');
  const hotwaterusageshours = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hotwaterusuageHours');
  const numberofplumbingshafts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.numberofPlumbingShafts');
  const co2emissionfactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.co2emmissionfactor');
  const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.multipliersource');
  const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.CustomCapexMultiplier');
  const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.vendorCapexMultiplier');
  const demandbyarea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.demandByArea');
  const numberoffloors = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.hotwaterfloors');
  const lpcdperoccupant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.lpcdPerOccupant');
  const lpcdperappartment = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.lpcdPerApartment');
  const vendorswhcapex = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.vendorswhcapex');
  const vendorhpcapex = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.vendorhpcapex');
  const customswhcapex = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.customswhcapex');
  const customhpcapex = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmApartment.customhpcapex');

  // console.log(demandbybath, demandassumptiontype, hwmdemandAssumption, hwmelecostperkwh, rooftopareaperswhlpd,
  //     hotwaterusageshours, numberofplumbingshafts, co2emissionfactor, capexmultipliersource, customcapexmultiplier,
  //     vendorcapexmultiplier, demandbyarea, numberoffloors, lpcdperoccupant, lpcdperappartment, 'all advance parameters')
  console.log(vendorswhcapex);
  const [apartmentoption, setApartmentOption] = useState('');
  const [demandBybath, setDemandByBath] = useState('');
  const [demandAssumption, setDemandAssumption] = useState('');
  const [assumptionperoccupant, setAssumptionperoccupant] = useState(0);
  const [assumptionPerApartment, setAssumptionPerApartment] = useState(0);
  const [vendorTextField, selectVendorTextField] = useState(false);
  const [customTextField, setCustomTextField] = useState(false);
  const [vendorCapex, setVendorCapex] = useState(0);
  const [customCapex, setCustomcapex] = useState(0);
  const [electricityCost, setElectricityCost] = useState(0);
  const [rftopAreaperSWhLPD, setRftopAreaperSWhLPD] = useState(0);
  const [rftopAreaperHPLPD, setRftopAreaperHPLPD] = useState(0);
  const [hwUsageHours, setHwUsageHours] = useState(0);
  const [numberofPlumbingShafts, setNumberofPlumbingShafts] = useState(0);
  const [disableDemandByBath, setDisableDemandByBath] = useState(false);
  const [demandArea, setDemandArea] = useState('');
  const [numberofFloors, setNumberofFloors] = useState(0);
  const [customSWHCapex, setCustomSWHCapex] = useState(0);
  const [customHPCapex, setCustomHPCapex] = useState(0);

  const handleDemandArea = (demandarea) => {
    if (demandarea === 'total demand') {
      if (demandArea === demandarea) {
        setDemandArea('');
        appendChanged(demandbyarea.id, '');
      } else {
        setDemandArea(demandarea);
        appendChanged(demandbyarea.id, demandarea);
      }
    }
    if (demandarea === 'number of floors') {
      if (demandArea === demandarea) {
        setDemandArea('');
        appendChanged(demandbyarea.id, '');
      } else {
        setDemandArea(demandarea);
        appendChanged(demandbyarea.id, demandarea);
      }
    }
  };
  const appendChanged = (id, value) => {
    const body = { id: id, value: !value ? 0 : value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  useEffect(() => {
    if (optionforswhApartment && optionforswhApartment?.value) {
      setApartmentOption(optionforswhApartment?.value);
    }
    if (customswhcapex && customswhcapex?.value) {
      setCustomSWHCapex(customswhcapex?.value);
    }
    if (customhpcapex && customhpcapex?.value) {
      setCustomHPCapex(customhpcapex?.value);
    }
    if (demandbybath && demandbybath.value) {
      if (demandassumptiontype?.value === 'lpd per apartment') {
        setDemandByBath('');
        setDisableDemandByBath(true);
        setDemandByBath('');
      } else {
        setDemandByBath(demandbybath.value);
      }
    }
    if (numberoffloors && numberoffloors.value) {
      setNumberofFloors(numberoffloors.value);
    }
    if (demandbyarea && demandbyarea.value) {
      setDemandArea(demandbyarea.value);
    }
    if (demandassumptiontype && demandassumptiontype.value) {
      setDemandAssumption(demandassumptiontype.value);
    }
    if (demandassumptiontype && lpcdperoccupant) {
      setAssumptionperoccupant(lpcdperoccupant?.value);
    }
    if (demandassumptiontype && lpcdperappartment) {
      setAssumptionPerApartment(lpcdperappartment?.value);
    }
    if (hwmelecostperkwh && hwmelecostperkwh.value) {
      setElectricityCost(hwmelecostperkwh.value);
    }
    //
    if (rooftopareaperswhlpd && rooftopareaperswhlpd.value) {
      setRftopAreaperSWhLPD(rooftopareaperswhlpd.value);
    }
    if (rooftopareaperhplpd && rooftopareaperhplpd.value) {
      setRftopAreaperHPLPD(rooftopareaperhplpd.value);
    }
    if (hotwaterusageshours && hotwaterusageshours.value) {
      setHwUsageHours(hotwaterusageshours.value);
    }
    if (numberofplumbingshafts && numberofplumbingshafts.value) {
      setNumberofPlumbingShafts(numberofplumbingshafts.value);
    }
    if (capexmultipliersource && capexmultipliersource.value === 'custom') {
      setCustomTextField(true);
    }
    if (capexmultipliersource && capexmultipliersource.value === 'vendor') {
      selectVendorTextField(true);
    }
  }, []);

  const handleDemandByBath = (demandbath) => {
    if (demandbath === 'all baths') {
      if (demandBybath === demandbath) {
        setDemandByBath('');
        appendChanged(demandbybath.id, '');
      } else {
        setDemandByBath(demandbath);
        appendChanged(demandbybath.id, demandbath);
      }
    } else if (demandbath === 'single bath') {
      if (demandBybath === demandbath) {
        setDemandByBath('');
        appendChanged(demandbybath.id, '');
      } else {
        setDemandByBath(demandbath);
        appendChanged(demandbybath.id, demandbath);
      }
    } else if (demandbath === 'remaining baths') {
      if (demandBybath === demandbath) {
        setDemandByBath('');
        appendChanged(demandbybath.id, '');
      } else {
        setDemandByBath(demandbath);
        appendChanged(demandbybath.id, demandbath);
      }
    }
  };
  const handleDemandByAssumption = (assumption) => {
    if (assumption === 'lpcd per occupant') {
      if (demandAssumption === assumption) {
        setDemandAssumption('');
        appendChanged(demandassumptiontype.id, '');
      } else {
        setDemandAssumption(assumption);
        appendChanged(demandassumptiontype.id, assumption);
        setDisableDemandByBath(false);
      }
    } else if (assumption === 'lpd per apartment') {
      if (demandAssumption === assumption) {
        setDemandAssumption('');
        appendChanged(demandassumptiontype.id, '');
        setDisableDemandByBath(false);
      } else {
        setDemandAssumption(assumption);
        setDemandByBath('');
        appendChanged(demandbybath.id, '');
        appendChanged(demandassumptiontype.id, assumption);
        setDisableDemandByBath(true);
      }
    } else if (assumption === 'link to installed efficient fixtures') {
      if (demandAssumption === assumption) {
        setDemandAssumption('');
        appendChanged(demandassumptiontype.id, '');
      } else {
        setDisableDemandByBath(false);
        appendChanged(demandassumptiontype.id, assumption);
        setDemandAssumption(assumption);
      }
    }
  };
  console.log(demandBybath, 'demandAssumption');
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%', paddingBottom: '3%' }}>
        <Typography className={classes.textHeader1}>Demand by Area</Typography>
        <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandArea === 'total demand' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandArea('total demand')} />
              )}
              {demandArea !== 'total demand' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandArea('total demand')} />
              )}
              Total Demand (All floors excl Penthouses)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandArea === 'number of floors' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandArea('number of floors')} />
              )}
              {demandArea !== 'number of floors' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandArea('number of floors')} />
              )}
              Number of Floors
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={numberofFloors}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setNumberofFloors(e.target.value);
                  appendChanged(numberoffloors && numberoffloors.id, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setNumberofFloors(numberoffloors?.defaultValue);
                  appendChanged(numberoffloors && numberoffloors.id, Number(numberoffloors?.defaultValue));
                }}
                disabled={demandArea !== 'number of floors' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">Floors</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1}>Demand by Bath</Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandBybath === 'all baths' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandBybath !== 'all baths' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandBybath !== 'all baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        All baths
      </Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandBybath === 'single bath' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandBybath !== 'single bath' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandBybath !== 'single bath' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        Master bathroom
      </Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandBybath === 'remaining baths' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandBybath !== 'remaining baths' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandBybath !== 'remaining baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        All baths except master bathroom
      </Typography>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%' }}>
        <Typography className={classes.textHeader1}>Demand Assumption</Typography>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandAssumption === 'lpcd per occupant' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('lpcd per occupant')} />
              )}
              {demandAssumption !== 'lpcd per occupant' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByAssumption('lpcd per occupant')} />
              )}
              Per Occupant
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={assumptionperoccupant}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setAssumptionperoccupant(Number(e.target.value));
                  appendChanged(lpcdperoccupant && lpcdperoccupant.id, Number(e.target.value));
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setAssumptionperoccupant(lpcdperoccupant?.defaultValue);
                  appendChanged(lpcdperoccupant && lpcdperoccupant.id, Number(lpcdperoccupant?.defaultValue));
                }}
                disabled={demandAssumption !== 'lpcd per occupant' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">LPCD</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandAssumption === 'lpd per apartment' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('lpd per apartment')} />
              )}
              {demandAssumption !== 'lpd per apartment' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByAssumption('lpd per apartment')} />
              )}
              Per Apartment
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={assumptionPerApartment}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setAssumptionPerApartment(Number(e.target.value));
                  appendChanged(lpcdperappartment && lpcdperappartment.id, Number(e.target.value));
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setAssumptionPerApartment(lpcdperappartment?.defaultValue);
                  appendChanged(lpcdperappartment && lpcdperappartment.id, Number(lpcdperappartment?.defaultValue));
                }}
                disabled={demandAssumption !== 'lpd per apartment' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">LPCD</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.textHeader3}>
          {demandAssumption === 'link to installed efficient fixturess' && (
            <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('link to installed efficient fixtures')} />
          )}
          {demandAssumption !== 'link to installed efficient fixtures' && (
            <RadioButtonUncheckedIcon
              className={classes.nonselectedRadiobox}
              onClick={() => handleDemandByAssumption('link to installed efficient fixtures')}
            />
          )}
          As per selected Efficient Fixtures
        </Typography>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {vendorTextField ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    selectVendorTextField(false);
                    appendChanged(capexmultipliersource.id, '');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    setCustomTextField(false);
                    selectVendorTextField(true);
                    appendChanged(capexmultipliersource.id, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          {apartmentoption !== 'hybrid' && (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <Typography className={classes.textconstants}>{numberWithCommas(vendorcapexmultiplier?.value)} RS</Typography>
              </Grid>
            </Grid>
          )}
          {apartmentoption === 'hybrid' && (
            <>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Solar Water Heater
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={vendorswhcapex?.value}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      setVendorCapex(e.target.value);
                    }}
                    fullWidth
                    required
                    // onClick={() => setVendorCapex(0)}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Heat Pump
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={vendorhpcapex?.value}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      setVendorCapex(e.target.value);
                    }}
                    fullWidth
                    required
                    // onClick={() => setVendorCapex(0)}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {customTextField ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    setCustomTextField(false);
                    appendChanged(capexmultipliersource.id, '');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    selectVendorTextField(false);
                    setCustomTextField(true);
                    appendChanged(capexmultipliersource.id, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          {apartmentoption !== 'hybrid' && (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={customCapex}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    setCustomcapex(e.target.value);
                    appendChanged(customcapexmultiplier && customcapexmultiplier.id, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={() => setCustomcapex(customcapexmultiplier?.defaultValue)}
                  disabled={!customTextField ? true : false}
                  removeResetIcon={true}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">RS</Typography>
              </Grid>
            </Grid>
          )}
          {apartmentoption === 'hybrid' && (
            <>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Solar Water Heater
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={customSWHCapex}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      setCustomSWHCapex(e.target.value);
                      appendChanged(customswhcapex && customswhcapex.id, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={() => setCustomcapex(customswhcapex?.defaultValue)}
                    disabled={!customTextField ? true : false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Heat Pump
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={customHPCapex}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      setCustomHPCapex(e.target.value);
                      appendChanged(customhpcapex && customhpcapex.id, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={() => setCustomcapex(customhpcapex?.defaultValue)}
                    disabled={!customTextField ? true : false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Project Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>Electricity Cost per kWh</Typography>
      <Grid item xs={4} direction="column" style={{ paddingTop: '10px' }}>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={electricityCost}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                setElectricityCost(e.target.value);
                appendChanged(hwmelecostperkwh && hwmelecostperkwh.id, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                setElectricityCost(hwmelecostperkwh?.defaultValue);
                appendChanged(hwmelecostperkwh && hwmelecostperkwh.id, hwmelecostperkwh?.defaultValue);
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">RS</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>Rooftop Area per SWH LPD</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={rftopAreaperSWhLPD}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setRftopAreaperSWhLPD(e.target.value);
                  appendChanged(rooftopareaperswhlpd && rooftopareaperswhlpd.id, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setRftopAreaperSWhLPD(rooftopareaperswhlpd?.defaultValue);
                  appendChanged(rooftopareaperswhlpd && rooftopareaperswhlpd.id, rooftopareaperswhlpd?.defaultValue);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">Sqm</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>Rooftop Area per HP LPD</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={rftopAreaperHPLPD}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setRftopAreaperHPLPD(e.target.value);
                  appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setRftopAreaperHPLPD(rooftopareaperhplpd?.defaultValue);
                  appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, rooftopareaperhplpd?.defaultValue);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">Sqm</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>Hot Water Usage Hours</Typography>
            <CustomizedTooltips
              icon="info"
              title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
              placement="right"
            />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={hwUsageHours}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setHwUsageHours(e.target.value);
                  appendChanged(hotwaterusageshours && hotwaterusageshours.id, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setHwUsageHours(hotwaterusageshours?.defaultValue);
                  appendChanged(hotwaterusageshours && hotwaterusageshours.id, hotwaterusageshours?.defaultValue);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">hrs</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>Number of Plumbing Shafts</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Used for calculating heat loss from standby water</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={numberofPlumbingShafts}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  setNumberofPlumbingShafts(e.target.value);
                  appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  setNumberofPlumbingShafts(numberofplumbingshafts?.defaultValue);
                  appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, numberofplumbingshafts?.defaultValue);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">nos</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>{numberWithCommas(co2emissionfactor?.value)} kg CO2e/kWh</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}>
        <ShowSaveAndCancel
          handleClose={onClose}
          handleSave={handleSave}
          // minError={minError && minError}
          // maxError={maxError && maxError}
        />
      </Grid>
    </Grid>
  );
};

export const HWMAprtmentProjectInputs = (props) => {
  const classes = useStyles();
  const { selectedInterventionDetails, onClose } = props;
  const onebhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.single');
  const twobhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.double');
  const threebhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.three');
  const fourbhk = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.four');
  const studio = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.studio');
  const rooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.rooftop');

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(Number(rooftoparea?.value))} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Studio</Typography>
          <Typography className={classes.textHeader3}>{studio?.value} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk?.value} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk?.value} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk?.value} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk?.value} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}>
        <ShowSaveAndCancel
          handleClose={onClose}
          // handleSave={handleSave}
          // minError={minError && minError}
          // maxError={maxError && maxError}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  textHeader1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  nonselectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  textheader2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.7px',
    color: 'rgba(46, 46, 46, 1)',
    paddingTop: '10%',
  },
  nonselectedRadiobox: {
    // color: "rgba(102, 227, 190, 1)",
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  textHeader3: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  textHeader3disabled: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(158, 158, 158, 1)',
  },
  textHeader4: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  textconstants: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.71px',
    // paddingTop: "10px",
    color: 'rgba(46, 46, 46, 1)',
  },
  textHeader5: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  nonselectedRadioboxdisabled: {
    color: 'rgba(158, 158, 158, 1)',
    marginRight: '10px',
  },
}));
