import React from 'react';
import { Grid, GridList, makeStyles, withWidth } from '@material-ui/core';
import ResourceHubImageThree from '../../../../../../../assets/Marketplace/ResourceHubImageThree.svg';// exceeds 500
import { products } from '../../../../utils/DummyProducts';
import ResourceCardMobile from '../../../../common/ResourceCard/ResourceCardMobile';

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));

const KnowledgeHubListMobile = (props) => {
  const classes = useStyles();
  const { width } = props;
  return (
    <>
      <GridList className={classes.gridList}>
        {products.map((d) => {
          return (
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container alignItems="center" justifyContent="center" style={{ height: 'auto' }}>
              <ResourceCardMobile
                ele={d}
                productimage={ResourceHubImageThree}
                width={width}
                text="Guide"
                longText="Choosing the best Wall Blocks for your next Real estate project next Real estate project"
              />
            </Grid>
          );
        })}
      </GridList>
    </>
  );
};

export default withWidth()(KnowledgeHubListMobile);
