import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import axios from '../../../../../config/Axios';
import { InterventionDetailsContext } from '../interventionDetails';
import EditInterventionDetails from './editInterventionDetails';
import ShowConstants from './showConstants';
import ShowProjectAndConstAssumptionData from './showProjectAndCostAssumptionData';
import ShowCapexAndDetails from './showCapexAndDetails';
import ShowInterventionCostAssumptionsParameters from './showInterventionCostAssumptionParameters';
import ShowSaveAndCancel from './showSaveAndCancel';
import { NotetakingContext } from '../../../../../projectRouting/Index';
import listicon from '../../../../../assets/interventions/listicon.svg'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { CheckBox, CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
}));
const getDefaultValueFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.defaultValue;
};
const getValueFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i?.id === id
    })[0]?.value;
};
const getBooleanFromId = (interventionData, id) => {
    return interventionData.filter(data => {
        return data;
    }).filter(i => {
        return i.id === id
    })[0]?.value || '';
};
export const NaturalSTPCustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    return (
        <Grid item xs={12} container alignItems="flex-start" direction="row">
            <div style={{ display: 'flex' }}>
                <>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textheader2}>
                            STP Capacity
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
                            <Typography>{getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.stpCapacity')}</Typography>
                            <Typography style={{ marginLeft: '5%' }}>KLD</Typography>
                        </div>
                    </Grid>
                </>
            </div>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                />
            </Grid>
        </Grid>
    );

}
export const NaturalSTPCustomAdvanceParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    const [customPrice, setCustomPrice] = useState(getValueFromId(selectedInterventionDetails, 'naturalstp.customCapex'));
    const [capexMultiplierSource, setCapexMultiplierSource] = useState(getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.capexMultiplierSource'))
    const [userTankerPrice, setUserTankerPrice] = useState(getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.waterTankerPrice.actual'))
    const classes = useStyles();
    return (
        <>
            <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid xs={4}>
                    <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Intervention Cost Assumptions</Typography>
                        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
                    </Grid>
                    <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                        {'Capex (per KL)'}
                    </Typography>
                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                            {(capexMultiplierSource === 'CUSTOM' || capexMultiplierSource === '') && <RadioButtonUncheckedIcon className={classes.selectedCheckbox} onClick={() => {
                                appendChanged('naturalstp.capexMultiplierSource', 'VENDOR');
                                setCapexMultiplierSource('VENDOR');
                            }} />}
                            {(capexMultiplierSource === 'VENDOR') && <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                setCapexMultiplierSource('');
                            }} />}
                            <Typography>Selected Vendor</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
                            <Typography>{getValueFromId(selectedInterventionDetails, 'naturalstp.vendorCapex')}</Typography>
                            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                            {(capexMultiplierSource === 'VENDOR' || capexMultiplierSource === '') && < RadioButtonUncheckedIcon className={classes.selectedCheckbox}
                                onClick={() => {
                                    appendChanged('naturalstp.capexMultiplierSource', 'CUSTOM');
                                    setCapexMultiplierSource('CUSTOM');
                                }} />}
                            {(capexMultiplierSource === 'CUSTOM') && <CheckCircleIcon className={classes.selectedCheckbox}
                                onClick={() => {
                                    setCapexMultiplierSource('');
                                }} />}
                            <Typography>Custom</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customPrice}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCustomPrice(e.target.value);
                                    appendChanged('naturalstp.customCapex', Number(e.target.value));
                                }}
                                onClick={(e) => {
                                    setCustomPrice(getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.customCapex'));
                                    appendChanged('naturalstp.customCapex', undefined);
                                }}
                                disabled={true}
                                disableTextField={capexMultiplierSource !== 'CUSTOM' ? true : false}
                            />
                            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                </div>
                <Grid xs={4}>
                    <Grid xs={12} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Project Cost Assumptions</Typography>
                        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
                    </Grid>
                    <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                        {'Water Tanker Price per KL'}
                    </Typography>
                    <Grid xs={8} style={{ display: 'flex' }}>
                        <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={userTankerPrice}
                                placeholder=""
                                fullWidth
                                required
                                onChange={(e) => {
                                    e.preventDefault();
                                    setUserTankerPrice(e.target.value);
                                    appendChanged('naturalstp.waterTankerPrice.user', Number(e.target.value));

                                }}
                                onClick={(e) => {
                                    console.log('helllooo', getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.waterTankerPrice.default'));
                                    setUserTankerPrice(getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.waterTankerPrice.default'))
                                    appendChanged('naturalstp.waterTankerPrice.user', undefined);

                                }}
                            />
                            <Typography style={{ marginLeft: '20%' }}>Rs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={4}>
                    <Grid xs={12} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Constants</Typography>
                    </Grid>
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid xs={12}>
                            <Typography color="textSecondary" variant="h6" style={{ marginTop: '2%' }}>
                                {'CO2e Emission Factor'}
                            </Typography>
                            <Grid xs={12} style={{ display: 'flex' }}>
                                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                                    <Typography>{getDefaultValueFromId(selectedInterventionDetails, 'naturalstp.coTwoEmissionFactor')}</Typography>
                                    <Typography style={{ marginLeft: '15%' }}>kg CO2e/kWh</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <ShowSaveAndCancel
                        handleClose={onClose}
                        handleSave={handleSave}
                    />
                </Grid>
            </Grid >
        </>
    );

};
export const NstpProjectInputParameters = (props) => {
    const { selectedInterventionDetails, onClose, } = props;
    const classes = useStyles();
    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Studio</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.studio') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.single') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.double') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.three') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.four') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.onebhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.twobhk') || 0} units</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.threebhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
                    <Typography className={classes.textHeader3}>{getValueFromId(selectedInterventionDetails, 'pip.penthouse.fourbhk') || 0} units</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={onClose}
                />
            </Grid>
        </Grid>
    )


};