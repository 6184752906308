import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';

export const HWMClubHouseCustomBasicParameters = (props) => {
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const classes = useStyles();
    const userswhcapcity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhCapacity')
    const optionforswhClubhouse = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.options')
    const swhTypeClubhouse = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhType')
    const hwmClubhouseswhVariant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhVariant')
    const hwmhpTypeClubhouse = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.HpType')
    const hwmhpheatingClubhouse = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.heatingSpeed')
    const individualswhproductcapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhindividualProductCapacity')
    const individualhpproductcapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.hpindividualProductCapacity')
    const swhnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhnumberOfProducts')
    const hpnumberofproducts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.hpnumberOfProducts')
    const swharearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.swhareaRequired')
    const hparearequired = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.hpareaRequired')
    const hwmClubhouseuserHpCapacity = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.userHpCapacity')
    const [hwmClubhouseOption, setHwmClubhouseOption] = useState('')
    const [swhType, setSwhType] = useState('')
    const [swhTechnology, setSwhTechnology] = useState('')
    const [hpType, setHpType] = useState('')
    const [hpTemp, setHpTemp] = useState('')

    useEffect(() => {
        if (optionforswhClubhouse?.value === "solar water heater") {
            setHwmClubhouseOption('solar water heater')
        } else if (optionforswhClubhouse.value === 'heat pump') {
            setHwmClubhouseOption('heat pump')
        }
        if (swhTypeClubhouse?.value === 'individual') {
            setSwhType('individual')
        } else if (swhTypeClubhouse?.value === 'central') {
            setSwhType('central')
        }
        if (hwmClubhouseswhVariant?.value === 'etc') {
            setSwhTechnology('etc')
        } else if (hwmClubhouseswhVariant?.value === "fpc") {
            setSwhTechnology('fpc')
        }
        if (hwmhpTypeClubhouse?.value === "domestic") {
            setHpType('domestic')
        } else if (hwmhpTypeClubhouse?.value === "commercial") {
            setHpType('commercial')
        }
        if (hwmhpheatingClubhouse?.value === 'slow') {
            setHpTemp('slow')
        } else if (hwmhpheatingClubhouse?.value === 'fast') {
            setHpTemp('fast')
        }
    }, [])

    const handleHwmClubhouseOption = (option) => {
        if (option === "solar water heater") {
            if (hwmClubhouseOption !== "solar water heater") {
                setHwmClubhouseOption('solar water heater')
                appendChanged(optionforswhClubhouse.id, 'solar water heater');
            } else {
                setHwmClubhouseOption('')
                appendChanged(optionforswhClubhouse.id, null);
            }
        } else if (option === "heat pump") {
            if (hwmClubhouseOption !== "heat pump") {
                setHwmClubhouseOption('heat pump')
                appendChanged(optionforswhClubhouse.id, 'heat pump');
            } else {
                setHwmClubhouseOption(null)
                appendChanged(optionforswhClubhouse.id, null);
            }
        }
    }
    const handleSWhTypeSWH = (type) => {
        console.log(type, swhType, 'type')
        if (type === 'central') {
            if (swhType === type) {
                setSwhType(null)
                appendChanged(swhTypeClubhouse.id, null);
            } else {
                setSwhTechnology(null)
                appendChanged(hwmClubhouseswhVariant.id, null);
                setSwhType(type)
                appendChanged(swhTypeClubhouse.id, type);
            }
        } else if (type === 'individual') {
            if (swhType === type) {
                setSwhType(null)
                appendChanged(swhTypeClubhouse.id, null);
            } else {
                setSwhType(type)
                appendChanged(swhTypeClubhouse.id, type);
            }
        }
    }
    const handleSWhTechnology = (technology) => {
        if (technology === 'etc') {
            if (swhTechnology === technology) {
                setSwhTechnology(null)
                appendChanged(hwmClubhouseswhVariant.id, null);
            } else {
                setSwhTechnology(technology)
                appendChanged(hwmClubhouseswhVariant.id, technology);
            }
        } else if (technology === 'fpc') {
            if (swhTechnology === technology) {
                setSwhTechnology(null)
                appendChanged(hwmClubhouseswhVariant.id, null);
            } else {
                setSwhTechnology(technology)
                appendChanged(hwmClubhouseswhVariant.id, technology);
            }
        }
    }
    const handleHpType = (type) => {
        if (type === 'commercial') {
            if (hpType === type) {
                setHpType(null)
                appendChanged(hwmhpTypeClubhouse.id, null);
            } else {
                setHpType(type)
                appendChanged(hwmhpTypeClubhouse.id, type);
            }
        } else if (type === 'domestic') {
            if (hpType === type) {
                setHpType(null)
                appendChanged(hwmhpTypeClubhouse.id, null);
            } else {
                setHpType(type)
                appendChanged(hwmhpTypeClubhouse.id, type);
            }
        }
    }
    const handleHpTemp = (temp) => {
        if (temp === 'slow') {
            if (hpTemp === temp) {
                setHpTemp(null)
                appendChanged(hwmhpheatingClubhouse.id, null);
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheatingClubhouse.id, temp);
            }
        } else if (temp === 'fast') {
            if (hpTemp === temp) {
                setHpTemp(null)
                appendChanged(hwmhpheatingClubhouse.id, null);
            } else {
                setHpTemp(temp)
                appendChanged(hwmhpheatingClubhouse.id, temp);
            }
        }
    }
    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };
    console.log(myArray, 'myArray')

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Typography className={classes.textHeader1}>
                        {hwmClubhouseOption === "solar water heater" ?
                            <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmClubhouseOption('solar water heater')} /> :
                            <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmClubhouseOption('solar water heater')} />}
                        Solar Water Heater</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.textHeader1}>
                        {hwmClubhouseOption === "heat pump" ? <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmClubhouseOption('heat pump')} />
                            : <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmClubhouseOption('heat pump')} />}
                        Heat Pump</Typography>
                </Grid>
            </Grid>
            {hwmClubhouseOption === 'solar water heater' && <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                    <Grid xs>
                        <Typography className={classes.textheader2}>
                            Solar Water Heater Capacity
                        </Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d.id === userswhcapcity.id)
                                    ? userswhcapcity.value
                                    : myArray.find((d) => d.id === userswhcapcity.id) && myArray.find((d) => d.id === userswhcapcity.id).value
                                }
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(userswhcapcity.id, Number(e.target.value));
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(userswhcapcity.id, Number(userswhcapcity?.defaultValue))}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">LPD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} container alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Type</Typography>
                        <Typography className={classes.textHeader3}>
                            {swhType === 'central' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />}
                            {swhType !== 'central' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />}
                            Central</Typography>
                        <Typography className={classes.textHeader3}>
                            {swhType === 'individual' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />}
                            {swhType !== 'individual' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />}
                            Individual</Typography>
                    </Grid>
                    {swhType === 'central' && <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Technology</Typography>
                        <Typography className={classes.textHeader3} style={{ color: "rgba(158, 158, 158, 1)" }}>
                            <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                            ETC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                                placement="right"
                            />
                        </Typography>
                        <Typography className={classes.textHeader3} style={{ color: "rgba(158, 158, 158, 1)" }}>
                            <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                            FPC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                                placement="right"
                            />
                        </Typography>
                    </Grid>}
                    {swhType !== 'central' && <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Technology</Typography>
                        <Typography className={classes.textHeader3}>
                            {swhTechnology === 'etc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                            {swhTechnology !== 'etc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />}
                            ETC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                                placement="right"
                            />
                        </Typography>
                        <Typography className={classes.textHeader3}>
                            {swhTechnology === 'fpc' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                            {swhTechnology !== 'fpc' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />}
                            FPC
                            <CustomizedTooltips
                                icon="info"
                                title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                                placement="right"
                            />
                        </Typography>
                    </Grid>}
                </Grid>
            </>}
            {hwmClubhouseOption === 'heat pump' && <>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                    <Grid xs>
                        <Typography className={classes.textheader2}>
                            Heat Pump Capacity
                        </Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={myArray && !myArray.find((d) => d.id === hwmClubhouseuserHpCapacity.id)
                                    ? hwmClubhouseuserHpCapacity.value
                                    : myArray.find((d) => d.id === hwmClubhouseuserHpCapacity.id) && myArray.find((d) => d.id === hwmClubhouseuserHpCapacity.id).value
                                }
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    appendChanged(hwmClubhouseuserHpCapacity.id, Number(e.target.value))
                                }}
                                fullWidth
                                required
                                onClick={() => appendChanged(hwmClubhouseuserHpCapacity.id, Number(hwmClubhouseuserHpCapacity?.defaultValue))} disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">LPH</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} container alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Type</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpType === 'commercial' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('commercial')} />}
                            {hpType !== 'commercial' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('commercial')} />}
                            Commercial</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpType === 'domestic' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('domestic')} />}
                            {hpType !== 'domestic' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />}
                            Domestic</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textheader2}>Heating</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpTemp === 'slow' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                            {hpTemp !== 'slow' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />}
                            Slow Heating</Typography>
                        <Typography className={classes.textHeader3}>
                            {hpTemp === 'fast' && <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                            {hpTemp !== 'fast' && <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />}
                            Fast Heating</Typography>
                    </Grid>
                </Grid>
            </>}
            {hwmClubhouseOption === 'solar water heater' && <Grid container item xs={12} direction="row">
                <Grid item xs={6}>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{individualswhproductcapacity?.value} LPD</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3} >No. of Products</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{swhnumberofproducts?.value} nos.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Area Required</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmClubhouseareaRequired?.value))} ${hwmClubhouseareaRequired?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{swharearequired?.value} sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            {hwmClubhouseOption === 'heat pump' && <Grid container item xs={12} direction="row">
                <Grid item xs={6}>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Individual Product Capacity</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{individualhpproductcapacity?.value} LPH</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3} >No. of Products</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{hpnumberofproducts?.value} nos.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems='flex-start' style={{ paddingTop: "3%" }}>
                        <Grid item xs={6}>
                            <Typography className={classes.textHeader3}>Area Required</Typography>
                            {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmClubhouseareaRequired?.value))} ${hwmClubhouseareaRequired?.unit}`}</Typography> */}
                            <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{hparearequired?.value} sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid>
    );
};

export const HWMClubHouseCustomAdvancesParameters = (props) => {
    const classes = useStyles();
    const { selectedInterventionDetails, myArray, setMyArray, onClose, handleSave } = props
    const hwmdemandAssumption = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.lpcdPerOccupant')
    const hwmelecostperkwh = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.ElectricityCostperkWh')
    const rooftopareaperswhlpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.rooftopAreaPerSWHLPD')
    const rooftopareaperhplpd = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.rooftopAreaPerHPLPD')
    const hotwaterusageshours = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.hotwaterusuageHours')
    const numberofplumbingshafts = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.numberofPlumbingShafts')
    const co2emissionfactor = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.co2emmissionfactor')
    const capexmultipliersource = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.multipliersource')
    const customcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.CustomCapexMultiplier')
    const vendorcapexmultiplier = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.vendorCapexMultiplier')
    const poolareaperoccupant = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'hwmClubHouse.poolareaperoccupant')

    const [assumptionperoccupant, setAssumptionperoccupant] = useState(0)
    const [vendorTextField, selectVendorTextField] = useState(false)
    const [customTextField, setCustomTextField] = useState(false)
    const [vendorCapex, setVendorCapex] = useState(0)
    const [customCapex, setCustomcapex] = useState(0)
    const [electricityCost, setElectricityCost] = useState(0)
    const [rftopAreaperSWhLPD, setRftopAreaperSWhLPD] = useState(0)
    const [rftopAreaperHPLPD, setRftopAreaperHPLPD] = useState(0)
    const [hwUsageHours, setHwUsageHours] = useState(0)
    const [numberofPlumbingShafts, setNumberofPlumbingShafts] = useState(0)
    const [poolAreaPerocuupant, setPoolAreaPerOccupant] = useState(0)

    const appendChanged = (id, value) => {
        const body = { id: id, value: !value ? 0 : value };
        let n =
            myArray &&
            myArray.find((data) => {
                return data.id === id;
            });
        if (n) {
            n.value = value;
            setMyArray((oldArray) => [...oldArray]);
        } else setMyArray((oldArray) => [...oldArray, body]);
    };

    useEffect(() => {
        if (hwmdemandAssumption) {
            setAssumptionperoccupant(hwmdemandAssumption.value)
        }
        if (hwmelecostperkwh && hwmelecostperkwh.value) {
            setElectricityCost(hwmelecostperkwh.value)
        }
        //
        if (rooftopareaperswhlpd && rooftopareaperswhlpd.value) {
            setRftopAreaperSWhLPD(rooftopareaperswhlpd.value)
        }
        if (rooftopareaperhplpd && rooftopareaperhplpd.value) {
            setRftopAreaperHPLPD(rooftopareaperhplpd.value)
        }
        if (hotwaterusageshours && hotwaterusageshours.value) {
            setHwUsageHours(hotwaterusageshours.value)
        }
        if (numberofplumbingshafts && numberofplumbingshafts.value) {
            setNumberofPlumbingShafts(numberofplumbingshafts.value)
        }
        if (poolareaperoccupant && poolareaperoccupant.value) {
            setPoolAreaPerOccupant(poolareaperoccupant.value)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'CUSTOM') {
            setCustomTextField(true)
        }
        if (capexmultipliersource && capexmultipliersource.value === 'VENDOR') {
            selectVendorTextField(true)
        }
    }, [])

    return (
        <Grid container direction="column" alignItems="left" item xs={12}>
            <Grid container item xs={12} direction="column" style={{ paddingTop: "3%" }}>
                <Typography className={classes.textHeader1}>Demand Assumption</Typography>
                <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
                    <Grid xs>
                        <Typography className={classes.textHeader3}>Per Occupant</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={assumptionperoccupant}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setAssumptionperoccupant(e.target.value);
                                    appendChanged(hwmdemandAssumption.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={() => setAssumptionperoccupant(0)}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">LPCD</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Intervention Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes will only affect this intervention </Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {vendorTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    appendChanged(capexmultipliersource.id, '')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    selectVendorTextField(true)
                                    appendChanged(capexmultipliersource.id, 'VENDOR')
                                }} />}
                            Selected Vendor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <Typography className={classes.textconstants} >{numberWithCommas(vendorcapexmultiplier?.value)} RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader3}>
                            {customTextField ?
                                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => {
                                    setCustomTextField(false)
                                    appendChanged(capexmultipliersource.id, '')
                                }} /> :
                                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => {
                                    selectVendorTextField(false)
                                    setCustomTextField(true)
                                    appendChanged(capexmultipliersource.id, 'CUSTOM')
                                }} />}
                            Custom</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={customCapex}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCustomcapex(e.target.value);
                                    appendChanged(customcapexmultiplier.id, e.target.value)
                                }}
                                fullWidth
                                required
                                onClick={() => setCustomcapex(customcapexmultiplier?.defaultValue)}
                                disabled={!customTextField ? true : false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">RS</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Project Cost Assumptions
                <CustomizedTooltips
                    icon="info"
                    title={<Typography>Changes may affect other project or intervention values</Typography>}
                    placement="right"
                />
            </Typography>
            <Typography className={classes.textHeader4}>Electricity Cost per kWh</Typography>
            <Grid item xs={4} direction="column" style={{ paddingTop: "10px" }}>
                <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                    <Grid xs={7}>
                        <ResetFields
                            id="outlined-basic"
                            variant="outlined"
                            value={electricityCost}
                            placeholder=""
                            onChange={(e) => {
                                e.preventDefault();
                                setElectricityCost(e.target.value);
                                appendChanged(hwmelecostperkwh.id, e.target.value);
                            }}
                            fullWidth
                            required
                            onClick={(e) => {
                                e.preventDefault()
                                setElectricityCost(hwmelecostperkwh?.defaultValue)
                                appendChanged(hwmelecostperkwh && hwmelecostperkwh.id, hwmelecostperkwh?.defaultValue)
                            }}
                            disabled={false}
                        />
                    </Grid>
                    <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                        <Typography variant="h6">RS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.textHeader1} style={{ paddingTop: "3%" }}>Constants</Typography>
            <Grid container item xs={12}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Rooftop Area per SWH LPD</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={rftopAreaperSWhLPD}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRftopAreaperSWhLPD(e.target.value);
                                    appendChanged(rooftopareaperswhlpd.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRftopAreaperSWhLPD(rooftopareaperswhlpd?.defaultValue)
                                    appendChanged(rooftopareaperswhlpd && rooftopareaperswhlpd.id, rooftopareaperswhlpd?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>Rooftop Area per HP LPD</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={rftopAreaperHPLPD}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setRftopAreaperHPLPD(e.target.value);
                                    appendChanged(rooftopareaperhplpd.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRftopAreaperHPLPD(rooftopareaperhplpd?.defaultValue)
                                    appendChanged(rooftopareaperhplpd && rooftopareaperhplpd.id, rooftopareaperhplpd?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Hot Water Usage Hours</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={hwUsageHours}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setHwUsageHours(e.target.value);
                                    appendChanged(hotwaterusageshours.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setHwUsageHours(hotwaterusageshours?.defaultValue)
                                    appendChanged(hotwaterusageshours && hotwaterusageshours.id, hotwaterusageshours?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">hrs</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "10px" }}>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Number of Plumbing Shafts</Typography>
                        <CustomizedTooltips
                            icon="info"
                            title={<Typography>Used for calculating heat loss from standby water</Typography>}
                            placement="right"
                        />
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={numberofPlumbingShafts}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setNumberofPlumbingShafts(e.target.value);
                                    appendChanged(numberofplumbingshafts.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setNumberofPlumbingShafts(numberofplumbingshafts?.defaultValue)
                                    appendChanged(numberofplumbingshafts && numberofplumbingshafts.id, numberofplumbingshafts?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">nos</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.textHeader5}>Pool Area per Occupant</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <ResetFields
                                id="outlined-basic"
                                variant="outlined"
                                value={poolAreaPerocuupant}
                                placeholder=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setPoolAreaPerOccupant(e.target.value);
                                    appendChanged(poolareaperoccupant.id, e.target.value);
                                }}
                                fullWidth
                                required
                                onClick={(e) => {
                                    e.preventDefault()
                                    setPoolAreaPerOccupant(poolareaperoccupant?.defaultValue)
                                    appendChanged(poolareaperoccupant && poolareaperoccupant.id, poolareaperoccupant?.defaultValue)
                                }}
                                disabled={false}
                            />
                        </Grid>
                        <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                            <Typography variant="h6">Sqm</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} direction="column">
                    <Grid container item xs={12} style={{ paddingBottom: "10px" }}>
                        <Typography className={classes.textHeader5}>CO2e Emission Factor</Typography>
                    </Grid>
                    <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: "flex", alignItems: "center" }}>
                        <Grid xs={7}>
                            <Typography className={classes.textconstants} >{numberWithCommas(co2emissionfactor?.value)} kg CO2e/kWh</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                    handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid >
    );
};

export const HWMClubHouseProjectInputs = (props) => {
    const classes = useStyles()
    const { selectedInterventionDetails, onClose } = props
    const rooftoparea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.clubhouse.rooftop.area')
    const swimmingpoolsurfacearea = selectedInterventionDetails && selectedInterventionDetails.find((ele) => ele.id === 'pip.pool')

    return (
        <Grid container item xs={12} direction="column">
            <Typography className={classes.textHeader1}>Project Inputs</Typography>
            <Grid container item xs={12} style={{ paddingTop: "1%" }}>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Club House Rooftop Area</Typography>
                    <Typography className={classes.textHeader3}>{rooftoparea?.value} Sqm</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textHeader5}>Swimming Pool Surface Area</Typography>
                    <Typography className={classes.textHeader3}>{swimmingpoolsurfacearea?.value} Sqm</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                <ShowSaveAndCancel
                    handleClose={onClose}
                // handleSave={handleSave}
                // minError={minError && minError}
                // maxError={maxError && maxError}
                />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    textHeader1: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19.2px',
        letterSpacing: '0.71px',
        color: 'rgba(46, 46, 46, 1)',
        textAlign: 'left',
        display: "flex",
        alignItems: "center"
    },
    nonselectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedCheckbox: {
        color: "rgba(102, 227, 190, 1)",
        fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textheader2: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.7px',
        color: 'rgba(46, 46, 46, 1)',
        paddingTop: '10%',
    },
    nonselectedRadiobox: {
        // color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    selectedRadiobox: {
        color: "rgba(102, 227, 190, 1)",
        // fontSize: "25px",
        marginRight: "10px",
        cursor: "pointer"
    },
    textHeader3: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px"
    },
    textHeader3disabled: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(158, 158, 158, 1)"
    },
    textHeader4: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.71px',
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    textHeader5: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.71px',
        textAlign: 'left',
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        color: "rgba(102, 102, 102, 1)"
    },
    nonselectedRadioboxdisabled: {
        color: "rgba(158, 158, 158, 1)",
        marginRight: "10px",
    },
}));
