import { useState, useEffect } from 'react';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
// import { categoryDetailsQuery,  } from '../graphql-queries/ProductDetails.query';
import { interventionDetailsQuery } from '../graphql-queries/InterventionDetails.query';

const useInterventionDetails = (identifiers) => {
    const [productDetailsData, setProductDetailsData] = useState('');
    console.log(identifiers, 'shortlistedProducts')
    const [productDetails, SendRroductDetailsRequest] = useGraphqlRequest(interventionDetailsQuery(identifiers));

    const fetchData = () => {
        SendRroductDetailsRequest();
    };
    useEffect(() => {
        if (productDetails && productDetails?.response !== null) setProductDetailsData(productDetails.response?.data?.data?.getProducts);
    }, [productDetails]);

    return { productDetailsData, fetchData };
};

export default useInterventionDetails;
