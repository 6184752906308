import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Switch, Card } from '@material-ui/core';
import wasteIcon from '../../../assets/GOALS/goalcategoryimages/WasteGoalIcon.svg'
import InfoIcon from '@material-ui/icons/Info';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgressTwo = withStyles(() => ({
    root: {
        height: 16,
        background: '#E5E5E5',
        borderRadius: 30,
    },
    bar: {
        borderRadius: 30,
        background: `#EDCDFF`,
    },
}))(LinearProgress);

const BorderLinearProgressThree = withStyles(() => ({
    root: {
        height: 16,
        background: '#E5E5E5',
        borderRadius: 30,
    },
    bar: {
        borderRadius: 30,
        background: `#E5E5E5`,
    },
}))(LinearProgress);

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 29,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const AntSwitchTwo = withStyles((theme) => ({
    root: {
        width: 29,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#CCCCCC',
                borderColor: '#CCCCCC',
            },
        },
    },
    thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #CCCCCC`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#CCCCCC',
    },
    checked: {},
}))(Switch);




export const VariantCommon = ({ progressValue, background, barBackground, style }) => {
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 16,
            background: background,
            borderRadius: 30,
        },
        bar: {
            borderRadius: 30,
            background: barBackground,
        },
    }))(LinearProgress);
    return <BorderLinearProgress variant="determinate" value={progressValue} style={style} />
}

// for top goals part // Final_______________-
export const VariantOne = ({ border, background, icon, title, text, progressValue, barBackground, barText }) => {
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 16,
            background: '#E5E5E5',
            borderRadius: 30,
        },
        bar: {
            borderRadius: 30,
            background: barBackground,
        },
    }))(LinearProgress);
    return (
        <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', background: background, height: 200, width: 250 }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='flex-end' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '3%' }} ><InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} /></Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ height: 60, marginBottom: '3%' }}><img src={icon} alt='waste icon' /></Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingTop: '2%' }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#000000' }}>
                        <b>{title}</b>
                    </Typography>
                </Grid>
                {text && progressValue && <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingTop: '20%' }}>
                    <Typography variant='h6' style={{ textAlign: 'center', }}>
                        <b>{text}</b>
                    </Typography>
                </Grid>}
                {text && progressValue && <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingTop: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <BorderLinearProgress variant="determinate" value={progressValue} style={{ flexGrow: '1', }} />
                        {<Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                                position: 'absolute',
                                top: 5,
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                fontSize: '10px',
                            }}
                        >
                            <b>{`${barText}%`}</b>
                        </Typography>}
                    </div>

                </Grid>}
            </Grid>
        </Paper>
    )
}
// Final_______________-
export const VariantFour = ({ border, title, tier, text, progressValue, barBackground, barText, disableBartext }) => {
    const BorderLinearProgressTwo = withStyles(() => ({
        root: {
            height: 16,
            background: '#E5E5E5',
            borderRadius: 30,
        },
        bar: {
            borderRadius: 30,
            background: barBackground,
        },
    }))(LinearProgress);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    return (
        <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', height: 200, width: 250, marginBottom: '10%' }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='center' justifyContent='center' style={{ height: 20 }}>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-start' style={{ paddingLeft: '3%', paddingTop: '1%' }}>
                        <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '2%', }}>
                        <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ height: 100 }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#000000' }}>
                        <b>{title}</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='flex-start' style={{ paddingLeft: '2%', height: 52 }}>
                    <Grid item xs={4} container alignItems='center' justifyContent='flex-start'>
                        <Paper variant="outlined" elevation={3} style={{ border: '1px solid #26E0A3', width: 40, height: 'auto', padding: '3px', backgroundColor: '#F0FBF2' }}>
                            <Typography variant='h6' style={{ textAlign: 'center', color: '#000000', fontSize: '8px', }}>
                                {tier}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} container alignItems='center' justifyContent='flex-start'>
                        <Typography style={{ textAlign: 'center', }} variant='h6'>
                            <b>{text}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingLeft: '1%', paddingRight: '1%', paddingTop: '3%' }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <BorderLinearProgressTwo variant="determinate" value={progressValue} style={{ flexGrow: '1', }} />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                                display: disableBartext && 'none',
                                position: 'absolute',
                                top: 5,
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                fontSize: '10px',
                            }}
                        >
                            <b>{`${barText}%`}</b>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Paper>

    )
}
// Final_______________-
export const VariantFourDisabled = ({ title, tier, text, progressValue, barText }) => {
    const BorderLinearProgressTwo = withStyles(() => ({
        root: {
            height: 16,
            background: '#E5E5E5',
            borderRadius: 30,
        },
        bar: {
            borderRadius: 30,
            background: '#CCCCCC',
        },
    }))(LinearProgress);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    return (
        <Paper variant="outlined" elevation={3} style={{ border: '2px solid #CCCCCC', borderRadius: '11px', height: 200, width: 250, marginBottom: '10%' }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='center' justifyContent='center' style={{ height: 20 }}>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-start' style={{ paddingLeft: '3%', paddingTop: '1%' }}>
                        <AntSwitchTwo checked={false} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '2%', }}>
                        <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ height: 100 }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#CCCCCC' }}>
                        <b>{title}</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='flex-start' style={{ paddingLeft: '2%', height: 52 }}>
                    <Grid item xs={4} container alignItems='center' justifyContent='flex-start'>
                        <Paper variant="outlined" elevation={3} style={{ border: '1px solid #CCCCCC', width: 40, height: 'auto', padding: '3px', backgroundColor: '#F0FBF2' }}>
                            <Typography variant='h6' style={{ textAlign: 'center', color: '#000000', fontSize: '8px', }}>
                                {tier}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} container alignItems='center' justifyContent='flex-start'>
                        <Typography style={{ textAlign: 'center', }} variant='h6'>
                            <b>{text}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingLeft: '1%', paddingRight: '1%', paddingTop: '3%' }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <BorderLinearProgressTwo variant="determinate" value={progressValue} style={{ flexGrow: '1', }} />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                                position: 'absolute',
                                top: 5,
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                fontSize: '10px',
                            }}
                        >
                            <b>{`${barText}%`}</b>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Paper>

    )

}


// Final_______________-
export const VariantSeven = ({ title, border }) => {
    return (
        <Paper variant="outlined" elevation={3} style={{ border: border, borderRadius: '11px', height: 200, width: 250 }}>
            <Grid container justifyContent='center' alignItems='center' >
                <Grid container alignItems='center' justifyContent='center'
                    style={{ marginTop: '25%' }}
                >
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#CCCCCC' }}>
                        <b>{title}</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center'
                    style={{ marginTop: '25%' }}
                >
                    <Typography variant='h6' style={{ textAlign: 'center', fontSize: '10px', color: '#CCCCCC' }}>
                        Not achievable for this project
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}


export const VariantThree = () => {
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    return (
        <Paper variant="outlined" elevation={3} style={{ border: '2px solid #916AAC', borderRadius: '11px', height: 200, width: 250 }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='center' justifyContent='center' >
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-start' style={{ paddingLeft: '3%', paddingTop: '1%' }}>
                        <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '2%', }}>
                        <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '11%', }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#000000' }}>
                        <b>Net Zero Embodied Carbon</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='flex-start' style={{ height: 70, paddingLeft: '2%', }} >
                    <Paper variant="outlined" elevation={3} style={{ border: '1px solid #916AAC', width: 25, height: 'auto', padding: '3px' }}>
                        <Typography variant='h6' style={{ textAlign: 'center', color: '#000000', fontSize: '8px' }}>
                            Tier 1
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <BorderLinearProgressTwo variant="determinate" value={50} style={{ flexGrow: '1', marginTop: '3%', }} />
                </Grid>
            </Grid>
        </Paper>

    )
}



export const VariantFive = () => {
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    return (
        <Paper variant="outlined" elevation={3} style={{ border: '2px solid #CCCCCC', borderRadius: '11px', height: 200, width: 250 }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='center' justifyContent='center' >
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-start' style={{ paddingLeft: '3%', paddingTop: '1%' }}>
                        <AntSwitchTwo checked={false} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '2%', }}>
                        <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '11%', }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#CCCCCC' }}>
                        <b>Net Zero Embodied Carbon</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='flex-start' style={{ height: 70, paddingLeft: '2%', }} >
                    <Paper variant="outlined" elevation={3} style={{ border: '1px solid #CCCCCC', width: 25, height: 'auto', padding: '3px' }}>
                        <Typography variant='h6' style={{ textAlign: 'center', color: '#000000', fontSize: '8px' }}>
                            Tier 1
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <BorderLinearProgressThree variant="determinate" value={50} style={{ flexGrow: '1', marginTop: '3%', }} />
                </Grid>
            </Grid>
        </Paper>

    )
}

export const VariantSix = () => {
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    return (
        <Paper variant="outlined" elevation={3} style={{ border: '2px solid #CCCCCC', borderRadius: '11px', height: 200, width: 250 }}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid container alignItems='center' justifyContent='center' >
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-start' style={{ paddingLeft: '3%', paddingTop: '1%' }}>
                        <AntSwitchTwo checked={false} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid xs={6} item container alignItems='center' justifyContent='flex-end' style={{ paddingRight: '5%', paddingTop: '2%', }}>
                        <InfoIcon style={{ color: '#66E3BE', fontSize: '20px' }} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '11%', }}>
                    <Typography variant='h6' style={{ textAlign: 'center', color: '#CCCCCC' }}>
                        <b>Net Zero Embodied Carbon</b>
                    </Typography>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='flex-start' style={{ height: 70, paddingLeft: '2%', }} >
                    <Grid item xs={3} container alignItems='center' justifyContent='flex-start'>
                        <Paper variant="outlined" elevation={3} style={{ border: '1px solid #CCCCCC', width: 25, height: 'auto', padding: '3px', }}>
                            <Typography variant='h6' style={{ textAlign: 'center', color: '#000000', fontSize: '8px' }}>
                                Tier 1
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} container alignItems='center' justifyContent='flex-start'>
                        <Typography style={{ textAlign: 'center', fontSize: '9px', color: '#CCCCCC' }}>
                            <b>+122 Rs/Sqft for 100%</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems='flex-end' justifyContent='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <BorderLinearProgressThree variant="determinate" value={50} style={{ flexGrow: '1', marginTop: '3%', }} />
                </Grid>
            </Grid>
        </Paper>

    )
}



export const Goals = () => {
    return (
        <>
            <VariantOne />
            <VariantThree />
            <VariantFour />
            <VariantFive />
            <VariantSix />
            <VariantSeven />
        </>
    )
}



export const dummyGoalsResponseForGoalsCreate = {
    "success": true,
    "data": {
        "version": "v1",
        "data": {
            "id": "661a393bb77e55e58247baa7",
            "name": "rishabh apr 13",
            "category": "residential",
            "createdBy": "601799db12ee04002364d2c9",
            "location": {
                "name": "HSR Layout, HAL 3rd Stage, Bhoomi Reddy Colony, New Tippasandra, Bengaluru, Karnataka 560075, India",
                "type": "Point",
                "coordinates": [
                    12.9741315,
                    77.6563865
                ]
            },
            "organization": {
                "uri": ""
            },
            "uri": "",
            "subcategory": "apartment",
            "goals": {
                "categories": {
                    "energy": {
                        "goal": {
                            "name": "Net Zero Energy",
                            "description": "Net Zero Energy buildings generate as much energy as they consume, resulting in a net-zero balance between energy production and consumption.",
                            "progress": 2,
                            "costPerSqFt": 37.69511532751566,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Maximize Onsite Renewable Energy",
                                "maxRenewableEnergySavings": 85371,
                                "maxContributionPercentage": 2.272976576661008,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 100,
                                "status": "Achieved",
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            },
                            {
                                "name": "Common Area Energy Neutral",
                                "maxRenewableEnergySavings": 85371,
                                "maxContributionPercent": 2.272976576661008,
                                "maxPotentialPercent": 2.272976576661008,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 2.272976576661008,
                                "status": "In Progress",
                                "achievability": "No",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            }
                        ]
                    },
                    "water": {
                        "goal": {
                            "name": "Net Zero Water",
                            "description": "Net Zero Water for a building involves strategies to minimize water usage and maximize reuse, aiming to balance water consumption with an equal return to the local watershed or aquifer, ultimately achieving a net-zero impact on water resources.",
                            "progress": 85,
                            "costPerSqFt": 236.9833798022957,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Complete Smart Water Metering",
                                "maxContributionPercent": 2.6318368261167806,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 100,
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            }
                        ]
                    },
                    "waste": {
                        "goal": {
                            "name": "Net Zero Waste",
                            "description": "Net Zero Waste in a building aims to reduce waste generation, increase recycling, and minimize landfill contributions, ultimately striving for a net-zero impact on waste disposal.",
                            "progress": 85,
                            "costPerSqFt": 236.9833798022957,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Sustainable Hazardous Waste Disposal",
                                "maxContributionPercent": 2.6318368261167806,
                                "additionalCost": 0,
                                "tier": "Tier II",
                                "progress": 100,
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            }
                        ]
                    },
                    "embodiedCarbon": {
                        "goal": {
                            "name": "Net Zero Embodied Carbon",
                            "description": "Net Zero Embodied Carbon in construction minimizes carbon emissions from building materials and processes, aiming for a net-zero impact on the building's carbon footprint.",
                            "progress": 527,
                            "costPerSqFt": 37.69511532751566,
                            "status": "achieved"
                        },
                        "targets": [
                            {
                                "name": "Core & Shell Alternative Materials",
                                "list": "Structural Steel, Cement",
                                "maxMaterialSavings": 1944,
                                "maxContributionPercent": 526.829268292683,
                                "additionalCost": 232038034,
                                "tier": "Tier IV",
                                "progress": 526.829268292683,
                                "status": "Achieved",
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 4201,
                                "state": true
                            }
                        ]
                    },
                    "scorecard": {
                        "goals": {
                            "achieved": 1,
                            "targeted": 4
                        },
                        "targets": {
                            "achieved": 2,
                            "targeted": 4
                        },
                        "capexIncrease": 549.3569902596228
                    }
                }
            },
            "interventions": {
                "quantities": {
                    "overall": {
                        "finance": {
                            "capex": {
                                "id": "project.Capex",
                                "name": "project.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 9542932,
                                "defaultValue": 0
                            },
                            "capexConverted": {
                                "id": "project.CapexConverted",
                                "name": "project.CapexConverted",
                                "label": "",
                                "tags": [
                                    "CapexConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs/Sqft",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 16,
                                "defaultValue": 0
                            },
                            "opexSavings": {
                                "id": "project.Opex",
                                "name": "project.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "irr": {
                                "id": "project.Irr",
                                "name": "project.Irr",
                                "label": "",
                                "tags": [
                                    "Irr",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "%",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "paybackPeriod": {
                                "id": "project.PaybackPeriod",
                                "name": "project.PaybackPeriod",
                                "label": "",
                                "tags": [
                                    "PaybackPeriod",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "years",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 2.36,
                                "defaultValue": 0
                            },
                            "overallExpenses": {
                                "id": "project.OverallExpenses",
                                "name": "project.OverallExpenses",
                                "label": "",
                                "tags": [
                                    "OverallExpenses",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "totalCapex": {
                                "id": "project.TotalCapex",
                                "name": "project.TotalCapex",
                                "label": "",
                                "tags": [
                                    "_TotalCapex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 15893592,
                                "defaultValue": 0
                            },
                            "totalCapexConverted": {
                                "id": "project.TotalCapexConverted",
                                "name": "project.TotalCapexConverted",
                                "label": "",
                                "tags": [
                                    "_TotalCapexConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs/Sqft",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 27,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "carbon": {
                                "id": "project.Carbon",
                                "name": "project.Carbon",
                                "label": "",
                                "tags": [
                                    "Carbon",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "tCO2e",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 113,
                                "defaultValue": 0
                            },
                            "carbonConverted": {
                                "id": "project.CarbonConverted",
                                "name": "project.CarbonConverted",
                                "label": "",
                                "tags": [
                                    "CarbonConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "cars off the road for a year",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 25,
                                "defaultValue": 0
                            }
                        }
                    }
                },
                "categorized": [
                    {
                        "name": "Waste",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "waste.Capex",
                                    "name": "waste.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 220000,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "waste.CapexConverted",
                                    "name": "waste.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 0,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "waste.Opex",
                                    "name": "waste.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 34595,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "waste.CapexTotal",
                                    "name": "waste.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 722000,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "waste.CapexTotalConverted",
                                    "name": "waste.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 1,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "waste.Savings",
                                    "name": "waste.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kg",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 104740,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "waste.SavingsConverted",
                                    "name": "waste.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Waste Trucks Avoided",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "waste.Carbon",
                                    "name": "waste.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "waste.CarbonConverted",
                                    "name": "waste.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 2,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "OrganicWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 198,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "DryWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 66,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "SanitaryWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "MedicalWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "E_Waste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 7,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "RejectWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 45,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "right": {
                                    "Biogas": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 198,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Compost": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "OnsiteIncinerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "OffsiteIncinerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Recycler": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Landfill": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 45,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Biogas",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Compost",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "OnsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "DryWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 66,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Biogas",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Compost",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "OnsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "MedicalWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "E_Waste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 7,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "RejectWaste",
                                        "right": "Landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 45,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Management",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.management.Capex",
                                            "name": "waste.management.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.management.CapexConverted",
                                            "name": "waste.management.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.management.Opex",
                                            "name": "waste.management.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.management.CapexTotal",
                                            "name": "waste.management.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 22000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.management.CapexTotalConverted",
                                            "name": "waste.management.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.management.Savings",
                                            "name": "waste.management.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 31740,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.management.Carbon",
                                            "name": "waste.management.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Collection & Segregation Point",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb28",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.management.casp.Capex",
                                                    "name": "waste.management.casp.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.management.casp.CapexConverted",
                                                    "name": "waste.management.casp.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.management.casp.Opex",
                                                    "name": "waste.management.casp.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.management.casp.CapexTotal",
                                                    "name": "waste.management.casp.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.management.casp.CapexTotalConverted",
                                                    "name": "waste.management.casp.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.management.casp.Savings",
                                                    "name": "waste.management.casp.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.management.casp.Carbon",
                                                    "name": "waste.management.casp.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Water_Saved",
                                                    "name": "waste.management.casp.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Water_Opex Savings",
                                                    "name": "waste.management.casp.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Energy_Saved",
                                                    "name": "waste.management.casp.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Energy_Opex Savings",
                                                    "name": "waste.management.casp.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.%_of Total waste",
                                                    "name": "waste.management.casp.%_of Total waste",
                                                    "label": "% of Total waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Dry_Waste as %",
                                                    "name": "waste.management.casp.Dry_Waste as %",
                                                    "label": "Dry Waste as %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 89,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Medical_Waste as %",
                                                    "name": "waste.management.casp.Medical_Waste as %",
                                                    "label": "Medical Waste as %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.E-waste_Waste %",
                                                    "name": "waste.management.casp.E-waste_Waste %",
                                                    "label": "E-waste Waste %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewMin",
                                                    "name": "waste.management.casp.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewMax",
                                                    "name": "waste.management.casp.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewTotal",
                                                    "name": "waste.management.casp.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Biogas",
                                                    "name": "waste.management.casp.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Compost",
                                                    "name": "waste.management.casp.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.on-site_incinerator",
                                                    "name": "waste.management.casp.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.off-site_incinerator",
                                                    "name": "waste.management.casp.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Recycler",
                                                    "name": "waste.management.casp.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26570,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Landfill",
                                                    "name": "waste.management.casp.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Savings",
                                                    "name": "waste.management.casp.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SavingsConverted",
                                                    "name": "waste.management.casp.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexPre",
                                                    "name": "waste.management.casp.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexPost",
                                                    "name": "waste.management.casp.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Capex",
                                                    "name": "waste.management.casp.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexConverted",
                                                    "name": "waste.management.casp.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Opex",
                                                    "name": "waste.management.casp.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Carbon",
                                                    "name": "waste.management.casp.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.IRR",
                                                    "name": "waste.management.casp.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.PaybackPeriod",
                                                    "name": "waste.management.casp.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SdgNumber",
                                                    "name": "waste.management.casp.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SdgText",
                                                    "name": "waste.management.casp.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Locked",
                                                    "name": "waste.management.casp.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Enabled",
                                                    "name": "waste.management.casp.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.GoalTarget",
                                                    "name": "waste.management.casp.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.GoalContribution",
                                                    "name": "waste.management.casp.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexTotal",
                                                    "name": "waste.management.casp.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexTotalConverted",
                                                    "name": "waste.management.casp.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.drywastedaily",
                                                    "name": "collectionandsegregationpoint.outcomes.drywastedaily",
                                                    "label": "Daily Dry Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 65.87774999999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "name": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "label": "Daily Medical Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.9881662499999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "name": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "label": "Daily E-Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6.917163749999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.drywastecontent",
                                                    "name": "collectionandsegregationpoint.constants.drywastecontent",
                                                    "label": "Dry waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.ewastecontent",
                                                    "name": "collectionandsegregationpoint.constants.ewastecontent",
                                                    "label": "E-waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.021,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.medicalwastecontent",
                                                    "name": "collectionandsegregationpoint.constants.medicalwastecontent",
                                                    "label": "Medical waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.003,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorfordrywaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorfordrywaste",
                                                    "label": "Dry Waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorforewaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorforewaste",
                                                    "label": "E-waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorformedicalwaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorformedicalwaste",
                                                    "label": "Medical Waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateDetails",
                                                    "name": "collectionandsegregationpoint.MandateDetails",
                                                    "label": "CASP Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, Bulk generators shall retain Dry waste within the premises and should be handed over to the authorised agency."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateSummary",
                                                    "name": "collectionandsegregationpoint.MandateSummary",
                                                    "label": "CASP Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateGenerals",
                                                    "name": "collectionandsegregationpoint.MandateGenerals",
                                                    "label": "CASP Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.arearequired",
                                                    "name": "collectionandsegregationpoint.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 74,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.drywasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.drywasteannualpresdplus",
                                                    "label": "Dry Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 24045,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.drywasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.drywasteannualpostsdplus",
                                                    "label": "Dry Waste Annual Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 24045,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.ewasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.ewasteannualpresdplus",
                                                    "label": "E Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2525,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.ewasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.ewasteannualpostsdplus",
                                                    "label": "E Waste Annual Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2525,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.medicalwasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.medicalwasteannualpresdplus",
                                                    "label": "Medical Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.medicalwasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.medicalwasteannualpostsdplus",
                                                    "label": "Medical Waste Annual post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.annualwastepresdplus",
                                                    "name": "collectionandsegregationpoint.annualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Sanitary Napkin Incinerator",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb34",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.management.sni.Capex",
                                                    "name": "waste.management.sni.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.management.sni.CapexConverted",
                                                    "name": "waste.management.sni.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.management.sni.Opex",
                                                    "name": "waste.management.sni.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.management.sni.CapexTotal",
                                                    "name": "waste.management.sni.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.management.sni.CapexTotalConverted",
                                                    "name": "waste.management.sni.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.management.sni.Savings",
                                                    "name": "waste.management.sni.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.management.sni.Carbon",
                                                    "name": "waste.management.sni.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Water_Saved",
                                                    "name": "waste.management.sni.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Water_Opex Savings",
                                                    "name": "waste.management.sni.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Energy_Saved",
                                                    "name": "waste.management.sni.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Energy_Opex Savings",
                                                    "name": "waste.management.sni.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.%_of Total Waste",
                                                    "name": "waste.management.sni.%_of Total Waste",
                                                    "label": "% of Total Waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewMin",
                                                    "name": "waste.management.sni.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewMax",
                                                    "name": "waste.management.sni.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewTotal",
                                                    "name": "waste.management.sni.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Biogas",
                                                    "name": "waste.management.sni.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Compost",
                                                    "name": "waste.management.sni.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.on-site_incinerator",
                                                    "name": "waste.management.sni.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.off-site_incinerator",
                                                    "name": "waste.management.sni.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Recycler",
                                                    "name": "waste.management.sni.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Landfill",
                                                    "name": "waste.management.sni.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Savings",
                                                    "name": "waste.management.sni.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SavingsConverted",
                                                    "name": "waste.management.sni.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexPre",
                                                    "name": "waste.management.sni.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexPost",
                                                    "name": "waste.management.sni.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Capex",
                                                    "name": "waste.management.sni.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexConverted",
                                                    "name": "waste.management.sni.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Opex",
                                                    "name": "waste.management.sni.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Carbon",
                                                    "name": "waste.management.sni.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.IRR",
                                                    "name": "waste.management.sni.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.PaybackPeriod",
                                                    "name": "waste.management.sni.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SdgNumber",
                                                    "name": "waste.management.sni.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SdgText",
                                                    "name": "waste.management.sni.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Locked",
                                                    "name": "waste.management.sni.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Enabled",
                                                    "name": "waste.management.sni.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.GoalTarget",
                                                    "name": "waste.management.sni.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.GoalContribution",
                                                    "name": "waste.management.sni.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexTotal",
                                                    "name": "waste.management.sni.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexTotalConverted",
                                                    "name": "waste.management.sni.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.capexmultiplier",
                                                    "name": "sanitarynapkinincinerator.constants.capexmultiplier",
                                                    "label": "Capex (per incinerator system)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 22000
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.VendorCapexMultiplier",
                                                    "name": "sanitarynapkinincinerator.VendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "SanitaryNapkinIncinerator.multipliersource",
                                                    "name": "SanitaryNapkinIncinerator.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.sanitarynapkincontent",
                                                    "name": "sanitarynapkinincinerator.constants.sanitarynapkincontent",
                                                    "label": "Sanitary content per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.emissionfactorforsanitarynapkin",
                                                    "name": "sanitarynapkinincinerator.constants.emissionfactorforsanitarynapkin",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.outcomes.snicapacity",
                                                    "name": "sanitarynapkinincinerator.outcomes.snicapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateDetails",
                                                    "name": "sanitarynapkinincinerator.MandateDetails",
                                                    "label": "SNI Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, bulk generators shall have the sanitary wastes to be separately collected by in-house staff and deposited at the nearest bio-medical waste centre to be specified by the BBMP or arranged by mutual consent."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateSummary",
                                                    "name": "sanitarynapkinincinerator.MandateSummary",
                                                    "label": "SNI Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateGenerals",
                                                    "name": "sanitarynapkinincinerator.MandateGenerals",
                                                    "label": "SNI Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.annualwastepresdplus",
                                                    "name": "sanitarynapkinincinerator.annualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Reduction",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.reduction.Capex",
                                            "name": "waste.reduction.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 220000,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.reduction.CapexConverted",
                                            "name": "waste.reduction.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.reduction.Opex",
                                            "name": "waste.reduction.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 34595,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.reduction.CapexTotal",
                                            "name": "waste.reduction.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 700000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.reduction.CapexTotalConverted",
                                            "name": "waste.reduction.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.reduction.Savings",
                                            "name": "waste.reduction.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 73000,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.reduction.Carbon",
                                            "name": "waste.reduction.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 9,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Wet Waste Management",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb1f",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.reduction.wwb.Capex",
                                                    "name": "waste.reduction.wwb.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.reduction.wwb.CapexConverted",
                                                    "name": "waste.reduction.wwb.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.reduction.wwb.Opex",
                                                    "name": "waste.reduction.wwb.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.reduction.wwb.CapexTotal",
                                                    "name": "waste.reduction.wwb.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.reduction.wwb.CapexTotalConverted",
                                                    "name": "waste.reduction.wwb.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.reduction.wwb.Savings",
                                                    "name": "waste.reduction.wwb.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.reduction.wwb.Carbon",
                                                    "name": "waste.reduction.wwb.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Water_Saved",
                                                    "name": "waste.reduction.wwb.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Water_OPEX Savings",
                                                    "name": "waste.reduction.wwb.Water_OPEX Savings",
                                                    "label": "Water OPEX Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Energy_Saved",
                                                    "name": "waste.reduction.wwb.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Energy_OPEX Savings",
                                                    "name": "waste.reduction.wwb.Energy_OPEX Savings",
                                                    "label": "Energy OPEX Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.%_of Total waste",
                                                    "name": "waste.reduction.wwb.%_of Total waste",
                                                    "label": "% of Total waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 61,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.%_of Common Area Energy",
                                                    "name": "waste.reduction.wwb.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewMin",
                                                    "name": "waste.reduction.wwb.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewMax",
                                                    "name": "waste.reduction.wwb.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewTotal",
                                                    "name": "waste.reduction.wwb.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Biogas",
                                                    "name": "waste.reduction.wwb.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Compost",
                                                    "name": "waste.reduction.wwb.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.on-site_incinerator",
                                                    "name": "waste.reduction.wwb.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.off-site_incinerator",
                                                    "name": "waste.reduction.wwb.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Recycler",
                                                    "name": "waste.reduction.wwb.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Landfill",
                                                    "name": "waste.reduction.wwb.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Savings",
                                                    "name": "waste.reduction.wwb.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SavingsConverted",
                                                    "name": "waste.reduction.wwb.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexPre",
                                                    "name": "waste.reduction.wwb.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 480000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexPost",
                                                    "name": "waste.reduction.wwb.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Capex",
                                                    "name": "waste.reduction.wwb.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexConverted",
                                                    "name": "waste.reduction.wwb.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Opex",
                                                    "name": "waste.reduction.wwb.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Carbon",
                                                    "name": "waste.reduction.wwb.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.IRR",
                                                    "name": "waste.reduction.wwb.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.PaybackPeriod",
                                                    "name": "waste.reduction.wwb.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SdgNumber",
                                                    "name": "waste.reduction.wwb.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SdgText",
                                                    "name": "waste.reduction.wwb.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Locked",
                                                    "name": "waste.reduction.wwb.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Enabled",
                                                    "name": "waste.reduction.wwb.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.GoalTarget",
                                                    "name": "waste.reduction.wwb.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.GoalContribution",
                                                    "name": "waste.reduction.wwb.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 70,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexTotal",
                                                    "name": "waste.reduction.wwb.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexTotalConverted",
                                                    "name": "waste.reduction.wwb.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.outcomes.wwbcapacity",
                                                    "name": "wetwastetobiogas.outcomes.wwbcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 200,
                                                    "defaultValue": 200
                                                },
                                                {
                                                    "id": "wetwastetobiogas.userinput.enduse",
                                                    "name": "wetwastetobiogas.userinput.enduse",
                                                    "label": "End Use",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Natural Composting (upto 1,000 kgs)",
                                                    "defaultValue": "Natural Composting (upto 1,000 kgs)",
                                                    "valueOptions": [
                                                        {
                                                            "option": "Biogas (1,000+ kgs)",
                                                            "enabled": false
                                                        },
                                                        {
                                                            "option": "Biogas to Electricity (1,000+ kgs)",
                                                            "enabled": false
                                                        },
                                                        {
                                                            "option": "Natural Composting (upto 1,000 kgs)",
                                                            "enabled": true
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.capexmultiplier",
                                                    "name": "wetwastetobiogas.constants.capexmultiplier",
                                                    "label": "Capex (per Kg)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 4500,
                                                    "defaultValue": 4500
                                                },
                                                {
                                                    "id": "wetwastetobiogas.VendorCapexMultiplier",
                                                    "name": "wetwastetobiogas.VendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.multipliersource",
                                                    "name": "wetwastetobiogas.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kglpgperkgwetwaste",
                                                    "name": "wetwastetobiogas.constants.kglpgperkgwetwaste",
                                                    "label": "kg LPG per kg wet waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.costoflpg",
                                                    "name": "global.constants.costoflpg",
                                                    "label": "LPG Price per Kg",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 60
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kwhperbiogascum",
                                                    "name": "wetwastetobiogas.constants.kwhperbiogascum",
                                                    "label": "kWh per Biogas cum",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1.5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.wetwastecontent",
                                                    "name": "wetwastetobiogas.constants.wetwastecontent",
                                                    "label": "Wet content per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.emmitionfactorforlpg",
                                                    "name": "wetwastetobiogas.constants.emmitionfactorforlpg",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3.13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kg2cumconv",
                                                    "name": "wetwastetobiogas.constants.kg2cumconv",
                                                    "label": "Kg to cum conversion",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cum",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.08,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateDetails",
                                                    "name": "wetwastetobiogas.MandateDetails",
                                                    "label": "WWB Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, Bulk Generators shall not mix Wet Waste with any other types of waste. Wet waste and garden waste generated shall be composted at source or processed using bio methanisation technique. Where it is not possible to compost wet waste at site, due to space constraint, alternate arrangements shall be made by the generator to hand over wet waste to private composters or BBMP wet waste collectors, on payment, as specified by concerned Zonal Commissioners."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateSummary",
                                                    "name": "wetwastetobiogas.MandateSummary",
                                                    "label": "WWB Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateGenerals",
                                                    "name": "wetwastetobiogas.MandateGenerals",
                                                    "label": "WWB Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.arearequired",
                                                    "name": "wetwastetobiogas.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.endusepresdplus",
                                                    "name": "wetwastetobiogas.endusepresdplus",
                                                    "label": "End use Pre SDPlus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Compost",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "name": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "label": "WWB Process Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Organic Waste Composter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbannualwastepresdplus",
                                                    "name": "wetwastetobiogas.wwbannualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72136,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "name": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "label": "WWB Process Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.custom-interventions.Capex",
                                            "name": "waste.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.custom-interventions.CapexConverted",
                                            "name": "waste.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.custom-interventions.Opex",
                                            "name": "waste.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.custom-interventions.CapexTotal",
                                            "name": "waste.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.custom-interventions.CapexTotalConverted",
                                            "name": "waste.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.custom-interventions.Savings",
                                            "name": "waste.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.custom-interventions.Carbon",
                                            "name": "waste.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Energy",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "energy.Capex",
                                    "name": "energy.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 2082052,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "energy.CapexConverted",
                                    "name": "energy.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 4,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "energy.Opex",
                                    "name": "energy.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 554912,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "energy.CapexTotal",
                                    "name": "energy.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 2082052,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "energy.CapexTotalConverted",
                                    "name": "energy.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 4,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "energy.Savings",
                                    "name": "energy.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kWh",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 85371,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "energy.SavingsConverted",
                                    "name": "energy.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Homes powered annually",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 79,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "energy.Carbon",
                                    "name": "energy.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 60,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "energy.CarbonConverted",
                                    "name": "energy.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 13,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "mainGrid": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 9747,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "dieselGenerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 3858,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "solarWaterHeater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 48,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "solarPv": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 186,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "biogas": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "right": {
                                    "residentialHotWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 3793,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialLighting": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2161,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialFan": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2595,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "homeAppliances": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2395,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialHvac": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 299,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "commonArea": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1298,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialHotWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 3745,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialLighting",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 2096,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialFan",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "homeAppliances",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialHvac",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 299,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1112,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "residentialLighting",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 65,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "residentialFan",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "homeAppliances",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "solarWaterHeater",
                                        "right": "residentialHotWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 48,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "solarPv",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 186,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "biogas",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Renewable Sources",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "energy.renewable-sources.Capex",
                                            "name": "energy.renewable-sources.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 2082052,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "energy.renewable-sources.CapexConverted",
                                            "name": "energy.renewable-sources.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "energy.renewable-sources.Opex",
                                            "name": "energy.renewable-sources.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 554912,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "energy.renewable-sources.CapexTotal",
                                            "name": "energy.renewable-sources.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 2082052,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "energy.renewable-sources.CapexTotalConverted",
                                            "name": "energy.renewable-sources.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 4,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "energy.renewable-sources.Savings",
                                            "name": "energy.renewable-sources.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kWh",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 85371,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "energy.renewable-sources.Carbon",
                                            "name": "energy.renewable-sources.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 60,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Solar PV",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.solarpv.Capex",
                                                    "name": "energy.renewable-sources.solarpv.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.solarpv.Opex",
                                                    "name": "energy.renewable-sources.solarpv.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 441324,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.solarpv.Savings",
                                                    "name": "energy.renewable-sources.solarpv.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.solarpv.Carbon",
                                                    "name": "energy.renewable-sources.solarpv.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Water_Saved",
                                                    "name": "energy.renewable-sources.solarpv.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.solarpv.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Waste_Saved",
                                                    "name": "energy.renewable-sources.solarpv.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.solarpv.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.%_of Total Energy",
                                                    "name": "energy.renewable-sources.solarpv.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.solarpv.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 14,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewMin",
                                                    "name": "energy.renewable-sources.solarpv.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewMax",
                                                    "name": "energy.renewable-sources.solarpv.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewTotal",
                                                    "name": "energy.renewable-sources.solarpv.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Lighting",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Fan",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Home_Appliances",
                                                    "name": "energy.renewable-sources.solarpv.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_HVAC",
                                                    "name": "energy.renewable-sources.solarpv.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Common_Area",
                                                    "name": "energy.renewable-sources.solarpv.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 186,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Savings",
                                                    "name": "energy.renewable-sources.solarpv.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SavingsConverted",
                                                    "name": "energy.renewable-sources.solarpv.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 63,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexPre",
                                                    "name": "energy.renewable-sources.solarpv.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexPost",
                                                    "name": "energy.renewable-sources.solarpv.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Capex",
                                                    "name": "energy.renewable-sources.solarpv.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Opex",
                                                    "name": "energy.renewable-sources.solarpv.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 441324,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Carbon",
                                                    "name": "energy.renewable-sources.solarpv.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.IRR",
                                                    "name": "energy.renewable-sources.solarpv.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.PaybackPeriod",
                                                    "name": "energy.renewable-sources.solarpv.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SdgNumber",
                                                    "name": "energy.renewable-sources.solarpv.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        7
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SdgText",
                                                    "name": "energy.renewable-sources.solarpv.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 7 - Affordable and Clean Energy",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Locked",
                                                    "name": "energy.renewable-sources.solarpv.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Enabled",
                                                    "name": "energy.renewable-sources.solarpv.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.GoalTarget",
                                                    "name": "energy.renewable-sources.solarpv.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.GoalContribution",
                                                    "name": "energy.renewable-sources.solarpv.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 80,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacity",
                                                    "name": "solarpv.SPVCapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 47,
                                                    "defaultValue": 47
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacityMin",
                                                    "name": "solarpv.SPVCapacityMin",
                                                    "label": "Min",
                                                    "tags": [
                                                        "BoundaryMin"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarpv.SPVCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacityMax",
                                                    "name": "solarpv.SPVCapacityMax",
                                                    "label": "Max",
                                                    "tags": [
                                                        "BoundaryMax"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarpv.SPVCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.CapexMultiplier",
                                                    "name": "solarpv.CapexMultiplier",
                                                    "label": "Capex (per kWp installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 41640,
                                                    "defaultValue": 41640
                                                },
                                                {
                                                    "id": "solarpv.vedorfaultcapexmultiplier",
                                                    "name": "solarpv.vendorfaultcapexmultiplier",
                                                    "label": "Vendor Capex per kWp installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 45000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.multipliersource",
                                                    "name": "solarpv.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.ModuleType",
                                                    "name": "solarpv.ModuleType",
                                                    "label": "Module Type",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Standard",
                                                        "Premium"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Standard",
                                                    "defaultValue": "Standard"
                                                },
                                                {
                                                    "id": "solarpv.RooftopAreaPerkWp",
                                                    "name": "solarpv.RooftopAreaPerkWp",
                                                    "label": "Rooftop Area per kWp",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 10
                                                },
                                                {
                                                    "id": "solarpv.MandateDetails",
                                                    "name": "solarpv.MandateDetails",
                                                    "label": "SolarPV Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        " As per Central Govt. mandate, projects need to install rooftop Solar Photo Voltaic systems to meet project's partial energy demand"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.MandateSummary",
                                                    "name": "solarpv.MandateSummary",
                                                    "label": "SolarPV Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.MandateGenerals",
                                                    "name": "solarpv.MandateGenerals",
                                                    "label": "SolarPV Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "The maximum capacity is limited by electricity requirement of common areas or total available rooftop area, whichever is lower"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.arearequired",
                                                    "name": "solarpv.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 465,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.basecasespvcapacity",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.spvannualgenerationpresdplus",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Solar Water Heater",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 113588,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "name": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Water_Saved",
                                                    "name": "energy.renewable-sources.solarwaterheater.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Waste_Saved",
                                                    "name": "energy.renewable-sources.solarwaterheater.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.%_of Total Energy",
                                                    "name": "energy.renewable-sources.solarwaterheater.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewMin",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewMax",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Lighting",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Fan",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Home_Appliances",
                                                    "name": "energy.renewable-sources.solarwaterheater.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_HVAC",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Common_Area",
                                                    "name": "energy.renewable-sources.solarwaterheater.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SavingsConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexPre",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexPost",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 113588,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "name": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.IRR",
                                                    "name": "energy.renewable-sources.solarwaterheater.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.PaybackPeriod",
                                                    "name": "energy.renewable-sources.solarwaterheater.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SdgNumber",
                                                    "name": "energy.renewable-sources.solarwaterheater.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        7
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SdgText",
                                                    "name": "energy.renewable-sources.solarwaterheater.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 7 - Affordable and Clean Energy",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Locked",
                                                    "name": "energy.renewable-sources.solarwaterheater.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Enabled",
                                                    "name": "energy.renewable-sources.solarwaterheater.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.GoalTarget",
                                                    "name": "energy.renewable-sources.solarwaterheater.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.GoalContribution",
                                                    "name": "energy.renewable-sources.solarwaterheater.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.RooftopAreaPerLPD",
                                                    "name": "solarwaterheater.RooftopAreaPerLPD",
                                                    "label": "Rooftop Area per LPD",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.03,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacity",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1165,
                                                    "defaultValue": 1165
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacityMin",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "Min",
                                                    "tags": [
                                                        "BoundaryMin"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarwaterheater.SWHCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 583,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacityMax",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "Max",
                                                    "tags": [
                                                        "BoundaryMax"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarwaterheater.SWHCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1165,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.ModuleType",
                                                    "name": "solarwaterheater.ModuleType",
                                                    "label": "System Type",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "ETC",
                                                    "defaultValue": "ETC"
                                                },
                                                {
                                                    "id": "solarwaterheater.CapexMultiplier",
                                                    "name": "solarwaterheater.CapexMultiplier",
                                                    "label": "Capex (per LPD installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 125,
                                                    "defaultValue": 125
                                                },
                                                {
                                                    "id": "solarwaterheater.DefaultCapexMultiplier",
                                                    "name": "solarwaterheater.DefaultCapexMultiplier",
                                                    "label": "Default Capex per KLD installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 118,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.multipliersource",
                                                    "name": "solarwaterheater.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateDetails",
                                                    "name": "solarwaterheater.MandateDetails",
                                                    "label": "SWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt. mandate, solar water heaters should be installed"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateSummary",
                                                    "name": "solarwaterheater.MandateSummary",
                                                    "label": "SWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateGenerals",
                                                    "name": "solarwaterheater.MandateGenerals",
                                                    "label": "Solar Water Heater Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "We assume the minimum capacity to be one that meets the partial water heating requirements of the top 1 floor and maximum capacity to be for top 2 floors.The capacity calculation assumes 20 litres consumed per occupant per day"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.PerLPDkWh",
                                                    "name": "solarwaterheater.PerLPDkWh",
                                                    "label": "kWh equivalent to LPD",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8.52,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.arearequired",
                                                    "name": "solarwaterheater.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 35,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.basecasespvcapacity",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.spvannualgenerationpresdplus",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Hot Water Management Apartment",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb50",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Water_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Waste_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.%_of Total Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewMin",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewMax",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Lighting",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Fan",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Home_Appliances",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_HVAC",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Common_Area",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SavingsConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexPre",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexPost",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.IRR",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.PaybackPeriod",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SdgNumber",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SdgText",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Locked",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Enabled",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.GoalTarget",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.GoalContribution",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.swhCapacity",
                                                    "name": "hwmApartment.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 177400
                                                },
                                                {
                                                    "id": "hwmApartment.options",
                                                    "name": "hwmApartment.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump",
                                                        "hybrid"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": "solar water heater"
                                                },
                                                {
                                                    "id": "hwmApartment.swhType",
                                                    "name": "hwmApartment.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "central",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmApartment.swhVariant",
                                                    "name": "hwmApartment.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fpc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmApartment.individualProductCapacity",
                                                    "name": "hwmApartment.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.numberOfProducts",
                                                    "name": "hwmApartment.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.areaRequired",
                                                    "name": "hwmApartment.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.userHpCapacity",
                                                    "name": "hwmApartment.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.HpType",
                                                    "name": "hwmApartment.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.heatingSpeed",
                                                    "name": "hwmApartment.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "slow",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandByArea",
                                                    "name": "hwmApartment.demandByArea",
                                                    "label": "Demand By Area",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "total demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandByBath",
                                                    "name": "hwmApartment.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "all baths",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.hotwaterfloors",
                                                    "name": "hwmApartment.hotwaterfloors",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 2
                                                },
                                                {
                                                    "id": "hwmApartment.demandAssumption",
                                                    "name": "hwmApartment.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 177400,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandAssumptionType",
                                                    "name": "hwmApartment.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Apartment",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpd per apartment",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.lpcdPerOccupant",
                                                    "name": "hwmApartment.lpcdPerOccupant",
                                                    "label": "lpcd Per Occupant",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.lpcdPerApartment",
                                                    "name": "hwmApartment.lpcdPerApartment",
                                                    "label": "lpcd Per Apartment",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.CustomCapexMultiplier",
                                                    "name": "hwmApartment.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.vendorCapexMultiplier",
                                                    "name": "hwmApartment.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 47013004,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.multipliersource",
                                                    "name": "hwmApartment.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "vendor",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.ElectricityCostperkWh",
                                                    "name": "hwmApartment.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.rooftopAreaPerSWHLPD",
                                                    "name": "hwmApartment.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.rooftopAreaPerHPLPD",
                                                    "name": "hwmApartment.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.hotwaterusuageHours",
                                                    "name": "hwmApartment.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.numberofPlumbingShafts",
                                                    "name": "hwmApartment.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 2
                                                },
                                                {
                                                    "id": "hwmApartment.co2emmissionfactor",
                                                    "name": "hwmApartment.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "hwmApartment.MandateSummary",
                                                    "name": "hwmApartment.MandateSummary",
                                                    "label": "hwmApartment Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.MandateGenerals",
                                                    "name": "hwmApartment.MandateGenerals",
                                                    "label": "hwmApartment Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.MandateDetails",
                                                    "name": "hwmApartment.MandateDetails",
                                                    "label": "hwmApartment Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Hot Water Management Villa",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb46",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -1200,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Water_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Waste_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.%_of Total Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewMin",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewMax",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Lighting",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Fan",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Home_Appliances",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_HVAC",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Common_Area",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SavingsConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexPre",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexPost",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -1200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.IRR",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.PaybackPeriod",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SdgNumber",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SdgText",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Locked",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Enabled",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.GoalTarget",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.GoalContribution",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "energy.custom-interventions.Capex",
                                            "name": "energy.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "energy.custom-interventions.CapexConverted",
                                            "name": "energy.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "energy.custom-interventions.Opex",
                                            "name": "energy.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "energy.custom-interventions.CapexTotal",
                                            "name": "energy.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "energy.custom-interventions.CapexTotalConverted",
                                            "name": "energy.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "energy.custom-interventions.Savings",
                                            "name": "energy.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kWh",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "energy.custom-interventions.Carbon",
                                            "name": "energy.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Water",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "water.Capex",
                                    "name": "water.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 7240880,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "water.CapexConverted",
                                    "name": "water.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 12,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "water.Opex",
                                    "name": "water.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 3452981,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "water.CapexTotal",
                                    "name": "water.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 13089540,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "water.CapexTotalConverted",
                                    "name": "water.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 22,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "water.Savings",
                                    "name": "water.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kL",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 60997,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "water.SavingsConverted",
                                    "name": "water.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Water Tankers Avoided",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10166,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "water.Carbon",
                                    "name": "water.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 43,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "water.CarbonConverted",
                                    "name": "water.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 9,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "freshWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 87,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "treatedGreyWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 52,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "wtp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 74,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "centralizedRO": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 14,
                                        "tags": [],
                                        "unit": "KL"
                                    }
                                },
                                "right": {
                                    "commonAreaUse": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "domesticWaterUse": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "healthFaucet": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "flushing": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 12,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "drinking": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 14,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "greyWaterStp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "blackWaterStp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "KL"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "freshWater",
                                        "right": "wtp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 74,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "freshWater",
                                        "right": "centralizedRO",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 14,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "domesticwateruse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "healthFaucet",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "commonAreaUse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "wtp",
                                        "right": "domesticwateruse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 73,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "wtp",
                                        "right": "healthFaucet",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "centralizedRO",
                                        "right": "drinking",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 14,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "domesticwateruse",
                                        "right": "greyWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 73,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "healthFaucet",
                                        "right": "blackWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 878,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "flushing",
                                        "right": "blackWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 12833,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "greyWaterStp",
                                        "right": "treatedGreyWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 52,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "greyWaterStp",
                                        "right": "municipalDrain",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "blackWaterStp",
                                        "right": "flushing",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "blackWaterStp",
                                        "right": "municipalDrain",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Source Creation",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.source-creation.Capex",
                                            "name": "water.source-creation.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.source-creation.CapexConverted",
                                            "name": "water.source-creation.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.source-creation.Opex",
                                            "name": "water.source-creation.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 72149,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.source-creation.CapexTotal",
                                            "name": "water.source-creation.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 360000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.source-creation.CapexTotalConverted",
                                            "name": "water.source-creation.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.source-creation.Savings",
                                            "name": "water.source-creation.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 787,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.source-creation.Carbon",
                                            "name": "water.source-creation.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Surface RWH",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb32",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.source-creation.srwh.Capex",
                                                    "name": "water.source-creation.srwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.source-creation.srwh.CapexConverted",
                                                    "name": "water.source-creation.srwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.source-creation.srwh.Opex",
                                                    "name": "water.source-creation.srwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.source-creation.srwh.CapexTotal",
                                                    "name": "water.source-creation.srwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.source-creation.srwh.CapexTotalConverted",
                                                    "name": "water.source-creation.srwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.source-creation.srwh.Savings",
                                                    "name": "water.source-creation.srwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.source-creation.srwh.Carbon",
                                                    "name": "water.source-creation.srwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Waste_Saved",
                                                    "name": "water.source-creation.srwh.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Waste_Opex Savings",
                                                    "name": "water.source-creation.srwh.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Energy_Saved",
                                                    "name": "water.source-creation.srwh.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Energy_Opex Savings",
                                                    "name": "water.source-creation.srwh.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of total water",
                                                    "name": "water.source-creation.srwh.%_of total water",
                                                    "label": "% of total water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of domestic demand",
                                                    "name": "water.source-creation.srwh.%_of domestic demand",
                                                    "label": "% of domestic demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 44,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of drinking demand",
                                                    "name": "water.source-creation.srwh.%_of drinking demand",
                                                    "label": "% of drinking demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 53,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewMin",
                                                    "name": "water.source-creation.srwh.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewMax",
                                                    "name": "water.source-creation.srwh.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewTotal",
                                                    "name": "water.source-creation.srwh.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Domestic",
                                                    "name": "water.source-creation.srwh.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Health_Faucet",
                                                    "name": "water.source-creation.srwh.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Flushing",
                                                    "name": "water.source-creation.srwh.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Common_Area",
                                                    "name": "water.source-creation.srwh.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Drinking",
                                                    "name": "water.source-creation.srwh.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Savings",
                                                    "name": "water.source-creation.srwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SavingsConverted",
                                                    "name": "water.source-creation.srwh.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexPre",
                                                    "name": "water.source-creation.srwh.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexPost",
                                                    "name": "water.source-creation.srwh.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Capex",
                                                    "name": "water.source-creation.srwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexConverted",
                                                    "name": "water.source-creation.srwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Opex",
                                                    "name": "water.source-creation.srwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Carbon",
                                                    "name": "water.source-creation.srwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.IRR",
                                                    "name": "water.source-creation.srwh.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.PaybackPeriod",
                                                    "name": "water.source-creation.srwh.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SdgNumber",
                                                    "name": "water.source-creation.srwh.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SdgText",
                                                    "name": "water.source-creation.srwh.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Locked",
                                                    "name": "water.source-creation.srwh.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Enabled",
                                                    "name": "water.source-creation.srwh.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.GoalTarget",
                                                    "name": "water.source-creation.srwh.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.GoalContribution",
                                                    "name": "water.source-creation.srwh.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexTotal",
                                                    "name": "water.source-creation.srwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexTotalConverted",
                                                    "name": "water.source-creation.srwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.multipliersource",
                                                    "name": "surfacerwh.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateDetails",
                                                    "name": "surfacerwh.MandateDetails",
                                                    "label": "SRWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BWSSB amendment act 2009, for per sqmt of roof top area 30 L storage or recharge structure should be provided."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateSummary",
                                                    "name": "surfacerwh.MandateSummary",
                                                    "label": "SRWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateGenerals",
                                                    "name": "surfacerwh.MandateGenerals",
                                                    "label": "SRWH Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.srwhtanksize",
                                                    "name": "surfacerwh.srwhtanksize",
                                                    "label": "Tank Size",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.constants.srwhtankcapexmultiplier",
                                                    "name": "surfacerwh.constants.srwhtankcapexmultiplier",
                                                    "label": "RWH Tank Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18000,
                                                    "defaultValue": 18000
                                                },
                                                {
                                                    "id": "surfacerwh.VendorCapexMultiplier",
                                                    "name": "surfacerwh.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.srwhdailydemand",
                                                    "name": "surfacerwh.srwhdailydemand",
                                                    "label": "Rainwater Used For",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "50% of (Drinking + Domestic)",
                                                    "defaultValue": "50% of (Drinking + Domestic)"
                                                },
                                                {
                                                    "id": "surfacerwh.PavedSurfaceArea",
                                                    "name": "surfacerwh.PavedSurfaceArea",
                                                    "label": "Paved Surface Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.PavedSurfaceArea",
                                                    "name": "surfacerwh.PavedSurfaceArea",
                                                    "label": "SRWH Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Rooftop RWH",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.source-creation.rrwh.Capex",
                                                    "name": "water.source-creation.rrwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.source-creation.rrwh.CapexConverted",
                                                    "name": "water.source-creation.rrwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.source-creation.rrwh.Opex",
                                                    "name": "water.source-creation.rrwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72149,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.source-creation.rrwh.CapexTotal",
                                                    "name": "water.source-creation.rrwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "name": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.source-creation.rrwh.Savings",
                                                    "name": "water.source-creation.rrwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.source-creation.rrwh.Carbon",
                                                    "name": "water.source-creation.rrwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Waste_Saved",
                                                    "name": "water.source-creation.rrwh.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Waste_Opex Savings",
                                                    "name": "water.source-creation.rrwh.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Energy_Saved",
                                                    "name": "water.source-creation.rrwh.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Energy_Opex Savings",
                                                    "name": "water.source-creation.rrwh.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Total Water",
                                                    "name": "water.source-creation.rrwh.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Domestic Demand",
                                                    "name": "water.source-creation.rrwh.%_of Domestic Demand",
                                                    "label": "% of Domestic Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Drinking Demand",
                                                    "name": "water.source-creation.rrwh.%_of Drinking Demand",
                                                    "label": "% of Drinking Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewMin",
                                                    "name": "water.source-creation.rrwh.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewMax",
                                                    "name": "water.source-creation.rrwh.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewTotal",
                                                    "name": "water.source-creation.rrwh.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Domestic",
                                                    "name": "water.source-creation.rrwh.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Health_Faucet",
                                                    "name": "water.source-creation.rrwh.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Flushing",
                                                    "name": "water.source-creation.rrwh.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Common_Area",
                                                    "name": "water.source-creation.rrwh.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Drinking",
                                                    "name": "water.source-creation.rrwh.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Savings",
                                                    "name": "water.source-creation.rrwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SavingsConverted",
                                                    "name": "water.source-creation.rrwh.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 131,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexPre",
                                                    "name": "water.source-creation.rrwh.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexPost",
                                                    "name": "water.source-creation.rrwh.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Capex",
                                                    "name": "water.source-creation.rrwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexConverted",
                                                    "name": "water.source-creation.rrwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Opex",
                                                    "name": "water.source-creation.rrwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72149,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Carbon",
                                                    "name": "water.source-creation.rrwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.IRR",
                                                    "name": "water.source-creation.rrwh.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.PaybackPeriod",
                                                    "name": "water.source-creation.rrwh.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SdgNumber",
                                                    "name": "water.source-creation.rrwh.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SdgText",
                                                    "name": "water.source-creation.rrwh.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Locked",
                                                    "name": "water.source-creation.rrwh.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Enabled",
                                                    "name": "water.source-creation.rrwh.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.GoalTarget",
                                                    "name": "water.source-creation.rrwh.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.GoalContribution",
                                                    "name": "water.source-creation.rrwh.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexTotal",
                                                    "name": "water.source-creation.rrwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "name": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhtanksizesource",
                                                    "name": "rooftoprwh.rrwhtanksizesource",
                                                    "label": "Tank Size - Default Recommendation",
                                                    "tags": [
                                                        "customizable",
                                                        "tank-size-options"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Default",
                                                    "defaultValue": "Default",
                                                    "valueOptions": [
                                                        "Default",
                                                        "Custom"
                                                    ]
                                                },
                                                {
                                                    "id": "rooftoprwh.constants.rrwhtankcapexmultiplier",
                                                    "name": "rooftoprwh.constants.rrwhtankcapexmultiplier",
                                                    "label": "RWH Tank Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18000,
                                                    "defaultValue": 18000
                                                },
                                                {
                                                    "id": "rooftoprwh.VendorCapexMultiplier",
                                                    "name": "rooftoprwh.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.multipliersource",
                                                    "name": "rooftoprwh.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateDetails",
                                                    "name": "rooftoprwh.MandateDetails",
                                                    "label": "RRWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP Building byelaw (2003) bye-law 32 rainwater harvesting is mandatory.",
                                                        "As per BWSSB amendment act 2009, for per sqmt of roof top area 60l storage or recharge structure should be provided."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateSummary",
                                                    "name": "rooftoprwh.MandateSummary",
                                                    "label": "RRWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateGenerals",
                                                    "name": "rooftoprwh.MandateGenerals",
                                                    "label": "RRWH Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.constants.minrwhtanksizemultiplier",
                                                    "name": "rooftoprwh.constants.minrwhtanksizemultiplier",
                                                    "label": "RWH tank size per sqm of rooftop",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.02,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhAnnualWaterPreSDplus",
                                                    "name": "rooftoprwh.rrwhAnnualWaterPreSDplus",
                                                    "label": "RRWH Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhdailydemand",
                                                    "name": "rooftoprwh.rrwhdailydemand",
                                                    "label": "Rainwater Used For",
                                                    "tags": [
                                                        "customizable",
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "50% of (Drinking + Domestic)",
                                                    "defaultValue": "50% of (Drinking + Domestic)",
                                                    "valueOptions": [
                                                        "50% of (Drinking + Domestic)",
                                                        null
                                                    ]
                                                },
                                                {
                                                    "id": "efficientfixtures.rrwhTankSizePreSDPlus",
                                                    "name": "efficientfixtures.rrwhTankSizePreSDPlus",
                                                    "label": "RRWH Tank Size Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhtanksize",
                                                    "name": "rooftoprwh.rrwhtanksize",
                                                    "label": "Tank Size",
                                                    "tags": [
                                                        "customizable",
                                                        "tank-size-customization",
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 20
                                                },
                                                {
                                                    "id": "rooftoprwh.arearequired",
                                                    "name": "rooftoprwh.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Demand Optimization",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.demand-optimization.Capex",
                                            "name": "water.demand-optimization.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3640880,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.demand-optimization.CapexConverted",
                                            "name": "water.demand-optimization.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 7,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.demand-optimization.Opex",
                                            "name": "water.demand-optimization.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3372193,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.demand-optimization.CapexTotal",
                                            "name": "water.demand-optimization.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 9129540,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.demand-optimization.CapexTotalConverted",
                                            "name": "water.demand-optimization.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 15,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.demand-optimization.Savings",
                                            "name": "water.demand-optimization.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 36790,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.demand-optimization.Carbon",
                                            "name": "water.demand-optimization.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 26,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Smart Water Meters",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb26",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.swm.Capex",
                                                    "name": "water.demand-optimization.swm.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.swm.CapexConverted",
                                                    "name": "water.demand-optimization.swm.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.swm.Opex",
                                                    "name": "water.demand-optimization.swm.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 172279,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.swm.CapexTotal",
                                                    "name": "water.demand-optimization.swm.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "name": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.swm.Savings",
                                                    "name": "water.demand-optimization.swm.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.swm.Carbon",
                                                    "name": "water.demand-optimization.swm.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Waste_Saved",
                                                    "name": "water.demand-optimization.swm.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.swm.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Energy_Saved",
                                                    "name": "water.demand-optimization.swm.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.swm.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.%_of Total Water",
                                                    "name": "water.demand-optimization.swm.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewMin",
                                                    "name": "water.demand-optimization.swm.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewMax",
                                                    "name": "water.demand-optimization.swm.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewTotal",
                                                    "name": "water.demand-optimization.swm.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Domestic",
                                                    "name": "water.demand-optimization.swm.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Health_Faucet",
                                                    "name": "water.demand-optimization.swm.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Flushing",
                                                    "name": "water.demand-optimization.swm.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Common_Area",
                                                    "name": "water.demand-optimization.swm.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Drinking",
                                                    "name": "water.demand-optimization.swm.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Savings",
                                                    "name": "water.demand-optimization.swm.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SavingsConverted",
                                                    "name": "water.demand-optimization.swm.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexPre",
                                                    "name": "water.demand-optimization.swm.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexPost",
                                                    "name": "water.demand-optimization.swm.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Capex",
                                                    "name": "water.demand-optimization.swm.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexConverted",
                                                    "name": "water.demand-optimization.swm.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Opex",
                                                    "name": "water.demand-optimization.swm.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 172279,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Carbon",
                                                    "name": "water.demand-optimization.swm.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.IRR",
                                                    "name": "water.demand-optimization.swm.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.PaybackPeriod",
                                                    "name": "water.demand-optimization.swm.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SdgNumber",
                                                    "name": "water.demand-optimization.swm.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SdgText",
                                                    "name": "water.demand-optimization.swm.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Locked",
                                                    "name": "water.demand-optimization.swm.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Enabled",
                                                    "name": "water.demand-optimization.swm.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.GoalTarget",
                                                    "name": "water.demand-optimization.swm.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.GoalContribution",
                                                    "name": "water.demand-optimization.swm.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexTotal",
                                                    "name": "water.demand-optimization.swm.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "name": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.constants.swmcapexmultiplier",
                                                    "name": "smartwatermeter.constants.swmcapexmultiplier",
                                                    "label": "Capex (per sensor installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3000,
                                                    "defaultValue": 3000
                                                },
                                                {
                                                    "id": "smartwatermeter.VendorCapexMultiplier",
                                                    "name": "smartwatermeter.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.multipliersource",
                                                    "name": "smartwatermeter.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateDetails",
                                                    "name": "smartwatermeter.MandateDetails",
                                                    "label": "SWM Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BWSSB regulation 43-A, for judicious consumption of water internal water meters should be provided for all houses"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateSummary",
                                                    "name": "smartwatermeter.MandateSummary",
                                                    "label": "SWM Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateGenerals",
                                                    "name": "smartwatermeter.MandateGenerals",
                                                    "label": "SWM Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Water meter Sensors are placed in all bathrooms (1 in each bathroom) and kitchens (1 each). In common areas sensors are placed in clubhouse (2 nos.), STP, borewell inlet & water tanker inlet"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.outcomes.swmdaily",
                                                    "name": "smartwatermeter.outcomes.swmdaily",
                                                    "label": "Number of Sensors",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 676,
                                                    "defaultValue": 5
                                                },
                                                {
                                                    "id": "smartwatermeter.outcomes.resourcepresdplus",
                                                    "name": "smartwatermeter.outcomes.resourcepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Centralized RO",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": true,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.cro.Capex",
                                                    "name": "water.demand-optimization.cro.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.cro.CapexConverted",
                                                    "name": "water.demand-optimization.cro.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.cro.Opex",
                                                    "name": "water.demand-optimization.cro.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.cro.CapexTotal",
                                                    "name": "water.demand-optimization.cro.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "name": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.cro.Savings",
                                                    "name": "water.demand-optimization.cro.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.cro.Carbon",
                                                    "name": "water.demand-optimization.cro.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Waste_Saved",
                                                    "name": "water.demand-optimization.cro.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.cro.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Energy_Saved",
                                                    "name": "water.demand-optimization.cro.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.cro.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.%_of Total Water",
                                                    "name": "water.demand-optimization.cro.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewMin",
                                                    "name": "water.demand-optimization.cro.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewMax",
                                                    "name": "water.demand-optimization.cro.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewTotal",
                                                    "name": "water.demand-optimization.cro.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Domestic",
                                                    "name": "water.demand-optimization.cro.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Health_Faucet",
                                                    "name": "water.demand-optimization.cro.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Flushing",
                                                    "name": "water.demand-optimization.cro.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Common_Area",
                                                    "name": "water.demand-optimization.cro.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Drinking",
                                                    "name": "water.demand-optimization.cro.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Savings",
                                                    "name": "water.demand-optimization.cro.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SavingsConverted",
                                                    "name": "water.demand-optimization.cro.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexPre",
                                                    "name": "water.demand-optimization.cro.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexPost",
                                                    "name": "water.demand-optimization.cro.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Capex",
                                                    "name": "water.demand-optimization.cro.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexConverted",
                                                    "name": "water.demand-optimization.cro.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Opex",
                                                    "name": "water.demand-optimization.cro.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Carbon",
                                                    "name": "water.demand-optimization.cro.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.IRR",
                                                    "name": "water.demand-optimization.cro.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.PaybackPeriod",
                                                    "name": "water.demand-optimization.cro.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SdgNumber",
                                                    "name": "water.demand-optimization.cro.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11,
                                                        6
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SdgText",
                                                    "name": "water.demand-optimization.cro.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities,SDG 6 - Clean Water and Sanitation",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Locked",
                                                    "name": "water.demand-optimization.cro.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Enabled",
                                                    "name": "water.demand-optimization.cro.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.GoalTarget",
                                                    "name": "water.demand-optimization.cro.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.GoalContribution",
                                                    "name": "water.demand-optimization.cro.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexTotal",
                                                    "name": "water.demand-optimization.cro.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "name": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.inputs.userinput",
                                                    "name": "centralizedro.inputs.userinput",
                                                    "label": "Consider Individual RO system",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "ProjectInput",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.inputs.userinput",
                                                    "name": "centralizedro.inputs.userinput",
                                                    "label": "CRO Installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.outcomes.crodaily",
                                                    "name": "centralizedro.outcomes.crodaily",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.roefficiency",
                                                    "name": "centralizedro.constants.roefficiency",
                                                    "label": "Individul RO Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.45,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.centralroefficiency",
                                                    "name": "centralizedro.constants.centralroefficiency",
                                                    "label": "Central RO Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.75,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.crocapexmultiplier",
                                                    "name": "centralizedro.constants.crocapexmultiplier",
                                                    "label": "Capex per KL installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionOutput",
                                                    "value": 19000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.DefaultCapexMultiplier",
                                                    "name": "centralizedro.DefaultCapexMultiplier",
                                                    "label": "Default Capex per KL installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 19000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.multipliersource",
                                                    "name": "centralizedro.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "",
                                                    "name": "",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateDetails",
                                                    "name": "centralizedro.MandateDetails",
                                                    "label": "CRO Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateSummary",
                                                    "name": "centralizedro.MandateSummary",
                                                    "label": "CRO Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateGenerals",
                                                    "name": "centralizedro.MandateGenerals",
                                                    "label": "CRO Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.crocapacitypre",
                                                    "name": "centralizedro.crocapacitypre",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.annualwaterpresdplus",
                                                    "name": "centralizedro.annualwaterpresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.RedidentialDrinking",
                                                    "name": "centralizedro.RedidentialDrinking",
                                                    "label": "Redidential Drinking",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.ResdidentialDomestic",
                                                    "name": "centralizedro.ResdidentialDomestic",
                                                    "label": "Resdidential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "Residential flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Drinking",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 84,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Dual Flush",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb30",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.df.Capex",
                                                    "name": "water.demand-optimization.df.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1219680,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.df.CapexConverted",
                                                    "name": "water.demand-optimization.df.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.df.Opex",
                                                    "name": "water.demand-optimization.df.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1186927,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.df.CapexTotal",
                                                    "name": "water.demand-optimization.df.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.df.CapexTotalConverted",
                                                    "name": "water.demand-optimization.df.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.df.Savings",
                                                    "name": "water.demand-optimization.df.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.df.Carbon",
                                                    "name": "water.demand-optimization.df.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Waste_Saved",
                                                    "name": "water.demand-optimization.df.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.df.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Energy_Saved",
                                                    "name": "water.demand-optimization.df.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.df.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.%_of Total Water",
                                                    "name": "water.demand-optimization.df.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 18,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewMin",
                                                    "name": "water.demand-optimization.df.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewMax",
                                                    "name": "water.demand-optimization.df.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewTotal",
                                                    "name": "water.demand-optimization.df.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Domestic",
                                                    "name": "water.demand-optimization.df.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Health_Faucet",
                                                    "name": "water.demand-optimization.df.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Flushing",
                                                    "name": "water.demand-optimization.df.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Common_Area",
                                                    "name": "water.demand-optimization.df.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Drinking",
                                                    "name": "water.demand-optimization.df.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Savings",
                                                    "name": "water.demand-optimization.df.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SavingsConverted",
                                                    "name": "water.demand-optimization.df.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2158,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexPre",
                                                    "name": "water.demand-optimization.df.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3594360,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexPost",
                                                    "name": "water.demand-optimization.df.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Capex",
                                                    "name": "water.demand-optimization.df.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1219680,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexConverted",
                                                    "name": "water.demand-optimization.df.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Opex",
                                                    "name": "water.demand-optimization.df.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1186927,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Carbon",
                                                    "name": "water.demand-optimization.df.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.IRR",
                                                    "name": "water.demand-optimization.df.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.PaybackPeriod",
                                                    "name": "water.demand-optimization.df.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SdgNumber",
                                                    "name": "water.demand-optimization.df.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SdgText",
                                                    "name": "water.demand-optimization.df.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Locked",
                                                    "name": "water.demand-optimization.df.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Enabled",
                                                    "name": "water.demand-optimization.df.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.GoalTarget",
                                                    "name": "water.demand-optimization.df.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.GoalContribution",
                                                    "name": "water.demand-optimization.df.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexTotal",
                                                    "name": "water.demand-optimization.df.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexTotalConverted",
                                                    "name": "water.demand-optimization.df.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.fixturedb.brand",
                                                    "name": "dualflush.fixturedb.brand",
                                                    "label": "Brand",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Hindware"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Hindware",
                                                    "defaultValue": "Hindware"
                                                },
                                                {
                                                    "id": "dualflush.fixturedb.tag",
                                                    "name": "dualflush",
                                                    "label": "Model",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Most Popular",
                                                        "Basic",
                                                        "Best Value",
                                                        "Premium"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Basic",
                                                    "defaultValue": "Basic"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping ref occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.derived.residentialflushing.fullwcprice",
                                                    "name": "dualflush.derived.residentialflushing.fullwcprice",
                                                    "label": "Full WC Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateDetails",
                                                    "name": "dualflush.MandateDetails",
                                                    "label": "DF Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateSummary",
                                                    "name": "dualflush.MandateSummary",
                                                    "label": "DF Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateGenerals",
                                                    "name": "dualflush.MandateGenerals",
                                                    "label": "Dual Flush Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Model type is broadly split into 4 types: <br>\n      Basic = Full flush - 3.57 lpf | half flush - 2.76 lpf <br>\n      Most Popular= 4 lpf | half flush - 2 lpf <br>\n      Best Value = 3.71 lpf | half flush - 2.20 lpf <br>\n      Premium = 3.42 lpf | half flush - 1.25 lpf"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingFullWCFlowPreSDplus",
                                                    "name": "dualflush.FlushingFullWCFlowPreSDplus",
                                                    "label": "Flushing Full WC Flow Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingFullWCFlow",
                                                    "name": "dualflush.FlushingFullWCFlow",
                                                    "label": "Flushing Full WC Flow",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingHalfWCFlowPreSDplus",
                                                    "name": "dualflush.FlushingHalfWCFlowPreSDplus",
                                                    "label": "Flushing Half WC Flow Pre SD plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingHalfWCFlow",
                                                    "name": "dualflush.FlushingHalfWCFlow",
                                                    "label": "Flushing Half WC Flow",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.clientBaseCaseResidentialFlushing",
                                                    "name": "dualflush.clientBaseCaseResidentialFlushing",
                                                    "label": "client Base Case Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.dualFlushResidentialFlushing",
                                                    "name": "dualflush.dualFlushResidentialFlushing",
                                                    "label": "dual Flush Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.DFAnnualWaterPreSdplus",
                                                    "name": "dualflush.DFAnnualWaterPreSdplus",
                                                    "label": "DF Annual Water Pre Sd plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17633,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Kitchen Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb52",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb42",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.ef.Capex",
                                                    "name": "water.demand-optimization.ef.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313200,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.ef.CapexConverted",
                                                    "name": "water.demand-optimization.ef.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.ef.Opex",
                                                    "name": "water.demand-optimization.ef.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2012986,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.ef.CapexTotal",
                                                    "name": "water.demand-optimization.ef.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "name": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.ef.Savings",
                                                    "name": "water.demand-optimization.ef.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.ef.Carbon",
                                                    "name": "water.demand-optimization.ef.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Waste_Saved",
                                                    "name": "water.demand-optimization.ef.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.ef.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Energy_Saved",
                                                    "name": "water.demand-optimization.ef.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.ef.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.%_of Total Water",
                                                    "name": "water.demand-optimization.ef.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 31,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Domestic_Use as % of Savings",
                                                    "name": "water.demand-optimization.ef.Domestic_Use as % of Savings",
                                                    "label": "Domestic Use as % of Savings",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 99,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.H._Faucet Use as % of Savings",
                                                    "name": "water.demand-optimization.ef.H._Faucet Use as % of Savings",
                                                    "label": "H. Faucet Use as % of Savings",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewMin",
                                                    "name": "water.demand-optimization.ef.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewMax",
                                                    "name": "water.demand-optimization.ef.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewTotal",
                                                    "name": "water.demand-optimization.ef.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Domestic",
                                                    "name": "water.demand-optimization.ef.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Health_Faucet",
                                                    "name": "water.demand-optimization.ef.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Flushing",
                                                    "name": "water.demand-optimization.ef.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Common_Area",
                                                    "name": "water.demand-optimization.ef.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Drinking",
                                                    "name": "water.demand-optimization.ef.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Savings",
                                                    "name": "water.demand-optimization.ef.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SavingsConverted",
                                                    "name": "water.demand-optimization.ef.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3660,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexPre",
                                                    "name": "water.demand-optimization.ef.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1894300,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexPost",
                                                    "name": "water.demand-optimization.ef.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Capex",
                                                    "name": "water.demand-optimization.ef.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexConverted",
                                                    "name": "water.demand-optimization.ef.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Opex",
                                                    "name": "water.demand-optimization.ef.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2012986,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Carbon",
                                                    "name": "water.demand-optimization.ef.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.IRR",
                                                    "name": "water.demand-optimization.ef.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.PaybackPeriod",
                                                    "name": "water.demand-optimization.ef.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SdgNumber",
                                                    "name": "water.demand-optimization.ef.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SdgText",
                                                    "name": "water.demand-optimization.ef.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Locked",
                                                    "name": "water.demand-optimization.ef.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Enabled",
                                                    "name": "water.demand-optimization.ef.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.GoalTarget",
                                                    "name": "water.demand-optimization.ef.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.GoalContribution",
                                                    "name": "water.demand-optimization.ef.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 36,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexTotal",
                                                    "name": "water.demand-optimization.ef.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "name": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.fixturedb.brand",
                                                    "name": "efficientfixtures.fixturedb.brand",
                                                    "label": "Brand",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Jaquar"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "ProjectInput",
                                                    "value": "Jaquar",
                                                    "defaultValue": "Jaquar"
                                                },
                                                {
                                                    "id": "efficientfixtures.fixturedb.tag",
                                                    "name": "efficientfixtures.fixturedb.tag",
                                                    "label": "Model",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Most Popular",
                                                        "Basic",
                                                        "Best Value",
                                                        "Premium"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Basic",
                                                    "defaultValue": "Basic"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateDetails",
                                                    "name": "efficientfixtures.MandateDetails",
                                                    "label": "EF Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateSummary",
                                                    "name": "efficientfixtures.MandateSummary",
                                                    "label": "EF Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateGenerals",
                                                    "name": "efficientfixtures.MandateGenerals",
                                                    "label": "Efficient Fixtures Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Model type is broadly split into 4 types: <br>\n      Basic = Kitchen Sink - 2.5 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Most Popular= Kitchen Sink - 6 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Best Value = Kitchen Sink - 4 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Premium = Kitchen Sink - 2.5 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm "
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domestichealthfaucetprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domestichealthfaucetprice",
                                                    "label": "Health Faucet Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domesticfaucetprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domesticfaucetprice",
                                                    "label": "Faucet Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domestickitchensinkprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domestickitchensinkprice",
                                                    "label": "Kitchen Sink Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domesticshowerprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domesticshowerprice",
                                                    "label": "Shower Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.healthfaucetpre",
                                                    "name": "efficientfixtures.healthfaucetpre",
                                                    "label": "EF Health faucet Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.healthfaucetpost",
                                                    "name": "efficientfixtures.healthfaucetpost",
                                                    "label": "EF Health faucet Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.faucetpre",
                                                    "name": "efficientfixtures.faucetpre",
                                                    "label": "EF faucet Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.faucetpost",
                                                    "name": "efficientfixtures.faucetpost",
                                                    "label": "EF faucet Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.showerpre",
                                                    "name": "efficientfixtures.showerpre",
                                                    "label": "EF shower Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.showerpost",
                                                    "name": "efficientfixtures.showerpost",
                                                    "label": "EF shower Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.kitchensinkpre",
                                                    "name": "efficientfixtures.kitchensinkpre",
                                                    "label": "EF Kitchensink Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.kitchensinkpost",
                                                    "name": "efficientfixtures.kitchensinkpost",
                                                    "label": "EF Kitchensink Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": {
                                                        "$numberDecimal": "2.5"
                                                    },
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.clientBaseCaseResidentialDomestic",
                                                    "name": "efficientfixtures.clientBaseCaseResidentialDomestic",
                                                    "label": "client Base Case Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.efficientFixturesCaseResidentialDomestic",
                                                    "name": "efficientfixtures.efficientFixturesCaseResidentialDomestic",
                                                    "label": "Efficient Fixtures Case Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 84,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.efannualwaterpresdplus",
                                                    "name": "efficientfixtures.efannualwaterpresdplus",
                                                    "label": "ef annual water pre sd plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48812,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Showers",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb54",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb56",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Health Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb6a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Treatment And Re-use",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.source-creation.Capex",
                                            "name": "water.source-creation.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3600000,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.source-creation.CapexConverted",
                                            "name": "water.source-creation.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 6,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.source-creation.Opex",
                                            "name": "water.source-creation.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 8640,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.source-creation.CapexTotal",
                                            "name": "water.source-creation.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 3600000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.source-creation.CapexTotalConverted",
                                            "name": "water.source-creation.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 6,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.source-creation.Savings",
                                            "name": "water.source-creation.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 23419,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.source-creation.Carbon",
                                            "name": "water.source-creation.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 16,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Black Water Re-use",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb22",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.treatment-reuse.bwr.Capex",
                                                    "name": "water.treatment-reuse.bwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.treatment-reuse.bwr.CapexConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.treatment-reuse.bwr.Opex",
                                                    "name": "water.treatment-reuse.bwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1371,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.treatment-reuse.bwr.CapexTotal",
                                                    "name": "water.treatment-reuse.bwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.treatment-reuse.bwr.Savings",
                                                    "name": "water.treatment-reuse.bwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.treatment-reuse.bwr.Carbon",
                                                    "name": "water.treatment-reuse.bwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Waste_Saved",
                                                    "name": "water.treatment-reuse.bwr.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Waste_Opex Savings",
                                                    "name": "water.treatment-reuse.bwr.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Energy_Saved",
                                                    "name": "water.treatment-reuse.bwr.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Energy_Opex Savings",
                                                    "name": "water.treatment-reuse.bwr.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Total Water",
                                                    "name": "water.treatment-reuse.bwr.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Flushing Demand",
                                                    "name": "water.treatment-reuse.bwr.%_of Flushing Demand",
                                                    "label": "% of Flushing Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Common Area Demand",
                                                    "name": "water.treatment-reuse.bwr.%_of Common Area Demand",
                                                    "label": "% of Common Area Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewMin",
                                                    "name": "water.treatment-reuse.bwr.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewMax",
                                                    "name": "water.treatment-reuse.bwr.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewTotal",
                                                    "name": "water.treatment-reuse.bwr.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Domestic",
                                                    "name": "water.treatment-reuse.bwr.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Health_Faucet",
                                                    "name": "water.treatment-reuse.bwr.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Flushing",
                                                    "name": "water.treatment-reuse.bwr.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Common_Area",
                                                    "name": "water.treatment-reuse.bwr.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Drinking",
                                                    "name": "water.treatment-reuse.bwr.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Savings",
                                                    "name": "water.treatment-reuse.bwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SavingsConverted",
                                                    "name": "water.treatment-reuse.bwr.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 753,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexPre",
                                                    "name": "water.treatment-reuse.bwr.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexPost",
                                                    "name": "water.treatment-reuse.bwr.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Capex",
                                                    "name": "water.treatment-reuse.bwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Opex",
                                                    "name": "water.treatment-reuse.bwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Carbon",
                                                    "name": "water.treatment-reuse.bwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.IRR",
                                                    "name": "water.treatment-reuse.bwr.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.PaybackPeriod",
                                                    "name": "water.treatment-reuse.bwr.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SdgNumber",
                                                    "name": "water.treatment-reuse.bwr.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SdgText",
                                                    "name": "water.treatment-reuse.bwr.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Locked",
                                                    "name": "water.treatment-reuse.bwr.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Enabled",
                                                    "name": "water.treatment-reuse.bwr.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.GoalTarget",
                                                    "name": "water.treatment-reuse.bwr.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.GoalContribution",
                                                    "name": "water.treatment-reuse.bwr.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexTotal",
                                                    "name": "water.treatment-reuse.bwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.VendorCapexMultiplier",
                                                    "name": "blackwaterreuse.VendorCapexMultiplier",
                                                    "label": "Vendor Capex per KL installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateDetails",
                                                    "name": "blackwaterreuse.MandateDetails",
                                                    "label": "BWR Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt mandate, treated water from onsite STP should be used for non potable (flushing & landscaping) usages after appropriate tertiery treatment."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateSummary",
                                                    "name": "blackwaterreuse.MandateSummary",
                                                    "label": "BWR Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateGenerals",
                                                    "name": "blackwaterreuse.MandateGenerals",
                                                    "label": "Black Water Reuse Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.derivatives.stpcapacity",
                                                    "name": "blackwaterreuse.derivatives.stpcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 14,
                                                    "defaultValue": 14
                                                },
                                                {
                                                    "id": "blackwaterreuse.blackwaterreusetowards",
                                                    "name": "blackwaterreuse.blackwaterreusetowards",
                                                    "label": "Black Water Reuse Towards",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Flushing"
                                                    ],
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": "Flushing"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.blackstpefficiency",
                                                    "name": "blackwaterreuse.constants.blackstpefficiency",
                                                    "label": "Black STP Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.tertiarytreatmentefficiency",
                                                    "name": "blackwaterreuse.constants.tertiarytreatmentefficiency",
                                                    "label": "Tertiary Treatment Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.bwrcapexmultiplier",
                                                    "name": "blackwaterreuse.constants.BWRcapexmultiplier",
                                                    "label": "Tertiary Treatment Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "BWR Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Combined",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Separate",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.dualpipingCalculationBefore",
                                                    "name": "blackwaterreuse.dualpipingCalculationBefore",
                                                    "label": "Dual Piping Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.dualpipingCalculationAfter",
                                                    "name": "blackwaterreuse.dualpipingCalculationAfter",
                                                    "label": "Dual Piping After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.ReuseOfTreatedWaterBefore",
                                                    "name": "blackwaterreuse.ReuseOfTreatedWaterBefore",
                                                    "label": "Reuse Of Treated Water Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Reuse Of Treated Water After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "name": "blackwaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "label": "Tertiary Treatment Required Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Tertiary Treatment Required After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Grey Water Re-use",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb48",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.treatment-reuse.gwr.Capex",
                                                    "name": "water.treatment-reuse.gwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.treatment-reuse.gwr.CapexConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.treatment-reuse.gwr.Opex",
                                                    "name": "water.treatment-reuse.gwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7269,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.treatment-reuse.gwr.CapexTotal",
                                                    "name": "water.treatment-reuse.gwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.treatment-reuse.gwr.Savings",
                                                    "name": "water.treatment-reuse.gwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.treatment-reuse.gwr.Carbon",
                                                    "name": "water.treatment-reuse.gwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Waste_Saved",
                                                    "name": "water.treatment-reuse.gwr.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Waste_Opex Savings",
                                                    "name": "water.treatment-reuse.gwr.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Energy_Saved",
                                                    "name": "water.treatment-reuse.gwr.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Energy_Opex Savings",
                                                    "name": "water.treatment-reuse.gwr.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Total Water",
                                                    "name": "water.treatment-reuse.gwr.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 26,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Domestic Demand",
                                                    "name": "water.treatment-reuse.gwr.%_of Domestic Demand",
                                                    "label": "% of Domestic Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Common Area Demand",
                                                    "name": "water.treatment-reuse.gwr.%_of Common Area Demand",
                                                    "label": "% of Common Area Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewMin",
                                                    "name": "water.treatment-reuse.gwr.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewMax",
                                                    "name": "water.treatment-reuse.gwr.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewTotal",
                                                    "name": "water.treatment-reuse.gwr.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Domestic",
                                                    "name": "water.treatment-reuse.gwr.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Health_Faucet",
                                                    "name": "water.treatment-reuse.gwr.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Flushing",
                                                    "name": "water.treatment-reuse.gwr.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Common_Area",
                                                    "name": "water.treatment-reuse.gwr.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Drinking",
                                                    "name": "water.treatment-reuse.gwr.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Savings",
                                                    "name": "water.treatment-reuse.gwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SavingsConverted",
                                                    "name": "water.treatment-reuse.gwr.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3150,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexPre",
                                                    "name": "water.treatment-reuse.gwr.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexPost",
                                                    "name": "water.treatment-reuse.gwr.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Capex",
                                                    "name": "water.treatment-reuse.gwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Opex",
                                                    "name": "water.treatment-reuse.gwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7269,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Carbon",
                                                    "name": "water.treatment-reuse.gwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.IRR",
                                                    "name": "water.treatment-reuse.gwr.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.PaybackPeriod",
                                                    "name": "water.treatment-reuse.gwr.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 495,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SdgNumber",
                                                    "name": "water.treatment-reuse.gwr.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SdgText",
                                                    "name": "water.treatment-reuse.gwr.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Locked",
                                                    "name": "water.treatment-reuse.gwr.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Enabled",
                                                    "name": "water.treatment-reuse.gwr.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.GoalTarget",
                                                    "name": "water.treatment-reuse.gwr.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.GoalContribution",
                                                    "name": "water.treatment-reuse.gwr.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 31,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexTotal",
                                                    "name": "water.treatment-reuse.gwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.multipliersource",
                                                    "name": "greywaterreuse.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateDetails",
                                                    "name": "greywaterreuse.MandateDetails",
                                                    "label": "GWR Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt mandate, treated water from onsite STP should be used for non potable (flushing & landscaping) usages after appropriate tertiery treatment."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateSummary",
                                                    "name": "greywaterreuse.MandateSummary",
                                                    "label": "GWR Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateGenerals",
                                                    "name": "greywaterreuse.MandateGenerals",
                                                    "label": "GWR Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.derivatives.stpcapacity",
                                                    "name": "greywaterreuse.derivatives.stpcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 73,
                                                    "defaultValue": 73
                                                },
                                                {
                                                    "id": "greywaterreuse.greywaterdemand",
                                                    "name": "greywaterreuse.greywaterdemand",
                                                    "label": "Grey Water Reuse Towards",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping",
                                                        "Domestic",
                                                        "Common Area Car Park, Common Area Cleaning, Landscaping",
                                                        "Common Area Car Park, Common Area Cleaning",
                                                        "Common Area Car Park",
                                                        "Common Area Cleaning",
                                                        "Landscaping",
                                                        "Domestic, Common Area Car Park, Common Area Cleaning"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping",
                                                    "defaultValue": "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "greywaterreuse.constants.greystpefficiency",
                                                    "name": "greywaterreuse.constants.greystpefficiency",
                                                    "label": "Grey STP Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.constant.greywaterdemand",
                                                    "name": "greywaterreuse.constant.greywaterdemand",
                                                    "label": "Tertiary Treatment Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 45000,
                                                    "defaultValue": 45000
                                                },
                                                {
                                                    "id": "greywaterreuse.VendorCapexMultiplier",
                                                    "name": "greywaterreuse.VendorCapexMultiplier",
                                                    "label": "Vendor Capex per KL installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Combined",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Separate",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.dualpipingCalculationBefore",
                                                    "name": "greywaterreuse.dualpipingCalculationBefore",
                                                    "label": "Dual Piping Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.dualpipingCalculationAfter",
                                                    "name": "greywaterreuse.dualpipingCalculationAfter",
                                                    "label": "Dual Piping After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.ReuseOfTreatedWaterBefore",
                                                    "name": "greywaterreuse.ReuseOfTreatedWaterBefore",
                                                    "label": "Reuse Of Treated Water Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.bwrannualWaterpresdplus",
                                                    "name": "greywaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Reuse Of Treated Water After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Grey Water Demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "name": "greywaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "label": "Tertiary Treatment Required Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.bwrannualWaterpresdplus",
                                                    "name": "greywaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Tertiary Treatment Required After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Grey Water Demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.gwrAnnualWaterPreSDplus",
                                                    "name": "greywaterreuse.gwrAnnualWaterPreSDplus",
                                                    "label": "GWR Annual Water Pre SD plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.custom-interventions.Capex",
                                            "name": "water.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.custom-interventions.CapexConverted",
                                            "name": "water.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.custom-interventions.Opex",
                                            "name": "water.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.custom-interventions.CapexTotal",
                                            "name": "water.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.custom-interventions.CapexTotalConverted",
                                            "name": "water.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.custom-interventions.Savings",
                                            "name": "water.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.custom-interventions.Carbon",
                                            "name": "water.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Materials",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "materials.Capex",
                                    "name": "materials.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": -5621637,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "materials.CapexConverted",
                                    "name": "materials.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": -9,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "materials.Opex",
                                    "name": "materials.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 0,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "materials.CapexTotal",
                                    "name": "materials.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 226535604,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "materials.CapexTotalConverted",
                                    "name": "materials.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 381,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "materials.Savings",
                                    "name": "materials.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 1945,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "materials.SavingsConverted",
                                    "name": "materials.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 423,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "materials.Carbon",
                                    "name": "materials.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 369,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "materials.CarbonConverted",
                                    "name": "materials.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 494,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {},
                                "right": {},
                                "connections": []
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Paints",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.paints.Capex",
                                            "name": "materials.paints.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 34776,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.paints.CapexConverted",
                                            "name": "materials.paints.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.paints.Opex",
                                            "name": "materials.paints.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.paints.CapexTotal",
                                            "name": "materials.paints.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 46706,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.paints.CapexTotalConverted",
                                            "name": "materials.paints.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.paints.Savings",
                                            "name": "materials.paints.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.paints.Carbon",
                                            "name": "materials.paints.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Interior Paints",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb36",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.paints.interiorpaints.Capex",
                                                    "name": "materials.paints.interiorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.paints.interiorpaints.CapexConverted",
                                                    "name": "materials.paints.interiorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.paints.interiorpaints.Opex",
                                                    "name": "materials.paints.interiorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.paints.interiorpaints.CapexTotal",
                                                    "name": "materials.paints.interiorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": -950,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.paints.interiorpaints.Savings",
                                                    "name": "materials.paints.interiorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.paints.interiorpaints.Carbon",
                                                    "name": "materials.paints.interiorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Water_Saved",
                                                    "name": "materials.paints.interiorpaints.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Water_Opex Savings",
                                                    "name": "materials.paints.interiorpaints.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Waste_Saved",
                                                    "name": "materials.paints.interiorpaints.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Waste_Opex Savings",
                                                    "name": "materials.paints.interiorpaints.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.interiorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_of Total Embodied Carbon",
                                                    "name": "materials.paints.interiorpaints.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_of Total Carbon Footprint",
                                                    "name": "materials.paints.interiorpaints.%_of Total Carbon Footprint",
                                                    "label": "% of Total Carbon Footprint",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewMin",
                                                    "name": "materials.paints.interiorpaints.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewMax",
                                                    "name": "materials.paints.interiorpaints.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewTotal",
                                                    "name": "materials.paints.interiorpaints.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Embodied Carbon",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Savings",
                                                    "name": "materials.paints.interiorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SavingsConverted",
                                                    "name": "materials.paints.interiorpaints.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexPre",
                                                    "name": "materials.paints.interiorpaints.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexPost",
                                                    "name": "materials.paints.interiorpaints.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Capex",
                                                    "name": "materials.paints.interiorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexConverted",
                                                    "name": "materials.paints.interiorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Opex",
                                                    "name": "materials.paints.interiorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Carbon",
                                                    "name": "materials.paints.interiorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.IRR",
                                                    "name": "materials.paints.interiorpaints.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.PaybackPeriod",
                                                    "name": "materials.paints.interiorpaints.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SdgNumber",
                                                    "name": "materials.paints.interiorpaints.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SdgText",
                                                    "name": "materials.paints.interiorpaints.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Locked",
                                                    "name": "materials.paints.interiorpaints.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Enabled",
                                                    "name": "materials.paints.interiorpaints.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.GoalTarget",
                                                    "name": "materials.paints.interiorpaints.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.GoalContribution",
                                                    "name": "materials.paints.interiorpaints.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexTotal",
                                                    "name": "materials.paints.interiorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": -950,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.recopaint",
                                                    "name": "interiorpaints.recopaint",
                                                    "label": "Recommended Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Apollo Paints Pride Gold ",
                                                    "defaultValue": "Apollo Paints Pride Gold ",
                                                    "valueOptions": [
                                                        "Apollo Paints Pride Gold ",
                                                        "Asian Paints Tractor Emulsion"
                                                    ]
                                                },
                                                {
                                                    "id": "interiorpaints.voclevel",
                                                    "name": "interiorpaints.voclevel",
                                                    "label": "VOC Level",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.solventnumberofcoats",
                                                    "name": "interiorpaints.constants.solventnumberofcoats",
                                                    "label": "Solvent Number of Coats",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Solventcoveragearea",
                                                    "name": "interiorpaints.constants.Solventcoveragearea ",
                                                    "label": "Solvent coverage area ",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18.7,
                                                    "defaultValue": 18.7
                                                },
                                                {
                                                    "id": "interiorpaints.constants.solventEC",
                                                    "name": "interiorpaints.constants.solventEC",
                                                    "label": "Solvent EC",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3.76,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.SolventCapex",
                                                    "name": "interiorpaints.constants.SolventCapex ",
                                                    "label": "Solvent Capex",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 15.1,
                                                    "defaultValue": 15.1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Manualnumberofcoats",
                                                    "name": "interiorpaints.constants.Manualnumberofcoats ",
                                                    "label": "Manual number of coats",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Manualcoveragearea",
                                                    "name": "interiorpaints.constants.Manualcoveragearea ",
                                                    "label": "Manual coverage area",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 10.86,
                                                    "defaultValue": 10.86
                                                },
                                                {
                                                    "id": "interiorpaints.constants.ManualEC",
                                                    "name": "interiorpaints.constants.ManualEC ",
                                                    "label": "Manual EC",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2.54,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.ManualCapex",
                                                    "name": "interiorpaints.constants.ManualCapex ",
                                                    "label": "Manual Capex",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 25,
                                                    "defaultValue": 25
                                                },
                                                {
                                                    "id": "interiorpaints.MandateDetails",
                                                    "name": "interiorpaints.MandateDetails",
                                                    "label": "Interior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.MandateSummary",
                                                    "name": "interiorpaints.MandateSummary",
                                                    "label": "Interior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Exterior Paints",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.paints.exteriorpaints.Capex",
                                                    "name": "materials.paints.exteriorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34427,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.paints.exteriorpaints.CapexConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.paints.exteriorpaints.Opex",
                                                    "name": "materials.paints.exteriorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.paints.exteriorpaints.CapexTotal",
                                                    "name": "materials.paints.exteriorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 47656,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.paints.exteriorpaints.Savings",
                                                    "name": "materials.paints.exteriorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.paints.exteriorpaints.Carbon",
                                                    "name": "materials.paints.exteriorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.floors",
                                                    "name": "pip.floors",
                                                    "label": "Number of Floors",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "nos",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.wwr",
                                                    "name": "pip.wwr",
                                                    "label": "WWR",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 0.25,
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.perimeter",
                                                    "name": "pip.perimeter",
                                                    "label": "Perimeter",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "110",
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Water_Saved",
                                                    "name": "materials.paints.exteriorpaints.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Water_Opex Savings",
                                                    "name": "materials.paints.exteriorpaints.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Waste_Saved",
                                                    "name": "materials.paints.exteriorpaints.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Waste_Opex Savings",
                                                    "name": "materials.paints.exteriorpaints.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "name": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "label": "% of total ECs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "name": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "label": "% of Carbon footprint",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewMin",
                                                    "name": "materials.paints.exteriorpaints.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewMax",
                                                    "name": "materials.paints.exteriorpaints.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewTotal",
                                                    "name": "materials.paints.exteriorpaints.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "name": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "label": "% of total ECs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "name": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "label": "% of Carbon footprint",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Savings",
                                                    "name": "materials.paints.exteriorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SavingsConverted",
                                                    "name": "materials.paints.exteriorpaints.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexPre",
                                                    "name": "materials.paints.exteriorpaints.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexPost",
                                                    "name": "materials.paints.exteriorpaints.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Capex",
                                                    "name": "materials.paints.exteriorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Opex",
                                                    "name": "materials.paints.exteriorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Carbon",
                                                    "name": "materials.paints.exteriorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.IRR",
                                                    "name": "materials.paints.exteriorpaints.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.PaybackPeriod",
                                                    "name": "materials.paints.exteriorpaints.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SdgNumber",
                                                    "name": "materials.paints.exteriorpaints.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SdgText",
                                                    "name": "materials.paints.exteriorpaints.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Locked",
                                                    "name": "materials.paints.exteriorpaints.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Enabled",
                                                    "name": "materials.paints.exteriorpaints.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.GoalTarget",
                                                    "name": "materials.paints.exteriorpaints.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.GoalContribution",
                                                    "name": "materials.paints.exteriorpaints.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexTotal",
                                                    "name": "materials.paints.exteriorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 47656,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.sunnyRecoCapex",
                                                    "name": "exteriorpaints.sunnyRecoCapex",
                                                    "label": "Sunny Areas paint Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 58.98375,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.sunnyRecoCapex",
                                                    "name": "exteriorpaints.sunnyRecoCapex",
                                                    "label": "Non-Sunny Areas paint Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 31.5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.BaseCaseCapex",
                                                    "name": "exteriorpaints.BaseCaseCapex",
                                                    "label": "Base Case Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30.866666666666667,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.SunnyReco",
                                                    "name": "exteriorpaints.SunnyReco",
                                                    "label": "Sunny Reco Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Excel Cool coat",
                                                    "defaultValue": 0,
                                                    "valueOptions": [
                                                        "Excel Cool coat"
                                                    ]
                                                },
                                                {
                                                    "id": "exteriorpaints.NonsunnyReco",
                                                    "name": "exteriorpaints.NonsunnyReco",
                                                    "label": "Non Sunny Reco Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Nerolac Excel Mica Marble",
                                                    "defaultValue": 0,
                                                    "valueOptions": [
                                                        "Nerolac Excel Mica Marble"
                                                    ]
                                                },
                                                {
                                                    "id": "exteriorpaints.sunIncidentAreas",
                                                    "name": "exteriorpaints.sunIncidentAreas",
                                                    "label": "Sun Incident Areas",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.VOCLEVEL",
                                                    "name": "exteriorpaints.VOCLEVEL",
                                                    "label": "VOC Levels",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Ultra-Low",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.MandateDetails",
                                                    "name": "exteriorpaints.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.MandateSummary",
                                                    "name": "exteriorpaints.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.custom-interventions.Capex",
                                            "name": "materials.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.custom-interventions.CapexConverted",
                                            "name": "materials.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.custom-interventions.Opex",
                                            "name": "materials.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.custom-interventions.CapexTotal",
                                            "name": "materials.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.custom-interventions.CapexTotalConverted",
                                            "name": "materials.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.custom-interventions.Savings",
                                            "name": "materials.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.custom-interventions.Carbon",
                                            "name": "materials.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            },
                            {
                                "name": "Structure",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.structure.Capex",
                                            "name": "materials.structure.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": -5828844,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.structure.CapexConverted",
                                            "name": "materials.structure.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": -10,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.structure.Opex",
                                            "name": "materials.structure.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.structure.CapexTotal",
                                            "name": "materials.structure.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 226209190,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.structure.CapexTotalConverted",
                                            "name": "materials.structure.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 380,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.structure.Savings",
                                            "name": "materials.structure.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1944,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.structure.Carbon",
                                            "name": "materials.structure.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1944,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Concrete",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb38",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.structure.concrete.Capex",
                                                    "name": "materials.structure.concrete.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -11267736,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.structure.concrete.CapexConverted",
                                                    "name": "materials.structure.concrete.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -19,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.structure.concrete.Opex",
                                                    "name": "materials.structure.concrete.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.structure.concrete.CapexTotal",
                                                    "name": "materials.structure.concrete.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 90141888,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.structure.concrete.CapexTotalConverted",
                                                    "name": "materials.structure.concrete.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.structure.concrete.Savings",
                                                    "name": "materials.structure.concrete.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.structure.concrete.Carbon",
                                                    "name": "materials.structure.concrete.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Water_Saved",
                                                    "name": "materials.structure.concrete.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Water_Opex Savings",
                                                    "name": "materials.structure.concrete.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Waste_Saved",
                                                    "name": "materials.structure.concrete.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Waste_Opex Savings",
                                                    "name": "materials.structure.concrete.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "name": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "label": "% of Reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewMin",
                                                    "name": "materials.structure.concrete.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewMax",
                                                    "name": "materials.structure.concrete.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewTotal",
                                                    "name": "materials.structure.concrete.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "name": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "label": "% of Reduction in VOCs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Savings",
                                                    "name": "materials.structure.concrete.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SavingsConverted",
                                                    "name": "materials.structure.concrete.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1690,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexPre",
                                                    "name": "materials.structure.concrete.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexPost",
                                                    "name": "materials.structure.concrete.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Capex",
                                                    "name": "materials.structure.concrete.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -11267736,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexConverted",
                                                    "name": "materials.structure.concrete.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -19,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Opex",
                                                    "name": "materials.structure.concrete.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Carbon",
                                                    "name": "materials.structure.concrete.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.IRR",
                                                    "name": "materials.structure.concrete.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.PaybackPeriod",
                                                    "name": "materials.structure.concrete.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SdgNumber",
                                                    "name": "materials.structure.concrete.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SdgText",
                                                    "name": "materials.structure.concrete.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Locked",
                                                    "name": "materials.structure.concrete.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Enabled",
                                                    "name": "materials.structure.concrete.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.GoalTarget",
                                                    "name": "materials.structure.concrete.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.GoalContribution",
                                                    "name": "materials.structure.concrete.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexTotal",
                                                    "name": "materials.structure.concrete.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 90141888,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexTotalConverted",
                                                    "name": "materials.structure.concrete.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.concretequantity",
                                                    "name": "concrete.concretequantity",
                                                    "label": "Concrete Quantity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cum",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22535,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.typeOfconcrete",
                                                    "name": "concrete.typeOfconcrete",
                                                    "label": "Type Of Concrete",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Portland Slag Cement (PSC)",
                                                        "Portland Pozzolana Cement (PPC)"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Portland Slag Cement (PSC)",
                                                    "defaultValue": "Portland Slag Cement (PSC)"
                                                },
                                                {
                                                    "id": "concrete.projectinput.typeOfconcrete",
                                                    "name": "concrete.projectinput.typeOfconcrete",
                                                    "label": "Type Of Concrete",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Portland Slag Cement (PSC)",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.BaseCaseCapex",
                                                    "name": "concrete.BaseCaseCapex",
                                                    "label": "Base Case Capex(per cum)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 4500,
                                                    "defaultValue": 4500
                                                },
                                                {
                                                    "id": "concrete.multipliersource",
                                                    "name": "concrete.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.ConcreteDensity",
                                                    "name": "concrete.ConcreteDensity",
                                                    "label": "Concrete Desnsity",
                                                    "tags": [
                                                        "assumption",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg/cum",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 2500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.MandateDetails",
                                                    "name": "concrete.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.MandateSummary",
                                                    "name": "concrete.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Structural Steel",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb40",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.structure.structuralsteel.Capex",
                                                    "name": "materials.structure.structuralsteel.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5438892,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.structure.structuralsteel.CapexConverted",
                                                    "name": "materials.structure.structuralsteel.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.structure.structuralsteel.Opex",
                                                    "name": "materials.structure.structuralsteel.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.structure.structuralsteel.CapexTotal",
                                                    "name": "materials.structure.structuralsteel.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 136067302,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "name": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 229,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.structure.structuralsteel.Savings",
                                                    "name": "materials.structure.structuralsteel.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.structure.structuralsteel.Carbon",
                                                    "name": "materials.structure.structuralsteel.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Water_Saved",
                                                    "name": "materials.structure.structuralsteel.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Water_Opex Savings",
                                                    "name": "materials.structure.structuralsteel.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Waste_Saved",
                                                    "name": "materials.structure.structuralsteel.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Waste_Opex Savings",
                                                    "name": "materials.structure.structuralsteel.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.%_of Total Embodied Carbon",
                                                    "name": "materials.structure.structuralsteel.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13536,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewMin",
                                                    "name": "materials.structure.structuralsteel.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewMax",
                                                    "name": "materials.structure.structuralsteel.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewTotal",
                                                    "name": "materials.structure.structuralsteel.OverviewTotal",
                                                    "label": "Steel EC savings by category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Savings",
                                                    "name": "materials.structure.structuralsteel.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SavingsConverted",
                                                    "name": "materials.structure.structuralsteel.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9975,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexPre",
                                                    "name": "materials.structure.structuralsteel.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexPost",
                                                    "name": "materials.structure.structuralsteel.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Capex",
                                                    "name": "materials.structure.structuralsteel.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5438892,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexConverted",
                                                    "name": "materials.structure.structuralsteel.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Opex",
                                                    "name": "materials.structure.structuralsteel.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Carbon",
                                                    "name": "materials.structure.structuralsteel.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.IRR",
                                                    "name": "materials.structure.structuralsteel.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.PaybackPeriod",
                                                    "name": "materials.structure.structuralsteel.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SdgNumber",
                                                    "name": "materials.structure.structuralsteel.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SdgText",
                                                    "name": "materials.structure.structuralsteel.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Locked",
                                                    "name": "materials.structure.structuralsteel.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Enabled",
                                                    "name": "materials.structure.structuralsteel.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.GoalTarget",
                                                    "name": "materials.structure.structuralsteel.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.GoalContribution",
                                                    "name": "materials.structure.structuralsteel.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexTotal",
                                                    "name": "materials.structure.structuralsteel.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 136067302,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "name": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 229,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.treesquivalentsaved",
                                                    "name": "structuralSteels.treesquivalentsaved",
                                                    "label": "Tree Equivalent Saved",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9975.260399999997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.percentageofreductioninvocs",
                                                    "name": "structuralSteels.percentageofreductioninvocs",
                                                    "label": "Steel Quantity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2375062,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.MandateDetails",
                                                    "name": "structuralSteels.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.MandateSummary",
                                                    "name": "structuralSteels.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.BaseCaseCapex",
                                                    "name": "structuralSteels.BaseCaseCapex",
                                                    "label": "Base Case Capex(per kg)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 55
                                                },
                                                {
                                                    "id": "structuralSteels.multipliersource",
                                                    "name": "structuralSteels.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.DefaultCapexMultiplier",
                                                    "name": "structuralSteels.DefaultCapexMultiplier",
                                                    "label": "structuralSteels Capex per KLD installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.StructuralSteelType",
                                                    "name": "structuralSteels.StructuralSteelType",
                                                    "label": "Type Of Steel",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "DRI Steel"
                                                    ],
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "DRI Steel",
                                                    "defaultValue": "DRI Steel"
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Internal Flooring",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.flooring.Capex",
                                            "name": "materials.flooring.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 172430,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.flooring.CapexConverted",
                                            "name": "materials.flooring.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.flooring.Opex",
                                            "name": "materials.flooring.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.flooring.CapexTotal",
                                            "name": "materials.flooring.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 279708,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.flooring.CapexTotalConverted",
                                            "name": "materials.flooring.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.flooring.Savings",
                                            "name": "materials.flooring.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.flooring.Carbon",
                                            "name": "materials.flooring.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 325,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Utility & Balcony",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.utilityandbalcony.Capex",
                                                    "name": "materials.flooring.utilityandbalcony.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.utilityandbalcony.Opex",
                                                    "name": "materials.flooring.utilityandbalcony.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.utilityandbalcony.Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.utilityandbalcony.Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.utilityandbalcony",
                                                    "name": "pip.materials.indoorflooring.utilityandbalcony",
                                                    "label": "Utility and Balcony",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Vitrified Tiles",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Water_Saved",
                                                    "name": "materials.flooring.utilityandbalcony.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Water_Opex Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Waste_Saved",
                                                    "name": "materials.flooring.utilityandbalcony.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Waste_Opex Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 77,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewMin",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewMax",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewTotal",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SavingsConverted",
                                                    "name": "materials.flooring.utilityandbalcony.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexPre",
                                                    "name": "materials.flooring.utilityandbalcony.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexPost",
                                                    "name": "materials.flooring.utilityandbalcony.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Capex",
                                                    "name": "materials.flooring.utilityandbalcony.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Opex",
                                                    "name": "materials.flooring.utilityandbalcony.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.IRR",
                                                    "name": "materials.flooring.utilityandbalcony.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.PaybackPeriod",
                                                    "name": "materials.flooring.utilityandbalcony.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SdgNumber",
                                                    "name": "materials.flooring.utilityandbalcony.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SdgText",
                                                    "name": "materials.flooring.utilityandbalcony.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Locked",
                                                    "name": "materials.flooring.utilityandbalcony.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Enabled",
                                                    "name": "materials.flooring.utilityandbalcony.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.GoalTarget",
                                                    "name": "materials.flooring.utilityandbalcony.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.GoalContribution",
                                                    "name": "materials.flooring.utilityandbalcony.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.recoflooringtype",
                                                    "name": "utilityandbalcony.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Ceramic Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Utility & Balcony",
                                                        "FlooringType": "Ceramic Tiles",
                                                        "Capex": 4.9,
                                                        "Density": 1636,
                                                        "EC": 0.67,
                                                        "Thickness": 0.011,
                                                        "iid": "c7f4bd01-6e63-4354-b0e3-001c4d39a158"
                                                    },
                                                    "valueOptions": [
                                                        "Ceramic Tiles",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "utilityandbalcony.CarpetArea",
                                                    "name": "utilityandbalcony.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.MandateDetails",
                                                    "name": "utilityandbalcony.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.MandateSummary",
                                                    "name": "utilityandbalcony.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Bedroom",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.bedroom.Capex",
                                                    "name": "materials.flooring.bedroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32150,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.bedroom.CapexConverted",
                                                    "name": "materials.flooring.bedroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.bedroom.Opex",
                                                    "name": "materials.flooring.bedroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.bedroom.CapexTotal",
                                                    "name": "materials.flooring.bedroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.bedroom.Savings",
                                                    "name": "materials.flooring.bedroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.bedroom.Carbon",
                                                    "name": "materials.flooring.bedroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.bedroom",
                                                    "name": "pip.materials.indoorflooring.bedroom",
                                                    "label": "Bedroom",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Water_Saved",
                                                    "name": "materials.flooring.bedroom.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Water_Opex Savings",
                                                    "name": "materials.flooring.bedroom.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Waste_Saved",
                                                    "name": "materials.flooring.bedroom.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Waste_Opex Savings",
                                                    "name": "materials.flooring.bedroom.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.bedroom.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 308,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewMin",
                                                    "name": "materials.flooring.bedroom.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewMax",
                                                    "name": "materials.flooring.bedroom.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewTotal",
                                                    "name": "materials.flooring.bedroom.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Savings",
                                                    "name": "materials.flooring.bedroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SavingsConverted",
                                                    "name": "materials.flooring.bedroom.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32203,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexPre",
                                                    "name": "materials.flooring.bedroom.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexPost",
                                                    "name": "materials.flooring.bedroom.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Capex",
                                                    "name": "materials.flooring.bedroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32150,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexConverted",
                                                    "name": "materials.flooring.bedroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Opex",
                                                    "name": "materials.flooring.bedroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Carbon",
                                                    "name": "materials.flooring.bedroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.IRR",
                                                    "name": "materials.flooring.bedroom.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.PaybackPeriod",
                                                    "name": "materials.flooring.bedroom.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SdgNumber",
                                                    "name": "materials.flooring.bedroom.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SdgText",
                                                    "name": "materials.flooring.bedroom.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Locked",
                                                    "name": "materials.flooring.bedroom.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Enabled",
                                                    "name": "materials.flooring.bedroom.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.GoalTarget",
                                                    "name": "materials.flooring.bedroom.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.GoalContribution",
                                                    "name": "materials.flooring.bedroom.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexTotal",
                                                    "name": "materials.flooring.bedroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.recoflooringtype",
                                                    "name": "bedroom.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Laminate",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Bedroom",
                                                        "FlooringType": "Laminate",
                                                        "Capex": 18.3,
                                                        "Density": 600,
                                                        "EC": 2,
                                                        "Thickness": 0.01,
                                                        "iid": "a3e158f2-2858-4f40-8aa1-de38e745cc0c"
                                                    },
                                                    "valueOptions": [
                                                        "Laminate",
                                                        "Bamboo",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "bedroom.CarpetArea",
                                                    "name": "bedroom.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9456,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.MandateDetails",
                                                    "name": "bedroom.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.MandateSummary",
                                                    "name": "bedroom.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Living & Dining",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.livinganddining.Capex",
                                                    "name": "materials.flooring.livinganddining.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 91274,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.livinganddining.CapexConverted",
                                                    "name": "materials.flooring.livinganddining.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.livinganddining.Opex",
                                                    "name": "materials.flooring.livinganddining.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.livinganddining.CapexTotal",
                                                    "name": "materials.flooring.livinganddining.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "name": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.livinganddining.Savings",
                                                    "name": "materials.flooring.livinganddining.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.livinganddining.Carbon",
                                                    "name": "materials.flooring.livinganddining.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.livinganddining",
                                                    "name": "pip.materials.indoorflooring.livinganddining",
                                                    "label": "Living and Dining",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Water_Saved",
                                                    "name": "materials.flooring.livinganddining.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Water_Opex Savings",
                                                    "name": "materials.flooring.livinganddining.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Waste_Saved",
                                                    "name": "materials.flooring.livinganddining.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Waste_Opex Savings",
                                                    "name": "materials.flooring.livinganddining.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.livinganddining.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 314,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewMin",
                                                    "name": "materials.flooring.livinganddining.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewMax",
                                                    "name": "materials.flooring.livinganddining.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewTotal",
                                                    "name": "materials.flooring.livinganddining.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Savings",
                                                    "name": "materials.flooring.livinganddining.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SavingsConverted",
                                                    "name": "materials.flooring.livinganddining.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 23969,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexPre",
                                                    "name": "materials.flooring.livinganddining.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexPost",
                                                    "name": "materials.flooring.livinganddining.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Capex",
                                                    "name": "materials.flooring.livinganddining.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 91274,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexConverted",
                                                    "name": "materials.flooring.livinganddining.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Opex",
                                                    "name": "materials.flooring.livinganddining.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Carbon",
                                                    "name": "materials.flooring.livinganddining.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.IRR",
                                                    "name": "materials.flooring.livinganddining.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.PaybackPeriod",
                                                    "name": "materials.flooring.livinganddining.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SdgNumber",
                                                    "name": "materials.flooring.livinganddining.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SdgText",
                                                    "name": "materials.flooring.livinganddining.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Locked",
                                                    "name": "materials.flooring.livinganddining.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Enabled",
                                                    "name": "materials.flooring.livinganddining.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.GoalTarget",
                                                    "name": "materials.flooring.livinganddining.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.GoalContribution",
                                                    "name": "materials.flooring.livinganddining.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexTotal",
                                                    "name": "materials.flooring.livinganddining.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "name": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.recoflooringtype",
                                                    "name": "livinganddining.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Vitrified Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Living & Dining",
                                                        "FlooringType": "Vitrified Tiles",
                                                        "Capex": 8.83,
                                                        "Density": 2182,
                                                        "EC": 0.68,
                                                        "Thickness": 0.011,
                                                        "iid": "e3dce9b6-e893-442e-9699-fd6ae4e926ca"
                                                    },
                                                    "valueOptions": [
                                                        "Vitrified Tiles",
                                                        "Solid Wood"
                                                    ]
                                                },
                                                {
                                                    "id": "livinganddining.CarpetArea",
                                                    "name": "livinganddining.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7092,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.MandateDetails",
                                                    "name": "livinganddining.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.MandateSummary",
                                                    "name": "livinganddining.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Bathroom",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.bathroom.Capex",
                                                    "name": "materials.flooring.bathroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.bathroom.CapexConverted",
                                                    "name": "materials.flooring.bathroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.bathroom.Opex",
                                                    "name": "materials.flooring.bathroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.bathroom.CapexTotal",
                                                    "name": "materials.flooring.bathroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.bathroom.Savings",
                                                    "name": "materials.flooring.bathroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.bathroom.Carbon",
                                                    "name": "materials.flooring.bathroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.bathroom",
                                                    "name": "pip.materials.indoorflooring.bathroom",
                                                    "label": "Bathroom",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Vitrified Tiles",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Water_Saved",
                                                    "name": "materials.flooring.bathroom.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Water_Opex Savings",
                                                    "name": "materials.flooring.bathroom.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Waste_Saved",
                                                    "name": "materials.flooring.bathroom.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Waste_Opex Savings",
                                                    "name": "materials.flooring.bathroom.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.bathroom.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 77,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewMin",
                                                    "name": "materials.flooring.bathroom.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewMax",
                                                    "name": "materials.flooring.bathroom.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewTotal",
                                                    "name": "materials.flooring.bathroom.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Savings",
                                                    "name": "materials.flooring.bathroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SavingsConverted",
                                                    "name": "materials.flooring.bathroom.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexPre",
                                                    "name": "materials.flooring.bathroom.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexPost",
                                                    "name": "materials.flooring.bathroom.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Capex",
                                                    "name": "materials.flooring.bathroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexConverted",
                                                    "name": "materials.flooring.bathroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Opex",
                                                    "name": "materials.flooring.bathroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Carbon",
                                                    "name": "materials.flooring.bathroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.IRR",
                                                    "name": "materials.flooring.bathroom.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.PaybackPeriod",
                                                    "name": "materials.flooring.bathroom.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SdgNumber",
                                                    "name": "materials.flooring.bathroom.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SdgText",
                                                    "name": "materials.flooring.bathroom.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Locked",
                                                    "name": "materials.flooring.bathroom.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Enabled",
                                                    "name": "materials.flooring.bathroom.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.GoalTarget",
                                                    "name": "materials.flooring.bathroom.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.GoalContribution",
                                                    "name": "materials.flooring.bathroom.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexTotal",
                                                    "name": "materials.flooring.bathroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.recoflooringtype",
                                                    "name": "bathroom.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Ceramic Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Bathroom",
                                                        "FlooringType": "Ceramic Tiles",
                                                        "Capex": 4.9,
                                                        "Density": 1636,
                                                        "EC": 0.67,
                                                        "Thickness": 0.011,
                                                        "iid": "96cec198-0a16-4304-a4ab-fa65ce0a2ae1"
                                                    },
                                                    "valueOptions": [
                                                        "Ceramic Tiles",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "bathroom.CarpetArea",
                                                    "name": "bathroom.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.MandateDetails",
                                                    "name": "bathroom.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.MandateSummary",
                                                    "name": "bathroom.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Kitchen",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb44",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.kitchen.Capex",
                                                    "name": "materials.flooring.kitchen.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30425,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.kitchen.CapexConverted",
                                                    "name": "materials.flooring.kitchen.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.kitchen.Opex",
                                                    "name": "materials.flooring.kitchen.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.kitchen.CapexTotal",
                                                    "name": "materials.flooring.kitchen.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "name": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.kitchen.Savings",
                                                    "name": "materials.flooring.kitchen.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.kitchen.Carbon",
                                                    "name": "materials.flooring.kitchen.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.kitchen",
                                                    "name": "pip.materials.indoorflooring.kitchen",
                                                    "label": "Kitchen",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Water_Saved",
                                                    "name": "materials.flooring.kitchen.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Water_Opex Savings",
                                                    "name": "materials.flooring.kitchen.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Waste_Saved",
                                                    "name": "materials.flooring.kitchen.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Waste_Opex Savings",
                                                    "name": "materials.flooring.kitchen.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.kitchen.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 105,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewMin",
                                                    "name": "materials.flooring.kitchen.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewMax",
                                                    "name": "materials.flooring.kitchen.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewTotal",
                                                    "name": "materials.flooring.kitchen.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Savings",
                                                    "name": "materials.flooring.kitchen.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SavingsConverted",
                                                    "name": "materials.flooring.kitchen.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7990,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexPre",
                                                    "name": "materials.flooring.kitchen.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexPost",
                                                    "name": "materials.flooring.kitchen.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Capex",
                                                    "name": "materials.flooring.kitchen.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30425,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexConverted",
                                                    "name": "materials.flooring.kitchen.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Opex",
                                                    "name": "materials.flooring.kitchen.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Carbon",
                                                    "name": "materials.flooring.kitchen.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.IRR",
                                                    "name": "materials.flooring.kitchen.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.PaybackPeriod",
                                                    "name": "materials.flooring.kitchen.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SdgNumber",
                                                    "name": "materials.flooring.kitchen.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SdgText",
                                                    "name": "materials.flooring.kitchen.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Locked",
                                                    "name": "materials.flooring.kitchen.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Enabled",
                                                    "name": "materials.flooring.kitchen.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.GoalTarget",
                                                    "name": "materials.flooring.kitchen.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.GoalContribution",
                                                    "name": "materials.flooring.kitchen.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexTotal",
                                                    "name": "materials.flooring.kitchen.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "name": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.recoflooringtype",
                                                    "name": "kitchen.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Vitrified Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Kitchen",
                                                        "FlooringType": "Vitrified Tiles",
                                                        "Capex": 8.83,
                                                        "Density": 2182,
                                                        "EC": 0.68,
                                                        "Thickness": 0.011,
                                                        "iid": "570d2345-e68a-4793-b297-fe573af4386c"
                                                    },
                                                    "valueOptions": [
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "kitchen.CarpetArea",
                                                    "name": "kitchen.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.MandateDetails",
                                                    "name": "kitchen.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.MandateSummary",
                                                    "name": "kitchen.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "marketing": [
                {
                    "name": "energy",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "energy.Capex",
                                "name": "energy.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 2082052,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "energy.Opex",
                                "name": "energy.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 554912,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "energy.Savings",
                                "name": "energy.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kWh",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 85371,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Energy",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.residentialHotWater",
                                            "label": "Residential Hot Water",
                                            "uri": "",
                                            "value": 38,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialLighting",
                                            "label": "Residential Lighting",
                                            "uri": "",
                                            "value": 21,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialFan",
                                            "label": "Residential Fan",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.homeAppliances",
                                            "label": "Home Appliances",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialHVAC",
                                            "label": "Residential HVAC",
                                            "uri": "",
                                            "value": 3,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.residentialHotWater",
                                            "label": "Residential Hot Water",
                                            "uri": "",
                                            "value": 38,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialLighting",
                                            "label": "Residential Lighting",
                                            "uri": "",
                                            "value": 21,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialFan",
                                            "label": "Residential Fan",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.homeAppliances",
                                            "label": "Home Appliances",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialHVAC",
                                            "label": "Residential HVAC",
                                            "uri": "",
                                            "value": 3,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 11,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 2,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": 2,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Renewable Sources",
                                "total": {
                                    "id": "",
                                    "name": "renewableSources.total",
                                    "label": "Renewable Sources Total",
                                    "uri": "",
                                    "value": 2,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "solarPv.contribution",
                                            "label": "Solar PV",
                                            "uri": "",
                                            "value": 2,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "solarWaterHeater.contribution",
                                            "label": "Solar Water Heater",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "name": "waste",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "waste.Capex",
                                "name": "waste.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 220000,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "waste.Opex",
                                "name": "waste.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 34595,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "waste.Savings",
                                "name": "waste.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kg",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 104740,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Waste",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.organicWaste",
                                            "label": "Organic Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.dryWaste",
                                            "label": "Dry Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.sanitaryWaste",
                                            "label": "Sanitary Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.medicalWaste",
                                            "label": "Medical Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.eWaste",
                                            "label": "E-Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.rejectWaste",
                                            "label": "Reject Waste",
                                            "uri": "",
                                            "value": 13.6,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 86.4,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.organicWaste",
                                            "label": "Organic Waste",
                                            "uri": "",
                                            "value": -0.72,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.dryWaste",
                                            "label": "Dry Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.sanitaryWaste",
                                            "label": "Sanitary Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.medicalWaste",
                                            "label": "Medical Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.eWaste",
                                            "label": "E-Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.rejectWaste",
                                            "label": "Reject Waste",
                                            "uri": "",
                                            "value": 13.6,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 87.12,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": 87,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Reduction",
                                "total": {
                                    "id": "",
                                    "name": "reduction.total",
                                    "label": "Reduction Total",
                                    "uri": "",
                                    "value": 100,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "wwb.contribution",
                                            "label": "Wet Waste Management",
                                            "uri": "",
                                            "value": 60.72,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Management",
                                "total": {
                                    "id": "",
                                    "name": "management.total",
                                    "label": "Management Total",
                                    "uri": "",
                                    "value": 100,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "sni.contribution",
                                            "label": "Sanitary Napkin Incinerator",
                                            "uri": "",
                                            "value": 4,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "casp.contribution",
                                            "label": "Collection And Segregation Point",
                                            "uri": "",
                                            "value": 22,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "name": "water",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "water.Capex",
                                "name": "water.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 7240880,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "water.Opex",
                                "name": "water.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 3452981,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "water.Savings",
                                "name": "water.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kL",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 60997,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Water",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.drinking",
                                            "label": "Drinking",
                                            "uri": "",
                                            "value": 6981.67,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.domestic",
                                            "label": "Domestic",
                                            "uri": "",
                                            "value": 68323.05,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.flushing",
                                            "label": "Flushing",
                                            "uri": "",
                                            "value": 24678.88,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 16.4,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.drinking",
                                            "label": "Drinking",
                                            "uri": "",
                                            "value": 6981.66,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.domestic",
                                            "label": "Domestic",
                                            "uri": "",
                                            "value": 68289.68,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.flushing",
                                            "label": "Flushing",
                                            "uri": "",
                                            "value": 24660.73,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": -68.99,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": -68.99,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Demand Optimization",
                                "total": {
                                    "id": "",
                                    "name": "demandOptimization.total",
                                    "label": "Demand Optimization Total",
                                    "uri": "",
                                    "value": 36790.23,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "cro.contribution",
                                            "label": "Centralized RO",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "ef.contribution",
                                            "label": "Efficient Fixtures",
                                            "uri": "",
                                            "value": 21961.45,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "df.contribution",
                                            "label": "Dual Flush",
                                            "uri": "",
                                            "value": 12949.24,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "swm.contribution",
                                            "label": "Smart Water Meters",
                                            "uri": "",
                                            "value": 1879.55,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Treatment And Re-use",
                                "total": {
                                    "id": "",
                                    "name": "treatmentReuse.total",
                                    "label": "Treatment And Re-use Total",
                                    "uri": "",
                                    "value": 23419.37,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "gwr.contribution",
                                            "label": "Grey Water Re-use",
                                            "uri": "",
                                            "value": 18902.67,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "bwr.contribution",
                                            "label": "Black Water Re-use",
                                            "uri": "",
                                            "value": 4516.69,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Source Creation",
                                "total": {
                                    "id": "",
                                    "name": "sourceCreation.total",
                                    "label": "Source Creation Total",
                                    "uri": "",
                                    "value": 787.14,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "rrwh.contribution",
                                            "label": "Rooftop Rainwater Harvesting",
                                            "uri": "",
                                            "value": 787.14,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "srwh.contribution",
                                            "label": "Surface Rainwater Harvesting",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            ],
            "sustainabilityLevel": {
                "id": "",
                "name": "sustainabilityLevel",
                "label": "Sustainability Level",
                "uri": "",
                "value": 1,
                "tags": [],
                "unit": "Level"
            },
            "locationBasedInsights": {
                "aqi": {
                    "id": "",
                    "name": "aqi",
                    "label": "AQI",
                    "uri": "",
                    "value": {
                        "aqi": 79,
                        "biggestPollutant": "PM2.5",
                        "color": "Yellow",
                        "stationName": "BWSSB Kadabesanahalli, Bengaluru - CPCB"
                    },
                    "tags": [],
                    "unit": "AQI"
                },
                "temperature": {
                    "id": "",
                    "name": "temperature",
                    "label": "Temperature",
                    "uri": "",
                    "value": {
                        "avgHumidity": 64.01,
                        "avgRainfall": 1018.2,
                        "avgTemp": 26.38,
                        "avgWindSpeed": 12.81,
                        "maxTemp": 29.63,
                        "minTemp": 20.18
                    },
                    "tags": [],
                    "unit": "Temperature"
                },
                "igbc": {
                    "id": "",
                    "name": "IGBC",
                    "label": "IGBC",
                    "uri": "",
                    "value": [
                        {
                            "_id": "61a8ac6f8b2d2c24c0ecf1a7",
                            "stationName": "Embassy Pristine",
                            "latitude": 12.9264932,
                            "longitude": 77.6620067,
                            "igbcRating": "Platinum",
                            "type": "Luxury",
                            "launchDate": "Jan 2017",
                            "sitearea": "14 Acres",
                            "builtupArea": 1669870,
                            "noOfapts": 424,
                            "configurations": "3, 4, 5, 5.5 BHK",
                            "avgPricePerSqftInArea": 7393,
                            "avgPricePerSqftForProject": 8500,
                            "startingPrice": "2.19 Cr",
                            "address": "Tower 4, Embassy Pristine, Iblur Village, Bellandur, Bengaluru, Karnataka 560102, India",
                            "createdAt": "2021-12-02T11:22:23.579Z",
                            "__v": 0
                        },
                        {
                            "_id": "61a9bf987f39333ab0c9012c",
                            "stationName": "G:Corp Residences",
                            "latitude": 12.9304,
                            "longitude": 77.6366,
                            "igbcRating": "Gold",
                            "type": "Luxury",
                            "launchDate": "Jul 2021",
                            "sitearea": "2.5 Acres",
                            "builtupArea": null,
                            "noOfapts": 234,
                            "configurations": "2,3,4 BHK",
                            "avgPricePerSqftInArea": 15380,
                            "avgPricePerSqftForProject": null,
                            "startingPrice": "2.53 cr",
                            "address": "115, 1st Block, Opp. Nirguna Mandir, S T Bed Layout, Koramangala, Bengaluru, Karnataka 560034",
                            "createdAt": "2021-12-03T06:56:24.914Z",
                            "__v": 0
                        }
                    ],
                    "tags": [],
                    "unit": "IGBC"
                }
            },
            "context": {
                "cache": {
                    "PaintsDBCache": {}
                },
                "project": {
                    "id": "661a393bb77e55e58247baa7",
                    "pip": {
                        "Location": "HSR Layout, HAL 3rd Stage, Bhoomi Reddy Colony, New Tippasandra, Bengaluru, Karnataka, India",
                        "RooftopArea": 1000,
                        "BuiltUpArea": 55234,
                        "NonavailableRooftop": 500,
                        "Studio": 50,
                        "BHK1": 48,
                        "BHK2": 52,
                        "BHK3": 30,
                        "BHK4": 34,
                        "NumberOfFloors": 30,
                        "WWR": 0.25,
                        "perimeter": 110,
                        "Floor2FloorHeight": 3,
                        "SoftSurfaceArea": 0,
                        "Sitearea": 3254,
                        "BasecaseSPVCapacity": 0,
                        "FlooringUtilityandBalcony": "Vitrified Tiles",
                        "FlooringBathroom": "Vitrified Tiles",
                        "FlooringBedroom": "Terrazzo",
                        "FlooringKitchen": "Terrazzo",
                        "FlooringLivingandDining": "Terrazzo",
                        "clubhouseRooftopArea": 11,
                        "materials": {
                            "interiorpaint": {
                                "solvent": {},
                                "manual": {
                                    "input": {}
                                },
                                "preset": {
                                    "input": {}
                                }
                            },
                            "concrete": {
                                "typeofconcrete": "Portland Slag Cement (PSC)"
                            },
                            "manual": {
                                "input": {}
                            },
                            "preset": {
                                "input": {
                                    "type": "Solvent-Based Paints"
                                }
                            }
                        },
                        "LandscapeArea": 0,
                        "NoOfCarParks": 0,
                        "AreaOfLobbiesAndCorridor": 0,
                        "SwimmingPoolSurfaceArea": 0,
                        "water": {
                            "manual": {
                                "enabled": true,
                                "flows": {
                                    "domesticHealthFaucet": 11,
                                    "domesticFaucet": 11,
                                    "domesticKitchenSink": 11,
                                    "domesticShower": 11,
                                    "flushingFullWC": 11,
                                    "flushingHalfWC": 11
                                }
                            },
                            "preset": {
                                "enabled": false,
                                "faucets": {
                                    "brand": "Jaquar",
                                    "tags": "Basic"
                                },
                                "ceramics": {
                                    "brand": "Hindware",
                                    "tags": "Basic"
                                }
                            },
                            "parameters": {
                                "STP": {},
                                "CRO": {
                                    "CROinstalled": {},
                                    "individualROsystem": {}
                                },
                                "GWR": {}
                            }
                        },
                        "waste": {
                            "paramters": {
                                "WWB": {}
                            }
                        }
                    },
                    "global": {
                        "parameters": {
                            "UtilityPricePerkWh": 6.5
                        }
                    },
                    "energy": {
                        "goal": {},
                        "parameters": {}
                    },
                    "waste": {
                        "goal": {},
                        "parameters": {}
                    },
                    "water": {
                        "goal": {},
                        "parameters": {}
                    }
                },
                "intervention": {
                    "solarpv": {
                        "enabled": true,
                        "parameters": {},
                        "solarpv": {
                            "parameters": {
                                "SPVCapacity": 47,
                                "CapexMultiplier": 1,
                                "MultiplierSource": "CUSTOM",
                                "SolarModule": "Standard",
                                "RooftopAreaPerkWp": 10
                            }
                        }
                    },
                    "solarwaterheater": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "hotwatermanagementvilla": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturehealthfaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturefaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturekitchenfaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixtureshower": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "hotwatermanagementapartment": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "wwb": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "casp": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "sni": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "cro": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixtures": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "smartwatermeters": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "dualflush": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "rrwh": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "srwh": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bwr": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "gwr": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "exteriorPaints": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "interiorPaints": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "structuralsteel": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "concrete": {
                        "enabled": true,
                        "parameters": {},
                        "concrete": {
                            "parameters": {
                                "TypeOfConcrete": "Portland Pozzolana Cement (PPC)",
                                "CapexMultiplier": 4500,
                                "MultiplierSource": "DEFAULT",
                                "ConcreteDensity": 2500
                            }
                        }
                    },
                    "livinganddining": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bedroom": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "kitchen": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bathroom": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "utilityandbalcony": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "custom": {}
                },
                "vendor": {
                    "bwr": {
                        "parameters": {
                            "capexMultiplier": 50000,
                            "resourceMultiplier": 85,
                            "areaMultiplier": 3.8
                        }
                    },
                    "sni": {
                        "parameters": {}
                    },
                    "solarwaterheater": {
                        "parameters": {
                            "capexMultiplier": 118,
                            "resourceMultiplier": 15,
                            "areaMultiplier": 0.03
                        }
                    },
                    "wwb": {
                        "parameters": {
                            "capexMultiplier": 4000,
                            "resourceMultiplier": 0.2,
                            "areaMultiplier": 0.056
                        }
                    },
                    "casp": {
                        "parameters": {
                            "capexMultiplier": 1,
                            "resourceMultiplier": 1,
                            "areaMultiplier": 2
                        }
                    },
                    "cro": {
                        "parameters": {
                            "capexMultiplier": 25,
                            "resourceMultiplier": 0.7,
                            "areaMultiplier": 20
                        }
                    },
                    "dualflush": {
                        "parameters": {
                            "capexMultiplier": 7000,
                            "resourceMultiplier": 18,
                            "areaMultiplier": 1
                        }
                    },
                    "smartwatermeters": {
                        "parameters": {
                            "capexMultiplier": 12000,
                            "resourceMultiplier": 7,
                            "areaMultiplier": 0
                        }
                    },
                    "gwr": {
                        "parameters": {
                            "capexMultiplier": 50000,
                            "resourceMultiplier": 85,
                            "areaMultiplier": 3.8
                        }
                    },
                    "rrwh": {
                        "parameters": {}
                    },
                    "srwh": {
                        "parameters": {}
                    },
                    "solarpv": {
                        "parameters": {
                            "capexMultiplier": 45000,
                            "resourceMultiplier": 4.4,
                            "areaMultiplier": 10
                        }
                    },
                    "efficientfixtures": {
                        "parameters": {
                            "capexMultiplier": 89,
                            "resourceMultiplier": 10,
                            "areaMultiplier": 0
                        }
                    }
                },
                "userInput": {
                    "NumberOfYears": null
                },
                "iip": {
                    "id": "661a393bb77e55e58247badf",
                    "projectID": "661a393bb77e55e58247baa7",
                    "allmappings": []
                }
            }
        }
    }
}
export const dummyGoalsResponseForGoalsInsights = {
    "success": true,
    "data": {
        "version": "v1",
        "data": {
            "id": "661a393bb77e55e58247baa7",
            "name": "rishabh apr 13",
            "category": "residential",
            "createdBy": "601799db12ee04002364d2c9",
            "location": {
                "name": "HSR Layout, HAL 3rd Stage, Bhoomi Reddy Colony, New Tippasandra, Bengaluru, Karnataka 560075, India",
                "type": "Point",
                "coordinates": [
                    12.9741315,
                    77.6563865
                ]
            },
            "organization": {
                "uri": ""
            },
            "uri": "",
            "subcategory": "apartment",
            "goals": {
                "categories": {
                    "energy": {
                        "goal": {
                            "name": "Net Zero Energy",
                            "description": "Net Zero Energy buildings generate as much energy as they consume, resulting in a net-zero balance between energy production and consumption.",
                            "progress": 2,
                            "costPerSqFt": 37.69511532751566,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Maximize Onsite Renewable Energy",
                                "maxRenewableEnergySavings": 85371,
                                "maxContributionPercentage": 2.272976576661008,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 100,
                                "status": "Achieved",
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            },
                            {
                                "name": "Common Area Energy Neutral",
                                "maxRenewableEnergySavings": 85371,
                                "maxContributionPercent": 2.272976576661008,
                                "maxPotentialPercent": 2.272976576661008,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 2.272976576661008,
                                "status": "In Progress",
                                "achievability": "No",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            }
                        ]
                    },
                    "water": {
                        "goal": {
                            "name": "Net Zero Water",
                            "description": "Net Zero Water for a building involves strategies to minimize water usage and maximize reuse, aiming to balance water consumption with an equal return to the local watershed or aquifer, ultimately achieving a net-zero impact on water resources.",
                            "progress": 85,
                            "costPerSqFt": 236.9833798022957,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Complete Smart Water Metering",
                                "maxContributionPercent": 2.6318368261167806,
                                "additionalCost": 0,
                                "tier": "Tier I",
                                "progress": 100,
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 0,
                                "state": true
                            }
                        ]
                    },
                    "waste": {
                        "goal": {
                            "name": "Net Zero Waste",
                            "description": "Net Zero Waste in a building aims to reduce waste generation, increase recycling, and minimize landfill contributions, ultimately striving for a net-zero impact on waste disposal.",
                            "progress": 85,
                            "costPerSqFt": 236.9833798022957,
                            "status": "in progress"
                        },
                        "targets": [
                            {
                                "name": "Sustainable Hazardous Waste Disposal",
                                "maxContributionPercent": 2.6318368261167806,
                                "additionalCost": 2,
                                "tier": "Tier II",
                                "progress": 25,
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 2,
                                "state": true
                            }
                        ]
                    },
                    "embodiedCarbon": {
                        "goal": {
                            "name": "Net Zero Embodied Carbon",
                            "description": "Net Zero Embodied Carbon in construction minimizes carbon emissions from building materials and processes, aiming for a net-zero impact on the building's carbon footprint.",
                            "progress": 527,
                            "costPerSqFt": 37.69511532751566,
                            "status": "achieved"
                        },
                        "targets": [
                            {
                                "name": "Core & Shell Alternative Materials",
                                "list": "Structural Steel, Cement",
                                "maxMaterialSavings": 1944,
                                "maxContributionPercent": 526.829268292683,
                                "additionalCost": 232038034,
                                "tier": "Tier IV",
                                "progress": 526.829268292683,
                                "status": "Achieved",
                                "achievability": "Yes",
                                "additionalCostPerUnitArea": 4201,
                                "state": true
                            }
                        ]
                    },
                    "scorecard": {
                        "goals": {
                            "achieved": 1,
                            "targeted": 4
                        },
                        "targets": {
                            "achieved": 2,
                            "targeted": 4
                        },
                        "capexIncrease": 549.3569902596228
                    }
                }
            },
            "interventions": {
                "quantities": {
                    "overall": {
                        "finance": {
                            "capex": {
                                "id": "project.Capex",
                                "name": "project.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 9542932,
                                "defaultValue": 0
                            },
                            "capexConverted": {
                                "id": "project.CapexConverted",
                                "name": "project.CapexConverted",
                                "label": "",
                                "tags": [
                                    "CapexConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs/Sqft",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 16,
                                "defaultValue": 0
                            },
                            "opexSavings": {
                                "id": "project.Opex",
                                "name": "project.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "irr": {
                                "id": "project.Irr",
                                "name": "project.Irr",
                                "label": "",
                                "tags": [
                                    "Irr",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "%",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "paybackPeriod": {
                                "id": "project.PaybackPeriod",
                                "name": "project.PaybackPeriod",
                                "label": "",
                                "tags": [
                                    "PaybackPeriod",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "years",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 2.36,
                                "defaultValue": 0
                            },
                            "overallExpenses": {
                                "id": "project.OverallExpenses",
                                "name": "project.OverallExpenses",
                                "label": "",
                                "tags": [
                                    "OverallExpenses",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": "n.a",
                                "defaultValue": 0
                            },
                            "totalCapex": {
                                "id": "project.TotalCapex",
                                "name": "project.TotalCapex",
                                "label": "",
                                "tags": [
                                    "_TotalCapex",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 15893592,
                                "defaultValue": 0
                            },
                            "totalCapexConverted": {
                                "id": "project.TotalCapexConverted",
                                "name": "project.TotalCapexConverted",
                                "label": "",
                                "tags": [
                                    "_TotalCapexConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "Rs/Sqft",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 27,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "carbon": {
                                "id": "project.Carbon",
                                "name": "project.Carbon",
                                "label": "",
                                "tags": [
                                    "Carbon",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "tCO2e",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 113,
                                "defaultValue": 0
                            },
                            "carbonConverted": {
                                "id": "project.CarbonConverted",
                                "name": "project.CarbonConverted",
                                "label": "",
                                "tags": [
                                    "CarbonConverted",
                                    "Project"
                                ],
                                "uri": "",
                                "unit": "cars off the road for a year",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 25,
                                "defaultValue": 0
                            }
                        }
                    }
                },
                "categorized": [
                    {
                        "name": "Waste",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "waste.Capex",
                                    "name": "waste.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 220000,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "waste.CapexConverted",
                                    "name": "waste.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 0,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "waste.Opex",
                                    "name": "waste.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 34595,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "waste.CapexTotal",
                                    "name": "waste.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 722000,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "waste.CapexTotalConverted",
                                    "name": "waste.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 1,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "waste.Savings",
                                    "name": "waste.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kg",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 104740,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "waste.SavingsConverted",
                                    "name": "waste.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Waste Trucks Avoided",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "waste.Carbon",
                                    "name": "waste.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "waste.CarbonConverted",
                                    "name": "waste.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 2,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "OrganicWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 198,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "DryWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 66,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "SanitaryWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "MedicalWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "E_Waste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 7,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "RejectWaste": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 45,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "right": {
                                    "Biogas": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 198,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Compost": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "OnsiteIncinerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "OffsiteIncinerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Recycler": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "Landfill": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 45,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Biogas",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Compost",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "OnsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "OrganicWaste",
                                        "right": "landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "DryWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 66,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Biogas",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Compost",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "OnsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "SanitaryWaste",
                                        "right": "Landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "MedicalWaste",
                                        "right": "OffsiteIncinerator",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "E_Waste",
                                        "right": "Recycler",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 7,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "RejectWaste",
                                        "right": "Landfill",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 45,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Management",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.management.Capex",
                                            "name": "waste.management.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.management.CapexConverted",
                                            "name": "waste.management.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.management.Opex",
                                            "name": "waste.management.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.management.CapexTotal",
                                            "name": "waste.management.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 22000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.management.CapexTotalConverted",
                                            "name": "waste.management.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.management.Savings",
                                            "name": "waste.management.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 31740,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.management.Carbon",
                                            "name": "waste.management.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Collection & Segregation Point",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb28",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.management.casp.Capex",
                                                    "name": "waste.management.casp.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.management.casp.CapexConverted",
                                                    "name": "waste.management.casp.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.management.casp.Opex",
                                                    "name": "waste.management.casp.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.management.casp.CapexTotal",
                                                    "name": "waste.management.casp.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.management.casp.CapexTotalConverted",
                                                    "name": "waste.management.casp.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.management.casp.Savings",
                                                    "name": "waste.management.casp.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.management.casp.Carbon",
                                                    "name": "waste.management.casp.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Water_Saved",
                                                    "name": "waste.management.casp.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Water_Opex Savings",
                                                    "name": "waste.management.casp.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Energy_Saved",
                                                    "name": "waste.management.casp.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Energy_Opex Savings",
                                                    "name": "waste.management.casp.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.%_of Total waste",
                                                    "name": "waste.management.casp.%_of Total waste",
                                                    "label": "% of Total waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Dry_Waste as %",
                                                    "name": "waste.management.casp.Dry_Waste as %",
                                                    "label": "Dry Waste as %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 89,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Medical_Waste as %",
                                                    "name": "waste.management.casp.Medical_Waste as %",
                                                    "label": "Medical Waste as %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.E-waste_Waste %",
                                                    "name": "waste.management.casp.E-waste_Waste %",
                                                    "label": "E-waste Waste %",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewMin",
                                                    "name": "waste.management.casp.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewMax",
                                                    "name": "waste.management.casp.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.OverviewTotal",
                                                    "name": "waste.management.casp.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Biogas",
                                                    "name": "waste.management.casp.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Compost",
                                                    "name": "waste.management.casp.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.on-site_incinerator",
                                                    "name": "waste.management.casp.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.off-site_incinerator",
                                                    "name": "waste.management.casp.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Recycler",
                                                    "name": "waste.management.casp.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26570,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Landfill",
                                                    "name": "waste.management.casp.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Savings",
                                                    "name": "waste.management.casp.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SavingsConverted",
                                                    "name": "waste.management.casp.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexPre",
                                                    "name": "waste.management.casp.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexPost",
                                                    "name": "waste.management.casp.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Capex",
                                                    "name": "waste.management.casp.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexConverted",
                                                    "name": "waste.management.casp.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Opex",
                                                    "name": "waste.management.casp.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Carbon",
                                                    "name": "waste.management.casp.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.IRR",
                                                    "name": "waste.management.casp.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.PaybackPeriod",
                                                    "name": "waste.management.casp.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SdgNumber",
                                                    "name": "waste.management.casp.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.SdgText",
                                                    "name": "waste.management.casp.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Locked",
                                                    "name": "waste.management.casp.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.Enabled",
                                                    "name": "waste.management.casp.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.GoalTarget",
                                                    "name": "waste.management.casp.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.GoalContribution",
                                                    "name": "waste.management.casp.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexTotal",
                                                    "name": "waste.management.casp.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.casp.CapexTotalConverted",
                                                    "name": "waste.management.casp.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.drywastedaily",
                                                    "name": "collectionandsegregationpoint.outcomes.drywastedaily",
                                                    "label": "Daily Dry Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 65.87774999999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "name": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "label": "Daily Medical Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.9881662499999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "name": "collectionandsegregationpoint.outcomes.dailymedicalwaste",
                                                    "label": "Daily E-Waste generated",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6.917163749999999,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.drywastecontent",
                                                    "name": "collectionandsegregationpoint.constants.drywastecontent",
                                                    "label": "Dry waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.ewastecontent",
                                                    "name": "collectionandsegregationpoint.constants.ewastecontent",
                                                    "label": "E-waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.021,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.medicalwastecontent",
                                                    "name": "collectionandsegregationpoint.constants.medicalwastecontent",
                                                    "label": "Medical waste per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.003,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorfordrywaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorfordrywaste",
                                                    "label": "Dry Waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorforewaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorforewaste",
                                                    "label": "E-waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.constants.emissionfactorformedicalwaste",
                                                    "name": "collectionandsegregationpoint.constants.emissionfactorformedicalwaste",
                                                    "label": "Medical Waste Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateDetails",
                                                    "name": "collectionandsegregationpoint.MandateDetails",
                                                    "label": "CASP Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, Bulk generators shall retain Dry waste within the premises and should be handed over to the authorised agency."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateSummary",
                                                    "name": "collectionandsegregationpoint.MandateSummary",
                                                    "label": "CASP Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.MandateGenerals",
                                                    "name": "collectionandsegregationpoint.MandateGenerals",
                                                    "label": "CASP Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.arearequired",
                                                    "name": "collectionandsegregationpoint.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 74,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.drywasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.drywasteannualpresdplus",
                                                    "label": "Dry Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 24045,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.drywasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.drywasteannualpostsdplus",
                                                    "label": "Dry Waste Annual Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 24045,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.ewasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.ewasteannualpresdplus",
                                                    "label": "E Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2525,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.ewasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.ewasteannualpostsdplus",
                                                    "label": "E Waste Annual Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2525,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.medicalwasteannualpresdplus",
                                                    "name": "collectionandsegregationpoint.medicalwasteannualpresdplus",
                                                    "label": "Medical Waste Annual Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.medicalwasteannualpostsdplus",
                                                    "name": "collectionandsegregationpoint.medicalwasteannualpostsdplus",
                                                    "label": "Medical Waste Annual post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 361,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "collectionandsegregationpoint.annualwastepresdplus",
                                                    "name": "collectionandsegregationpoint.annualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 26931,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Sanitary Napkin Incinerator",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb34",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.management.sni.Capex",
                                                    "name": "waste.management.sni.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.management.sni.CapexConverted",
                                                    "name": "waste.management.sni.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.management.sni.Opex",
                                                    "name": "waste.management.sni.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.management.sni.CapexTotal",
                                                    "name": "waste.management.sni.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.management.sni.CapexTotalConverted",
                                                    "name": "waste.management.sni.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.management.sni.Savings",
                                                    "name": "waste.management.sni.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.management.sni.Carbon",
                                                    "name": "waste.management.sni.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Water_Saved",
                                                    "name": "waste.management.sni.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Water_Opex Savings",
                                                    "name": "waste.management.sni.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Energy_Saved",
                                                    "name": "waste.management.sni.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Energy_Opex Savings",
                                                    "name": "waste.management.sni.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.%_of Total Waste",
                                                    "name": "waste.management.sni.%_of Total Waste",
                                                    "label": "% of Total Waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewMin",
                                                    "name": "waste.management.sni.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewMax",
                                                    "name": "waste.management.sni.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.OverviewTotal",
                                                    "name": "waste.management.sni.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Biogas",
                                                    "name": "waste.management.sni.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Compost",
                                                    "name": "waste.management.sni.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.on-site_incinerator",
                                                    "name": "waste.management.sni.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.off-site_incinerator",
                                                    "name": "waste.management.sni.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Recycler",
                                                    "name": "waste.management.sni.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Landfill",
                                                    "name": "waste.management.sni.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Savings",
                                                    "name": "waste.management.sni.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SavingsConverted",
                                                    "name": "waste.management.sni.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexPre",
                                                    "name": "waste.management.sni.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexPost",
                                                    "name": "waste.management.sni.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Capex",
                                                    "name": "waste.management.sni.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexConverted",
                                                    "name": "waste.management.sni.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Opex",
                                                    "name": "waste.management.sni.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Carbon",
                                                    "name": "waste.management.sni.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.IRR",
                                                    "name": "waste.management.sni.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.PaybackPeriod",
                                                    "name": "waste.management.sni.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SdgNumber",
                                                    "name": "waste.management.sni.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.SdgText",
                                                    "name": "waste.management.sni.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Locked",
                                                    "name": "waste.management.sni.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.Enabled",
                                                    "name": "waste.management.sni.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.GoalTarget",
                                                    "name": "waste.management.sni.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.GoalContribution",
                                                    "name": "waste.management.sni.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexTotal",
                                                    "name": "waste.management.sni.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.management.sni.CapexTotalConverted",
                                                    "name": "waste.management.sni.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.capexmultiplier",
                                                    "name": "sanitarynapkinincinerator.constants.capexmultiplier",
                                                    "label": "Capex (per incinerator system)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 22000,
                                                    "defaultValue": 22000
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.VendorCapexMultiplier",
                                                    "name": "sanitarynapkinincinerator.VendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "SanitaryNapkinIncinerator.multipliersource",
                                                    "name": "SanitaryNapkinIncinerator.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.sanitarynapkincontent",
                                                    "name": "sanitarynapkinincinerator.constants.sanitarynapkincontent",
                                                    "label": "Sanitary content per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.constants.emissionfactorforsanitarynapkin",
                                                    "name": "sanitarynapkinincinerator.constants.emissionfactorforsanitarynapkin",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.outcomes.snicapacity",
                                                    "name": "sanitarynapkinincinerator.outcomes.snicapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateDetails",
                                                    "name": "sanitarynapkinincinerator.MandateDetails",
                                                    "label": "SNI Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, bulk generators shall have the sanitary wastes to be separately collected by in-house staff and deposited at the nearest bio-medical waste centre to be specified by the BBMP or arranged by mutual consent."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateSummary",
                                                    "name": "sanitarynapkinincinerator.MandateSummary",
                                                    "label": "SNI Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.MandateGenerals",
                                                    "name": "sanitarynapkinincinerator.MandateGenerals",
                                                    "label": "SNI Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "sanitarynapkinincinerator.annualwastepresdplus",
                                                    "name": "sanitarynapkinincinerator.annualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4809,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Reduction",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.reduction.Capex",
                                            "name": "waste.reduction.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 220000,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.reduction.CapexConverted",
                                            "name": "waste.reduction.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.reduction.Opex",
                                            "name": "waste.reduction.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 34595,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.reduction.CapexTotal",
                                            "name": "waste.reduction.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 700000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.reduction.CapexTotalConverted",
                                            "name": "waste.reduction.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.reduction.Savings",
                                            "name": "waste.reduction.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 73000,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.reduction.Carbon",
                                            "name": "waste.reduction.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 9,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Wet Waste Management",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb1f",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "waste.reduction.wwb.Capex",
                                                    "name": "waste.reduction.wwb.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "waste.reduction.wwb.CapexConverted",
                                                    "name": "waste.reduction.wwb.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "waste.reduction.wwb.Opex",
                                                    "name": "waste.reduction.wwb.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "waste.reduction.wwb.CapexTotal",
                                                    "name": "waste.reduction.wwb.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "waste.reduction.wwb.CapexTotalConverted",
                                                    "name": "waste.reduction.wwb.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "waste.reduction.wwb.Savings",
                                                    "name": "waste.reduction.wwb.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "waste.reduction.wwb.Carbon",
                                                    "name": "waste.reduction.wwb.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "waste.CapexTotal",
                                                    "name": "waste.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 722000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexTotalConverted",
                                                    "name": "waste.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Savings",
                                                    "name": "waste.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 104740,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.SavingsConverted",
                                                    "name": "waste.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Capex",
                                                    "name": "waste.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CapexConverted",
                                                    "name": "waste.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Opex",
                                                    "name": "waste.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.Carbon",
                                                    "name": "waste.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.CarbonConverted",
                                                    "name": "waste.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.totalpercentagesaving",
                                                    "name": "waste.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.annualbaselinewastegenration",
                                                    "name": "waste.annualbaselinewastegenration",
                                                    "label": "Annual Baseline Waste Generation",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 120227,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Water_Saved",
                                                    "name": "waste.reduction.wwb.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Water_OPEX Savings",
                                                    "name": "waste.reduction.wwb.Water_OPEX Savings",
                                                    "label": "Water OPEX Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Energy_Saved",
                                                    "name": "waste.reduction.wwb.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Energy_OPEX Savings",
                                                    "name": "waste.reduction.wwb.Energy_OPEX Savings",
                                                    "label": "Energy OPEX Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.%_of Total waste",
                                                    "name": "waste.reduction.wwb.%_of Total waste",
                                                    "label": "% of Total waste",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 61,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.%_of Common Area Energy",
                                                    "name": "waste.reduction.wwb.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewMin",
                                                    "name": "waste.reduction.wwb.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewMax",
                                                    "name": "waste.reduction.wwb.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.OverviewTotal",
                                                    "name": "waste.reduction.wwb.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Biogas",
                                                    "name": "waste.reduction.wwb.Biogas",
                                                    "label": "Biogas",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Compost",
                                                    "name": "waste.reduction.wwb.Compost",
                                                    "label": "Compost",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.on-site_incinerator",
                                                    "name": "waste.reduction.wwb.on-site_incinerator",
                                                    "label": "on-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.off-site_incinerator",
                                                    "name": "waste.reduction.wwb.off-site_incinerator",
                                                    "label": "off-site incinerator",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Recycler",
                                                    "name": "waste.reduction.wwb.Recycler",
                                                    "label": "Recycler",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Landfill",
                                                    "name": "waste.reduction.wwb.Landfill",
                                                    "label": "Landfill",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Savings",
                                                    "name": "waste.reduction.wwb.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SavingsConverted",
                                                    "name": "waste.reduction.wwb.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Waste Trucks",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexPre",
                                                    "name": "waste.reduction.wwb.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 480000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexPost",
                                                    "name": "waste.reduction.wwb.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Capex",
                                                    "name": "waste.reduction.wwb.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 220000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexConverted",
                                                    "name": "waste.reduction.wwb.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Opex",
                                                    "name": "waste.reduction.wwb.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34595,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Carbon",
                                                    "name": "waste.reduction.wwb.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.IRR",
                                                    "name": "waste.reduction.wwb.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.PaybackPeriod",
                                                    "name": "waste.reduction.wwb.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SdgNumber",
                                                    "name": "waste.reduction.wwb.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        12
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.SdgText",
                                                    "name": "waste.reduction.wwb.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 12 - Responsible Consumption and Production",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Locked",
                                                    "name": "waste.reduction.wwb.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.Enabled",
                                                    "name": "waste.reduction.wwb.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.GoalTarget",
                                                    "name": "waste.reduction.wwb.GoalTarget",
                                                    "label": "Waste Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 87,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.GoalContribution",
                                                    "name": "waste.reduction.wwb.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 70,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexTotal",
                                                    "name": "waste.reduction.wwb.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "waste.reduction.wwb.CapexTotalConverted",
                                                    "name": "waste.reduction.wwb.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.outcomes.wwbcapacity",
                                                    "name": "wetwastetobiogas.outcomes.wwbcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 200,
                                                    "defaultValue": 200
                                                },
                                                {
                                                    "id": "wetwastetobiogas.userinput.enduse",
                                                    "name": "wetwastetobiogas.userinput.enduse",
                                                    "label": "End Use",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Natural Composting (upto 1,000 kgs)",
                                                    "defaultValue": "Natural Composting (upto 1,000 kgs)",
                                                    "valueOptions": [
                                                        {
                                                            "option": "Biogas (1,000+ kgs)",
                                                            "enabled": false
                                                        },
                                                        {
                                                            "option": "Biogas to Electricity (1,000+ kgs)",
                                                            "enabled": false
                                                        },
                                                        {
                                                            "option": "Natural Composting (upto 1,000 kgs)",
                                                            "enabled": true
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.capexmultiplier",
                                                    "name": "wetwastetobiogas.constants.capexmultiplier",
                                                    "label": "Capex (per Kg)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 4500,
                                                    "defaultValue": 4500
                                                },
                                                {
                                                    "id": "wetwastetobiogas.VendorCapexMultiplier",
                                                    "name": "wetwastetobiogas.VendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.multipliersource",
                                                    "name": "wetwastetobiogas.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kglpgperkgwetwaste",
                                                    "name": "wetwastetobiogas.constants.kglpgperkgwetwaste",
                                                    "label": "kg LPG per kg wet waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.04,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.costoflpg",
                                                    "name": "global.constants.costoflpg",
                                                    "label": "LPG Price per Kg",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 60
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kwhperbiogascum",
                                                    "name": "wetwastetobiogas.constants.kwhperbiogascum",
                                                    "label": "kWh per Biogas cum",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1.5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.wetwastecontent",
                                                    "name": "wetwastetobiogas.constants.wetwastecontent",
                                                    "label": "Wet content per Kg Waste",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.emmitionfactorforlpg",
                                                    "name": "wetwastetobiogas.constants.emmitionfactorforlpg",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3.13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "wetwastetobiogas.constants.kg2cumconv",
                                                    "name": "wetwastetobiogas.constants.kg2cumconv",
                                                    "label": "Kg to cum conversion",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cum",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.08,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateDetails",
                                                    "name": "wetwastetobiogas.MandateDetails",
                                                    "label": "WWB Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP, Bulk Generators shall not mix Wet Waste with any other types of waste. Wet waste and garden waste generated shall be composted at source or processed using bio methanisation technique. Where it is not possible to compost wet waste at site, due to space constraint, alternate arrangements shall be made by the generator to hand over wet waste to private composters or BBMP wet waste collectors, on payment, as specified by concerned Zonal Commissioners."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateSummary",
                                                    "name": "wetwastetobiogas.MandateSummary",
                                                    "label": "WWB Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.MandateGenerals",
                                                    "name": "wetwastetobiogas.MandateGenerals",
                                                    "label": "WWB Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.arearequired",
                                                    "name": "wetwastetobiogas.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.endusepresdplus",
                                                    "name": "wetwastetobiogas.endusepresdplus",
                                                    "label": "End use Pre SDPlus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Compost",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "name": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "label": "WWB Process Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Organic Waste Composter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbannualwastepresdplus",
                                                    "name": "wetwastetobiogas.wwbannualwastepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72136,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "name": "wetwastetobiogas.wwbprocesspresdplus",
                                                    "label": "WWB Process Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 700000,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "waste.custom-interventions.Capex",
                                            "name": "waste.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "waste.custom-interventions.CapexConverted",
                                            "name": "waste.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "waste.custom-interventions.Opex",
                                            "name": "waste.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "waste.custom-interventions.CapexTotal",
                                            "name": "waste.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "waste.custom-interventions.CapexTotalConverted",
                                            "name": "waste.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "waste.custom-interventions.Savings",
                                            "name": "waste.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kg",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "waste.custom-interventions.Carbon",
                                            "name": "waste.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Energy",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "energy.Capex",
                                    "name": "energy.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 2082052,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "energy.CapexConverted",
                                    "name": "energy.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 4,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "energy.Opex",
                                    "name": "energy.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 554912,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "energy.CapexTotal",
                                    "name": "energy.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 2082052,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "energy.CapexTotalConverted",
                                    "name": "energy.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 4,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "energy.Savings",
                                    "name": "energy.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kWh",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 85371,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "energy.SavingsConverted",
                                    "name": "energy.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Homes powered annually",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 79,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "energy.Carbon",
                                    "name": "energy.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 60,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "energy.CarbonConverted",
                                    "name": "energy.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 13,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "mainGrid": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 9747,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "dieselGenerator": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 3858,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "solarWaterHeater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 48,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "solarPv": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 186,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "biogas": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "right": {
                                    "residentialHotWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 3793,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialLighting": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2161,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialFan": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2595,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "homeAppliances": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 2395,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "residentialHvac": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 299,
                                        "tags": [],
                                        "unit": "kWh"
                                    },
                                    "commonArea": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1298,
                                        "tags": [],
                                        "unit": "kWh"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialHotWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 3745,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialLighting",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 2096,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialFan",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "homeAppliances",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "residentialHvac",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 299,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "mainGrid",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1112,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "residentialLighting",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 65,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "residentialFan",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "homeAppliances",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1198,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "dieselGenerator",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1298,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "solarWaterHeater",
                                        "right": "residentialHotWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 48,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "solarPv",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 186,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    },
                                    {
                                        "left": "biogas",
                                        "right": "commonArea",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "kWh"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Renewable Sources",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "energy.renewable-sources.Capex",
                                            "name": "energy.renewable-sources.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 2082052,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "energy.renewable-sources.CapexConverted",
                                            "name": "energy.renewable-sources.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "energy.renewable-sources.Opex",
                                            "name": "energy.renewable-sources.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 554912,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "energy.renewable-sources.CapexTotal",
                                            "name": "energy.renewable-sources.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 2082052,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "energy.renewable-sources.CapexTotalConverted",
                                            "name": "energy.renewable-sources.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 4,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "energy.renewable-sources.Savings",
                                            "name": "energy.renewable-sources.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kWh",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 85371,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "energy.renewable-sources.Carbon",
                                            "name": "energy.renewable-sources.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 60,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Solar PV",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.solarpv.Capex",
                                                    "name": "energy.renewable-sources.solarpv.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.solarpv.Opex",
                                                    "name": "energy.renewable-sources.solarpv.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 441324,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.solarpv.Savings",
                                                    "name": "energy.renewable-sources.solarpv.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.solarpv.Carbon",
                                                    "name": "energy.renewable-sources.solarpv.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Water_Saved",
                                                    "name": "energy.renewable-sources.solarpv.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.solarpv.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Waste_Saved",
                                                    "name": "energy.renewable-sources.solarpv.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.solarpv.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.%_of Total Energy",
                                                    "name": "energy.renewable-sources.solarpv.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.solarpv.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 14,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewMin",
                                                    "name": "energy.renewable-sources.solarpv.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewMax",
                                                    "name": "energy.renewable-sources.solarpv.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.OverviewTotal",
                                                    "name": "energy.renewable-sources.solarpv.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Lighting",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_Fan",
                                                    "name": "energy.renewable-sources.solarpv.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Home_Appliances",
                                                    "name": "energy.renewable-sources.solarpv.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Residential_HVAC",
                                                    "name": "energy.renewable-sources.solarpv.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Common_Area",
                                                    "name": "energy.renewable-sources.solarpv.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 186,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Savings",
                                                    "name": "energy.renewable-sources.solarpv.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 67896,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SavingsConverted",
                                                    "name": "energy.renewable-sources.solarpv.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 63,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexPre",
                                                    "name": "energy.renewable-sources.solarpv.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexPost",
                                                    "name": "energy.renewable-sources.solarpv.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Capex",
                                                    "name": "energy.renewable-sources.solarpv.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Opex",
                                                    "name": "energy.renewable-sources.solarpv.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 441324,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Carbon",
                                                    "name": "energy.renewable-sources.solarpv.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.IRR",
                                                    "name": "energy.renewable-sources.solarpv.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.PaybackPeriod",
                                                    "name": "energy.renewable-sources.solarpv.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SdgNumber",
                                                    "name": "energy.renewable-sources.solarpv.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        7
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.SdgText",
                                                    "name": "energy.renewable-sources.solarpv.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 7 - Affordable and Clean Energy",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Locked",
                                                    "name": "energy.renewable-sources.solarpv.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.Enabled",
                                                    "name": "energy.renewable-sources.solarpv.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.GoalTarget",
                                                    "name": "energy.renewable-sources.solarpv.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.GoalContribution",
                                                    "name": "energy.renewable-sources.solarpv.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 80,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1936427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarpv.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacity",
                                                    "name": "solarpv.SPVCapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 47,
                                                    "defaultValue": 47
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacityMin",
                                                    "name": "solarpv.SPVCapacityMin",
                                                    "label": "Min",
                                                    "tags": [
                                                        "BoundaryMin"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarpv.SPVCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.SPVCapacityMax",
                                                    "name": "solarpv.SPVCapacityMax",
                                                    "label": "Max",
                                                    "tags": [
                                                        "BoundaryMax"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarpv.SPVCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.CapexMultiplier",
                                                    "name": "solarpv.CapexMultiplier",
                                                    "label": "Capex (per kWp installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 41640,
                                                    "defaultValue": 41640
                                                },
                                                {
                                                    "id": "solarpv.vedorfaultcapexmultiplier",
                                                    "name": "solarpv.vendorfaultcapexmultiplier",
                                                    "label": "Vendor Capex per kWp installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 45000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.multipliersource",
                                                    "name": "solarpv.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.ModuleType",
                                                    "name": "solarpv.ModuleType",
                                                    "label": "Module Type",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Standard",
                                                        "Premium"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Standard",
                                                    "defaultValue": "Standard"
                                                },
                                                {
                                                    "id": "solarpv.RooftopAreaPerkWp",
                                                    "name": "solarpv.RooftopAreaPerkWp",
                                                    "label": "Rooftop Area per kWp",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 10
                                                },
                                                {
                                                    "id": "solarpv.MandateDetails",
                                                    "name": "solarpv.MandateDetails",
                                                    "label": "SolarPV Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        " As per Central Govt. mandate, projects need to install rooftop Solar Photo Voltaic systems to meet project's partial energy demand"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.MandateSummary",
                                                    "name": "solarpv.MandateSummary",
                                                    "label": "SolarPV Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.MandateGenerals",
                                                    "name": "solarpv.MandateGenerals",
                                                    "label": "SolarPV Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "The maximum capacity is limited by electricity requirement of common areas or total available rooftop area, whichever is lower"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.arearequired",
                                                    "name": "solarpv.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 465,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.basecasespvcapacity",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.spvannualgenerationpresdplus",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Solar Water Heater",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 113588,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "name": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Water_Saved",
                                                    "name": "energy.renewable-sources.solarwaterheater.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Waste_Saved",
                                                    "name": "energy.renewable-sources.solarwaterheater.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.%_of Total Energy",
                                                    "name": "energy.renewable-sources.solarwaterheater.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewMin",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewMax",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.OverviewTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Lighting",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_Fan",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Home_Appliances",
                                                    "name": "energy.renewable-sources.solarwaterheater.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Residential_HVAC",
                                                    "name": "energy.renewable-sources.solarwaterheater.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Common_Area",
                                                    "name": "energy.renewable-sources.solarwaterheater.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "name": "energy.renewable-sources.solarwaterheater.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17475,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SavingsConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexPre",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexPost",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "name": "energy.renewable-sources.solarwaterheater.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 113588,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "name": "energy.renewable-sources.solarwaterheater.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.IRR",
                                                    "name": "energy.renewable-sources.solarwaterheater.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.PaybackPeriod",
                                                    "name": "energy.renewable-sources.solarwaterheater.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SdgNumber",
                                                    "name": "energy.renewable-sources.solarwaterheater.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        7
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.SdgText",
                                                    "name": "energy.renewable-sources.solarwaterheater.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 7 - Affordable and Clean Energy",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Locked",
                                                    "name": "energy.renewable-sources.solarwaterheater.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.Enabled",
                                                    "name": "energy.renewable-sources.solarwaterheater.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.GoalTarget",
                                                    "name": "energy.renewable-sources.solarwaterheater.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.GoalContribution",
                                                    "name": "energy.renewable-sources.solarwaterheater.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 145625,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.solarwaterheater.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.RooftopAreaPerLPD",
                                                    "name": "solarwaterheater.RooftopAreaPerLPD",
                                                    "label": "Rooftop Area per LPD",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.03,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacity",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1165,
                                                    "defaultValue": 1165
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacityMin",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "Min",
                                                    "tags": [
                                                        "BoundaryMin"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarwaterheater.SWHCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 583,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.SWHCapacityMax",
                                                    "name": "solarwaterheater.SWHCapacity",
                                                    "label": "Max",
                                                    "tags": [
                                                        "BoundaryMax"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "relatedTo": "solarwaterheater.SWHCapacity",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1165,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.ModuleType",
                                                    "name": "solarwaterheater.ModuleType",
                                                    "label": "System Type",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "ETC",
                                                    "defaultValue": "ETC"
                                                },
                                                {
                                                    "id": "solarwaterheater.CapexMultiplier",
                                                    "name": "solarwaterheater.CapexMultiplier",
                                                    "label": "Capex (per LPD installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 125,
                                                    "defaultValue": 125
                                                },
                                                {
                                                    "id": "solarwaterheater.DefaultCapexMultiplier",
                                                    "name": "solarwaterheater.DefaultCapexMultiplier",
                                                    "label": "Default Capex per KLD installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 118,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.multipliersource",
                                                    "name": "solarwaterheater.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateDetails",
                                                    "name": "solarwaterheater.MandateDetails",
                                                    "label": "SWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt. mandate, solar water heaters should be installed"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateSummary",
                                                    "name": "solarwaterheater.MandateSummary",
                                                    "label": "SWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.MandateGenerals",
                                                    "name": "solarwaterheater.MandateGenerals",
                                                    "label": "Solar Water Heater Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "We assume the minimum capacity to be one that meets the partial water heating requirements of the top 1 floor and maximum capacity to be for top 2 floors.The capacity calculation assumes 20 litres consumed per occupant per day"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.PerLPDkWh",
                                                    "name": "solarwaterheater.PerLPDkWh",
                                                    "label": "kWh equivalent to LPD",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8.52,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarwaterheater.arearequired",
                                                    "name": "solarwaterheater.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 35,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.basecasespvcapacity",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "solarpv.spvannualgenerationpresdplus",
                                                    "name": "solarpv.basecasespvcapacity",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Hot Water Management Apartment",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb50",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Water_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Waste_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.%_of Total Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewMin",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewMax",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.OverviewTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Lighting",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_Fan",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Home_Appliances",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Residential_HVAC",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Common_Area",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SavingsConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexPre",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexPost",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.IRR",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.PaybackPeriod",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SdgNumber",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.SdgText",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Locked",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.Enabled",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.GoalTarget",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.GoalContribution",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-apartment.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.swhCapacity",
                                                    "name": "hwmApartment.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 177400
                                                },
                                                {
                                                    "id": "hwmApartment.options",
                                                    "name": "hwmApartment.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump",
                                                        "hybrid"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": "solar water heater"
                                                },
                                                {
                                                    "id": "hwmApartment.swhType",
                                                    "name": "hwmApartment.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "central",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmApartment.swhVariant",
                                                    "name": "hwmApartment.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fpc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmApartment.individualProductCapacity",
                                                    "name": "hwmApartment.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.numberOfProducts",
                                                    "name": "hwmApartment.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.areaRequired",
                                                    "name": "hwmApartment.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.userHpCapacity",
                                                    "name": "hwmApartment.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.HpType",
                                                    "name": "hwmApartment.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.heatingSpeed",
                                                    "name": "hwmApartment.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "slow",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandByArea",
                                                    "name": "hwmApartment.demandByArea",
                                                    "label": "Demand By Area",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "total demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandByBath",
                                                    "name": "hwmApartment.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "all baths",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.hotwaterfloors",
                                                    "name": "hwmApartment.hotwaterfloors",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 2
                                                },
                                                {
                                                    "id": "hwmApartment.demandAssumption",
                                                    "name": "hwmApartment.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 177400,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.demandAssumptionType",
                                                    "name": "hwmApartment.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Apartment",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpd per apartment",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.lpcdPerOccupant",
                                                    "name": "hwmApartment.lpcdPerOccupant",
                                                    "label": "lpcd Per Occupant",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.lpcdPerApartment",
                                                    "name": "hwmApartment.lpcdPerApartment",
                                                    "label": "lpcd Per Apartment",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.CustomCapexMultiplier",
                                                    "name": "hwmApartment.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.vendorCapexMultiplier",
                                                    "name": "hwmApartment.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 47013004,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.multipliersource",
                                                    "name": "hwmApartment.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "vendor",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.ElectricityCostperkWh",
                                                    "name": "hwmApartment.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.rooftopAreaPerSWHLPD",
                                                    "name": "hwmApartment.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.rooftopAreaPerHPLPD",
                                                    "name": "hwmApartment.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.hotwaterusuageHours",
                                                    "name": "hwmApartment.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.numberofPlumbingShafts",
                                                    "name": "hwmApartment.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 2
                                                },
                                                {
                                                    "id": "hwmApartment.co2emmissionfactor",
                                                    "name": "hwmApartment.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "hwmApartment.MandateSummary",
                                                    "name": "hwmApartment.MandateSummary",
                                                    "label": "hwmApartment Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.MandateGenerals",
                                                    "name": "hwmApartment.MandateGenerals",
                                                    "label": "hwmApartment Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmApartment.MandateDetails",
                                                    "name": "hwmApartment.MandateDetails",
                                                    "label": "hwmApartment Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Hot Water Management Villa",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb46",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -1200,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "energy.CapexTotal",
                                                    "name": "energy.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexTotalConverted",
                                                    "name": "energy.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Savings",
                                                    "name": "energy.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.SavingsConverted",
                                                    "name": "energy.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes powered annually",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 79,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Capex",
                                                    "name": "energy.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2082052,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CapexConverted",
                                                    "name": "energy.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Opex",
                                                    "name": "energy.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 554912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.Carbon",
                                                    "name": "energy.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.CarbonConverted",
                                                    "name": "energy.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "global.constants.utilitypriceperkwh",
                                                    "name": "global.constants.utilitypriceperkwh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "assumption",
                                                        "Category",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 6.5,
                                                    "defaultValue": 6.5
                                                },
                                                {
                                                    "id": "energy.totalpercentagesaving",
                                                    "name": "energy.totalpercentagesaving",
                                                    "label": "% Total Energy Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.annualbaselineenergyconsumption",
                                                    "name": "energy.annualbaselineenergyconsumption",
                                                    "label": "Annual Baseline Energy Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3755912,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.LocationEmissionFactor",
                                                    "name": "energy.LocationEmissionFactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.energyaftersavings",
                                                    "name": "energy.energyaftersavings",
                                                    "label": "Energy After Savings",
                                                    "tags": [
                                                        "EnergyAfterSavings"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3670541,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Water_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Water_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Waste_Saved",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Waste_Opex Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.%_of Total Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.%_of Common Area Energy",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewMin",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewMax",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.OverviewTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Hot Water",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Lighting",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_Fan",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Home_Appliances",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Residential_HVAC",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Common_Area",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SavingsConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexPre",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexPost",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -1200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.IRR",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.PaybackPeriod",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SdgNumber",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.SdgText",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Locked",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.Enabled",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.GoalTarget",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.GoalTarget",
                                                    "label": "Energy Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 100,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.GoalContribution",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "name": "energy.renewable-sources.hot-water-management-villa.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "energy.custom-interventions.Capex",
                                            "name": "energy.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "energy.custom-interventions.CapexConverted",
                                            "name": "energy.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "energy.custom-interventions.Opex",
                                            "name": "energy.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "energy.custom-interventions.CapexTotal",
                                            "name": "energy.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "energy.custom-interventions.CapexTotalConverted",
                                            "name": "energy.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "energy.custom-interventions.Savings",
                                            "name": "energy.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kWh",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "energy.custom-interventions.Carbon",
                                            "name": "energy.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Water",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "water.Capex",
                                    "name": "water.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 7240880,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "water.CapexConverted",
                                    "name": "water.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 12,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "water.Opex",
                                    "name": "water.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 3452981,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "water.CapexTotal",
                                    "name": "water.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 13089540,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "water.CapexTotalConverted",
                                    "name": "water.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 22,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "water.Savings",
                                    "name": "water.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "kL",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 60997,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "water.SavingsConverted",
                                    "name": "water.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Water Tankers Avoided",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 10166,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "water.Carbon",
                                    "name": "water.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 43,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "water.CarbonConverted",
                                    "name": "water.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 9,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {
                                    "freshWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 87,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "treatedGreyWater": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 52,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "wtp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 74,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "centralizedRO": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 14,
                                        "tags": [],
                                        "unit": "KL"
                                    }
                                },
                                "right": {
                                    "commonAreaUse": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 0,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "domesticWaterUse": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "healthFaucet": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 1,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "flushing": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 12,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "drinking": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 14,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "greyWaterStp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 73,
                                        "tags": [],
                                        "unit": "KL"
                                    },
                                    "blackWaterStp": {
                                        "id": "",
                                        "name": "random",
                                        "label": "random",
                                        "uri": "",
                                        "value": 13,
                                        "tags": [],
                                        "unit": "KL"
                                    }
                                },
                                "connections": [
                                    {
                                        "left": "freshWater",
                                        "right": "wtp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 74,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "freshWater",
                                        "right": "centralizedRO",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 14,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "domesticwateruse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "healthFaucet",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "treatedGreyWater",
                                        "right": "commonAreaUse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "wtp",
                                        "right": "domesticwateruse",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 73,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "wtp",
                                        "right": "healthFaucet",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 1,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "centralizedRO",
                                        "right": "drinking",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 14,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "domesticwateruse",
                                        "right": "greyWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 73,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "healthFaucet",
                                        "right": "blackWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 878,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "flushing",
                                        "right": "blackWaterStp",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 12833,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "greyWaterStp",
                                        "right": "treatedGreyWater",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 52,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "greyWaterStp",
                                        "right": "municipalDrain",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "blackWaterStp",
                                        "right": "flushing",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    },
                                    {
                                        "left": "blackWaterStp",
                                        "right": "municipalDrain",
                                        "value": {
                                            "id": "",
                                            "name": "random",
                                            "label": "random",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "KL"
                                        }
                                    }
                                ]
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Source Creation",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.source-creation.Capex",
                                            "name": "water.source-creation.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.source-creation.CapexConverted",
                                            "name": "water.source-creation.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.source-creation.Opex",
                                            "name": "water.source-creation.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 72149,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.source-creation.CapexTotal",
                                            "name": "water.source-creation.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 360000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.source-creation.CapexTotalConverted",
                                            "name": "water.source-creation.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.source-creation.Savings",
                                            "name": "water.source-creation.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 787,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.source-creation.Carbon",
                                            "name": "water.source-creation.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Surface RWH",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb32",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.source-creation.srwh.Capex",
                                                    "name": "water.source-creation.srwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.source-creation.srwh.CapexConverted",
                                                    "name": "water.source-creation.srwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.source-creation.srwh.Opex",
                                                    "name": "water.source-creation.srwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.source-creation.srwh.CapexTotal",
                                                    "name": "water.source-creation.srwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.source-creation.srwh.CapexTotalConverted",
                                                    "name": "water.source-creation.srwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.source-creation.srwh.Savings",
                                                    "name": "water.source-creation.srwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.source-creation.srwh.Carbon",
                                                    "name": "water.source-creation.srwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Waste_Saved",
                                                    "name": "water.source-creation.srwh.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Waste_Opex Savings",
                                                    "name": "water.source-creation.srwh.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Energy_Saved",
                                                    "name": "water.source-creation.srwh.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Energy_Opex Savings",
                                                    "name": "water.source-creation.srwh.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of total water",
                                                    "name": "water.source-creation.srwh.%_of total water",
                                                    "label": "% of total water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of domestic demand",
                                                    "name": "water.source-creation.srwh.%_of domestic demand",
                                                    "label": "% of domestic demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 44,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.%_of drinking demand",
                                                    "name": "water.source-creation.srwh.%_of drinking demand",
                                                    "label": "% of drinking demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 53,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewMin",
                                                    "name": "water.source-creation.srwh.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewMax",
                                                    "name": "water.source-creation.srwh.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.OverviewTotal",
                                                    "name": "water.source-creation.srwh.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Domestic",
                                                    "name": "water.source-creation.srwh.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Health_Faucet",
                                                    "name": "water.source-creation.srwh.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Flushing",
                                                    "name": "water.source-creation.srwh.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Common_Area",
                                                    "name": "water.source-creation.srwh.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Drinking",
                                                    "name": "water.source-creation.srwh.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Savings",
                                                    "name": "water.source-creation.srwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SavingsConverted",
                                                    "name": "water.source-creation.srwh.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexPre",
                                                    "name": "water.source-creation.srwh.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexPost",
                                                    "name": "water.source-creation.srwh.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Capex",
                                                    "name": "water.source-creation.srwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexConverted",
                                                    "name": "water.source-creation.srwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Opex",
                                                    "name": "water.source-creation.srwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Carbon",
                                                    "name": "water.source-creation.srwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.IRR",
                                                    "name": "water.source-creation.srwh.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.PaybackPeriod",
                                                    "name": "water.source-creation.srwh.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SdgNumber",
                                                    "name": "water.source-creation.srwh.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.SdgText",
                                                    "name": "water.source-creation.srwh.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Locked",
                                                    "name": "water.source-creation.srwh.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.Enabled",
                                                    "name": "water.source-creation.srwh.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.GoalTarget",
                                                    "name": "water.source-creation.srwh.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.GoalContribution",
                                                    "name": "water.source-creation.srwh.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexTotal",
                                                    "name": "water.source-creation.srwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.srwh.CapexTotalConverted",
                                                    "name": "water.source-creation.srwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.multipliersource",
                                                    "name": "surfacerwh.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateDetails",
                                                    "name": "surfacerwh.MandateDetails",
                                                    "label": "SRWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BWSSB amendment act 2009, for per sqmt of roof top area 30 L storage or recharge structure should be provided."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateSummary",
                                                    "name": "surfacerwh.MandateSummary",
                                                    "label": "SRWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.MandateGenerals",
                                                    "name": "surfacerwh.MandateGenerals",
                                                    "label": "SRWH Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.srwhtanksize",
                                                    "name": "surfacerwh.srwhtanksize",
                                                    "label": "Tank Size",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.constants.srwhtankcapexmultiplier",
                                                    "name": "surfacerwh.constants.srwhtankcapexmultiplier",
                                                    "label": "RWH Tank Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18000,
                                                    "defaultValue": 18000
                                                },
                                                {
                                                    "id": "surfacerwh.VendorCapexMultiplier",
                                                    "name": "surfacerwh.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.srwhdailydemand",
                                                    "name": "surfacerwh.srwhdailydemand",
                                                    "label": "Rainwater Used For",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "50% of (Drinking + Domestic)",
                                                    "defaultValue": "50% of (Drinking + Domestic)"
                                                },
                                                {
                                                    "id": "surfacerwh.PavedSurfaceArea",
                                                    "name": "surfacerwh.PavedSurfaceArea",
                                                    "label": "Paved Surface Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "surfacerwh.PavedSurfaceArea",
                                                    "name": "surfacerwh.PavedSurfaceArea",
                                                    "label": "SRWH Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Rooftop RWH",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.source-creation.rrwh.Capex",
                                                    "name": "water.source-creation.rrwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.source-creation.rrwh.CapexConverted",
                                                    "name": "water.source-creation.rrwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.source-creation.rrwh.Opex",
                                                    "name": "water.source-creation.rrwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72149,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.source-creation.rrwh.CapexTotal",
                                                    "name": "water.source-creation.rrwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "name": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.source-creation.rrwh.Savings",
                                                    "name": "water.source-creation.rrwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.source-creation.rrwh.Carbon",
                                                    "name": "water.source-creation.rrwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.rooftop",
                                                    "name": "pip.rooftop",
                                                    "label": "Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "1000",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Waste_Saved",
                                                    "name": "water.source-creation.rrwh.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Waste_Opex Savings",
                                                    "name": "water.source-creation.rrwh.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Energy_Saved",
                                                    "name": "water.source-creation.rrwh.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Energy_Opex Savings",
                                                    "name": "water.source-creation.rrwh.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Total Water",
                                                    "name": "water.source-creation.rrwh.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Domestic Demand",
                                                    "name": "water.source-creation.rrwh.%_of Domestic Demand",
                                                    "label": "% of Domestic Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.%_of Drinking Demand",
                                                    "name": "water.source-creation.rrwh.%_of Drinking Demand",
                                                    "label": "% of Drinking Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewMin",
                                                    "name": "water.source-creation.rrwh.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewMax",
                                                    "name": "water.source-creation.rrwh.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.OverviewTotal",
                                                    "name": "water.source-creation.rrwh.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Domestic",
                                                    "name": "water.source-creation.rrwh.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Health_Faucet",
                                                    "name": "water.source-creation.rrwh.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Flushing",
                                                    "name": "water.source-creation.rrwh.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Common_Area",
                                                    "name": "water.source-creation.rrwh.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Drinking",
                                                    "name": "water.source-creation.rrwh.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Savings",
                                                    "name": "water.source-creation.rrwh.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 787,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SavingsConverted",
                                                    "name": "water.source-creation.rrwh.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 131,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexPre",
                                                    "name": "water.source-creation.rrwh.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexPost",
                                                    "name": "water.source-creation.rrwh.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Capex",
                                                    "name": "water.source-creation.rrwh.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexConverted",
                                                    "name": "water.source-creation.rrwh.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Opex",
                                                    "name": "water.source-creation.rrwh.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 72149,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Carbon",
                                                    "name": "water.source-creation.rrwh.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.IRR",
                                                    "name": "water.source-creation.rrwh.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.PaybackPeriod",
                                                    "name": "water.source-creation.rrwh.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SdgNumber",
                                                    "name": "water.source-creation.rrwh.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.SdgText",
                                                    "name": "water.source-creation.rrwh.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Locked",
                                                    "name": "water.source-creation.rrwh.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.Enabled",
                                                    "name": "water.source-creation.rrwh.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.GoalTarget",
                                                    "name": "water.source-creation.rrwh.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.GoalContribution",
                                                    "name": "water.source-creation.rrwh.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexTotal",
                                                    "name": "water.source-creation.rrwh.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 360000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "name": "water.source-creation.rrwh.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhtanksizesource",
                                                    "name": "rooftoprwh.rrwhtanksizesource",
                                                    "label": "Tank Size - Default Recommendation",
                                                    "tags": [
                                                        "customizable",
                                                        "tank-size-options"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Default",
                                                    "defaultValue": "Default",
                                                    "valueOptions": [
                                                        "Default",
                                                        "Custom"
                                                    ]
                                                },
                                                {
                                                    "id": "rooftoprwh.constants.rrwhtankcapexmultiplier",
                                                    "name": "rooftoprwh.constants.rrwhtankcapexmultiplier",
                                                    "label": "RWH Tank Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18000,
                                                    "defaultValue": 18000
                                                },
                                                {
                                                    "id": "rooftoprwh.VendorCapexMultiplier",
                                                    "name": "rooftoprwh.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.multipliersource",
                                                    "name": "rooftoprwh.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateDetails",
                                                    "name": "rooftoprwh.MandateDetails",
                                                    "label": "RRWH Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BBMP Building byelaw (2003) bye-law 32 rainwater harvesting is mandatory.",
                                                        "As per BWSSB amendment act 2009, for per sqmt of roof top area 60l storage or recharge structure should be provided."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateSummary",
                                                    "name": "rooftoprwh.MandateSummary",
                                                    "label": "RRWH Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.MandateGenerals",
                                                    "name": "rooftoprwh.MandateGenerals",
                                                    "label": "RRWH Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.constants.minrwhtanksizemultiplier",
                                                    "name": "rooftoprwh.constants.minrwhtanksizemultiplier",
                                                    "label": "RWH tank size per sqm of rooftop",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.02,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhAnnualWaterPreSDplus",
                                                    "name": "rooftoprwh.rrwhAnnualWaterPreSDplus",
                                                    "label": "RRWH Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhdailydemand",
                                                    "name": "rooftoprwh.rrwhdailydemand",
                                                    "label": "Rainwater Used For",
                                                    "tags": [
                                                        "customizable",
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "50% of (Drinking + Domestic)",
                                                    "defaultValue": "50% of (Drinking + Domestic)",
                                                    "valueOptions": [
                                                        "50% of (Drinking + Domestic)",
                                                        null
                                                    ]
                                                },
                                                {
                                                    "id": "efficientfixtures.rrwhTankSizePreSDPlus",
                                                    "name": "efficientfixtures.rrwhTankSizePreSDPlus",
                                                    "label": "RRWH Tank Size Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "rooftoprwh.rrwhtanksize",
                                                    "name": "rooftoprwh.rrwhtanksize",
                                                    "label": "Tank Size",
                                                    "tags": [
                                                        "customizable",
                                                        "tank-size-customization",
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 20
                                                },
                                                {
                                                    "id": "rooftoprwh.arearequired",
                                                    "name": "rooftoprwh.arearequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Demand Optimization",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.demand-optimization.Capex",
                                            "name": "water.demand-optimization.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3640880,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.demand-optimization.CapexConverted",
                                            "name": "water.demand-optimization.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 7,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.demand-optimization.Opex",
                                            "name": "water.demand-optimization.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3372193,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.demand-optimization.CapexTotal",
                                            "name": "water.demand-optimization.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 9129540,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.demand-optimization.CapexTotalConverted",
                                            "name": "water.demand-optimization.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 15,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.demand-optimization.Savings",
                                            "name": "water.demand-optimization.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 36790,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.demand-optimization.Carbon",
                                            "name": "water.demand-optimization.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 26,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Smart Water Meters",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb26",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.swm.Capex",
                                                    "name": "water.demand-optimization.swm.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.swm.CapexConverted",
                                                    "name": "water.demand-optimization.swm.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.swm.Opex",
                                                    "name": "water.demand-optimization.swm.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 172279,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.swm.CapexTotal",
                                                    "name": "water.demand-optimization.swm.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "name": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.swm.Savings",
                                                    "name": "water.demand-optimization.swm.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.swm.Carbon",
                                                    "name": "water.demand-optimization.swm.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Waste_Saved",
                                                    "name": "water.demand-optimization.swm.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.swm.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Energy_Saved",
                                                    "name": "water.demand-optimization.swm.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.swm.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.%_of Total Water",
                                                    "name": "water.demand-optimization.swm.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewMin",
                                                    "name": "water.demand-optimization.swm.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewMax",
                                                    "name": "water.demand-optimization.swm.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.OverviewTotal",
                                                    "name": "water.demand-optimization.swm.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Domestic",
                                                    "name": "water.demand-optimization.swm.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Health_Faucet",
                                                    "name": "water.demand-optimization.swm.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Flushing",
                                                    "name": "water.demand-optimization.swm.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Common_Area",
                                                    "name": "water.demand-optimization.swm.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Drinking",
                                                    "name": "water.demand-optimization.swm.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Savings",
                                                    "name": "water.demand-optimization.swm.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SavingsConverted",
                                                    "name": "water.demand-optimization.swm.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexPre",
                                                    "name": "water.demand-optimization.swm.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexPost",
                                                    "name": "water.demand-optimization.swm.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Capex",
                                                    "name": "water.demand-optimization.swm.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexConverted",
                                                    "name": "water.demand-optimization.swm.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Opex",
                                                    "name": "water.demand-optimization.swm.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 172279,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Carbon",
                                                    "name": "water.demand-optimization.swm.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.IRR",
                                                    "name": "water.demand-optimization.swm.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.PaybackPeriod",
                                                    "name": "water.demand-optimization.swm.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SdgNumber",
                                                    "name": "water.demand-optimization.swm.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.SdgText",
                                                    "name": "water.demand-optimization.swm.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Locked",
                                                    "name": "water.demand-optimization.swm.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.Enabled",
                                                    "name": "water.demand-optimization.swm.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.GoalTarget",
                                                    "name": "water.demand-optimization.swm.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.GoalContribution",
                                                    "name": "water.demand-optimization.swm.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexTotal",
                                                    "name": "water.demand-optimization.swm.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2108000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "name": "water.demand-optimization.swm.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.constants.swmcapexmultiplier",
                                                    "name": "smartwatermeter.constants.swmcapexmultiplier",
                                                    "label": "Capex (per sensor installed)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3000,
                                                    "defaultValue": 3000
                                                },
                                                {
                                                    "id": "smartwatermeter.VendorCapexMultiplier",
                                                    "name": "smartwatermeter.VendorCapexMultiplier",
                                                    "label": "Vendor Capex Multiplier",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.multipliersource",
                                                    "name": "smartwatermeter.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateDetails",
                                                    "name": "smartwatermeter.MandateDetails",
                                                    "label": "SWM Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per BWSSB regulation 43-A, for judicious consumption of water internal water meters should be provided for all houses"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateSummary",
                                                    "name": "smartwatermeter.MandateSummary",
                                                    "label": "SWM Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.MandateGenerals",
                                                    "name": "smartwatermeter.MandateGenerals",
                                                    "label": "SWM Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Water meter Sensors are placed in all bathrooms (1 in each bathroom) and kitchens (1 each). In common areas sensors are placed in clubhouse (2 nos.), STP, borewell inlet & water tanker inlet"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "smartwatermeter.outcomes.swmdaily",
                                                    "name": "smartwatermeter.outcomes.swmdaily",
                                                    "label": "Number of Sensors",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 676,
                                                    "defaultValue": 5
                                                },
                                                {
                                                    "id": "smartwatermeter.outcomes.resourcepresdplus",
                                                    "name": "smartwatermeter.outcomes.resourcepresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Centralized RO",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb2e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": true,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.cro.Capex",
                                                    "name": "water.demand-optimization.cro.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.cro.CapexConverted",
                                                    "name": "water.demand-optimization.cro.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.cro.Opex",
                                                    "name": "water.demand-optimization.cro.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.cro.CapexTotal",
                                                    "name": "water.demand-optimization.cro.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "name": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.cro.Savings",
                                                    "name": "water.demand-optimization.cro.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.cro.Carbon",
                                                    "name": "water.demand-optimization.cro.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Waste_Saved",
                                                    "name": "water.demand-optimization.cro.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.cro.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Energy_Saved",
                                                    "name": "water.demand-optimization.cro.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.cro.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.%_of Total Water",
                                                    "name": "water.demand-optimization.cro.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewMin",
                                                    "name": "water.demand-optimization.cro.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewMax",
                                                    "name": "water.demand-optimization.cro.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.OverviewTotal",
                                                    "name": "water.demand-optimization.cro.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Domestic",
                                                    "name": "water.demand-optimization.cro.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Health_Faucet",
                                                    "name": "water.demand-optimization.cro.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Flushing",
                                                    "name": "water.demand-optimization.cro.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Common_Area",
                                                    "name": "water.demand-optimization.cro.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Drinking",
                                                    "name": "water.demand-optimization.cro.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Savings",
                                                    "name": "water.demand-optimization.cro.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SavingsConverted",
                                                    "name": "water.demand-optimization.cro.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexPre",
                                                    "name": "water.demand-optimization.cro.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexPost",
                                                    "name": "water.demand-optimization.cro.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Capex",
                                                    "name": "water.demand-optimization.cro.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexConverted",
                                                    "name": "water.demand-optimization.cro.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Opex",
                                                    "name": "water.demand-optimization.cro.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Carbon",
                                                    "name": "water.demand-optimization.cro.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.IRR",
                                                    "name": "water.demand-optimization.cro.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.PaybackPeriod",
                                                    "name": "water.demand-optimization.cro.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SdgNumber",
                                                    "name": "water.demand-optimization.cro.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11,
                                                        6
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.SdgText",
                                                    "name": "water.demand-optimization.cro.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities,SDG 6 - Clean Water and Sanitation",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Locked",
                                                    "name": "water.demand-optimization.cro.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.Enabled",
                                                    "name": "water.demand-optimization.cro.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.GoalTarget",
                                                    "name": "water.demand-optimization.cro.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.GoalContribution",
                                                    "name": "water.demand-optimization.cro.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexTotal",
                                                    "name": "water.demand-optimization.cro.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "name": "water.demand-optimization.cro.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.inputs.userinput",
                                                    "name": "centralizedro.inputs.userinput",
                                                    "label": "Consider Individual RO system",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "ProjectInput",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.inputs.userinput",
                                                    "name": "centralizedro.inputs.userinput",
                                                    "label": "CRO Installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.outcomes.crodaily",
                                                    "name": "centralizedro.outcomes.crodaily",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.roefficiency",
                                                    "name": "centralizedro.constants.roefficiency",
                                                    "label": "Individul RO Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.45,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.centralroefficiency",
                                                    "name": "centralizedro.constants.centralroefficiency",
                                                    "label": "Central RO Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.75,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.constants.crocapexmultiplier",
                                                    "name": "centralizedro.constants.crocapexmultiplier",
                                                    "label": "Capex per KL installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionOutput",
                                                    "value": 19000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.DefaultCapexMultiplier",
                                                    "name": "centralizedro.DefaultCapexMultiplier",
                                                    "label": "Default Capex per KL installed",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 19000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.multipliersource",
                                                    "name": "centralizedro.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "",
                                                    "name": "",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateDetails",
                                                    "name": "centralizedro.MandateDetails",
                                                    "label": "CRO Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateSummary",
                                                    "name": "centralizedro.MandateSummary",
                                                    "label": "CRO Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.MandateGenerals",
                                                    "name": "centralizedro.MandateGenerals",
                                                    "label": "CRO Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.crocapacitypre",
                                                    "name": "centralizedro.crocapacitypre",
                                                    "label": "Capacity Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.annualwaterpresdplus",
                                                    "name": "centralizedro.annualwaterpresdplus",
                                                    "label": "Annual Generation Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.RedidentialDrinking",
                                                    "name": "centralizedro.RedidentialDrinking",
                                                    "label": "Redidential Drinking",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.ResdidentialDomestic",
                                                    "name": "centralizedro.ResdidentialDomestic",
                                                    "label": "Resdidential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "Residential flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Drinking",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 16,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 84,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "centralizedro.Residentialflushing",
                                                    "name": "centralizedro.Residentialflushing",
                                                    "label": "current Water Table Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Dual Flush",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb30",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.df.Capex",
                                                    "name": "water.demand-optimization.df.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1219680,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.df.CapexConverted",
                                                    "name": "water.demand-optimization.df.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.df.Opex",
                                                    "name": "water.demand-optimization.df.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1186927,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.df.CapexTotal",
                                                    "name": "water.demand-optimization.df.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.df.CapexTotalConverted",
                                                    "name": "water.demand-optimization.df.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.df.Savings",
                                                    "name": "water.demand-optimization.df.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.df.Carbon",
                                                    "name": "water.demand-optimization.df.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Waste_Saved",
                                                    "name": "water.demand-optimization.df.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.df.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Energy_Saved",
                                                    "name": "water.demand-optimization.df.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.df.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.%_of Total Water",
                                                    "name": "water.demand-optimization.df.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 18,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewMin",
                                                    "name": "water.demand-optimization.df.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewMax",
                                                    "name": "water.demand-optimization.df.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.OverviewTotal",
                                                    "name": "water.demand-optimization.df.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Domestic",
                                                    "name": "water.demand-optimization.df.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Health_Faucet",
                                                    "name": "water.demand-optimization.df.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Flushing",
                                                    "name": "water.demand-optimization.df.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Common_Area",
                                                    "name": "water.demand-optimization.df.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Drinking",
                                                    "name": "water.demand-optimization.df.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Savings",
                                                    "name": "water.demand-optimization.df.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12949,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SavingsConverted",
                                                    "name": "water.demand-optimization.df.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2158,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexPre",
                                                    "name": "water.demand-optimization.df.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3594360,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexPost",
                                                    "name": "water.demand-optimization.df.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Capex",
                                                    "name": "water.demand-optimization.df.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1219680,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexConverted",
                                                    "name": "water.demand-optimization.df.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Opex",
                                                    "name": "water.demand-optimization.df.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1186927,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Carbon",
                                                    "name": "water.demand-optimization.df.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.IRR",
                                                    "name": "water.demand-optimization.df.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.PaybackPeriod",
                                                    "name": "water.demand-optimization.df.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SdgNumber",
                                                    "name": "water.demand-optimization.df.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.SdgText",
                                                    "name": "water.demand-optimization.df.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Locked",
                                                    "name": "water.demand-optimization.df.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.Enabled",
                                                    "name": "water.demand-optimization.df.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.GoalTarget",
                                                    "name": "water.demand-optimization.df.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.GoalContribution",
                                                    "name": "water.demand-optimization.df.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexTotal",
                                                    "name": "water.demand-optimization.df.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4814040,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.df.CapexTotalConverted",
                                                    "name": "water.demand-optimization.df.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 8,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.fixturedb.brand",
                                                    "name": "dualflush.fixturedb.brand",
                                                    "label": "Brand",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Hindware"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Hindware",
                                                    "defaultValue": "Hindware"
                                                },
                                                {
                                                    "id": "dualflush.fixturedb.tag",
                                                    "name": "dualflush",
                                                    "label": "Model",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Most Popular",
                                                        "Basic",
                                                        "Best Value",
                                                        "Premium"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Basic",
                                                    "defaultValue": "Basic"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping ref occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.derived.residentialflushing.fullwcprice",
                                                    "name": "dualflush.derived.residentialflushing.fullwcprice",
                                                    "label": "Full WC Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateDetails",
                                                    "name": "dualflush.MandateDetails",
                                                    "label": "DF Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateSummary",
                                                    "name": "dualflush.MandateSummary",
                                                    "label": "DF Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.MandateGenerals",
                                                    "name": "dualflush.MandateGenerals",
                                                    "label": "Dual Flush Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Model type is broadly split into 4 types: <br>\n      Basic = Full flush - 3.57 lpf | half flush - 2.76 lpf <br>\n      Most Popular= 4 lpf | half flush - 2 lpf <br>\n      Best Value = 3.71 lpf | half flush - 2.20 lpf <br>\n      Premium = 3.42 lpf | half flush - 1.25 lpf"
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingFullWCFlowPreSDplus",
                                                    "name": "dualflush.FlushingFullWCFlowPreSDplus",
                                                    "label": "Flushing Full WC Flow Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingFullWCFlow",
                                                    "name": "dualflush.FlushingFullWCFlow",
                                                    "label": "Flushing Full WC Flow",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingHalfWCFlowPreSDplus",
                                                    "name": "dualflush.FlushingHalfWCFlowPreSDplus",
                                                    "label": "Flushing Half WC Flow Pre SD plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.FlushingHalfWCFlow",
                                                    "name": "dualflush.FlushingHalfWCFlow",
                                                    "label": "Flushing Half WC Flow",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.clientBaseCaseResidentialFlushing",
                                                    "name": "dualflush.clientBaseCaseResidentialFlushing",
                                                    "label": "client Base Case Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.dualFlushResidentialFlushing",
                                                    "name": "dualflush.dualFlushResidentialFlushing",
                                                    "label": "dual Flush Residential Flushing",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "dualflush.DFAnnualWaterPreSdplus",
                                                    "name": "dualflush.DFAnnualWaterPreSdplus",
                                                    "label": "DF Annual Water Pre Sd plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 17633,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Kitchen Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb52",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-kitchen-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb42",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.ef.Capex",
                                                    "name": "water.demand-optimization.ef.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313200,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.ef.CapexConverted",
                                                    "name": "water.demand-optimization.ef.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.ef.Opex",
                                                    "name": "water.demand-optimization.ef.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2012986,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.ef.CapexTotal",
                                                    "name": "water.demand-optimization.ef.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "name": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.ef.Savings",
                                                    "name": "water.demand-optimization.ef.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.ef.Carbon",
                                                    "name": "water.demand-optimization.ef.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Waste_Saved",
                                                    "name": "water.demand-optimization.ef.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.ef.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Energy_Saved",
                                                    "name": "water.demand-optimization.ef.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Energy_Opex Savings",
                                                    "name": "water.demand-optimization.ef.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.%_of Total Water",
                                                    "name": "water.demand-optimization.ef.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 31,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Domestic_Use as % of Savings",
                                                    "name": "water.demand-optimization.ef.Domestic_Use as % of Savings",
                                                    "label": "Domestic Use as % of Savings",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 99,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.H._Faucet Use as % of Savings",
                                                    "name": "water.demand-optimization.ef.H._Faucet Use as % of Savings",
                                                    "label": "H. Faucet Use as % of Savings",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewMin",
                                                    "name": "water.demand-optimization.ef.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewMax",
                                                    "name": "water.demand-optimization.ef.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.OverviewTotal",
                                                    "name": "water.demand-optimization.ef.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Domestic",
                                                    "name": "water.demand-optimization.ef.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 73,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Health_Faucet",
                                                    "name": "water.demand-optimization.ef.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Flushing",
                                                    "name": "water.demand-optimization.ef.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Common_Area",
                                                    "name": "water.demand-optimization.ef.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Drinking",
                                                    "name": "water.demand-optimization.ef.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Savings",
                                                    "name": "water.demand-optimization.ef.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 21961,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SavingsConverted",
                                                    "name": "water.demand-optimization.ef.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3660,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexPre",
                                                    "name": "water.demand-optimization.ef.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1894300,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexPost",
                                                    "name": "water.demand-optimization.ef.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Capex",
                                                    "name": "water.demand-optimization.ef.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 313200,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexConverted",
                                                    "name": "water.demand-optimization.ef.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Opex",
                                                    "name": "water.demand-optimization.ef.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2012986,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Carbon",
                                                    "name": "water.demand-optimization.ef.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 15,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.IRR",
                                                    "name": "water.demand-optimization.ef.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.PaybackPeriod",
                                                    "name": "water.demand-optimization.ef.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SdgNumber",
                                                    "name": "water.demand-optimization.ef.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.SdgText",
                                                    "name": "water.demand-optimization.ef.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Locked",
                                                    "name": "water.demand-optimization.ef.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.Enabled",
                                                    "name": "water.demand-optimization.ef.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.GoalTarget",
                                                    "name": "water.demand-optimization.ef.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.GoalContribution",
                                                    "name": "water.demand-optimization.ef.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 36,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexTotal",
                                                    "name": "water.demand-optimization.ef.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 2207500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "name": "water.demand-optimization.ef.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.fixturedb.brand",
                                                    "name": "efficientfixtures.fixturedb.brand",
                                                    "label": "Brand",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Jaquar"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "ProjectInput",
                                                    "value": "Jaquar",
                                                    "defaultValue": "Jaquar"
                                                },
                                                {
                                                    "id": "efficientfixtures.fixturedb.tag",
                                                    "name": "efficientfixtures.fixturedb.tag",
                                                    "label": "Model",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "valueOptions": [
                                                        "Most Popular",
                                                        "Basic",
                                                        "Best Value",
                                                        "Premium"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Basic",
                                                    "defaultValue": "Basic"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "project.constants.housekeepingrefoccupancy",
                                                    "name": "project.constants.housekeepingrefoccupancy",
                                                    "label": "Housekeeping Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.005,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.clubhouserefoccupancy",
                                                    "name": "project.constants.clubhouserefoccupancy",
                                                    "label": "Club House Ref Occupancy",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateDetails",
                                                    "name": "efficientfixtures.MandateDetails",
                                                    "label": "EF Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateSummary",
                                                    "name": "efficientfixtures.MandateSummary",
                                                    "label": "EF Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.MandateGenerals",
                                                    "name": "efficientfixtures.MandateGenerals",
                                                    "label": "Efficient Fixtures Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "Model type is broadly split into 4 types: <br>\n      Basic = Kitchen Sink - 2.5 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Most Popular= Kitchen Sink - 6 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Best Value = Kitchen Sink - 4 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm <br>\n      Premium = Kitchen Sink - 2.5 lpm | Health Faucet - 4 lpm | Faucet - 4 lpm | Shower 6 lpm "
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domestichealthfaucetprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domestichealthfaucetprice",
                                                    "label": "Health Faucet Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domesticfaucetprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domesticfaucetprice",
                                                    "label": "Faucet Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domestickitchensinkprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domestickitchensinkprice",
                                                    "label": "Kitchen Sink Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.derived.residentialdomestic.domesticshowerprice",
                                                    "name": "efficientfixtures.derived.residentialdomestic.domesticshowerprice",
                                                    "label": "Shower Unit Capex",
                                                    "tags": [
                                                        "assumption",
                                                        "intervention-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.healthfaucetpre",
                                                    "name": "efficientfixtures.healthfaucetpre",
                                                    "label": "EF Health faucet Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.healthfaucetpost",
                                                    "name": "efficientfixtures.healthfaucetpost",
                                                    "label": "EF Health faucet Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.faucetpre",
                                                    "name": "efficientfixtures.faucetpre",
                                                    "label": "EF faucet Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.faucetpost",
                                                    "name": "efficientfixtures.faucetpost",
                                                    "label": "EF faucet Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.showerpre",
                                                    "name": "efficientfixtures.showerpre",
                                                    "label": "EF shower Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.showerpost",
                                                    "name": "efficientfixtures.showerpost",
                                                    "label": "EF shower Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.kitchensinkpre",
                                                    "name": "efficientfixtures.kitchensinkpre",
                                                    "label": "EF Kitchensink Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 11,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.kitchensinkpost",
                                                    "name": "efficientfixtures.kitchensinkpost",
                                                    "label": "EF Kitchensink Post SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": {
                                                        "$numberDecimal": "2.5"
                                                    },
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.clientBaseCaseResidentialDomestic",
                                                    "name": "efficientfixtures.clientBaseCaseResidentialDomestic",
                                                    "label": "client Base Case Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.efficientFixturesCaseResidentialDomestic",
                                                    "name": "efficientfixtures.efficientFixturesCaseResidentialDomestic",
                                                    "label": "Efficient Fixtures Case Residential Domestic",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 84,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "efficientfixtures.efannualwaterpresdplus",
                                                    "name": "efficientfixtures.efannualwaterpresdplus",
                                                    "label": "ef annual water pre sd plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 48812,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Showers",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb54",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-shower.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb56",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Efficient Fixtures - Health Faucet",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb6a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": false,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.nonavailable",
                                                    "name": "pip.nonavailable",
                                                    "label": "Unavailable Rooftop Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "500",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Water_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Water_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Saved",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Opex Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.%_of Total Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.%_of Total Energy",
                                                    "label": "% of Total Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.%_of Common Area Energy",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.%_of Common Area Energy",
                                                    "label": "% of Common Area Energy",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMin",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMax",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.OverviewTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Hot Water",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Hot Water",
                                                    "label": "Residential Hot Water",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Lighting",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Lighting",
                                                    "label": "Residential Lighting",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Fan",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_Fan",
                                                    "label": "Residential Fan",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Home_Appliances",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Home_Appliances",
                                                    "label": "Home Appliances",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Residential_HVAC",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Residential_HVAC",
                                                    "label": "Residential HVAC",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Common_Area",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SavingsConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SavingsConverted",
                                                    "label": "Powered Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Homes",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexPre",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexPost",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.IRR",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.PaybackPeriod",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SdgNumber",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.SdgText",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Locked",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.Enabled",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.GoalTarget",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.GoalContribution",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "name": "water.demand-optimization.efficient-fixture-health-faucet.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhCapacity",
                                                    "name": "hwmVilla.swhCapacity",
                                                    "label": "Solar Water Heater Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWp",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.options",
                                                    "name": "hwmVilla.options",
                                                    "label": "",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "solar water heater",
                                                        "heat pump"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "solar water heater",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.swhType",
                                                    "name": "hwmVilla.swhType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "individual",
                                                        "central"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "individual",
                                                    "defaultValue": "individual"
                                                },
                                                {
                                                    "id": "hwmVilla.swhVariant",
                                                    "name": "hwmVilla.swhVariant",
                                                    "label": "Technology",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "ETC",
                                                        "FPC"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "etc",
                                                    "defaultValue": "etc"
                                                },
                                                {
                                                    "id": "hwmVilla.individualProductCapacity",
                                                    "name": "hwmVilla.individualProductCapacity",
                                                    "label": "Individual Product Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.numberOfProducts",
                                                    "name": "hwmVilla.numberOfProducts",
                                                    "label": "No. of Products",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.areaRequired",
                                                    "name": "hwmVilla.areaRequired",
                                                    "label": "Area Required",
                                                    "tags": [
                                                        "customizable",
                                                        "constant",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.userHpCapacity",
                                                    "name": "hwmVilla.userHpCapacity",
                                                    "label": "Heat Pump Capacity",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "LPD",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.HpType",
                                                    "name": "hwmVilla.HpType",
                                                    "label": "Type",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic",
                                                        "Commercial"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "domestic",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.heatingSpeed",
                                                    "name": "hwmVilla.heatingSpeed",
                                                    "label": "Heating",
                                                    "tags": [
                                                        "customizable",
                                                        "basic-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Slow Heating",
                                                        "Fast Heating"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "fast",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandByBath",
                                                    "name": "hwmVilla.demandByBath",
                                                    "label": "Demand By Bath",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "All baths",
                                                        "Master bathroom",
                                                        "All baths except master bathroom"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "single bath",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumption",
                                                    "name": "hwmVilla.demandAssumption",
                                                    "label": "Demand Assumption",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 20,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.demandAssumptionType",
                                                    "name": "hwmVilla.demandAssumptionType",
                                                    "label": "Demand Assumption Type",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Per Occupant",
                                                        "Per Villa",
                                                        "As per selected Efficient Fixtures"
                                                    ],
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "lpcd per occupant",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.capexPerUnitInstalled",
                                                    "name": "hwmVilla.capexPerUnitInstalled",
                                                    "label": "Capex Per Unit Installed",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.CustomCapexMultiplier",
                                                    "name": "hwmVilla.CustomCapexMultiplier",
                                                    "label": "Custom Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.vendorCapexMultiplier",
                                                    "name": "hwmVilla.vendorCapexMultiplier",
                                                    "label": "Vendor Capex",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.multipliersource",
                                                    "name": "hwmVilla.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "customizable",
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.ElectricityCostperkWh",
                                                    "name": "hwmVilla.ElectricityCostperkWh",
                                                    "label": "Electricity Cost per kWh",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "name": "hwmVilla.rooftopAreaPerSWHLPD",
                                                    "label": "Rooftop Area per SWH LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "name": "hwmVilla.rooftopAreaPerHPLPD",
                                                    "label": "Rooftop Area per HP LPD",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.hotwaterusuageHours",
                                                    "name": "hwmVilla.hotwaterusuageHours",
                                                    "label": "Hot Water Usage Hours",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.numberofPlumbingShafts",
                                                    "name": "hwmVilla.numberofPlumbingShafts",
                                                    "label": "Number of Plumbing Shafts",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter"
                                                },
                                                {
                                                    "id": "hwmVilla.co2emmissionfactor",
                                                    "name": "hwmVilla.co2emmissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "customizable",
                                                        "advanced-parameters",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateSummary",
                                                    "name": "hwmVilla.MandateSummary",
                                                    "label": "hwmVilla Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateGenerals",
                                                    "name": "hwmVilla.MandateGenerals",
                                                    "label": "hwmVilla Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "hwmVilla.MandateDetails",
                                                    "name": "hwmVilla.MandateDetails",
                                                    "label": "hwmVilla Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Treatment And Re-use",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.source-creation.Capex",
                                            "name": "water.source-creation.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 3600000,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.source-creation.CapexConverted",
                                            "name": "water.source-creation.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 6,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.source-creation.Opex",
                                            "name": "water.source-creation.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 8640,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.source-creation.CapexTotal",
                                            "name": "water.source-creation.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 3600000,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.source-creation.CapexTotalConverted",
                                            "name": "water.source-creation.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 6,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.source-creation.Savings",
                                            "name": "water.source-creation.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 23419,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.source-creation.Carbon",
                                            "name": "water.source-creation.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 16,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Black Water Re-use",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb22",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.treatment-reuse.bwr.Capex",
                                                    "name": "water.treatment-reuse.bwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.treatment-reuse.bwr.CapexConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.treatment-reuse.bwr.Opex",
                                                    "name": "water.treatment-reuse.bwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1371,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.treatment-reuse.bwr.CapexTotal",
                                                    "name": "water.treatment-reuse.bwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.treatment-reuse.bwr.Savings",
                                                    "name": "water.treatment-reuse.bwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.treatment-reuse.bwr.Carbon",
                                                    "name": "water.treatment-reuse.bwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Waste_Saved",
                                                    "name": "water.treatment-reuse.bwr.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Waste_Opex Savings",
                                                    "name": "water.treatment-reuse.bwr.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Energy_Saved",
                                                    "name": "water.treatment-reuse.bwr.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Energy_Opex Savings",
                                                    "name": "water.treatment-reuse.bwr.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Total Water",
                                                    "name": "water.treatment-reuse.bwr.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Flushing Demand",
                                                    "name": "water.treatment-reuse.bwr.%_of Flushing Demand",
                                                    "label": "% of Flushing Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.%_of Common Area Demand",
                                                    "name": "water.treatment-reuse.bwr.%_of Common Area Demand",
                                                    "label": "% of Common Area Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewMin",
                                                    "name": "water.treatment-reuse.bwr.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewMax",
                                                    "name": "water.treatment-reuse.bwr.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.OverviewTotal",
                                                    "name": "water.treatment-reuse.bwr.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Domestic",
                                                    "name": "water.treatment-reuse.bwr.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Health_Faucet",
                                                    "name": "water.treatment-reuse.bwr.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Flushing",
                                                    "name": "water.treatment-reuse.bwr.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Common_Area",
                                                    "name": "water.treatment-reuse.bwr.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Drinking",
                                                    "name": "water.treatment-reuse.bwr.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Savings",
                                                    "name": "water.treatment-reuse.bwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 4517,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SavingsConverted",
                                                    "name": "water.treatment-reuse.bwr.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 753,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexPre",
                                                    "name": "water.treatment-reuse.bwr.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexPost",
                                                    "name": "water.treatment-reuse.bwr.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Capex",
                                                    "name": "water.treatment-reuse.bwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Opex",
                                                    "name": "water.treatment-reuse.bwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1371,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Carbon",
                                                    "name": "water.treatment-reuse.bwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.IRR",
                                                    "name": "water.treatment-reuse.bwr.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.PaybackPeriod",
                                                    "name": "water.treatment-reuse.bwr.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SdgNumber",
                                                    "name": "water.treatment-reuse.bwr.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.SdgText",
                                                    "name": "water.treatment-reuse.bwr.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Locked",
                                                    "name": "water.treatment-reuse.bwr.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.Enabled",
                                                    "name": "water.treatment-reuse.bwr.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.GoalTarget",
                                                    "name": "water.treatment-reuse.bwr.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.GoalContribution",
                                                    "name": "water.treatment-reuse.bwr.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexTotal",
                                                    "name": "water.treatment-reuse.bwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.bwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.VendorCapexMultiplier",
                                                    "name": "blackwaterreuse.VendorCapexMultiplier",
                                                    "label": "Vendor Capex per KL installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateDetails",
                                                    "name": "blackwaterreuse.MandateDetails",
                                                    "label": "BWR Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt mandate, treated water from onsite STP should be used for non potable (flushing & landscaping) usages after appropriate tertiery treatment."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateSummary",
                                                    "name": "blackwaterreuse.MandateSummary",
                                                    "label": "BWR Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.MandateGenerals",
                                                    "name": "blackwaterreuse.MandateGenerals",
                                                    "label": "Black Water Reuse Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.derivatives.stpcapacity",
                                                    "name": "blackwaterreuse.derivatives.stpcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 14,
                                                    "defaultValue": 14
                                                },
                                                {
                                                    "id": "blackwaterreuse.blackwaterreusetowards",
                                                    "name": "blackwaterreuse.blackwaterreusetowards",
                                                    "label": "Black Water Reuse Towards",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Flushing"
                                                    ],
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": "Flushing"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.blackstpefficiency",
                                                    "name": "blackwaterreuse.constants.blackstpefficiency",
                                                    "label": "Black STP Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.tertiarytreatmentefficiency",
                                                    "name": "blackwaterreuse.constants.tertiarytreatmentefficiency",
                                                    "label": "Tertiary Treatment Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.constants.bwrcapexmultiplier",
                                                    "name": "blackwaterreuse.constants.BWRcapexmultiplier",
                                                    "label": "Tertiary Treatment Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "BWR Annual Water Pre SD Plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Combined",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "blackwaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Separate",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.dualpipingCalculationBefore",
                                                    "name": "blackwaterreuse.dualpipingCalculationBefore",
                                                    "label": "Dual Piping Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.dualpipingCalculationAfter",
                                                    "name": "blackwaterreuse.dualpipingCalculationAfter",
                                                    "label": "Dual Piping After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.ReuseOfTreatedWaterBefore",
                                                    "name": "blackwaterreuse.ReuseOfTreatedWaterBefore",
                                                    "label": "Reuse Of Treated Water Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Reuse Of Treated Water After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "name": "blackwaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "label": "Tertiary Treatment Required Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "blackwaterreuse.bwrannualWaterpresdplus",
                                                    "name": "blackwaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Tertiary Treatment Required After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Flushing",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Grey Water Re-use",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb48",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "water.treatment-reuse.gwr.Capex",
                                                    "name": "water.treatment-reuse.gwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "water.treatment-reuse.gwr.CapexConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "water.treatment-reuse.gwr.Opex",
                                                    "name": "water.treatment-reuse.gwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7269,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "water.treatment-reuse.gwr.CapexTotal",
                                                    "name": "water.treatment-reuse.gwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "water.treatment-reuse.gwr.Savings",
                                                    "name": "water.treatment-reuse.gwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "water.treatment-reuse.gwr.Carbon",
                                                    "name": "water.treatment-reuse.gwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "water.CapexTotal",
                                                    "name": "water.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13089540,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexTotalConverted",
                                                    "name": "water.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 22,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Savings",
                                                    "name": "water.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.SavingsConverted",
                                                    "name": "water.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers Avoided",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10166,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Capex",
                                                    "name": "water.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7240880,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CapexConverted",
                                                    "name": "water.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Opex",
                                                    "name": "water.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3452981,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.Carbon",
                                                    "name": "water.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 43,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.CarbonConverted",
                                                    "name": "water.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.totalpercentagesaving",
                                                    "name": "water.totalpercentagesaving",
                                                    "label": "% Total Water Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.annualbaselinewaterconsumption",
                                                    "name": "water.annualbaselinewaterconsumption",
                                                    "label": "Annual Baseline Water Consumption",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 71433,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Waste_Saved",
                                                    "name": "water.treatment-reuse.gwr.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Waste_Opex Savings",
                                                    "name": "water.treatment-reuse.gwr.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Energy_Saved",
                                                    "name": "water.treatment-reuse.gwr.Energy_Saved",
                                                    "label": "Energy Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kWh",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Energy_Opex Savings",
                                                    "name": "water.treatment-reuse.gwr.Energy_Opex Savings",
                                                    "label": "Energy Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Total Water",
                                                    "name": "water.treatment-reuse.gwr.%_of Total Water",
                                                    "label": "% of Total Water",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 26,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Domestic Demand",
                                                    "name": "water.treatment-reuse.gwr.%_of Domestic Demand",
                                                    "label": "% of Domestic Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.%_of Common Area Demand",
                                                    "name": "water.treatment-reuse.gwr.%_of Common Area Demand",
                                                    "label": "% of Common Area Demand",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewMin",
                                                    "name": "water.treatment-reuse.gwr.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewMax",
                                                    "name": "water.treatment-reuse.gwr.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.OverviewTotal",
                                                    "name": "water.treatment-reuse.gwr.OverviewTotal",
                                                    "label": "Usage by Category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Domestic",
                                                    "name": "water.treatment-reuse.gwr.Domestic",
                                                    "label": "Domestic",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Health_Faucet",
                                                    "name": "water.treatment-reuse.gwr.Health_Faucet",
                                                    "label": "Health Faucet",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Flushing",
                                                    "name": "water.treatment-reuse.gwr.Flushing",
                                                    "label": "Flushing",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Common_Area",
                                                    "name": "water.treatment-reuse.gwr.Common_Area",
                                                    "label": "Common Area",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Drinking",
                                                    "name": "water.treatment-reuse.gwr.Drinking",
                                                    "label": "Drinking",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Savings",
                                                    "name": "water.treatment-reuse.gwr.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 18903,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SavingsConverted",
                                                    "name": "water.treatment-reuse.gwr.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Water Tankers",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3150,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexPre",
                                                    "name": "water.treatment-reuse.gwr.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexPost",
                                                    "name": "water.treatment-reuse.gwr.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Capex",
                                                    "name": "water.treatment-reuse.gwr.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Opex",
                                                    "name": "water.treatment-reuse.gwr.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7269,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Carbon",
                                                    "name": "water.treatment-reuse.gwr.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 13,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.IRR",
                                                    "name": "water.treatment-reuse.gwr.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.PaybackPeriod",
                                                    "name": "water.treatment-reuse.gwr.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "years",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 495,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SdgNumber",
                                                    "name": "water.treatment-reuse.gwr.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        6,
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.SdgText",
                                                    "name": "water.treatment-reuse.gwr.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 6 - Clean Water and Sanitation,SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Locked",
                                                    "name": "water.treatment-reuse.gwr.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.Enabled",
                                                    "name": "water.treatment-reuse.gwr.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.GoalTarget",
                                                    "name": "water.treatment-reuse.gwr.GoalTarget",
                                                    "label": "Water Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 85,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.GoalContribution",
                                                    "name": "water.treatment-reuse.gwr.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 31,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexTotal",
                                                    "name": "water.treatment-reuse.gwr.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3600000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "name": "water.treatment-reuse.gwr.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 6,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.multipliersource",
                                                    "name": "greywaterreuse.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "CUSTOM",
                                                        "VENDOR",
                                                        "DEFAULT"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateDetails",
                                                    "name": "greywaterreuse.MandateDetails",
                                                    "label": "GWR Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        "As per Govt mandate, treated water from onsite STP should be used for non potable (flushing & landscaping) usages after appropriate tertiery treatment."
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateSummary",
                                                    "name": "greywaterreuse.MandateSummary",
                                                    "label": "GWR Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.MandateGenerals",
                                                    "name": "greywaterreuse.MandateGenerals",
                                                    "label": "GWR Mandate Generals",
                                                    "tags": [
                                                        "Generals"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.derivatives.stpcapacity",
                                                    "name": "greywaterreuse.derivatives.stpcapacity",
                                                    "label": "System Capacity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 73,
                                                    "defaultValue": 73
                                                },
                                                {
                                                    "id": "greywaterreuse.greywaterdemand",
                                                    "name": "greywaterreuse.greywaterdemand",
                                                    "label": "Grey Water Reuse Towards",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "valueOptions": [
                                                        "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping",
                                                        "Domestic",
                                                        "Common Area Car Park, Common Area Cleaning, Landscaping",
                                                        "Common Area Car Park, Common Area Cleaning",
                                                        "Common Area Car Park",
                                                        "Common Area Cleaning",
                                                        "Landscaping",
                                                        "Domestic, Common Area Car Park, Common Area Cleaning"
                                                    ],
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping",
                                                    "defaultValue": "Domestic, Common Area Car Park, Common Area Cleaning, Landscaping"
                                                },
                                                {
                                                    "id": "water.derivatives.waterpriceperkl",
                                                    "name": "water.derivatives.waterpriceperkl",
                                                    "label": "Water price per KL",
                                                    "tags": [
                                                        "assumption",
                                                        "project-customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 91.66,
                                                    "defaultValue": 91.66
                                                },
                                                {
                                                    "id": "greywaterreuse.constants.greystpefficiency",
                                                    "name": "greywaterreuse.constants.greystpefficiency",
                                                    "label": "Grey STP Efficiency",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 0.95,
                                                    "defaultValue": 0.95
                                                },
                                                {
                                                    "id": "project.constants.locationemissionfactor",
                                                    "name": "project.constants.locationemissionfactor",
                                                    "label": "CO2e Emission Factor",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kL",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0.7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.constant.greywaterdemand",
                                                    "name": "greywaterreuse.constant.greywaterdemand",
                                                    "label": "Tertiary Treatment Capex (per kL)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 45000,
                                                    "defaultValue": 45000
                                                },
                                                {
                                                    "id": "greywaterreuse.VendorCapexMultiplier",
                                                    "name": "greywaterreuse.VendorCapexMultiplier",
                                                    "label": "Vendor Capex per KL installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 50000,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Combined",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "name": "greywaterreuse.CombinedOrSeparateBlackWaterSTPBefore",
                                                    "label": "Combined Or Separate Black Water STP After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Separate",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.dualpipingCalculationBefore",
                                                    "name": "greywaterreuse.dualpipingCalculationBefore",
                                                    "label": "Dual Piping Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.dualpipingCalculationAfter",
                                                    "name": "greywaterreuse.dualpipingCalculationAfter",
                                                    "label": "Dual Piping After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Yes",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.ReuseOfTreatedWaterBefore",
                                                    "name": "greywaterreuse.ReuseOfTreatedWaterBefore",
                                                    "label": "Reuse Of Treated Water Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.bwrannualWaterpresdplus",
                                                    "name": "greywaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Reuse Of Treated Water After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Grey Water Demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "name": "greywaterreuse.TertiaryTreatmentRequiredBefore",
                                                    "label": "Tertiary Treatment Required Before",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "No",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.bwrannualWaterpresdplus",
                                                    "name": "greywaterreuse.bwrannualWaterpresdPlus",
                                                    "label": "Tertiary Treatment Required After",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Grey Water Demand",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "greywaterreuse.gwrAnnualWaterPreSDplus",
                                                    "name": "greywaterreuse.gwrAnnualWaterPreSDplus",
                                                    "label": "GWR Annual Water Pre SD plus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "water.custom-interventions.Capex",
                                            "name": "water.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "water.custom-interventions.CapexConverted",
                                            "name": "water.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "water.custom-interventions.Opex",
                                            "name": "water.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "water.custom-interventions.CapexTotal",
                                            "name": "water.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "water.custom-interventions.CapexTotalConverted",
                                            "name": "water.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "water.custom-interventions.Savings",
                                            "name": "water.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "kL",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "water.custom-interventions.Carbon",
                                            "name": "water.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            }
                        ]
                    },
                    {
                        "name": "Materials",
                        "quantities": {
                            "finance": {
                                "capex": {
                                    "id": "materials.Capex",
                                    "name": "materials.Capex",
                                    "label": "",
                                    "tags": [
                                        "Capex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": -5621637,
                                    "defaultValue": 0
                                },
                                "capexConverted": {
                                    "id": "materials.CapexConverted",
                                    "name": "materials.CapexConverted",
                                    "label": "",
                                    "tags": [
                                        "CapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": -9,
                                    "defaultValue": 0
                                },
                                "opexSavings": {
                                    "id": "materials.Opex",
                                    "name": "materials.Opex",
                                    "label": "",
                                    "tags": [
                                        "Opex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 0,
                                    "defaultValue": 0
                                },
                                "totalCapex": {
                                    "id": "materials.CapexTotal",
                                    "name": "materials.CapexTotal",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapex",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 226535604,
                                    "defaultValue": 0
                                },
                                "totalCapexConverted": {
                                    "id": "materials.CapexTotalConverted",
                                    "name": "materials.CapexTotalConverted",
                                    "label": "",
                                    "tags": [
                                        "_TotalCapexConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "Rs/Sqft",
                                    "editable": false,
                                    "type": "InterventionOutput",
                                    "value": 381,
                                    "defaultValue": 0
                                }
                            },
                            "resource": {
                                "category": {
                                    "id": "materials.Savings",
                                    "name": "materials.Savings",
                                    "label": "",
                                    "tags": [
                                        "Resource",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 1945,
                                    "defaultValue": 0
                                },
                                "categoryConverted": {
                                    "id": "materials.SavingsConverted",
                                    "name": "materials.SavingsConverted",
                                    "label": "",
                                    "tags": [
                                        "ResourceConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 423,
                                    "defaultValue": 0
                                },
                                "carbon": {
                                    "id": "materials.Carbon",
                                    "name": "materials.Carbon",
                                    "label": "",
                                    "tags": [
                                        "Carbon",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "tCO2e",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 369,
                                    "defaultValue": 0
                                },
                                "carbonConverted": {
                                    "id": "materials.CarbonConverted",
                                    "name": "materials.CarbonConverted",
                                    "label": "",
                                    "tags": [
                                        "CarbonConverted",
                                        "Category"
                                    ],
                                    "uri": "",
                                    "unit": "cars off the road for a year",
                                    "editable": false,
                                    "type": "InterventionParameter",
                                    "value": 494,
                                    "defaultValue": 0
                                }
                            },
                            "flowDiagram": {
                                "left": {},
                                "right": {},
                                "connections": []
                            }
                        },
                        "subcategories": [
                            {
                                "name": "Paints",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.paints.Capex",
                                            "name": "materials.paints.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 34776,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.paints.CapexConverted",
                                            "name": "materials.paints.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.paints.Opex",
                                            "name": "materials.paints.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.paints.CapexTotal",
                                            "name": "materials.paints.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 46706,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.paints.CapexTotalConverted",
                                            "name": "materials.paints.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.paints.Savings",
                                            "name": "materials.paints.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.paints.Carbon",
                                            "name": "materials.paints.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Interior Paints",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb36",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.paints.interiorpaints.Capex",
                                                    "name": "materials.paints.interiorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.paints.interiorpaints.CapexConverted",
                                                    "name": "materials.paints.interiorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.paints.interiorpaints.Opex",
                                                    "name": "materials.paints.interiorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.paints.interiorpaints.CapexTotal",
                                                    "name": "materials.paints.interiorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": -950,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.paints.interiorpaints.Savings",
                                                    "name": "materials.paints.interiorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.paints.interiorpaints.Carbon",
                                                    "name": "materials.paints.interiorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.studio",
                                                    "name": "pip.studio",
                                                    "label": "Studio",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "50",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.single",
                                                    "name": "pip.single",
                                                    "label": "1 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "48",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.double",
                                                    "name": "pip.double",
                                                    "label": "2 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "52",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.three",
                                                    "name": "pip.three",
                                                    "label": "3 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.four",
                                                    "name": "pip.four",
                                                    "label": "4 BHK",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "34",
                                                    "unit": "units",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Water_Saved",
                                                    "name": "materials.paints.interiorpaints.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Water_Opex Savings",
                                                    "name": "materials.paints.interiorpaints.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Waste_Saved",
                                                    "name": "materials.paints.interiorpaints.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Waste_Opex Savings",
                                                    "name": "materials.paints.interiorpaints.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.interiorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_of Total Embodied Carbon",
                                                    "name": "materials.paints.interiorpaints.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.%_of Total Carbon Footprint",
                                                    "name": "materials.paints.interiorpaints.%_of Total Carbon Footprint",
                                                    "label": "% of Total Carbon Footprint",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewMin",
                                                    "name": "materials.paints.interiorpaints.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewMax",
                                                    "name": "materials.paints.interiorpaints.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.OverviewTotal",
                                                    "name": "materials.paints.interiorpaints.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Embodied Carbon",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Savings",
                                                    "name": "materials.paints.interiorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SavingsConverted",
                                                    "name": "materials.paints.interiorpaints.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexPre",
                                                    "name": "materials.paints.interiorpaints.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexPost",
                                                    "name": "materials.paints.interiorpaints.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Capex",
                                                    "name": "materials.paints.interiorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexConverted",
                                                    "name": "materials.paints.interiorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 350,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Opex",
                                                    "name": "materials.paints.interiorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Carbon",
                                                    "name": "materials.paints.interiorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.IRR",
                                                    "name": "materials.paints.interiorpaints.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.PaybackPeriod",
                                                    "name": "materials.paints.interiorpaints.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SdgNumber",
                                                    "name": "materials.paints.interiorpaints.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.SdgText",
                                                    "name": "materials.paints.interiorpaints.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Locked",
                                                    "name": "materials.paints.interiorpaints.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.Enabled",
                                                    "name": "materials.paints.interiorpaints.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.GoalTarget",
                                                    "name": "materials.paints.interiorpaints.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.GoalContribution",
                                                    "name": "materials.paints.interiorpaints.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexTotal",
                                                    "name": "materials.paints.interiorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": -950,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.interiorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.recopaint",
                                                    "name": "interiorpaints.recopaint",
                                                    "label": "Recommended Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Apollo Paints Pride Gold ",
                                                    "defaultValue": "Apollo Paints Pride Gold ",
                                                    "valueOptions": [
                                                        "Apollo Paints Pride Gold ",
                                                        "Asian Paints Tractor Emulsion"
                                                    ]
                                                },
                                                {
                                                    "id": "interiorpaints.voclevel",
                                                    "name": "interiorpaints.voclevel",
                                                    "label": "VOC Level",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 12,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.solventnumberofcoats",
                                                    "name": "interiorpaints.constants.solventnumberofcoats",
                                                    "label": "Solvent Number of Coats",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Solventcoveragearea",
                                                    "name": "interiorpaints.constants.Solventcoveragearea ",
                                                    "label": "Solvent coverage area ",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 18.7,
                                                    "defaultValue": 18.7
                                                },
                                                {
                                                    "id": "interiorpaints.constants.solventEC",
                                                    "name": "interiorpaints.constants.solventEC",
                                                    "label": "Solvent EC",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3.76,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.SolventCapex",
                                                    "name": "interiorpaints.constants.SolventCapex ",
                                                    "label": "Solvent Capex",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 15.1,
                                                    "defaultValue": 15.1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Manualnumberofcoats",
                                                    "name": "interiorpaints.constants.Manualnumberofcoats ",
                                                    "label": "Manual number of coats",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "no.",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 1
                                                },
                                                {
                                                    "id": "interiorpaints.constants.Manualcoveragearea",
                                                    "name": "interiorpaints.constants.Manualcoveragearea ",
                                                    "label": "Manual coverage area",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 10.86,
                                                    "defaultValue": 10.86
                                                },
                                                {
                                                    "id": "interiorpaints.constants.ManualEC",
                                                    "name": "interiorpaints.constants.ManualEC ",
                                                    "label": "Manual EC",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg CO2e/kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2.54,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.constants.ManualCapex",
                                                    "name": "interiorpaints.constants.ManualCapex ",
                                                    "label": "Manual Capex",
                                                    "tags": [
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 25,
                                                    "defaultValue": 25
                                                },
                                                {
                                                    "id": "interiorpaints.MandateDetails",
                                                    "name": "interiorpaints.MandateDetails",
                                                    "label": "Interior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "interiorpaints.MandateSummary",
                                                    "name": "interiorpaints.MandateSummary",
                                                    "label": "Interior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Exterior Paints",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.paints.exteriorpaints.Capex",
                                                    "name": "materials.paints.exteriorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34427,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.paints.exteriorpaints.CapexConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.paints.exteriorpaints.Opex",
                                                    "name": "materials.paints.exteriorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.paints.exteriorpaints.CapexTotal",
                                                    "name": "materials.paints.exteriorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 47656,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.paints.exteriorpaints.Savings",
                                                    "name": "materials.paints.exteriorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.paints.exteriorpaints.Carbon",
                                                    "name": "materials.paints.exteriorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.floors",
                                                    "name": "pip.floors",
                                                    "label": "Number of Floors",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "30",
                                                    "unit": "nos",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.wwr",
                                                    "name": "pip.wwr",
                                                    "label": "WWR",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 0.25,
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.perimeter",
                                                    "name": "pip.perimeter",
                                                    "label": "Perimeter",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "110",
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "pip.floor2floorheight",
                                                    "name": "pip.floor2floorheight",
                                                    "label": "Floor to Floor Height",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": 3,
                                                    "unit": "m",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Water_Saved",
                                                    "name": "materials.paints.exteriorpaints.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Water_Opex Savings",
                                                    "name": "materials.paints.exteriorpaints.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Waste_Saved",
                                                    "name": "materials.paints.exteriorpaints.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Waste_Opex Savings",
                                                    "name": "materials.paints.exteriorpaints.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "name": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "label": "% of total ECs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 3,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "name": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "label": "% of Carbon footprint",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewMin",
                                                    "name": "materials.paints.exteriorpaints.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewMax",
                                                    "name": "materials.paints.exteriorpaints.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.OverviewTotal",
                                                    "name": "materials.paints.exteriorpaints.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "name": "materials.paints.exteriorpaints.%_reduction in VOCs",
                                                    "label": "% reduction in VOCs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "name": "materials.paints.exteriorpaints.%_of total ECs",
                                                    "label": "% of total ECs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "name": "materials.paints.exteriorpaints.%_of Carbon footprint",
                                                    "label": "% of Carbon footprint",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Savings",
                                                    "name": "materials.paints.exteriorpaints.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SavingsConverted",
                                                    "name": "materials.paints.exteriorpaints.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexPre",
                                                    "name": "materials.paints.exteriorpaints.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexPost",
                                                    "name": "materials.paints.exteriorpaints.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Capex",
                                                    "name": "materials.paints.exteriorpaints.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 34427,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Opex",
                                                    "name": "materials.paints.exteriorpaints.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Carbon",
                                                    "name": "materials.paints.exteriorpaints.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.IRR",
                                                    "name": "materials.paints.exteriorpaints.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.PaybackPeriod",
                                                    "name": "materials.paints.exteriorpaints.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SdgNumber",
                                                    "name": "materials.paints.exteriorpaints.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.SdgText",
                                                    "name": "materials.paints.exteriorpaints.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Locked",
                                                    "name": "materials.paints.exteriorpaints.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.Enabled",
                                                    "name": "materials.paints.exteriorpaints.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.GoalTarget",
                                                    "name": "materials.paints.exteriorpaints.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.GoalContribution",
                                                    "name": "materials.paints.exteriorpaints.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexTotal",
                                                    "name": "materials.paints.exteriorpaints.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 47656,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "name": "materials.paints.exteriorpaints.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.sunnyRecoCapex",
                                                    "name": "exteriorpaints.sunnyRecoCapex",
                                                    "label": "Sunny Areas paint Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 58.98375,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.sunnyRecoCapex",
                                                    "name": "exteriorpaints.sunnyRecoCapex",
                                                    "label": "Non-Sunny Areas paint Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 31.5,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.BaseCaseCapex",
                                                    "name": "exteriorpaints.BaseCaseCapex",
                                                    "label": "Base Case Capex",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30.866666666666667,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.SunnyReco",
                                                    "name": "exteriorpaints.SunnyReco",
                                                    "label": "Sunny Reco Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Excel Cool coat",
                                                    "defaultValue": 0,
                                                    "valueOptions": [
                                                        "Excel Cool coat"
                                                    ]
                                                },
                                                {
                                                    "id": "exteriorpaints.NonsunnyReco",
                                                    "name": "exteriorpaints.NonsunnyReco",
                                                    "label": "Non Sunny Reco Paint",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Nerolac Excel Mica Marble",
                                                    "defaultValue": 0,
                                                    "valueOptions": [
                                                        "Nerolac Excel Mica Marble"
                                                    ]
                                                },
                                                {
                                                    "id": "exteriorpaints.sunIncidentAreas",
                                                    "name": "exteriorpaints.sunIncidentAreas",
                                                    "label": "Sun Incident Areas",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 50,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.VOCLEVEL",
                                                    "name": "exteriorpaints.VOCLEVEL",
                                                    "label": "VOC Levels",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Ultra-Low",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.MandateDetails",
                                                    "name": "exteriorpaints.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "exteriorpaints.MandateSummary",
                                                    "name": "exteriorpaints.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Custom Interventions",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.custom-interventions.Capex",
                                            "name": "materials.custom-interventions.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.custom-interventions.CapexConverted",
                                            "name": "materials.custom-interventions.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.custom-interventions.Opex",
                                            "name": "materials.custom-interventions.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.custom-interventions.CapexTotal",
                                            "name": "materials.custom-interventions.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.custom-interventions.CapexTotalConverted",
                                            "name": "materials.custom-interventions.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.custom-interventions.Savings",
                                            "name": "materials.custom-interventions.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.custom-interventions.Carbon",
                                            "name": "materials.custom-interventions.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": []
                            },
                            {
                                "name": "Structure",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.structure.Capex",
                                            "name": "materials.structure.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": -5828844,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.structure.CapexConverted",
                                            "name": "materials.structure.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": -10,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.structure.Opex",
                                            "name": "materials.structure.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.structure.CapexTotal",
                                            "name": "materials.structure.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 226209190,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.structure.CapexTotalConverted",
                                            "name": "materials.structure.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 380,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.structure.Savings",
                                            "name": "materials.structure.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1944,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.structure.Carbon",
                                            "name": "materials.structure.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 1944,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Concrete",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb38",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.structure.concrete.Capex",
                                                    "name": "materials.structure.concrete.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -11267736,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.structure.concrete.CapexConverted",
                                                    "name": "materials.structure.concrete.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -19,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.structure.concrete.Opex",
                                                    "name": "materials.structure.concrete.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.structure.concrete.CapexTotal",
                                                    "name": "materials.structure.concrete.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 90141888,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.structure.concrete.CapexTotalConverted",
                                                    "name": "materials.structure.concrete.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.structure.concrete.Savings",
                                                    "name": "materials.structure.concrete.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.structure.concrete.Carbon",
                                                    "name": "materials.structure.concrete.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Water_Saved",
                                                    "name": "materials.structure.concrete.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Water_Opex Savings",
                                                    "name": "materials.structure.concrete.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Waste_Saved",
                                                    "name": "materials.structure.concrete.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Waste_Opex Savings",
                                                    "name": "materials.structure.concrete.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "name": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "label": "% of Reduction in VOCs",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewMin",
                                                    "name": "materials.structure.concrete.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewMax",
                                                    "name": "materials.structure.concrete.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.OverviewTotal",
                                                    "name": "materials.structure.concrete.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "name": "materials.structure.concrete.%_of Reduction in VOCs",
                                                    "label": "% of Reduction in VOCs",
                                                    "tags": [
                                                        "overview-breakup"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Savings",
                                                    "name": "materials.structure.concrete.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SavingsConverted",
                                                    "name": "materials.structure.concrete.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1690,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexPre",
                                                    "name": "materials.structure.concrete.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexPost",
                                                    "name": "materials.structure.concrete.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Capex",
                                                    "name": "materials.structure.concrete.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -11267736,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexConverted",
                                                    "name": "materials.structure.concrete.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -19,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Opex",
                                                    "name": "materials.structure.concrete.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Carbon",
                                                    "name": "materials.structure.concrete.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 282,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.IRR",
                                                    "name": "materials.structure.concrete.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.PaybackPeriod",
                                                    "name": "materials.structure.concrete.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SdgNumber",
                                                    "name": "materials.structure.concrete.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.SdgText",
                                                    "name": "materials.structure.concrete.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Locked",
                                                    "name": "materials.structure.concrete.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.Enabled",
                                                    "name": "materials.structure.concrete.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.GoalTarget",
                                                    "name": "materials.structure.concrete.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.GoalContribution",
                                                    "name": "materials.structure.concrete.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexTotal",
                                                    "name": "materials.structure.concrete.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 90141888,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.concrete.CapexTotalConverted",
                                                    "name": "materials.structure.concrete.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 152,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.concretequantity",
                                                    "name": "concrete.concretequantity",
                                                    "label": "Concrete Quantity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cum",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 22535,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.typeOfconcrete",
                                                    "name": "concrete.typeOfconcrete",
                                                    "label": "Type Of Concrete",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "Portland Slag Cement (PSC)",
                                                        "Portland Pozzolana Cement (PPC)"
                                                    ],
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Portland Slag Cement (PSC)",
                                                    "defaultValue": "Portland Slag Cement (PSC)"
                                                },
                                                {
                                                    "id": "concrete.projectinput.typeOfconcrete",
                                                    "name": "concrete.projectinput.typeOfconcrete",
                                                    "label": "Type Of Concrete",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "Portland Slag Cement (PSC)",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.BaseCaseCapex",
                                                    "name": "concrete.BaseCaseCapex",
                                                    "label": "Base Case Capex(per cum)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 4500,
                                                    "defaultValue": 4500
                                                },
                                                {
                                                    "id": "concrete.multipliersource",
                                                    "name": "concrete.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.ConcreteDensity",
                                                    "name": "concrete.ConcreteDensity",
                                                    "label": "Concrete Desnsity",
                                                    "tags": [
                                                        "assumption",
                                                        "constant"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg/cum",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 2500,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.MandateDetails",
                                                    "name": "concrete.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "concrete.MandateSummary",
                                                    "name": "concrete.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Structural Steel",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb40",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.structure.structuralsteel.Capex",
                                                    "name": "materials.structure.structuralsteel.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5438892,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.structure.structuralsteel.CapexConverted",
                                                    "name": "materials.structure.structuralsteel.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.structure.structuralsteel.Opex",
                                                    "name": "materials.structure.structuralsteel.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.structure.structuralsteel.CapexTotal",
                                                    "name": "materials.structure.structuralsteel.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 136067302,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "name": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 229,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.structure.structuralsteel.Savings",
                                                    "name": "materials.structure.structuralsteel.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.structure.structuralsteel.Carbon",
                                                    "name": "materials.structure.structuralsteel.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.builtup",
                                                    "name": "pip.builtup",
                                                    "label": "Built Up Area",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "55234",
                                                    "unit": "Sqm",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Water_Saved",
                                                    "name": "materials.structure.structuralsteel.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kL",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Water_Opex Savings",
                                                    "name": "materials.structure.structuralsteel.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Waste_Saved",
                                                    "name": "materials.structure.structuralsteel.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Waste_Opex Savings",
                                                    "name": "materials.structure.structuralsteel.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.%_of Total Embodied Carbon",
                                                    "name": "materials.structure.structuralsteel.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 13536,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewMin",
                                                    "name": "materials.structure.structuralsteel.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewMax",
                                                    "name": "materials.structure.structuralsteel.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.OverviewTotal",
                                                    "name": "materials.structure.structuralsteel.OverviewTotal",
                                                    "label": "Steel EC savings by category",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Savings",
                                                    "name": "materials.structure.structuralsteel.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SavingsConverted",
                                                    "name": "materials.structure.structuralsteel.SavingsConverted",
                                                    "label": "Avoided Annually",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9975,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexPre",
                                                    "name": "materials.structure.structuralsteel.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexPost",
                                                    "name": "materials.structure.structuralsteel.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Capex",
                                                    "name": "materials.structure.structuralsteel.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5438892,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexConverted",
                                                    "name": "materials.structure.structuralsteel.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Opex",
                                                    "name": "materials.structure.structuralsteel.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Carbon",
                                                    "name": "materials.structure.structuralsteel.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1663,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.IRR",
                                                    "name": "materials.structure.structuralsteel.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.PaybackPeriod",
                                                    "name": "materials.structure.structuralsteel.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SdgNumber",
                                                    "name": "materials.structure.structuralsteel.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.SdgText",
                                                    "name": "materials.structure.structuralsteel.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Locked",
                                                    "name": "materials.structure.structuralsteel.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.Enabled",
                                                    "name": "materials.structure.structuralsteel.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.GoalTarget",
                                                    "name": "materials.structure.structuralsteel.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.GoalContribution",
                                                    "name": "materials.structure.structuralsteel.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexTotal",
                                                    "name": "materials.structure.structuralsteel.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 136067302,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "name": "materials.structure.structuralsteel.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 229,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.treesquivalentsaved",
                                                    "name": "structuralSteels.treesquivalentsaved",
                                                    "label": "Tree Equivalent Saved",
                                                    "tags": [
                                                        "assumption"
                                                    ],
                                                    "uri": "",
                                                    "unit": "nos.",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9975.260399999997,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.percentageofreductioninvocs",
                                                    "name": "structuralSteels.percentageofreductioninvocs",
                                                    "label": "Steel Quantity",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2375062,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.MandateDetails",
                                                    "name": "structuralSteels.MandateDetails",
                                                    "label": "Exterior Paints Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.MandateSummary",
                                                    "name": "structuralSteels.MandateSummary",
                                                    "label": "Exterior Paints Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.BaseCaseCapex",
                                                    "name": "structuralSteels.BaseCaseCapex",
                                                    "label": "Base Case Capex(per kg)",
                                                    "tags": [
                                                        "assumption",
                                                        "capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 55
                                                },
                                                {
                                                    "id": "structuralSteels.multipliersource",
                                                    "name": "structuralSteels.multipliersource",
                                                    "label": "Multiplier Source",
                                                    "tags": [
                                                        "assumption",
                                                        "multiplier-source"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "DEFAULT",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.DefaultCapexMultiplier",
                                                    "name": "structuralSteels.DefaultCapexMultiplier",
                                                    "label": "structuralSteels Capex per KLD installed",
                                                    "tags": [
                                                        "assumption",
                                                        "vendor-capex-multiplier"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 55,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "structuralSteels.StructuralSteelType",
                                                    "name": "structuralSteels.StructuralSteelType",
                                                    "label": "Type Of Steel",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "valueOptions": [
                                                        "DRI Steel"
                                                    ],
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "DRI Steel",
                                                    "defaultValue": "DRI Steel"
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            },
                            {
                                "name": "Internal Flooring",
                                "quantities": {
                                    "finance": {
                                        "capex": {
                                            "id": "materials.flooring.Capex",
                                            "name": "materials.flooring.Capex",
                                            "label": "",
                                            "tags": [
                                                "Capex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 172430,
                                            "defaultValue": 0
                                        },
                                        "capexConverted": {
                                            "id": "materials.flooring.CapexConverted",
                                            "name": "materials.flooring.CapexConverted",
                                            "label": "",
                                            "tags": [
                                                "CapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "opexSavings": {
                                            "id": "materials.flooring.Opex",
                                            "name": "materials.flooring.Opex",
                                            "label": "",
                                            "tags": [
                                                "Opex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "totalCapex": {
                                            "id": "materials.flooring.CapexTotal",
                                            "name": "materials.flooring.CapexTotal",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapex",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 279708,
                                            "defaultValue": 0
                                        },
                                        "totalCapexConverted": {
                                            "id": "materials.flooring.CapexTotalConverted",
                                            "name": "materials.flooring.CapexTotalConverted",
                                            "label": "",
                                            "tags": [
                                                "_TotalCapexConverted",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "Rs/Sqft",
                                            "editable": false,
                                            "type": "InterventionOutput",
                                            "value": 0,
                                            "defaultValue": 0
                                        }
                                    },
                                    "resource": {
                                        "category": {
                                            "id": "materials.flooring.Savings",
                                            "name": "materials.flooring.Savings",
                                            "label": "",
                                            "tags": [
                                                "Resource",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 0,
                                            "defaultValue": 0
                                        },
                                        "carbon": {
                                            "id": "materials.flooring.Carbon",
                                            "name": "materials.flooring.Carbon",
                                            "label": "",
                                            "tags": [
                                                "Carbon",
                                                "SubCategory"
                                            ],
                                            "uri": "",
                                            "unit": "tCO2e",
                                            "editable": false,
                                            "type": "InterventionParameter",
                                            "value": 325,
                                            "defaultValue": 0
                                        }
                                    }
                                },
                                "interventions": [
                                    {
                                        "name": "Utility & Balcony",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb3c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.utilityandbalcony.Capex",
                                                    "name": "materials.flooring.utilityandbalcony.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.utilityandbalcony.Opex",
                                                    "name": "materials.flooring.utilityandbalcony.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.utilityandbalcony.Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.utilityandbalcony.Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.utilityandbalcony",
                                                    "name": "pip.materials.indoorflooring.utilityandbalcony",
                                                    "label": "Utility and Balcony",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Vitrified Tiles",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Water_Saved",
                                                    "name": "materials.flooring.utilityandbalcony.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Water_Opex Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Waste_Saved",
                                                    "name": "materials.flooring.utilityandbalcony.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Waste_Opex Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 77,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewMin",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewMax",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.OverviewTotal",
                                                    "name": "materials.flooring.utilityandbalcony.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Savings",
                                                    "name": "materials.flooring.utilityandbalcony.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SavingsConverted",
                                                    "name": "materials.flooring.utilityandbalcony.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexPre",
                                                    "name": "materials.flooring.utilityandbalcony.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexPost",
                                                    "name": "materials.flooring.utilityandbalcony.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Capex",
                                                    "name": "materials.flooring.utilityandbalcony.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Opex",
                                                    "name": "materials.flooring.utilityandbalcony.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Carbon",
                                                    "name": "materials.flooring.utilityandbalcony.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.IRR",
                                                    "name": "materials.flooring.utilityandbalcony.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.PaybackPeriod",
                                                    "name": "materials.flooring.utilityandbalcony.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SdgNumber",
                                                    "name": "materials.flooring.utilityandbalcony.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.SdgText",
                                                    "name": "materials.flooring.utilityandbalcony.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Locked",
                                                    "name": "materials.flooring.utilityandbalcony.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.Enabled",
                                                    "name": "materials.flooring.utilityandbalcony.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.GoalTarget",
                                                    "name": "materials.flooring.utilityandbalcony.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.GoalContribution",
                                                    "name": "materials.flooring.utilityandbalcony.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "name": "materials.flooring.utilityandbalcony.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.recoflooringtype",
                                                    "name": "utilityandbalcony.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Ceramic Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Utility & Balcony",
                                                        "FlooringType": "Ceramic Tiles",
                                                        "Capex": 4.9,
                                                        "Density": 1636,
                                                        "EC": 0.67,
                                                        "Thickness": 0.011,
                                                        "iid": "c7f4bd01-6e63-4354-b0e3-001c4d39a158"
                                                    },
                                                    "valueOptions": [
                                                        "Ceramic Tiles",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "utilityandbalcony.CarpetArea",
                                                    "name": "utilityandbalcony.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.MandateDetails",
                                                    "name": "utilityandbalcony.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "utilityandbalcony.MandateSummary",
                                                    "name": "utilityandbalcony.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Bedroom",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4e",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.bedroom.Capex",
                                                    "name": "materials.flooring.bedroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32150,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.bedroom.CapexConverted",
                                                    "name": "materials.flooring.bedroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.bedroom.Opex",
                                                    "name": "materials.flooring.bedroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.bedroom.CapexTotal",
                                                    "name": "materials.flooring.bedroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.bedroom.Savings",
                                                    "name": "materials.flooring.bedroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.bedroom.Carbon",
                                                    "name": "materials.flooring.bedroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.bedroom",
                                                    "name": "pip.materials.indoorflooring.bedroom",
                                                    "label": "Bedroom",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Water_Saved",
                                                    "name": "materials.flooring.bedroom.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Water_Opex Savings",
                                                    "name": "materials.flooring.bedroom.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Waste_Saved",
                                                    "name": "materials.flooring.bedroom.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Waste_Opex Savings",
                                                    "name": "materials.flooring.bedroom.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.bedroom.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 308,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewMin",
                                                    "name": "materials.flooring.bedroom.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewMax",
                                                    "name": "materials.flooring.bedroom.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.OverviewTotal",
                                                    "name": "materials.flooring.bedroom.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Savings",
                                                    "name": "materials.flooring.bedroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SavingsConverted",
                                                    "name": "materials.flooring.bedroom.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32203,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexPre",
                                                    "name": "materials.flooring.bedroom.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexPost",
                                                    "name": "materials.flooring.bedroom.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Capex",
                                                    "name": "materials.flooring.bedroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 32150,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexConverted",
                                                    "name": "materials.flooring.bedroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Opex",
                                                    "name": "materials.flooring.bedroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Carbon",
                                                    "name": "materials.flooring.bedroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 5367,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.IRR",
                                                    "name": "materials.flooring.bedroom.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.PaybackPeriod",
                                                    "name": "materials.flooring.bedroom.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SdgNumber",
                                                    "name": "materials.flooring.bedroom.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.SdgText",
                                                    "name": "materials.flooring.bedroom.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Locked",
                                                    "name": "materials.flooring.bedroom.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.Enabled",
                                                    "name": "materials.flooring.bedroom.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.GoalTarget",
                                                    "name": "materials.flooring.bedroom.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.GoalContribution",
                                                    "name": "materials.flooring.bedroom.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexTotal",
                                                    "name": "materials.flooring.bedroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bedroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.recoflooringtype",
                                                    "name": "bedroom.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Laminate",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Bedroom",
                                                        "FlooringType": "Laminate",
                                                        "Capex": 18.3,
                                                        "Density": 600,
                                                        "EC": 2,
                                                        "Thickness": 0.01,
                                                        "iid": "a3e158f2-2858-4f40-8aa1-de38e745cc0c"
                                                    },
                                                    "valueOptions": [
                                                        "Laminate",
                                                        "Bamboo",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "bedroom.CarpetArea",
                                                    "name": "bedroom.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9456,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.MandateDetails",
                                                    "name": "bedroom.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bedroom.MandateSummary",
                                                    "name": "bedroom.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Living & Dining",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4c",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.livinganddining.Capex",
                                                    "name": "materials.flooring.livinganddining.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 91274,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.livinganddining.CapexConverted",
                                                    "name": "materials.flooring.livinganddining.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.livinganddining.Opex",
                                                    "name": "materials.flooring.livinganddining.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.livinganddining.CapexTotal",
                                                    "name": "materials.flooring.livinganddining.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "name": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.livinganddining.Savings",
                                                    "name": "materials.flooring.livinganddining.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.livinganddining.Carbon",
                                                    "name": "materials.flooring.livinganddining.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.livinganddining",
                                                    "name": "pip.materials.indoorflooring.livinganddining",
                                                    "label": "Living and Dining",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Water_Saved",
                                                    "name": "materials.flooring.livinganddining.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Water_Opex Savings",
                                                    "name": "materials.flooring.livinganddining.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Waste_Saved",
                                                    "name": "materials.flooring.livinganddining.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Waste_Opex Savings",
                                                    "name": "materials.flooring.livinganddining.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.livinganddining.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 314,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewMin",
                                                    "name": "materials.flooring.livinganddining.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewMax",
                                                    "name": "materials.flooring.livinganddining.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.OverviewTotal",
                                                    "name": "materials.flooring.livinganddining.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Savings",
                                                    "name": "materials.flooring.livinganddining.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SavingsConverted",
                                                    "name": "materials.flooring.livinganddining.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 23969,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexPre",
                                                    "name": "materials.flooring.livinganddining.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexPost",
                                                    "name": "materials.flooring.livinganddining.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Capex",
                                                    "name": "materials.flooring.livinganddining.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 91274,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexConverted",
                                                    "name": "materials.flooring.livinganddining.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Opex",
                                                    "name": "materials.flooring.livinganddining.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Carbon",
                                                    "name": "materials.flooring.livinganddining.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 3995,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.IRR",
                                                    "name": "materials.flooring.livinganddining.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.PaybackPeriod",
                                                    "name": "materials.flooring.livinganddining.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SdgNumber",
                                                    "name": "materials.flooring.livinganddining.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.SdgText",
                                                    "name": "materials.flooring.livinganddining.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Locked",
                                                    "name": "materials.flooring.livinganddining.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.Enabled",
                                                    "name": "materials.flooring.livinganddining.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.GoalTarget",
                                                    "name": "materials.flooring.livinganddining.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.GoalContribution",
                                                    "name": "materials.flooring.livinganddining.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexTotal",
                                                    "name": "materials.flooring.livinganddining.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "name": "materials.flooring.livinganddining.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.recoflooringtype",
                                                    "name": "livinganddining.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Vitrified Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Living & Dining",
                                                        "FlooringType": "Vitrified Tiles",
                                                        "Capex": 8.83,
                                                        "Density": 2182,
                                                        "EC": 0.68,
                                                        "Thickness": 0.011,
                                                        "iid": "e3dce9b6-e893-442e-9699-fd6ae4e926ca"
                                                    },
                                                    "valueOptions": [
                                                        "Vitrified Tiles",
                                                        "Solid Wood"
                                                    ]
                                                },
                                                {
                                                    "id": "livinganddining.CarpetArea",
                                                    "name": "livinganddining.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7092,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.MandateDetails",
                                                    "name": "livinganddining.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "livinganddining.MandateSummary",
                                                    "name": "livinganddining.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Bathroom",
                                        "interventionProjectMappingId": "661a393cb77e55e58247bb4a",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.bathroom.Capex",
                                                    "name": "materials.flooring.bathroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.bathroom.CapexConverted",
                                                    "name": "materials.flooring.bathroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.bathroom.Opex",
                                                    "name": "materials.flooring.bathroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.bathroom.CapexTotal",
                                                    "name": "materials.flooring.bathroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.bathroom.Savings",
                                                    "name": "materials.flooring.bathroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.bathroom.Carbon",
                                                    "name": "materials.flooring.bathroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.bathroom",
                                                    "name": "pip.materials.indoorflooring.bathroom",
                                                    "label": "Bathroom",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Vitrified Tiles",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Water_Saved",
                                                    "name": "materials.flooring.bathroom.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Water_Opex Savings",
                                                    "name": "materials.flooring.bathroom.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Waste_Saved",
                                                    "name": "materials.flooring.bathroom.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Waste_Opex Savings",
                                                    "name": "materials.flooring.bathroom.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.bathroom.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 77,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewMin",
                                                    "name": "materials.flooring.bathroom.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewMax",
                                                    "name": "materials.flooring.bathroom.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.OverviewTotal",
                                                    "name": "materials.flooring.bathroom.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Savings",
                                                    "name": "materials.flooring.bathroom.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SavingsConverted",
                                                    "name": "materials.flooring.bathroom.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 60,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexPre",
                                                    "name": "materials.flooring.bathroom.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexPost",
                                                    "name": "materials.flooring.bathroom.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Capex",
                                                    "name": "materials.flooring.bathroom.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 9291,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexConverted",
                                                    "name": "materials.flooring.bathroom.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Opex",
                                                    "name": "materials.flooring.bathroom.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Carbon",
                                                    "name": "materials.flooring.bathroom.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 10,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.IRR",
                                                    "name": "materials.flooring.bathroom.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.PaybackPeriod",
                                                    "name": "materials.flooring.bathroom.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SdgNumber",
                                                    "name": "materials.flooring.bathroom.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.SdgText",
                                                    "name": "materials.flooring.bathroom.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Locked",
                                                    "name": "materials.flooring.bathroom.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.Enabled",
                                                    "name": "materials.flooring.bathroom.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.GoalTarget",
                                                    "name": "materials.flooring.bathroom.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.GoalContribution",
                                                    "name": "materials.flooring.bathroom.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexTotal",
                                                    "name": "materials.flooring.bathroom.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "name": "materials.flooring.bathroom.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.recoflooringtype",
                                                    "name": "bathroom.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Ceramic Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Bathroom",
                                                        "FlooringType": "Ceramic Tiles",
                                                        "Capex": 4.9,
                                                        "Density": 1636,
                                                        "EC": 0.67,
                                                        "Thickness": 0.011,
                                                        "iid": "96cec198-0a16-4304-a4ab-fa65ce0a2ae1"
                                                    },
                                                    "valueOptions": [
                                                        "Ceramic Tiles",
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "bathroom.CarpetArea",
                                                    "name": "bathroom.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.MandateDetails",
                                                    "name": "bathroom.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "bathroom.MandateSummary",
                                                    "name": "bathroom.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    },
                                    {
                                        "name": "Kitchen",
                                        "interventionProjectMappingId": "661a393bb77e55e58247bb44",
                                        "interventionProjectMappingUri": "",
                                        "enabled": true,
                                        "locked": false,
                                        "quantities": {
                                            "finance": {
                                                "capex": {
                                                    "id": "materials.flooring.kitchen.Capex",
                                                    "name": "materials.flooring.kitchen.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30425,
                                                    "defaultValue": 0
                                                },
                                                "capexConverted": {
                                                    "id": "materials.flooring.kitchen.CapexConverted",
                                                    "name": "materials.flooring.kitchen.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "opexSavings": {
                                                    "id": "materials.flooring.kitchen.Opex",
                                                    "name": "materials.flooring.kitchen.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapex": {
                                                    "id": "materials.flooring.kitchen.CapexTotal",
                                                    "name": "materials.flooring.kitchen.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                "totalCapexConverted": {
                                                    "id": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "name": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "resource": {
                                                "category": {
                                                    "id": "materials.flooring.kitchen.Savings",
                                                    "name": "materials.flooring.kitchen.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                "carbon": {
                                                    "id": "materials.flooring.kitchen.Carbon",
                                                    "name": "materials.flooring.kitchen.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                }
                                            },
                                            "details": [
                                                {
                                                    "id": "pip.materials.indoorflooring.kitchen",
                                                    "name": "pip.materials.indoorflooring.kitchen",
                                                    "label": "Kitchen",
                                                    "tags": [
                                                        "project-input"
                                                    ],
                                                    "uri": "",
                                                    "value": "Terrazzo",
                                                    "unit": "NA",
                                                    "editable": false,
                                                    "type": "ProjectInput"
                                                },
                                                {
                                                    "id": "materials.CapexTotal",
                                                    "name": "materials.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 226535604,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexTotalConverted",
                                                    "name": "materials.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 381,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Savings",
                                                    "name": "materials.Savings",
                                                    "label": "",
                                                    "tags": [
                                                        "Resource",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1945,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.SavingsConverted",
                                                    "name": "materials.SavingsConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "ResourceConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 423,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Capex",
                                                    "name": "materials.Capex",
                                                    "label": "",
                                                    "tags": [
                                                        "Capex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -5621637,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CapexConverted",
                                                    "name": "materials.CapexConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": -9,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Opex",
                                                    "name": "materials.Opex",
                                                    "label": "",
                                                    "tags": [
                                                        "Opex",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.Carbon",
                                                    "name": "materials.Carbon",
                                                    "label": "",
                                                    "tags": [
                                                        "Carbon",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.CarbonConverted",
                                                    "name": "materials.CarbonConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "CarbonConverted",
                                                        "Category"
                                                    ],
                                                    "uri": "",
                                                    "unit": "cars off the road for a year",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 494,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.totalpercentagesaving",
                                                    "name": "material.totalpercentagesaving",
                                                    "label": "% Total Material Saving",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 527,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "material.annualbaselineEC",
                                                    "name": "material.annualbaselineEC",
                                                    "label": "Annual Baseline EC",
                                                    "tags": [
                                                        "pdf"
                                                    ],
                                                    "uri": "",
                                                    "unit": "kg",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 369,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Water_Saved",
                                                    "name": "materials.flooring.kitchen.Water_Saved",
                                                    "label": "Water Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Water_Opex Savings",
                                                    "name": "materials.flooring.kitchen.Water_Opex Savings",
                                                    "label": "Water Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Waste_Saved",
                                                    "name": "materials.flooring.kitchen.Waste_Saved",
                                                    "label": "Waste Saved",
                                                    "tags": [
                                                        "passive-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Waste_Opex Savings",
                                                    "name": "materials.flooring.kitchen.Waste_Opex Savings",
                                                    "label": "Waste Opex Savings",
                                                    "tags": [
                                                        "passive-opex"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.%_of Total Embodied Carbon",
                                                    "name": "materials.flooring.kitchen.%_of Total Embodied Carbon",
                                                    "label": "% of Total Embodied Carbon",
                                                    "tags": [
                                                        "overview-donut"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 105,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewMin",
                                                    "name": "materials.flooring.kitchen.OverviewMin",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-min"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewMax",
                                                    "name": "materials.flooring.kitchen.OverviewMax",
                                                    "label": "",
                                                    "tags": [
                                                        "overview-bar-max"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.OverviewTotal",
                                                    "name": "materials.flooring.kitchen.OverviewTotal",
                                                    "tags": [
                                                        "overview-bar-total"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Savings",
                                                    "name": "materials.flooring.kitchen.Savings",
                                                    "label": "Savings (Annual)",
                                                    "tags": [
                                                        "result-resource",
                                                        "Resource",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SavingsConverted",
                                                    "name": "materials.flooring.kitchen.SavingsConverted",
                                                    "label": "Trees Equivalent Saved",
                                                    "tags": [
                                                        "result-resource",
                                                        "ResourceConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Trees Saved",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 7990,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexPre",
                                                    "name": "materials.flooring.kitchen.CapexPre",
                                                    "label": "CAPEX Pre SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexPost",
                                                    "name": "materials.flooring.kitchen.CapexPost",
                                                    "label": "CAPEX Post SDplus",
                                                    "tags": [
                                                        "Report"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Capex",
                                                    "name": "materials.flooring.kitchen.Capex",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "result-financial",
                                                        "Capex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 30425,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexConverted",
                                                    "name": "materials.flooring.kitchen.CapexConverted",
                                                    "label": "CAPEX",
                                                    "tags": [
                                                        "CapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Opex",
                                                    "name": "materials.flooring.kitchen.Opex",
                                                    "label": "OPEX Savings",
                                                    "tags": [
                                                        "result-financial",
                                                        "Opex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Carbon",
                                                    "name": "materials.flooring.kitchen.Carbon",
                                                    "label": "Carbon Mitigated",
                                                    "tags": [
                                                        "result-resource",
                                                        "Carbon",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "tCO2e",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 1332,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.IRR",
                                                    "name": "materials.flooring.kitchen.IRR",
                                                    "label": "IRR",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.PaybackPeriod",
                                                    "name": "materials.flooring.kitchen.PaybackPeriod",
                                                    "label": "Payback Period",
                                                    "tags": [
                                                        "result-financial"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SdgNumber",
                                                    "name": "materials.flooring.kitchen.SdgNumber",
                                                    "label": "SDG Number",
                                                    "tags": [
                                                        "result-resource"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": [
                                                        11
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.SdgText",
                                                    "name": "materials.flooring.kitchen.SdgText",
                                                    "label": "SDG Text",
                                                    "tags": [
                                                        "SdgText"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": "SDG 11 - Sustainable Cities and Communities",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Locked",
                                                    "name": "materials.flooring.kitchen.Locked",
                                                    "label": "Locked",
                                                    "tags": [
                                                        "Locked"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "NO",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.Enabled",
                                                    "name": "materials.flooring.kitchen.Enabled",
                                                    "label": "Enabled",
                                                    "tags": [
                                                        "Enabled"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "YES",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.GoalTarget",
                                                    "name": "materials.flooring.kitchen.GoalTarget",
                                                    "label": "Materials Goal",
                                                    "tags": [
                                                        "goal-target"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": "n.a",
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.GoalContribution",
                                                    "name": "materials.flooring.kitchen.GoalContribution",
                                                    "label": "Contribution",
                                                    "tags": [
                                                        "goal-contribution"
                                                    ],
                                                    "uri": "",
                                                    "unit": "%",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexTotal",
                                                    "name": "materials.flooring.kitchen.CapexTotal",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapex",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "name": "materials.flooring.kitchen.CapexTotalConverted",
                                                    "label": "",
                                                    "tags": [
                                                        "_TotalCapexConverted",
                                                        "Intervention"
                                                    ],
                                                    "uri": "",
                                                    "unit": "Rs/Sqft",
                                                    "editable": false,
                                                    "type": "InterventionOutput",
                                                    "value": 0,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.recoflooringtype",
                                                    "name": "kitchen.recoflooringtype",
                                                    "label": "Sustainable Flooring",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "",
                                                    "editable": true,
                                                    "type": "InterventionParameter",
                                                    "value": "Vitrified Tiles",
                                                    "defaultValue": {
                                                        "case": "Reco Case 1",
                                                        "Space": "Kitchen",
                                                        "FlooringType": "Vitrified Tiles",
                                                        "Capex": 8.83,
                                                        "Density": 2182,
                                                        "EC": 0.68,
                                                        "Thickness": 0.011,
                                                        "iid": "570d2345-e68a-4793-b297-fe573af4386c"
                                                    },
                                                    "valueOptions": [
                                                        "Vitrified Tiles"
                                                    ]
                                                },
                                                {
                                                    "id": "kitchen.CarpetArea",
                                                    "name": "kitchen.CarpetArea",
                                                    "label": "Carpet Area",
                                                    "tags": [
                                                        "customizable"
                                                    ],
                                                    "uri": "",
                                                    "unit": "sqm",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": 2364,
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.MandateDetails",
                                                    "name": "kitchen.MandateDetails",
                                                    "label": "Flooring Mandate Details",
                                                    "tags": [
                                                        "Mandate"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                },
                                                {
                                                    "id": "kitchen.MandateSummary",
                                                    "name": "kitchen.MandateSummary",
                                                    "label": "Flooring Mandate Summary",
                                                    "tags": [
                                                        "Summary"
                                                    ],
                                                    "uri": "",
                                                    "unit": "n.a",
                                                    "editable": false,
                                                    "type": "InterventionParameter",
                                                    "value": [
                                                        ""
                                                    ],
                                                    "defaultValue": 0
                                                }
                                            ]
                                        },
                                        "sandboxes": []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "marketing": [
                {
                    "name": "energy",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "energy.Capex",
                                "name": "energy.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 2082052,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "energy.Opex",
                                "name": "energy.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 554912,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "energy.Savings",
                                "name": "energy.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kWh",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 85371,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Energy",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.residentialHotWater",
                                            "label": "Residential Hot Water",
                                            "uri": "",
                                            "value": 38,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialLighting",
                                            "label": "Residential Lighting",
                                            "uri": "",
                                            "value": 21,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialFan",
                                            "label": "Residential Fan",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.homeAppliances",
                                            "label": "Home Appliances",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.residentialHVAC",
                                            "label": "Residential HVAC",
                                            "uri": "",
                                            "value": 3,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.residentialHotWater",
                                            "label": "Residential Hot Water",
                                            "uri": "",
                                            "value": 38,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialLighting",
                                            "label": "Residential Lighting",
                                            "uri": "",
                                            "value": 21,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialFan",
                                            "label": "Residential Fan",
                                            "uri": "",
                                            "value": 13,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.homeAppliances",
                                            "label": "Home Appliances",
                                            "uri": "",
                                            "value": 12,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.residentialHVAC",
                                            "label": "Residential HVAC",
                                            "uri": "",
                                            "value": 3,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 11,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 2,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": 2,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Renewable Sources",
                                "total": {
                                    "id": "",
                                    "name": "renewableSources.total",
                                    "label": "Renewable Sources Total",
                                    "uri": "",
                                    "value": 2,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "solarPv.contribution",
                                            "label": "Solar PV",
                                            "uri": "",
                                            "value": 2,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "solarWaterHeater.contribution",
                                            "label": "Solar Water Heater",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "name": "waste",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "waste.Capex",
                                "name": "waste.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 220000,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "waste.Opex",
                                "name": "waste.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 34595,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "waste.Savings",
                                "name": "waste.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kg",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 104740,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Waste",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.organicWaste",
                                            "label": "Organic Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.dryWaste",
                                            "label": "Dry Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.sanitaryWaste",
                                            "label": "Sanitary Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.medicalWaste",
                                            "label": "Medical Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.eWaste",
                                            "label": "E-Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.rejectWaste",
                                            "label": "Reject Waste",
                                            "uri": "",
                                            "value": 13.6,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 86.4,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.organicWaste",
                                            "label": "Organic Waste",
                                            "uri": "",
                                            "value": -0.72,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.dryWaste",
                                            "label": "Dry Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.sanitaryWaste",
                                            "label": "Sanitary Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.medicalWaste",
                                            "label": "Medical Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.eWaste",
                                            "label": "E-Waste",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.rejectWaste",
                                            "label": "Reject Waste",
                                            "uri": "",
                                            "value": 13.6,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 87.12,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": 87,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Reduction",
                                "total": {
                                    "id": "",
                                    "name": "reduction.total",
                                    "label": "Reduction Total",
                                    "uri": "",
                                    "value": 100,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "wwb.contribution",
                                            "label": "Wet Waste Management",
                                            "uri": "",
                                            "value": 60.72,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Management",
                                "total": {
                                    "id": "",
                                    "name": "management.total",
                                    "label": "Management Total",
                                    "uri": "",
                                    "value": 100,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "sni.contribution",
                                            "label": "Sanitary Napkin Incinerator",
                                            "uri": "",
                                            "value": 4,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "casp.contribution",
                                            "label": "Collection And Segregation Point",
                                            "uri": "",
                                            "value": 22,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "name": "water",
                    "summary": {
                        "financial": {
                            "capex": {
                                "id": "water.Capex",
                                "name": "water.Capex",
                                "label": "",
                                "tags": [
                                    "Capex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 7240880,
                                "defaultValue": 0
                            },
                            "opex": {
                                "id": "water.Opex",
                                "name": "water.Opex",
                                "label": "",
                                "tags": [
                                    "Opex",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "Rs",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 3452981,
                                "defaultValue": 0
                            }
                        },
                        "resource": {
                            "savings": {
                                "id": "water.Savings",
                                "name": "water.Savings",
                                "label": "",
                                "tags": [
                                    "Resource",
                                    "Category"
                                ],
                                "uri": "",
                                "unit": "kL",
                                "editable": false,
                                "type": "InterventionParameter",
                                "value": 60997,
                                "defaultValue": 0
                            }
                        }
                    },
                    "consumption": [
                        {
                            "name": "Water",
                            "quantities": {
                                "before": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "before.drinking",
                                            "label": "Drinking",
                                            "uri": "",
                                            "value": 6981.67,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.domestic",
                                            "label": "Domestic",
                                            "uri": "",
                                            "value": 68323.05,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.flushing",
                                            "label": "Flushing",
                                            "uri": "",
                                            "value": 24678.88,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "before.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": 16.4,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                },
                                "after": {
                                    "percentageContribution": [
                                        {
                                            "id": "",
                                            "name": "after.drinking",
                                            "label": "Drinking",
                                            "uri": "",
                                            "value": 6981.66,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.domestic",
                                            "label": "Domestic",
                                            "uri": "",
                                            "value": 68289.68,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.flushing",
                                            "label": "Flushing",
                                            "uri": "",
                                            "value": 24660.73,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.commonArea",
                                            "label": "Common Area",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "after.savings",
                                            "label": "Savings",
                                            "uri": "",
                                            "value": -68.99,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "details": {
                        "total": {
                            "id": "",
                            "name": "savings.total",
                            "label": "Total Savings",
                            "uri": "",
                            "value": -68.99,
                            "tags": [],
                            "unit": "%"
                        },
                        "interventionsBySubcategory": [
                            {
                                "name": "Demand Optimization",
                                "total": {
                                    "id": "",
                                    "name": "demandOptimization.total",
                                    "label": "Demand Optimization Total",
                                    "uri": "",
                                    "value": 36790.23,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "cro.contribution",
                                            "label": "Centralized RO",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "ef.contribution",
                                            "label": "Efficient Fixtures",
                                            "uri": "",
                                            "value": 21961.45,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "df.contribution",
                                            "label": "Dual Flush",
                                            "uri": "",
                                            "value": 12949.24,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "swm.contribution",
                                            "label": "Smart Water Meters",
                                            "uri": "",
                                            "value": 1879.55,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Treatment And Re-use",
                                "total": {
                                    "id": "",
                                    "name": "treatmentReuse.total",
                                    "label": "Treatment And Re-use Total",
                                    "uri": "",
                                    "value": 23419.37,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "gwr.contribution",
                                            "label": "Grey Water Re-use",
                                            "uri": "",
                                            "value": 18902.67,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "bwr.contribution",
                                            "label": "Black Water Re-use",
                                            "uri": "",
                                            "value": 4516.69,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            },
                            {
                                "name": "Source Creation",
                                "total": {
                                    "id": "",
                                    "name": "sourceCreation.total",
                                    "label": "Source Creation Total",
                                    "uri": "",
                                    "value": 787.14,
                                    "tags": [],
                                    "unit": "%"
                                },
                                "quantities": {
                                    "contribution": [
                                        {
                                            "id": "",
                                            "name": "rrwh.contribution",
                                            "label": "Rooftop Rainwater Harvesting",
                                            "uri": "",
                                            "value": 787.14,
                                            "tags": [],
                                            "unit": "%"
                                        },
                                        {
                                            "id": "",
                                            "name": "srwh.contribution",
                                            "label": "Surface Rainwater Harvesting",
                                            "uri": "",
                                            "value": 0,
                                            "tags": [],
                                            "unit": "%"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            ],
            "sustainabilityLevel": {
                "id": "",
                "name": "sustainabilityLevel",
                "label": "Sustainability Level",
                "uri": "",
                "value": 1,
                "tags": [],
                "unit": "Level"
            },
            "locationBasedInsights": {
                "aqi": {
                    "id": "",
                    "name": "aqi",
                    "label": "AQI",
                    "uri": "",
                    "value": {
                        "aqi": 79,
                        "biggestPollutant": "PM2.5",
                        "color": "Yellow",
                        "stationName": "BWSSB Kadabesanahalli, Bengaluru - CPCB"
                    },
                    "tags": [],
                    "unit": "AQI"
                },
                "temperature": {
                    "id": "",
                    "name": "temperature",
                    "label": "Temperature",
                    "uri": "",
                    "value": {
                        "avgHumidity": 64.01,
                        "avgRainfall": 1018.2,
                        "avgTemp": 26.38,
                        "avgWindSpeed": 12.81,
                        "maxTemp": 29.63,
                        "minTemp": 20.18
                    },
                    "tags": [],
                    "unit": "Temperature"
                },
                "igbc": {
                    "id": "",
                    "name": "IGBC",
                    "label": "IGBC",
                    "uri": "",
                    "value": [
                        {
                            "_id": "61a8ac6f8b2d2c24c0ecf1a7",
                            "stationName": "Embassy Pristine",
                            "latitude": 12.9264932,
                            "longitude": 77.6620067,
                            "igbcRating": "Platinum",
                            "type": "Luxury",
                            "launchDate": "Jan 2017",
                            "sitearea": "14 Acres",
                            "builtupArea": 1669870,
                            "noOfapts": 424,
                            "configurations": "3, 4, 5, 5.5 BHK",
                            "avgPricePerSqftInArea": 7393,
                            "avgPricePerSqftForProject": 8500,
                            "startingPrice": "2.19 Cr",
                            "address": "Tower 4, Embassy Pristine, Iblur Village, Bellandur, Bengaluru, Karnataka 560102, India",
                            "createdAt": "2021-12-02T11:22:23.579Z",
                            "__v": 0
                        },
                        {
                            "_id": "61a9bf987f39333ab0c9012c",
                            "stationName": "G:Corp Residences",
                            "latitude": 12.9304,
                            "longitude": 77.6366,
                            "igbcRating": "Gold",
                            "type": "Luxury",
                            "launchDate": "Jul 2021",
                            "sitearea": "2.5 Acres",
                            "builtupArea": null,
                            "noOfapts": 234,
                            "configurations": "2,3,4 BHK",
                            "avgPricePerSqftInArea": 15380,
                            "avgPricePerSqftForProject": null,
                            "startingPrice": "2.53 cr",
                            "address": "115, 1st Block, Opp. Nirguna Mandir, S T Bed Layout, Koramangala, Bengaluru, Karnataka 560034",
                            "createdAt": "2021-12-03T06:56:24.914Z",
                            "__v": 0
                        }
                    ],
                    "tags": [],
                    "unit": "IGBC"
                }
            },
            "context": {
                "cache": {
                    "PaintsDBCache": {}
                },
                "project": {
                    "id": "661a393bb77e55e58247baa7",
                    "pip": {
                        "Location": "HSR Layout, HAL 3rd Stage, Bhoomi Reddy Colony, New Tippasandra, Bengaluru, Karnataka, India",
                        "RooftopArea": 1000,
                        "BuiltUpArea": 55234,
                        "NonavailableRooftop": 500,
                        "Studio": 50,
                        "BHK1": 48,
                        "BHK2": 52,
                        "BHK3": 30,
                        "BHK4": 34,
                        "NumberOfFloors": 30,
                        "WWR": 0.25,
                        "perimeter": 110,
                        "Floor2FloorHeight": 3,
                        "SoftSurfaceArea": 0,
                        "Sitearea": 3254,
                        "BasecaseSPVCapacity": 0,
                        "FlooringUtilityandBalcony": "Vitrified Tiles",
                        "FlooringBathroom": "Vitrified Tiles",
                        "FlooringBedroom": "Terrazzo",
                        "FlooringKitchen": "Terrazzo",
                        "FlooringLivingandDining": "Terrazzo",
                        "clubhouseRooftopArea": 11,
                        "materials": {
                            "interiorpaint": {
                                "solvent": {},
                                "manual": {
                                    "input": {}
                                },
                                "preset": {
                                    "input": {}
                                }
                            },
                            "concrete": {
                                "typeofconcrete": "Portland Slag Cement (PSC)"
                            },
                            "manual": {
                                "input": {}
                            },
                            "preset": {
                                "input": {
                                    "type": "Solvent-Based Paints"
                                }
                            }
                        },
                        "LandscapeArea": 0,
                        "NoOfCarParks": 0,
                        "AreaOfLobbiesAndCorridor": 0,
                        "SwimmingPoolSurfaceArea": 0,
                        "water": {
                            "manual": {
                                "enabled": true,
                                "flows": {
                                    "domesticHealthFaucet": 11,
                                    "domesticFaucet": 11,
                                    "domesticKitchenSink": 11,
                                    "domesticShower": 11,
                                    "flushingFullWC": 11,
                                    "flushingHalfWC": 11
                                }
                            },
                            "preset": {
                                "enabled": false,
                                "faucets": {
                                    "brand": "Jaquar",
                                    "tags": "Basic"
                                },
                                "ceramics": {
                                    "brand": "Hindware",
                                    "tags": "Basic"
                                }
                            },
                            "parameters": {
                                "STP": {},
                                "CRO": {
                                    "CROinstalled": {},
                                    "individualROsystem": {}
                                },
                                "GWR": {}
                            }
                        },
                        "waste": {
                            "paramters": {
                                "WWB": {}
                            }
                        }
                    },
                    "global": {
                        "parameters": {
                            "UtilityPricePerkWh": 6.5
                        }
                    },
                    "energy": {
                        "goal": {},
                        "parameters": {}
                    },
                    "waste": {
                        "goal": {},
                        "parameters": {}
                    },
                    "water": {
                        "goal": {},
                        "parameters": {}
                    }
                },
                "intervention": {
                    "solarpv": {
                        "enabled": true,
                        "parameters": {},
                        "solarpv": {
                            "parameters": {
                                "SPVCapacity": 47,
                                "CapexMultiplier": 1,
                                "MultiplierSource": "CUSTOM",
                                "SolarModule": "Standard",
                                "RooftopAreaPerkWp": 10
                            }
                        }
                    },
                    "solarwaterheater": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "hotwatermanagementvilla": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturehealthfaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturefaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixturekitchenfaucet": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixtureshower": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "hotwatermanagementapartment": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "wwb": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "casp": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "sni": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "cro": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "efficientfixtures": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "smartwatermeters": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "dualflush": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "rrwh": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "srwh": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bwr": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "gwr": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "exteriorPaints": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "interiorPaints": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "structuralsteel": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "concrete": {
                        "enabled": true,
                        "parameters": {},
                        "concrete": {
                            "parameters": {
                                "TypeOfConcrete": "Portland Pozzolana Cement (PPC)",
                                "CapexMultiplier": 4500,
                                "MultiplierSource": "DEFAULT",
                                "ConcreteDensity": 2500
                            }
                        }
                    },
                    "livinganddining": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bedroom": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "kitchen": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "bathroom": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "utilityandbalcony": {
                        "enabled": true,
                        "parameters": {}
                    },
                    "custom": {}
                },
                "vendor": {
                    "bwr": {
                        "parameters": {
                            "capexMultiplier": 50000,
                            "resourceMultiplier": 85,
                            "areaMultiplier": 3.8
                        }
                    },
                    "sni": {
                        "parameters": {}
                    },
                    "solarwaterheater": {
                        "parameters": {
                            "capexMultiplier": 118,
                            "resourceMultiplier": 15,
                            "areaMultiplier": 0.03
                        }
                    },
                    "wwb": {
                        "parameters": {
                            "capexMultiplier": 4000,
                            "resourceMultiplier": 0.2,
                            "areaMultiplier": 0.056
                        }
                    },
                    "casp": {
                        "parameters": {
                            "capexMultiplier": 1,
                            "resourceMultiplier": 1,
                            "areaMultiplier": 2
                        }
                    },
                    "cro": {
                        "parameters": {
                            "capexMultiplier": 25,
                            "resourceMultiplier": 0.7,
                            "areaMultiplier": 20
                        }
                    },
                    "dualflush": {
                        "parameters": {
                            "capexMultiplier": 7000,
                            "resourceMultiplier": 18,
                            "areaMultiplier": 1
                        }
                    },
                    "smartwatermeters": {
                        "parameters": {
                            "capexMultiplier": 12000,
                            "resourceMultiplier": 7,
                            "areaMultiplier": 0
                        }
                    },
                    "gwr": {
                        "parameters": {
                            "capexMultiplier": 50000,
                            "resourceMultiplier": 85,
                            "areaMultiplier": 3.8
                        }
                    },
                    "rrwh": {
                        "parameters": {}
                    },
                    "srwh": {
                        "parameters": {}
                    },
                    "solarpv": {
                        "parameters": {
                            "capexMultiplier": 45000,
                            "resourceMultiplier": 4.4,
                            "areaMultiplier": 10
                        }
                    },
                    "efficientfixtures": {
                        "parameters": {
                            "capexMultiplier": 89,
                            "resourceMultiplier": 10,
                            "areaMultiplier": 0
                        }
                    }
                },
                "userInput": {
                    "NumberOfYears": null
                },
                "iip": {
                    "id": "661a393bb77e55e58247badf",
                    "projectID": "661a393bb77e55e58247baa7",
                    "allmappings": []
                }
            }
        }
    }
}